// import React, { useState, useEffect } from 'react';

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [nestedToggle, setNestedToggle] = useState({});
//     const [data, setData] = useState([]);

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const updateNestedToggle = (nestedId) => {
//         setNestedToggle((prevState) => ({
//             ...prevState,
//             [nestedId]: !prevState[nestedId],
//         }));
//     };

//     // Correct the typo here from 'ususeEffect' to 'useEffect'
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch('your-api-url');
//                 const data = await response.json();
//                 setData(data); // Update the state with fetched data
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
//         fetchData();
//     }, []);

   

//     const listContent = [
        
//         {
//             id: 1,
//             title: "Industrial Revolutions and Their Impact",
//             content: null,
//             nestedContent: [
//               // Your nested content goes here (if any)
//             ],
//           },
//         {
//             id: 2,
//             title: "The 1st Industrial Revolution: Mechanization and Steam Power ",
//             content: null,
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Key Innovations  ",
//                     dummyText: ["*		Mechanization: Introduction of machines in manufacturing.","*		Steam Engine: Development of steam-powered engines by James Watt."	],
//                 },
//                 { 
//                     id: 2,
//                     text: "•		 Impact ",
//                     dummyText: ["*					Growth of the textile industry through mechanized production.", "*				Introduction of steam-powered trains and ships for efficient transportation", "*		Urbanization: Migration from rural to urban areas for factory work.",],
//                 },
                
//                  {
//                     id: 3,
//                      text: "• Relevance to Engineers: ",
//                      dummyText: ["*	`	Introduction of mechanical engineering concepts like gears, levers, and power systems.","*		Basics of thermodynamics applied in steam engines.","*Case Example: The role of the steam engine in revolutionizing textile production"],
//                  },
                
//             ],
//         },
//         {
//             id: 3,
//             title: " The 2nd Industrial Revolution: Mass Production and Electrification  ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	 	Key Innovations: ",
//                     dummyText: ["*				Mass Production: Introduction of assembly lines (e.g., Henry Ford's automobile manufacturing).", "*	Electrification: Use of electricity for factories, homes, and cities.",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Impact",
//                     dummyText: ["*	Growth of industries like automobiles, steel, and chemical manufacturing.","*•	Improved urban infrastructure with power grids and telecommunication.","*	Increased affordability and availability of consumer goods."],
//                 },
//                 {
//                     id: 3,
//                     text: "•	 	Discussion Activity: ",
//                     dummyText: ["*			Analyse how Henry Ford's assembly line changed production efficiency",],
//                 },
               
                
//             ],
//         },
//         {
//             id: 4,
//             title: "The 3rd Industrial Revolution: Digital Revolution and Automation   ",
           
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Key Innovations: ",
//                     dummyText: ["*				Digital Systems: Shift from analog to digital technologies (computers, software, and communication systems).", "*		Automation: Integration of robotics and programmable systems in manufacturing.", ],
//                 },
//                 {
//                     id: 2,
//                     text: "•		Impact:  ",
//                     dummyText: ["*				Creation of the IT industry and digital communication systems.","*		Increased productivity through robotics and automated processes.","*	Introduction of the internet for global connectivity."],
//                 },
//                 {
//                     id: 3,
//                     text: "•		Relevance to Engineers: ",
//                     dummyText: ["*					Development of computer science, programming, and electronics.","*		•	Use of PLC (Programmable Logic Controllers) in industrial automation.","*Case Example: How automation improved assembly line precision and efficiency in manufacturing."],
//                 },
                
               
//             ],
//         },
//         {
//             id: 5,
//             title: "The 4th Industrial Revolution: Industry 4.0 and Smart Technologies    ",
//          dummyText: [,],

//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Key Innovations: ",
//                      dummyText: ["*		Cyber-Physical Systems: Integration of digital and physical processes.","*		Internet of Things (IoT): Smart devices interconnected for real-time data exchange.","*	Artificial Intelligence (AI) and Big Data: Enhanced decision-making and predictive analytics."],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Impact: ",
//                     dummyText: ["*					Development of smart factories with real-time monitoring and optimization.","	*	Advancements in healthcare (e.g., personalized medicine) and renewable energy..","*•	Enhanced supply chain management using IoT and AI."],
//                 },
//                 {
//                     id: 3,
//                     text: "•	 	Relevance to Engineers:",
//                     dummyText: ["*						Importance of IoT, AI, and data analysis for modern engineering solutions.","	*		Role of engineers in designing sustainable and smart systems.",],
//                 },
//                 {
//                     id: 4,
//                     text: "•	 	Interactive Discussion: ",
//                     dummyText: ["*						Discuss key technologies of Industry 4.0 (IoT, AI, robotics) and their impact on ","	*		Future Trends: Overview of 5G, 3D printing, and renewable energy innovations.",],
//                 },
//             ],
//         },
        
       
//     ];

//     return (
//         <div className="container my-5">
//             <h2
//                 className="fw-bold mb-4"
//                 style={{ color: "#000", fontFamily: "Roboto", width: "100%", textAlign: "center",marginTop:"-50px" }}
//             >
//                 PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
//             </h2>

//             <div className="list-tab" style={{ width: "100%", padding: 0 }}>
//                 {listContent.map(({ id, title, content, nestedContent }) => (
//                     <div key={id} className="mb-3" style={{ width: "100%" }}>
//                         <div
//                             className="p-3 bg-light rounded cursor-pointer d-flex justify-content-between align-items-center"
//                             onClick={() => updateToggle(id)}
//                             style={{
//                                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                                 width: "100%",
//                             }}
//                         >
//                             <span className="fw-bold" style={{ fontFamily: "Roboto", fontSize: "20px" }}>
//                                 {title}
//                             </span>
//                             <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>
//                         </div>
//                         {toggle === id && (
//                             <div className="mt-2 px-4">
//                                 {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
//                                 {nestedContent.map(({ id: nestedId, text, dummyText }) => (
//                                     <div key={nestedId} className="my-2">
//                                         <div
//                                             className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
//                                             onClick={() => updateNestedToggle(nestedId)}
//                                             style={{ border: "1px solid #ddd" }}
//                                         >
//                                             <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
//                                             <span style={{ fontSize: "18px" }}>
//                                                 {nestedToggle[nestedId] ? "-" : "+"}
//                                             </span>
//                                         </div>
//                                         {nestedToggle[nestedId] && (
//                                             <div className="mt-2 px-3">
//                                                 <ul style={{ fontFamily: "Roboto", color: "#555" }}>
//                                                     {dummyText.map((item, index) => (
//                                                         <li key={index}>{item}</li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;




import React, { useState, useEffect } from 'react';

const LearningPathTFD = () => {
    const [toggle, setToggle] = useState(null);
    const [nestedToggle, setNestedToggle] = useState({});
    const [data, setData] = useState([]);

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const updateNestedToggle = (nestedId) => {
        setNestedToggle((prevState) => ({
            ...prevState,
            [nestedId]: !prevState[nestedId],
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('your-api-url');
                const data = await response.json();
                setData(data); // Update the state with fetched data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const listContent = [
        {
            id: 1,
            title: " INDUSTRIAL REVOLUTIONS AND THEIR IMPACT",
            content: null,
            nestedContent: [],
        },
        {
                        id: 1,
                        title: "The 1st Industrial Revolution: Mechanization and Steam Power ",
                        content: null,
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Key Innovations  ",
                                dummyText: ["*		Mechanization: Introduction of machines in manufacturing.","*		Steam Engine: Development of steam-powered engines by James Watt."	],
                            },
                            { 
                                id: 2,
                                text: "•		 Impact ",
                                dummyText: ["*					Growth of the textile industry through mechanized production.", "*				Introduction of steam-powered trains and ships for efficient transportation", "*		Urbanization: Migration from rural to urban areas for factory work.",],
                            },
                            
                             {
                                id: 3,
                                 text: "• Relevance to Engineers: ",
                                 dummyText: ["*	`	Introduction of mechanical engineering concepts like gears, levers, and power systems.","*		Basics of thermodynamics applied in steam engines.","*Case Example: The role of the steam engine in revolutionizing textile production"],
                             },
                            
                        ],
                    },
                    {
                        id: 2,
                        title: " The 2nd Industrial Revolution: Mass Production and Electrification  ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	 	Key Innovations: ",
                                dummyText: ["*				Mass Production: Introduction of assembly lines (e.g., Henry Ford's automobile manufacturing).", "*	Electrification: Use of electricity for factories, homes, and cities.",],
                            },
                            {
                                id: 2,
                                text: "•	 	Impact",
                                dummyText: ["*	Growth of industries like automobiles, steel, and chemical manufacturing.","*•	Improved urban infrastructure with power grids and telecommunication.","*	Increased affordability and availability of consumer goods."],
                            },
                            {
                                id: 3,
                                text: "•	 	Discussion Activity: ",
                                dummyText: ["*			Analyse how Henry Ford's assembly line changed production efficiency",],
                            },
                           
                            
                        ],
                    },
                    {
                        id: 3,
                        title: "The 3rd Industrial Revolution: Digital Revolution and Automation   ",
                       
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Key Innovations: ",
                                dummyText: ["*				Digital Systems: Shift from analog to digital technologies (computers, software, and communication systems).", "*		Automation: Integration of robotics and programmable systems in manufacturing.", ],
                            },
                            {
                                id: 2,
                                text: "•		Impact:  ",
                                dummyText: ["*				Creation of the IT industry and digital communication systems.","*		Increased productivity through robotics and automated processes.","*	Introduction of the internet for global connectivity."],
                            },
                            {
                                id: 3,
                                text: "•		Relevance to Engineers: ",
                                dummyText: ["*					Development of computer science, programming, and electronics.","*		•	Use of PLC (Programmable Logic Controllers) in industrial automation.","*Case Example: How automation improved assembly line precision and efficiency in manufacturing."],
                            },
                            
                           
                        ],
                    },
                    {
                        id: 4,
                        title: "The 4th Industrial Revolution: Industry 4.0 and Smart Technologies    ",
                     dummyText: [,],
            
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Key Innovations: ",
                                 dummyText: ["*		Cyber-Physical Systems: Integration of digital and physical processes.","*		Internet of Things (IoT): Smart devices interconnected for real-time data exchange.","*	Artificial Intelligence (AI) and Big Data: Enhanced decision-making and predictive analytics."],
                            },
                            {
                                id: 2,
                                text: "•	 	Impact: ",
                                dummyText: ["*					Development of smart factories with real-time monitoring and optimization.","	*	Advancements in healthcare (e.g., personalized medicine) and renewable energy..","*•	Enhanced supply chain management using IoT and AI."],
                            },
                            {
                                id: 3,
                                text: "•	 	Relevance to Engineers:",
                                dummyText: ["*						Importance of IoT, AI, and data analysis for modern engineering solutions.","	*		Role of engineers in designing sustainable and smart systems.",],
                            },
                            {
                                id: 4,
                                text: "•	 	Interactive Discussion: ",
                                dummyText: ["*						Discuss key technologies of Industry 4.0 (IoT, AI, robotics) and their impact on ","	*		Future Trends: Overview of 5G, 3D printing, and renewable energy innovations.",],
                            },
                        ],
                    },
                    
                   
                ];

    return (
        <div className="container my-5">
            <h2
                className="fw-bold mb-4"
                style={{
                    color: "#000",
                    fontFamily: "Roboto",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "-50px",
                }}
            >
                PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
            </h2>

            <div className="list-tab" style={{ width: "100%", padding: 0 }}>
                {listContent.map(({ id, title, content, nestedContent }, index) => (
                    <div key={id} className="mb-3" style={{ width: "100%" }}>
                        <div
                            className={`p-3 rounded cursor-pointer d-flex justify-content-between align-items-center ${
                                index === 0 ? "bg-white" : "bg-light"
                            }`}
                            onClick={() => index !== 0 && updateToggle(id)}
                            style={{
                                boxShadow: index === 0 ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "100%", 
                                cursor: index === 0 ? "default" : "pointer",
                            }}
                        >
                            <span
                                className={`fw-bold`}
                                style={{
                                    fontFamily: "Roboto",
                                    fontSize: index === 0 ? "19px" : "20px",
                                    color: index === 0 ? "#0072FF" : undefined,
                                }}
                            >
                                {title}
                            </span>
                            {index !== 0 && (
                                <span style={{ fontSize: "20px" }}>
                                    {toggle === id ? "-" : "+"}
                                </span>
                            )}
                        </div>
                        {/* Add line below first item */}
                        {/* {index === 0 && (
                            <div
                                style={{
                                    height: "1px",
                                    backgroundColor: "orange",
                                    width: "20%",
                                     margin: "-13px",
                                     marginLeft: '50px',
                                }}
                            ></div>
                        )} */}
                        {toggle === id && index !== 0 && (
                            <div className="mt-2 px-4">
                                {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
                                {nestedContent.map(({ id: nestedId, text, dummyText }) => (
                                    <div key={nestedId} className="my-2">
                                        <div
                                            className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
                                            onClick={() => updateNestedToggle(nestedId)}
                                            style={{ border: "1px solid #ddd" }}
                                        >
                                            <span
                                                style={{
                                                    fontFamily: "Roboto",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                {text}
                                            </span>
                                            <span style={{ fontSize: "18px" }}>
                                                {nestedToggle[nestedId] ? "-" : "+"}
                                            </span>
                                        </div>
                                        {nestedToggle[nestedId] && (
                                            <div className="mt-2 px-3">
                                                <ul
                                                    style={{
                                                        fontFamily: "Roboto",
                                                        color: "#555",
                                                    }}
                                                >
                                                    {dummyText.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LearningPathTFD;





