import React from 'react';
import fourdays from "../img/virtualroboticsbanners/whats-app-imge-removebg-preview (1).png"; // Importing the WhatsApp image

const WhatsAppChat = () => {
  const phoneNumber = "8096120173"; // Replace with your WhatsApp number
  const message = "Hello! How can we help you today?"; // Optional predefined message

  // Handle click event to open WhatsApp chat
  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <div className="whatsapp-chat">
      <img
        src={fourdays} // Using the provided WhatsApp image
        alt="WhatsApp"
        width="60"
        height="60"
        className="whatsapp-button"
        onClick={handleClick} // Trigger WhatsApp chat when the image is clicked
      />
      
      <style jsx>{`
        .whatsapp-chat {
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 1000;
        }

        .whatsapp-button {
          border-radius: 50%;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          transition: transform 0.2s ease-in-out;
        }

        .whatsapp-button:hover {
          transform: scale(1.1); /* Slightly increase the size on hover */
        }
      `}</style>
    </div>
  );
};

export default WhatsAppChat;
