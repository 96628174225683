import React, { useState, useEffect } from 'react';

const RegistrationForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    if (!name || !email || !password || !gender || !dateOfBirth || !mobileNumber) {
      setError('Please fill in all fields.');
      return;
    }
  
    const userData = {
      name,
      emailId: email,
      mobileNumber,
      password,
      gender,
      dateOfBirth,
    };
  
    console.log('User data being sent:', userData);
  
    try {
      const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
  
      console.log('Response received:', response);
  
      const text = await response.text();
      console.log('Raw response text:', text);
  
      // Check if the response is a valid JSON string
      let data;
      try {
        data = JSON.parse(text);
      } catch {
        // If parsing fails, treat the response as plain text
        if (response.ok) {
          setSuccess(text);
          alert(text);
          return;
        } else {
          throw new Error('Invalid response from server');
        }
      }
  
      console.log('Parsed response data:', data);
  
      if (data.success) {
        setSuccess(data.message || 'Registration successful!');
        alert(data.message || 'Registration successful!');
      } else {
        setError(data.message || 'Registration failed. Please try again.');
        alert(data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An error occurred. Please try again.');
      alert(error.message || 'An error occurred. Please try again.');
    }
  };
  
  
  
  

  useEffect(() => {
    // Set body background color when the component mounts
    document.body.style.backgroundColor = '#f0f4f8';

    // Scroll to the top smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    // Cleanup background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <div className="container" style={{ marginTop: '150px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Registration Form</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="inputGroup">
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input"
          />
        </div>
        <div className="inputGroup">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
        </div>
        <div className="inputGroup">
          <label>Number:</label>
          <input type="text" placeholder="Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} className="input"  />
        </div>
        <div className="inputGroup">
          <label>Password:</label>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="showPasswordBtn"
          >
            {/* <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i> */}
          </button>
        </div>
      

        <div className="inputGroup">
          <label>Gender:</label>
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className="input"
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="inputGroup">
          <label>Date of Birth:</label>
          <input
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            className="input"
          />
        </div>

        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <button type="submit" className="button">Register</button>
      </form>

      <style>{`
        .container {
          max-width: 400px;
          margin: 50px auto;
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          font-family: Arial, sans-serif;
          background-color: #fff;
        }

        .form {
          display: flex;
          flex-direction: column;
        }

        .inputGroup {
          margin-bottom: 8px;
          text-align: center;
        }

        .inputGroup label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        .input {
          width: 100%;
          padding: 8px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }

        .showPasswordBtn {
          background: none;
          border: none;
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 30px;
        }

        .button {
          padding: 10px;
          background-color: #fb9100;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .button:hover {
          background-color: #218838;
        }

        .error {
          color: red;
          text-align: center;
        }

        .success {
          color: green;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default RegistrationForm;
