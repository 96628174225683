// import React, { useState, useEffect } from "react";
// import axios from "axios"; // Ensure axios is installed
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSpinner } from '@fortawesome/free-solid-svg-icons';


// const ShoppingCart = () => {
//   // Initialize the cart from localStorage or use an empty array
//   const [cart, setCart] = useState(() => {
//     const userId = localStorage.getItem("userId");
   
//     const storedCarts = localStorage.getItem("carts");
   
//     const userCarts = storedCarts ? JSON.parse(storedCarts) : {};
   
//     // Ensure that the cart is always an array, even if no cart exists for the userId
//     return Array.isArray(userCarts[userId]) ? userCarts[userId] : [];
//   });
//   const [showEnquiryPopup, setShowEnquiryPopup] = useState(false); // State to control the popup visibility
//   const [selectedCourse, setSelectedCourse] = useState(null); 
//   const [coupon, setCoupon] = useState("");
//   const [fileName, setFileName] = useState("Resume Upload");
//   const [sources, setSources] = useState([]);
//   const [options, setOptions] = useState([]); 
//   const [isLoading, setIsLoading] = useState(false);
//   const [name, setName] = useState('');
//   const [formData, setFormData] = useState({
//     program: "",
//     course: "",
//     date:"",                   // Include the specific date
//     time:"",    
//     name: "",
//     email: "",
//     mobile: "",
//     Source:"",
//     councellor:"",
//     reference:"",
//   });
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };


  


//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     setFileName(file ? file.name : "Resume Upload");
//     if (file) {
//       console.log('Selected file:', file);
  
//       const reader = new FileReader();
//       reader.onload = () => {
//         setFormData((prevFormData) => ({
//           ...prevFormData,
//           resume: reader.result.split(',')[1], // Store the Base64 string (exclude the prefix)
//         }));
//       };
//       reader.readAsDataURL(file); // Read the file as a Data URL (Base64 encoded)
//     }
//   };
  

//   useEffect(() => {
//     const fetchSources = async () => {
//       try {
//         const response = await axios.get(
//           "http://10.10.20.9:7071/IIA-Website-1.0.0/enum/sources"
//         );
//         if (Array.isArray(response.data)) {
//           setOptions(response.data); // Populate options with the API response
//         } else {
//           console.error("Unexpected API response format");
//         }
//       } catch (error) {
//         console.error("Error fetching sources:", error.message);
//       }
//     };

//     fetchSources();
//   }, []);




//   useEffect(() => {
//     // Get the name from localStorage when the component loads
//     const storedName = localStorage.getItem('name');
//     if (storedName) {
//       setName(storedName); // Set the name state if it exists in localStorage
//     }
//   }, []); 


//   useEffect(() => {
//     // Fetch source options when the component mounts
//     const fetchSources = async () => {
//       try {
//         const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/enum/sources');
//         if (response.ok) {
//           const data = await response.json();
//           setSources(data); // Assuming the API returns an array of source options
//         } else {
//           console.error('Failed to fetch sources:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching sources:', error);
//       }
//     };

//     fetchSources();
//   }, []);


//   useEffect(() => {
//     const userId = localStorage.getItem("userId"); // Get the current user ID
//     const storedCarts = localStorage.getItem("carts"); // Retrieve the stored carts from localStorage
//     const userCarts = storedCarts ? JSON.parse(storedCarts) : {}; // Parse or initialize as an empty object
    
//     // Ensure the cart is always an array, even if no cart exists for the userId
//     const cartItems = Array.isArray(userCarts[userId]) ? userCarts[userId] : [];
//     setCart(cartItems); // Initialize cart state
    
//     // Retrieve other user data from localStorage
//     const storedName = localStorage.getItem("name") || ""; // Default to empty string if not found
//     const storedEmail = localStorage.getItem("emailId") || "";
//     const storedMobile = localStorage.getItem("phoneno") || "";
  
//     // Get the latest cart item (last index)
//     const latestItem = cartItems[cartItems.length - 1] || {};
  
//     // Initialize the formData state immediately based on retrieved data
//     setFormData({
//       program: latestItem.type || "",
//       name: storedName,
//       email: storedEmail,
//       mobile: storedMobile,
//       date: latestItem.date || "",
//       time: latestItem.time || "",
//       course: latestItem.courses || "",
//     });
//   }, []); // Run once on mount
  
//   // Update formData when cart changes
//   useEffect(() => {
//     const latestItem = cart[cart.length - 1] || {}; // Get the latest cart item (last index)
//     setFormData((prev) => ({
//       ...prev,
//       program: latestItem.type || "",
//       date: latestItem.date || "",
//       time: latestItem.time || "",
//       course: latestItem.courses || "",
//     }));
//   }, [cart]);
  
  
//   // Sync cart state to localStorage whenever it changes
//   useEffect(() => {
//     const userId = localStorage.getItem("userId");
//     const storedCarts = localStorage.getItem("carts");
//     const userCarts = storedCarts ? JSON.parse(storedCarts) : {};
  
//     // Update the cart for the specific userId
//     userCarts[userId] = cart;
  
//     // Store the updated carts in localStorage
//     localStorage.setItem("carts", JSON.stringify(userCarts));
//   }, [cart]);
  
//   // Calculate the total price of items in the cart
//   const total = cart.reduce((sum, item) => sum + item.price, 0);
  
//   // Handle removal of an item from the cart
//   const handleRemove = (id) => {
//     const updatedCart = cart.filter((item, index) => index !== id);
//     setCart(updatedCart); // This will trigger formData update via useEffect
//   };
  

//   // Helper function to generate stars based on rating
//   const generateStars = (rating, totalRatings) => {
//     const fullStars = Math.floor(rating); // Count of full stars
//     const halfStar = rating % 1 >= 0.5 ? 1 : 0; // Check for a half star
//     const emptyStars = 5 - fullStars - halfStar; // Remaining empty stars
  
//     return (
//       <div style={styles.ratingContainer}>
//         {/* Display the rating number (e.g., 4.6) */}
//         <span class="axis" style={styles.ratingNumber}>{rating.toFixed(1)}</span>
  
//         {/* Render the stars */}
//         <span  style={styles.starsContainer}>
//           {[...Array(fullStars)].map((_, index) => (
//             <span key={`full-${index}`} style={styles.fullStar}>
//               ★
//             </span>
//           ))}
  
//           {halfStar === 1 && (
//             <span style={styles.halfStar}>
//               ★
//             </span>
//           )}
  
//           {[...Array(emptyStars)].map((_, index) => (
//             <span key={`empty-${index}`} style={styles.emptyStar}>
//               ★
//             </span>
//           ))}
//         </span>
  
//         {/* Display the total ratings text */}
//         <span class="axis" style={styles.totalRatings}>{totalRatings}</span>
//       </div>
//     );
//   };

//   const handleEnquirySubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true); // Show loader
  
//     const userId = localStorage.getItem('userId');
  
//     const dateParts = formData.date.split('-');  // Updated to handle 'DD-MM-YYYY'
    
//     // Ensure valid date format
//     if (dateParts.length !== 3) {
//       alert('Invalid date format. Please use DD-MM-YYYY.');
//       setIsLoading(false); // Hide loader
//       return;
//     }
  
//     const day = dateParts[0];
//     const month = dateParts[1] - 1; // JavaScript months are 0-indexed (0-11)
//     const year = dateParts[2];
//     const dateObj = new Date(year, month, day);
    
//     if (isNaN(dateObj)) {
//       alert('Invalid date provided');
//       setIsLoading(false); // Hide loader
//       return;
//     }
  
//     // Convert to YYYY/MM/DD format
//     const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
  
//     // Time formatting logic (unchanged)
//     const timeParts = formData.time.split(' ');
//     let [hours, minutes] = timeParts[0].split(':');
//     const period = timeParts[1];
//     if (period === 'PM' && hours !== '12') {
//       hours = (parseInt(hours, 10) + 12).toString();
//     } else if (period === 'AM' && hours === '12') {
//       hours = '00';
//     }
//     const formattedTime = `${hours}:${minutes}:00`;
  
//     let programValue = formData.program;
//     if (programValue.includes('%')) {
//       programValue = programValue.replace('%', 'PERCENT');
//     }
  
//     const payload = {
//       program: programValue,
//       course: formData.course,
//       userId: parseInt(userId, 10),
//       startDate: formattedDate,  // Use the new date format
//       startTime: formattedTime,
//       source: formData.Source,
//       councellor: formData.councellor,
//       reference: formData.reference,
//       resume: formData.resume,
//     };

//     console.log(payload)
  
//     try {
//       const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/enquiryform/add', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(payload),
//       });
  
//       if (response.ok) {
//         const result = await response.json();
//         console.log('Enquiry submitted successfully:', result);
//         alert('Enquiry submitted successfully!');
//       } else {
//         console.error('Error submitting enquiry:', response.statusText);
//         alert('Failed to submit enquiry. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert('An error occurred. Please try again later.');
//     } finally {
//       setIsLoading(false); // Hide loader
//     }
//   };
  

  
  


//   return (
//     <div className="vertical" style={styles.container}>
//     {/* Left Section - Courses */}
//     <div className="widths" style={styles.leftSection}>
//       <span style={{ display: 'inline-block' }}>
//         <span  className="fonts" style={styles.heading}>Shopping Cart</span>
//         <span class="fonts" style={styles.courseCount}>{cart.length} Courses in Cart</span>
//       </span>
  
//       {cart.map((course, index) => (
//   <div
//     key={index}
//     style={{
//       ...styles.courseContainer,  // Add container style
//       // Add border for the first item
//     }}
//   >
//     <div style={styles.courseCard}>
//       <img
//         src={course.bannerImage || course.image}
//         alt={course.type}
//         style={{
//           ...styles.image,
//           marginTop: index === 0 ? "10px" : "0",  // Apply marginTop only to the first image
//         }}
//       />
//       <div style={styles.details}>
//         <div style={styles.topRow}>
//           <div>
//             <h3 class="axis mar" style={{ ...styles.title, textAlign: 'left', marginTop: index === 0 ? "21px" : "0", }}>{course.courses}</h3>
//             <h3 class="axis" style={{ ...styles.title, textAlign: 'left', fontSize: '14px', marginTop: '14px !important' }}>{course.type || course.title}</h3>

//             {/* Display stars on the left column */}
//             {generateStars(4.6, "(1,400 ratings)")}
//           </div>
//           <div style={styles.rightAligned}>
//             {course.originalPrice && (
//               <span style={styles.originalPrice}>₹{course.originalPrice}</span>
//             )}
//             {/* <button class="axis"
//               style={styles.removeButton}
//               onClick={() => {
//                 const storedName = localStorage.getItem('name');
//                 const storedEmail = localStorage.getItem('emailId');
//                 const storedMobile = localStorage.getItem('phoneno');
                
//                 // Update formData with course info and name, email, mobile from localStorage
//                 setFormData({
//                   ...formData,
//                   program: course.title || course.type || "Course Title",
//                   name: storedName || '',       // Pass the name from localStorage
//                   email: storedEmail || '',     // Pass the email from localStorage
//                   mobile: storedMobile || '', 
//                   date:course.date,                   // Include the specific date
//                   time:course.time,      // Pass the mobile number from localStorage
//                 });

//                 // Update formData with course info and name from localStorage
//                 setSelectedCourse(course);
//               }}
//             >
//               Enquiry
//             </button> */}

//             <button class="axis"
//               style={styles.removeButton}
//               // onClick={() =>}
//             >
//               WishList
//             </button>
//             <button class="axis"
//               style={styles.removeButton}
//               onClick={() => handleRemove(index)}
//             >
//               Remove
//             </button>
//           </div>
//         </div>

//         {/* Star Rating below course title */}
//       </div>
//     </div>
//   </div>
// ))}

//     </div>
  
//     {/* Right Section - Enquiry Form */}
//     <div
//   className="width"
//   style={{ ...styles.rightSection, maxHeight: '400px', overflowY: 'auto', position: 'relative' }}
// >
//   {isLoading && (
//     <div style={styles.loaderOverlay}>
//       <FontAwesomeIcon icon={faSpinner} spin size="2x" color="#9b59b6" />
//     </div>
//   )}
//   <h3 className="fonts" style={{ fontSize: '32px' }}>Enquiry</h3>
//   <form
//     onSubmit={handleEnquirySubmit}
//     style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}
//   >
//     <input
//       type="text"
//       name="Course"
//       value={formData.course}
//       onChange={handleInputChange}
//       placeholder="Course"
//       style={styles.input}
//       readOnly
//     />
//     <input
//       type="text"
//       name="Program"
//       value={formData.program}
//       onChange={handleInputChange}
//       placeholder="Program"
//       style={styles.input}
//       readOnly
//     />
//     <input
//       type="text"
//       name="Date"
//       value={formData.date}
//       onChange={handleInputChange}
//       placeholder="Date"
//       style={styles.input}
//       readOnly
//       required
//     />
//     <input
//       type="text"
//       name="time"
//       value={formData.time}
//       onChange={handleInputChange}
//       placeholder="Time"
//       style={styles.input}
//       readOnly
//       required
//     />
//     <input
//       type="text"
//       name="name"
//       value={formData.name}
//       onChange={handleInputChange}
//       placeholder="Name"
//       style={styles.input}
//       readOnly
//       required
//     />
//     <input
//       type="email"
//       name="email"
//       value={formData.email}
//       onChange={handleInputChange}
//       placeholder="Email"
//       style={styles.input}
//       readOnly
//       required
//     />
//     <input
//       type="tel"
//       name="mobile"
//       value={formData.mobile}
//       onChange={handleInputChange}
//       placeholder="Mobile Number"
//       style={styles.input}
//       readOnly
//       required
//     />
//     <select
//       name="Source"
//       value={formData.Source}
//       onChange={handleInputChange}
//       style={styles.input}
//       required
//     >
//       <option value="" disabled>
//         Source
//       </option>
//       {sources.map((source, index) => (
//         <option key={index} value={source.value}>
//           {source}
//         </option>
//       ))}
//     </select>
//     <input
//       type="text"
//       name="councellor"
//       value={formData.councellor}
//       onChange={handleInputChange}
//       placeholder="Counsellor Name"
//       style={styles.input}
//       required
//     />
//       <input
//       type="text"
//       name="reference"
//       value={formData.reference}
//       onChange={handleInputChange}
//       placeholder="Reference Name"
//       style={styles.input}
//       required
//     />
//     <div>
//       {/* Label for the custom file input */}
//       <label htmlFor="file-upload" style={styles.customField}>
//         {/* <button type="button" style={styles.button}>Browse</button> */}
//         <span style={styles.placeholder}>{fileName}</span>
//       </label>
//       {/* Hidden file input */}
//       <input
//         id="file-upload"
//         type="file"
//         name="uploadFile"
//         onChange={handleFileChange}
//         style={styles.fileInput}
//         required
//       />
//     </div>

//     <button type="submit" style={styles.submitButton}>
//       Send
//     </button>
//   </form>
// </div>
// </div>
  







//   );
// };
   



  




// const styles = {

//   starsContainer:{
//    marginLeft:'3px'
//   },

//   fileInput: {
//     display: "none", // Completely hide the native file input
//   },
//   customField: {
//     display: "flex",
//     alignItems: "center", // Vertically align items
//     justifyContent: "flex-start", // Align items close together
//     gap: "5px", // Reduce space between items
//     border: "1px solid #ccc",
//     borderRadius: "5px",
//     padding: "10px",
//     cursor: "pointer",
//     width: "100%",
//     maxWidth: "400px",
//     backgroundColor: "#f9f9f9",
//   },
//   placeholder: {
//     color: "#888",
//     fontSize: "14px",
//   },
//   button: {
//     padding: "5px 10px", // Button padding
//     fontSize: "14px",
//     border: "none",
//     backgroundColor: "#007bff",
//     color: "#fff",
//     borderRadius: "3px",
//     cursor: "pointer",
//   },

//   courseContainer: {
//     height:'130px',
//     display: "flex",
//     flexDirection: "column",
//     padding: "15px",
//     backgroundColor: "#fff",
//     borderRadius: "8px",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Optional shadow for a clean container look
//     marginBottom: "20px", // Spacing between items
//   },
//   container: {
//     marginTop: "100px",
//     display: "flex",
//     justifyContent: "space-between",
//     padding: "20px",
//     fontFamily: "Arial, sans-serif",
//     backgroundColor: "#fff",
//   },
//   leftSection: {
//     width: "65%",
//   },
//   heading: {
//     marginLeft:'5px',
//     fontSize: "32px",
//     marginBottom: "10px",
//   },
//   courseCount: {
//     marginLeft:'20px',
//     fontSize: "21px",
//     color: "#555",
//     // marginRight: "80%",
//     marginBottom: "50px",
//   },
//   courseCard: {
//     display: "flex",
//     marginBottom: "20px",
//     // borderBottom: "2px solid #ddd",
//     paddingBottom: "20px",
//   },
//   image: {
//     width: "120px",
//     height: "90px",
//     marginRight: "20px",
//   },
//   details: {
//     flex: 1,
//     display: "flex",
//     flexDirection: "column",
//   },
//   topRow: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   rightSection: {
//     width: "30%",
//     padding: "20px",
//     backgroundColor: "#f9f9f9",
//     borderRadius: "5px",
//     border: "1px solid #ddd",
//   },

//   title: {
//     fontSize: "16px",
//     marginTop:'8px',
//     margin: "0",
//   },
//   rightAligned: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "flex-end",
//   },
//   discountedPrice: {
//     fontSize: "18px",
//     fontWeight: "bold",
//     color: "#2ecc71",
//     marginBottom: "5px",
//   },
//   originalPrice: {
//     textDecoration: "line-through",
//     color: "#aaa",
//     marginBottom: "5px",
//   },
//   removeButton: {
//     fontSize:'15px',
//     marginTop: "10px",
//     background: "none",
//     border: "none",
//     color: "#5022c3",
//     cursor: "pointer",
//   },
//   ratingContainer: {
//     marginTop: "20px",
//     display: "flex",
//   },
//   star: {
//     fontSize: "18px",
//     color: "#f39c12", // Gold color for stars
//     marginRight: "2px",
//   },
//   udemyRating: {
//     fontSize: "16px",
//     color: "#555",
//     marginTop: "5px",
//   },
//   ratingContainer: {
//     marginTop:'20px',
//     display: "flex",
//     alignItems: "center",
//     fontSize: "14px",
//   },
//   fullStar: {
//     color: "orange",
//     marginRight: "2px",
//   },
//   halfStar: {
//     color: "orange",
//     marginRight: "2px", // Styled as orange even for a half star
//     clipPath: "polygon(0 0, 50% 0, 50% 100%, 0 100%)", // Use CSS clipPath for a half-star effect
//   },
//   emptyStar: {
//     color: "#e4e5e9", // Light gray color for empty stars
//     marginRight: "2px",
//   },
//   ratingText: {
//     marginLeft: "8px",
//     color: "#555", // Text color
//   },
//   // Popup styles
//   popupContainer: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   loaderOverlay: {
//     position: "absolute",
//     top: '150px',
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(255, 255, 255, 0.7)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     zIndex: 1000,
//   },
//   popup: {
//     backgroundColor: "#fff",
//     padding: "20px",
//     borderRadius: "5px",
//     width: "300px",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between", // This ensures the button stays at the bottom
//   },
//   input: {
    
//     width: "100%",
//     padding: "10px",
//     marginBottom: "10px",
//     borderRadius: "5px",
//     border: "1px solid #ddd",
//   },
//   submitButton: {
//     width: "100%",
//     padding: "10px",
//     backgroundColor: "#9b59b6",
//     color: "#fff",
//     border: "none",
//     cursor: "pointer",
//     borderRadius: "5px",
//     marginTop: "10px", // This margin separates the button from the form fields
//   },
//   closeButton: {
//     width: "100%",
//     padding: "10px",
//     backgroundColor: "#ccc",
//     border: "none",
//     cursor: "pointer",
//     borderRadius: "5px",
//     marginTop: "10px",
//   },
// };



// export default ShoppingCart;



// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// const ShoppingCart = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });

//   const [cart, setCart] = useState([
//     {
//       id: 1,
//       image: "https://via.placeholder.com/150",
//       jobTitle: "Software Engineer",
//       workMode: "Hybrid",
//       ctc: "₹8,00,000",
//       location: "Bangalore",
//     },
//     {
//       id: 2,
//       image: "https://via.placeholder.com/150",
//       jobTitle: "Data Analyst",
//       workMode: "Remote",
//       ctc: "₹7,00,000",
//       location: "Delhi",
//     },
//   ]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     setTimeout(() => {
//       alert("Form Submitted!");
//       setIsLoading(false);
//     }, 2000);
//   };

//   return (
//     <div style={styles.container}>
//       {/* Left Column: Job Cards */}
//       <div style={styles.leftColumn}>
//         <div style={styles.cardContainer}>
//           {cart.map((job) => (
//             <div key={job.id} style={styles.card}>
//               <img src={job.image} alt={job.jobTitle} style={styles.cardImage} />
//               <div style={styles.cardDetails}>
//                 <h3>{job.jobTitle}</h3>
//                 <p><strong>Work Mode:</strong> {job.workMode}</p>
//                 <p><strong>CTC:</strong> {job.ctc}</p>
//                 <p><strong>Location:</strong> {job.location}</p>
//                 <div style={styles.buttonContainer}>
//                   <button style={styles.button}>Enquiry</button>
//                   <button style={styles.button}>Wishlist</button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Right Column: Forms */}
//       <div style={styles.rightColumn}>
//         <div style={styles.formContainer}>
//           {/* Register to Apply Form */}
//           <h3 style={styles.formTitle}>Register to Apply</h3>
//           <form onSubmit={handleFormSubmit}>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               placeholder="Email"
//               style={styles.input}
//               required
//             />
//             <input
//               type="password"
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//               placeholder="Password"
//               style={styles.input}
//               required
//             />
//             <button type="submit" style={styles.submitButton}>
//               {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Register"}
//             </button>
//           </form>

//           {/* Login to Apply Form */}
//           <h3 style={styles.formTitle}>Login to Apply</h3>
//           <form onSubmit={handleFormSubmit}>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               placeholder="Email"
//               style={styles.input}
//               required
//             />
//             <input
//               type="password"
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//               placeholder="Password"
//               style={styles.input}
//               required
//             />
//             <button type="submit" style={styles.submitButton}>
//               {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Login"}
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: "flex",
//     justifyContent: "space-between",
//     padding: "20px",
//     fontFamily: "Arial, sans-serif",
//     marginTop:'120px'
//   },
//   leftColumn: {
//     width: "65%",
//   },
//   cardContainer: {
//     display: "grid",
//     gridTemplateColumns: "1fr 1fr",
//     gap: "20px",
//   },
//   card: {
//     backgroundColor: "#fff",
//     borderRadius: "8px",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//     padding: "15px",
//     textAlign: "center",
//   },
//   cardImage: {
//     width: "100%",
//     height: "150px",
//     objectFit: "cover",
//     borderRadius: "8px",
//   },
//   cardDetails: {
//     marginTop: "10px",
//   },
//   buttonContainer: {
//     marginTop: "10px",
//   },
//   button: {
//     margin: "5px",
//     padding: "10px 20px",
//     backgroundColor: "#9b59b6",
//     color: "#fff",
//     border: "none",
//     cursor: "pointer",
//     borderRadius: "5px",
//   },
//   rightColumn: {
//     width: "30%",
//     padding: "20px",
//     backgroundColor: "#f9f9f9",
//     borderRadius: "5px",
//     border: "1px solid #ddd",
//   },
//   formContainer: {
//     marginBottom: "30px",
//   },
//   formTitle: {
//     fontSize: "18px",
//     marginBottom: "10px",
//     fontWeight: "bold",
//   },
//   input: {
//     width: "100%",
//     padding: "10px",
//     marginBottom: "10px",
//     borderRadius: "5px",
//     border: "1px solid #ddd",
//   },
//   submitButton: {
//     width: "100%",
//     padding: "10px",
//     backgroundColor: "#9b59b6",
//     color: "#fff",
//     border: "none",
//     cursor: "pointer",
//     borderRadius: "5px",
//   },
// };

// export default ShoppingCart;












import React, { useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import airtelLogo from "../img/aaaservices/Picture2.png";
import { color } from "@mui/system";
const ShoppingCart = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  

  

  // const popupStyles = {
  //     display: isPopupOpen ? 'block' : 'none',
  //     // Add other styles here...
  // };


  const handleEnquiry = (job) => {
    setSelectedJob(job);
    setFormData({
      ...formData,
      course: job.jobRole || "",
      program: job.jobDescription || "",
      workMode: job.workMode,
      skillsRequired:job.skillsRequired,
      mobile: "",
      Source: "",
      location:job.location,
      salary:job.salary,
      councellor: "",
      reference: "",
      uploadFile: null,
    });
  };
  
  const [fileName, setFileName] = useState("Resume Upload");
 
  const [cartJobs, setCartJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null); 

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleEnquirySubmit = (e) => {
    e.preventDefault();
    alert("Enquiry Submitted!");
    // Add logic for submitting the enquiry (e.g., API call)
  };

  const sources = [
    { id: 1, sourceName: "Source 1" },
    { id: 2, sourceName: "Source 2" },
  ];

  const cart = [
    {
      id: 1,
      image: "https://via.placeholder.com/150",
      jobTitle: "Software Engineer",
      jobDescription: "Developing and maintaining software applications, collaborating with cross-functional teams.",
      jobRole: "Full-stack Developer",
      workMode: "Hybrid",
      ctc: "₹8,00,000",
      location: "Bangalore",
      skillsRequired: "JavaScript, React, Node.js, HTML, CSS, SQL",
    },
    {
      id: 2,
      image: "https://via.placeholder.com/150",
      jobTitle: "Data Analyst",
      jobDescription: "Analyze data and provide insights to improve business strategies, work with various data sources.",
      jobRole: "Data Scientist",
      workMode: "Remote",
      ctc: "₹7,00,000",
      location: "Delhi",
      skillsRequired: "Python, SQL, Excel, Data Visualization, Machine Learning",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    const storedCartData = JSON.parse(localStorage.getItem("carts1")) || [];
    setCartJobs(storedCartData);
    if (storedCartData.length > 0) {
      setSelectedJob(storedCartData[0]); // Set the first job as default
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handlePopupToggle = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Job alert created for: ${email}`);
    setEmail(""); // Clear the input
    setIsPopupOpen(false); // Close the popup
  };


  
    
  
  

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  };
  

  const ratingStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#ffa500",
  };

  const detailsStyle = {
    fontSize: "14px",
    color: "#666",
    margin: "15px 0",
  };

  const descriptionStyle = {
    fontSize: "14px",
    color: "#444",
    marginBottom: "10px",
  };

  const skillsStyle = {
    fontSize: "12px",
    color: "#007bff",
    margin: "5px 0",
  };

  const dateStyle = {
    fontSize: "12px",
    color: "#aaa",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const saveStyle = {
    fontSize: "16px",
    cursor: "pointer",
    color: "#007bff",
  };
  


  return (
    <div style={styles.container}>
    {/* Left Column: Job Description Box */}
    <div className="jobbbd" style={styles.leftColumn}>
      {selectedJob ? (
        <div style={styles.descriptionBox}>
          <h3>{selectedJob.jobTitle || "Job Details"}</h3>
          <div style={styles.scrollableBox}>
          <h2 style={{ fontSize: "18px", fontWeight: "bold", margin: 0 }}>
          Backend Java Developer
        </h2>
        <img
          src={airtelLogo}
          alt="Airtel Logo"
          style={{ width: "176px", height: "40px",marginTop:'20px',  }}
        />
      </div>
      <p onClick={handlePopupToggle} style={{ color: 'blue', cursor: 'pointer',marginTop:'-37px',fontFamily:'roboto' }}>
       
          Send me jobs like this
      </p>

      {/* Popup Form */}
      {isPopupOpen && (
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <button style={styles.closeButton} onClick={handlePopupToggle}>
              ×
            </button>
            <h2 style={styles.title}>Enter your Email ID to get job alerts</h2>
            <form onSubmit={handleSubmit}>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Email ID</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your active Email ID"
                  style={styles.input}
                  required
                />
              </div>
              <div style={styles.actions}>
                <button
                  type="button"
                  style={styles.cancelButton}
                  onClick={handlePopupToggle}
                >
                  Cancel
                </button>
                <button type="submit" style={styles.submitButtonss}>
                  Create job alert
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* <div style={ratingStyle}>
        ★ <span style={{ marginLeft: "5px", fontWeight: "bold", color: "#000" }}>4.0 | 13305 Reviews</span>
      </div> */}
      <div style={detailsStyle}>
          <span>Hyderabad  Chenai </span>
      </div>
      <div style={descriptionStyle}>
        About the Role: To contribute and lead a team of enthusiastic individuals...
      </div>
      <div style={skillsStyle}>
        Java · Springboot Java · Microservices · Spring Boot · Development · Java
      </div>
      <div style={dateStyle}>
        <span>1 Day Ago</span>
        <span style={saveStyle}>❤️ Save</span>
          <button style={styles.inquiryButton} onClick={() => handleEnquiry(selectedJob)}>Apply Now</button>
          
      </div>
      </div>
      ) : (
        <p>No job selected.</p>
      )}
    </div>

      {/* Right Column: Form */}
      <div style={styles.rightColumn}>
        {isLoading && (
          <div style={styles.loaderOverlay}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" color="#9b59b6" />
          </div>
        )}
        <h3 className="fonts" style={{ fontSize: '32px' }}>Enquiry</h3>
        <form
          onSubmit={handleEnquirySubmit}
          style={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}
        >
         <input
            type="text"
            name="course"
            value={formData.course}
            onChange={handleInputChange}
            placeholder="course"
            style={styles.input}
            readOnly
          />
          <input
            type="text"
            name="Program"
            value={formData.program}
            onChange={handleInputChange}
            placeholder="Program"
            style={styles.input}
            readOnly
          />
          <input
            type="text"
            name="Location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder="Location"
            style={styles.input}
            required
          />
          <input
            type="text"
            name="salary"
            value={formData.salary}
            onChange={handleInputChange}
            placeholder="Ctc"
            style={styles.input}
            required
          />
          <input
            type="text"
            name="workMode"
            value={formData.workMode}
            onChange={handleInputChange}
            placeholder="workMode"
            style={styles.input}
            required
          />
          
         
          <input
            type="text"
            name="skillsRequired"
            value={formData.skillsRequired}
            onChange={handleInputChange}
            placeholder="SkillsRequired"
            style={styles.input}
            required
          />
          {/* <input
            type="tel"
            name="mobile"
            value={formData.mobile}
            onChange={handleInputChange}
            placeholder="Mobile Number"
            style={styles.input}
            required
          /> */}
          {/* <select
            name="Source"
            value={formData.Source}
            onChange={handleInputChange}
            style={styles.input}
            required
          >
            <option value="" disabled>
              Source
            </option>
            {sources.map((source, index) => (
              <option key={index} value={source.id}>
                {source.sourceName}
              </option>
            ))}
          </select> */}
          <input
            type="text"
            name="councellor"
            value={formData.councellor}
            onChange={handleInputChange}
            placeholder="Counsellor Name"
            style={styles.input}
            required
          />
          <input
            type="text"
            name="reference"
            value={formData.reference}
            onChange={handleInputChange}
            placeholder="Reference Name"
            style={styles.input}
            required
          />
          
          <div>
      {/* Label for the custom file input */}
      <label htmlFor="file-upload" style={styles.customField}>
        {/* <button type="button" style={styles.button}>Browse</button> */}
        <span style={styles.placeholder}>{fileName}</span>
      </label>
      {/* Hidden file input */}
      <input
        id="file-upload"
        type="file"
        name="uploadFile"
        onChange={handleFileChange}
        style={styles.fileInput}
        required
      />
    </div>
          <br />
          <button type="submit" style={styles.submitButton}>
             Send
           </button>
         </form>
       </div>
       {/* <LeftAdd /> */}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    marginTop: '120px',
    maxWidth: '1200px', // Set max width to 1200px
    margin: '0 auto',  // Center align the container
    flexWrap: 'wrap', 
     // Allow content to wrap on smaller screens
  },
  fileInput: {
    display: "none", // Completely hide the native file input
  },
  customField: {
    display: "flex",
    alignItems: "center", // Vertically align items
    justifyContent: "flex-start", // Align items close together
    gap: "5px", // Reduce space between items
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#f9f9f9",
  },
  leftColumn: {
    width: '60%',
    height:'60%',
    marginTop:'120px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
  },
  descriptionBox: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },
  jobInfoBox: {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "15px",
  },
  inquiryButton: {
    padding: "10px 20px",
    backgroundColor: "#9b59b6",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    marginTop: "10px",
    width: "150px",
  },
  rightColumn: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#f9f9f9",
    padding: "20px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    maxHeight: "500px",  // Max height for scroll
    overflowY: "auto",   // Make it scrollable
    marginTop:'120px',
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  },
  submitButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#9b59b6",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
  },
  scrollableBox: {
    maxHeight: '400px', // Height restriction for scrolling (can be adjusted)
    overflowY: 'auto', // Enables vertical scrolling
    marginBottom: '20px', // Adds space between the content and the button
    paddingRight: '10px', // Padding for scroll bar appearance
  },

  jobInfoBox: {
    marginTop: '10px',
    lineHeight: '1.5',
  },
  infoText: {
    marginBottom: '6px', // Small space between rows
    fontSize: '16px', // Consistent font size
    display: 'flex', // Flexbox for alignment
    justifyContent: 'flex-start', // No extra space between label and value
    alignItems: 'center', // Vertically center items
  },
  strong: {
    fontWeight: 'bold',
  },
  span: {
    marginLeft: '20px', // Slight space between label and value
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    width: "100%",
    maxWidth: "600px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    marginBottom: "20px",
    color:'black',
  },
  inputGroup: {
    marginBottom: "20px",
    textAlign: "left",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "bold",
  },
  // input: {
  //   width: "100%",
  //   padding: "10px",
  //   borderRadius: "5px",
  //   border: "1px solid #ccc",
  //   fontSize: "16px",
  // },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  cancelButton: {
    background: "none",
    border: "none",
    color: "#007bff",
    fontSize: "16px",
    cursor: "pointer",
  },
  submitButtonss: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "16px",
    cursor: "pointer",
  }
};


export default ShoppingCart;








