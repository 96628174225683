// // import React, { useState } from 'react';
// // import ClockLogo from '../img/vik-extra-logo/clock-regular.svg';
// // import MoneyLogo from '../img/vik-extra-logo/money-bill-solid (1).svg';
// // import calendar from '../img/vik-extra-logo/calendar-days-regular.svg';
// // import book from '../img/vik-extra-logo/book-open-solid.svg';
// // import enrollIcon from '../img/vik-extra-logo/enroll.png'; // Import a new icon for Enroll Now

// // const ProgramFeesAndSchedules = () => {
// //   const [hoveredCard, setHoveredCard] = useState(null);

// //   const listItemStyle = {
// //     position: 'relative',
// //     display: 'flex',
// //     flexDirection: 'column',
// //     alignItems: 'center',
// //     textAlign: 'center',
// //     padding: '20px',
// //     border: '1px solid rgb(255 165 0)',
// //     borderRadius: '10px',
// //     backgroundColor: '#ffffff',
// //     height: '140px',
// //     width: '115%',
// //     margin: '10px auto',
// //     overflow: 'hidden',
// //     transition: 'transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease',
// //   };

// //   const listItemHoverStyle = {
// //     transform: 'scale(1.05)',
// //     boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
// //     border: '4px solid rgb(255 165 0)',
// //   };

// //   const overlayStyle = {
// //     position: 'absolute',
// //     top: '10px',
// //     left: '10px',
// //     right: '10px',
// //     bottom: '10px',
// //     borderRadius: '8px',
// //     backgroundColor: 'rgba(0, 0, 0, 0)',
// //     transition: 'background-color 0.3s ease',
// //     zIndex: 1,
// //   };

// //   const overlayHoverStyle = {
// //     backgroundColor: 'rgba(0, 0, 0, 0.6)',
// //   };

// //   const dataStyle = {
// //     position: 'relative',
// //     zIndex: 2,
// //     color: '#fff',
// //     fontSize: '14px',
// //   };

// //   const iconStyle = {
// //     height: '30px',
// //     transition: 'color 0.3s ease',
// //     filter: 'none',
// //   };

// //   const iconHoverStyle = {
// //     filter: 'brightness(0) saturate(100%) invert(17%) sepia(99%) saturate(6032%) hue-rotate(190deg) brightness(104%) contrast(101%)',
// //   };

// //   const boldStyle = {
// //     fontSize: '16px',
// //     color: '#333',
// //     marginBottom: '5px',
// //   };

// //   const cardsData = [
// //     {
// //       id: 1,
// //       img: MoneyLogo,
// //       title: 'Program Fees',
// //       value: '₹ 74,999.00 /-',
// //       subtitle: 'EMI ₹ 6,250/month',
// //     },
// //     {
// //       id: 2,
// //       img: ClockLogo,
// //       title: 'Application closes on',
// //       value: '1 Dec, 2024',
// //       subtitle: '',
// //     },
// //     {
// //       id: 3,
// //       img: calendar,
// //       title: 'Program duration',
// //       value: '6 months',
// //       subtitle: '',
// //     },
// //     {
// //       id: 4,
// //       img: book,
// //       title: 'Learning Format',
// //       value: 'Offline, Interactive',
// //       subtitle: '',
// //     },
// //     {
// //       id: 5,
// //       img: enrollIcon,
// //       title: 'Enroll Now',
// //       value: 'Click to Enroll',
// //       subtitle: '',
// //       link: 'https://dashboard.iiautomate.com/', // URL to redirect to
// //     },
// //   ];

// //   return (
// //     <div
// //       className="partners-main revamp-partners-main"
// //       data-test="nextCohort"
// //       style={{
// //         backgroundColor: 'white',
// //         padding: '20px',
// //       }}
// //     >
// //       <div className="container sheduleprograms" style={{ maxWidth: '1200px', margin: '0 auto' }}>
// //         <div className="row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
// //           {cardsData.map((item, index) => (
// //             <div
// //               key={item.id}
// //               className="col-12 col-sm-6 col-md-4 col-lg-2 mb-3"
// //               onMouseEnter={() => setHoveredCard(index)}
// //               onMouseLeave={() => setHoveredCard(null)}
// //             >
// //               <div
// //                 style={{
// //                   ...listItemStyle,
// //                   ...(hoveredCard === index ? listItemHoverStyle : {}),
// //                   cursor: item.link ? 'pointer' : 'default',
// //                 }}
// //                 onClick={() => {
// //                   if (item.link) {
// //                     window.open(item.link, '_blank');
// //                   }
// //                 }}
// //               >
// //                 <div
// //                   style={{
// //                     ...overlayStyle,
// //                     ...(hoveredCard === index ? overlayHoverStyle : {}),
// //                   }}
// //                 />
// //                 <img
// //                   src={item.img}
// //                   alt={item.title}
// //                   style={{
// //                     ...iconStyle,
// //                     ...(hoveredCard === index ? iconHoverStyle : {}),
// //                   }}
// //                 />
// //                 <div className="programFee-data" style={dataStyle}>
// //                   <p style={{ color: hoveredCard === index ? 'yellow' : '#666' }}>
// //                     {item.title}
// //                   </p>
// //                   <b style={{ ...boldStyle, color: hoveredCard === index ? 'white' : '#333' }}>
// //                     {item.value}
// //                   </b>
// //                   {item.subtitle && (
// //                     <p style={{ color: hoveredCard === index ? 'yellow' : '#666' }}>
// //                       {item.subtitle}
// //                     </p>
// //                   )}
// //                 </div>
// //               </div>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ProgramFeesAndSchedules;





// // import React from 'react';

// // const DentalImplants = () => {
// //   return (
// //     <div className="textdetail">
// //       <ul style={{ fontSize: '20px', textAlign: 'justify', listStyleType: 'none', paddingLeft: '0', fontFamily: 'Roboto', color: '#000' }}>
// //         <li style={{ marginBottom: '20px' }}>
// //           <div style={boxStyle}>
// //             <strong>DENTAL IMPLANTS</strong>: A permanent solution for replacing missing teeth, involving the placement of titanium posts into the jawbone.
// //           </div>
// //         </li>
// //         <li style={{ marginBottom: '20px' }}>
// //           <div style={boxStyle}>
// //             <strong>DENTAL IMPLANTS</strong>: Designed to match the look and feel of natural teeth, offering a more comfortable and secure fit than traditional dentures.
// //           </div>
// //         </li>
// //         <li style={{ marginBottom: '20px' }}>
// //           <div style={boxStyle}>
// //             <strong>DENTAL IMPLANTS</strong>: Help restore function and appearance, allowing you to eat, speak, and smile with confidence.
// //           </div>
// //         </li>
// //         <li style={{ marginBottom: '20px' }}>
// //           <div style={boxStyle}>
// //             <strong>DENTAL IMPLANTS</strong>: Provide long-lasting results with proper care, often lasting for decades or a lifetime.
// //           </div>
// //         </li>
// //         <li style={{ marginBottom: '20px' }}>
// //           <div style={boxStyle}>
// //             <strong>DENTAL IMPLANTS</strong>: A safe and effective procedure with a high success rate, often preferred over other alternatives.
// //           </div>
// //         </li>
// //       </ul>
// //     </div>
// //   );
// // }

// // const boxStyle = {
// //   border: '1px solid #ccc',
// //   padding: '15px',
// //   borderRadius: '8px',
// //   backgroundColor: '#f9f9f9',
// //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
// //   fontSize: '18px',
// //   margin: '10px 0'
// // };

// // export default DentalImplants;





// // import React from 'react';

// // const DentalImplants = () => {
// //   return (
// //     <div className="textdetail" style={containerStyle}>
// //       <h3 style={headingStyle}>
// //       WHAT YOU WILL  <span style={{ color: 'orange' }}>GAIN</span>
// //       </h3>
// //       <ul style={listStyle}>
// //         <li style={listItemStyle}>
// //           <div style={boxStyle}>
// //             <strong>IN-DEPTH KNOWLEDGE OF 6-AXIS ROBOTICS</strong>: Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools
// //           </div>
// //         </li>
// //         <li style={listItemStyle}>
// //           <div style={boxStyle}>
// //             <strong> PRACTIAL SKILLS: </strong>:Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.
// //           </div>
// //         </li>
// //         <li style={listItemStyle}>
// //           <div style={boxStyle}>
// //             <strong>CAREER ADVANCEMENT:</strong> Develop skills for roles such as robotic engineer,automation specialist,and simulation programmer,opening up new opportunities in the growing field
// //           </div>
// //         </li>
// //         <li style={listItemStyle}>
// //           <div style={boxStyle}>
// //             <strong>HANDS-ON TRAINING:</strong> Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions,Work on real-world projects, .
// //           </div>
// //         </li>
// //       </ul>
// //     </div>
// //   );
// // };

// // // Styles for the container (fluid layout with space between items)
// // const containerStyle = {
// //   display: 'flex',
// //   flexDirection: 'column',
// //   justifyContent: 'center',
// //   padding: '0 15px',
// //   width: '100%',
// //   maxWidth: '1200px', // Limiting the max width to prevent full-screen usage
// //   margin: '0 auto', // Center the container
// // };

// // // Styles for the heading
// // const headingStyle = {
// //   fontWeight: "bold",
// //   color: "#000",
// //   textAlign: "center",
// //   marginTop: '67px',
// // };

// // // Styles for the list
// // const listStyle = {
// //   listStyleType: 'none',
// //   paddingLeft: '0',
// //   fontFamily: 'Roboto',
// //   fontSize: '20px',
// //   color: '#000',
// //   textAlign: 'center', // Center-align the text inside the boxes
// //   width: '100%',
// //   display: 'flex',
// //   flexWrap: 'wrap', // Wraps the boxes to the next line if there's not enough space
// //   justifyContent: 'space-between', // Distribute space evenly between boxes
// // };

// // // Styles for the list items
// // const listItemStyle = {
// //   marginBottom: '20px',
// //   width: 'calc(50% - 10px)', // Each box takes up 50% of the container width (2 per row)
// //   padding: '10px',
// //   boxSizing: 'border-box',
// // };

// // // Styles for the individual boxes
// // const boxStyle = {
// //   border: '1px solid #ccc',
// //   padding: '15px',
// //   borderRadius: '8px',
// //   backgroundColor: '#f9f9f9',
// //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
// //   fontSize: '18px',
// //   margin: '10px',
// //   textAlign: 'left', // Align the text inside the box to the left
// // };

// // export default DentalImplants;






// import React, { useState } from 'react';
// import robotics from "../img/robotics hands working.mp4";

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         phone: "",
//     });

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const handleChange = (e) => {
//         const { id, value } = e.target;
//         setFormData((prevState) => ({
//             ...prevState,
//             [id]: value,
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const apiBody = {
//             firstName: formData.name,
//             emailId: formData.email,
//             contactNumber: formData.phone,
//         };

//         try {
//             const response = await fetch(
//                 "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
//                 {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                     body: JSON.stringify(apiBody),
//                 }
//             );

//             if (response.ok) {
//                 alert("Your details have been submitted successfully!");
//             } else {
//                 alert("There was an issue submitting your details.");
//             }
//         } catch (error) {
//             alert("Something went wrong. Please try again later.");
//         }
//     };

//     return (
//         <div className="container my-5">
//             <div className="row justify-content-center">
//                 <div className="col-lg-8">
//                     {/* <div className="p-4 bg-white shadow rounded mb-4">
//                         <h2 style={{ fontWeight: "bold" }}>
//                             Tools & Fixture Designs{" "}
//                             <span style={{ color: "#ffa500", fontWeight: "800", fontFamily: 'Roboto' }}>
//                                 Course Details
//                             </span>
//                         </h2>
//                         <p style={{ fontFamily: 'Roboto' }}>
//                             This course is tailored for aspiring engineers and tech enthusiasts eager to explore robotics in a virtual environment. It covers robotic simulation, and control systems with hands-on projects to build practical skills. Ideal for students and professionals aiming to excel in robotics and automation industries.
//                         </p>
//                     </div> */}


//                 </div>

//                 <div className="col-lg-4">
//                     {/* <div>
//                         <video
//                             src={robotics}
//                             controls
//                             style={{
//                                 width: '100%',
//                                 borderRadius: '10px',
//                                 boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
//                                 marginBottom: '20px',
//                             }}
//                         />
//                     </div> */}

//                     {/* <div
//                         style={{
//                             width: '100%',
//                             minHeight: '500px',
//                             maxHeight: '90vh',
//                             overflowY: 'auto',
//                             padding: '30px',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             position: 'relative',
//                             boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
//                             borderRadius: '10px',
//                             backgroundColor: '#fff',
//                         }}
//                       >
//                         <h2 style={{ textAlign: 'center', width: '100%', fontFamily: 'Roboto' }}>Enquiry Form</h2>
//                         <p style={{ marginBottom: '30px', fontFamily: 'Roboto' }}>Fill in your details and we’ll get back to you shortly.</p>

//                         <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
//                             <input
//                                 type="text"
//                                 name="name"
//                                 placeholder="Name"
//                                 value={formData.name}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <input
//                                 type="email"
//                                 name="email"
//                                 placeholder="Email"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <input
//                                 type="tel"
//                                 name="phone"
//                                 placeholder="Phone Number"
//                                 value={formData.phone}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <input
//                                 type="date"
//                                 name="date"
//                                 value={formData.date}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <div style={{ marginBottom: '10px' }}>
//                                 <select
//                                     name="gender"
//                                     value={formData.gender}
//                                     onChange={handleChange}
//                                     style={{
//                                         width: '100%',
//                                         padding: '10px',
//                                         borderRadius: '5px',
//                                         border: '1px solid #ccc',
//                                     }}
//                                     required
//                                 >
//                                     <option value="">Select Gender</option>
//                                     <option value="male">Male</option>
//                                     <option value="female">Female</option>
//                                     <option value="other">Other</option>
//                                 </select>
//                             </div>

//                             <div style={{ marginBottom: '10px' }}>
//                                 <textarea
//                                     placeholder="Your Message"
//                                     rows="4"
//                                     style={{
//                                         width: '100%',
//                                         padding: '10px',
//                                         borderRadius: '5px',
//                                         border: '1px solid #ccc',
//                                     }}
//                                 ></textarea>
//                             </div>

//                             <button
//                                 type="submit"
//                                 style={{
//                                     width: '100%',
//                                     padding: '12px',
//                                     backgroundColor: '#495057',
//                                     color: '#fff',
//                                     border: 'none',
//                                     borderRadius: '5px',
//                                     fontSize: '16px',
//                                     cursor: 'pointer',
//                                     textAlign: 'center',
//                                 }}
//                             >
//                                 Submit
//                             </button>
//                         </form>
//                     </div> */}
//                 </div>
//                 <h2 className="fw-bold mb-4" style={{ color: '#000', fontFamily: 'Roboto', width: '100%', textAlign: 'center' }}>
//                 WHAT YOU WILL  <span style={{color:'rgb(251 145 0)'}}>GAIN</span> 
//                 </h2>

//                 <div className="list-tab" style={{ width: '100%', padding: 0 }}>
//                     {[{
//                         id: 1, title: "In-Depth Knowledge of 6-Axis Robotics:", content: "Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.."
//                     }, {
//                         id: 2, title: "Practical Skills:", content: "Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming."
//                     }, {
//                         id: 3, title: "Career Advancement:", content: "In BIW fixture design, achieving a 3D finish typically refers to ensuring that the fixture not only meets functional requirements (alignment, clamping, support, etc.) but also supports the aesthetic or dimensional accuracy needed for the car's final body panel surfaces."
//                     }, {
//                         id: 4, title: "Hands-On Training:", content: "Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions."
//                     },
//                     ].map(({ id, title, content }) => (
//                         <div key={id} className="mb-3" style={{ width: '100%' }}>
//                             <div
//                                 className="p-3 bg-light rounded cursor-pointer"
//                                 onClick={() => updateToggle(id)}
//                                 style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}
//                             >
//                                 <span className="fw-bold" style={{ fontFamily: 'Roboto',fontSize:'20px' }}>{toggle === id ? '-' : '+'} {title}</span>
//                             </div>
//                             {toggle === id && (
//                                 <div className="mt-2 px-4">
//                                     <p style={{ fontFamily: 'Roboto' }}>{content}</p>
//                                 </div>
//                             )}
//                         </div>
//                     ))}
//                 </div>

//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;





// import React from 'react';

// const LearningPathTFD = () => {
//     return (
//         <div className="container my-5">
//             <div className="row justify-content-center">
//                 <div className="col-lg-8">
//                     <h2 className="fw-bold mb-4" style={{ color: '#000', fontFamily: 'Roboto', width: '100%', textAlign: 'center' }}>
//                         WHAT YOU WILL <span style={{ color: 'rgb(251 145 0)' }}>GAIN</span>
//                     </h2>
//                     {[{
//                         id: 1, title: "In-Depth Knowledge of 6-Axis Robotics:", content: "Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.."
//                     }, {
//                         id: 2, title: "Practical Skills:", content: "Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming."
//                     }, {
//                         id: 3, title: "Career Advancement:", content: "In BIW fixture design, achieving a 3D finish typically refers to ensuring that the fixture not only meets functional requirements (alignment, clamping, support, etc.) but also supports the aesthetic or dimensional accuracy needed for the car's final body panel surfaces."
//                     }, {
//                         id: 4, title: "Hands-On Training:", content: "Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions."
//                     },
//                     ].map(({ id, title, content }) => (
//                         <div key={id} className="mb-3" style={{ width: '100%' }}>
//                             <div
//                                 className="p-3 bg-light rounded shadow-sm"
//                                 style={{ width: '150%', borderRadius: '50px',border:'1px solid',textAlign:'center', padding: '20px', backgroundColor: '#fff',justifyContent:'center',marginLeft:'-220px', }}
//                             >
//                                 <span className="fw-bold" style={{ fontFamily: 'Roboto', fontSize: '20px', color: '#000' }}>
//                                     {title}
//                                 </span>
//                                 <div className="mt-2">
//                                     <p style={{ fontFamily: 'Roboto', color: '#333' }}>{content}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;






// import React from 'react';

// const WhyAttend = () => {
//   const features = [
//     {
//       heading: "In-Depth Knowledge of 6-Axis Robotics:",
//       description:
//         "Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.",
//     },
//     {
//       heading: "Practical Skills: ",
//       description:
//         "Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.",
//     },
//     {
//       heading: "Career Advancement: ",
//       description:
//         "Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.",
//     },
//     {
//       heading: "Hands-On Training: ",
//       description:
//         "Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions."
//     },
//   ];

//   return (
//     <div className="container my-8" style={{ fontFamily: "Roboto ,sans serif" }}>
//       <div className="row justify-content-center">
//         <div className="col-lg-8">
//           <h3
//             style={{
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               marginBottom: "44px",
//               marginTop:'20px',
//               marginLeft:'390px',
//               fontSize:'30px',
//             }}
//           >
//            WHAT YOU WILL <span style={{ color: "orange" }}>GAIN</span>
//           </h3>

//           <ul
//             style={{
//               listStyleType: "none",
//               padding: 0,
//               display: "flex",
//               flexDirection: "column", // Arrange features in a column
//               gap: "20px", // Add spacing between list items
//               justifyContent: "center",
//               alignItems: "center", // Center-align list items
              
//             }}
//           >
//             {features.map((feature, index) => (
//               <li
//                 key={index}
//                 style={{
//                   padding: "20px",
//                   marginLeft:'auto',
//                   borderRight: "5px solid blue",borderRight: "5px solid blue",
//                   marginBottom: "20px", // Add spacing between list items
//                   backgroundColor: "#f9f9f9", // Light background color
//                   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional subtle shadow
//                   width: "148%", // Make sure items occupy full width of the column
//                   textAlign: "center", // Center text within each item
//                   border:'1px solid',
//                   borderradious:'50px',
//                 }}
//               >
//                 <strong style={{ color: "#333", fontSize: "18px" }}>
//                   {feature.heading}
//                 </strong>
//                 <p style={{ marginTop: "10px", fontSize: "16px", color: "#555" }}>
//                   {feature.description}
//                 </p>
//               </li>
//             ))}
//           </ul>
//         </div>

//         {/* Optional right column for future content */}
//         <div className="col-lg-4">
//           {/* Additional content can be placed here if needed */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WhyAttend;





// import React from "react";

// const WhyAttend = () => {
//   const features = [
//     {
//       heading: "In-Depth Knowledge of 6-Axis Robotics:",
//       description:
//         "Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.",
//     },
//     {
//       heading: "Practical Skills: ",
//       description:
//         "Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.",
//     },
//     {
//       heading: "Career Advancement: ",
//       description:
//         "Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.",
//     },
//     {
//       heading: "Hands-On Training: ",
//       description:
//         "Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions."
//     },
//   ];

//   return (
//     <div className="container my-5" style={{ fontFamily: "Roboto, sans-serif" }}>
//       <div className="row justify-content-center">
//         <div className="col-12 col-lg-8">
//           <h3 className="text-center fw-bold mb-4" style={{ fontSize: "30px", marginTop: "20px" }}>
//             WHAT YOU WILL <span style={{ color: "orange" }}>GAIN</span>
//           </h3>

//           <ul className="list-unstyled">
//             {features.map((feature, index) => (
//               <li
//                 key={index}
//                 className="p-3 mb-3 bg-light shadow-sm"
//                 style={{
//                   borderRight: "5px solid blue",
//                   border: "1px solid #ddd",
//                   textAlign: "center",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <strong className="d-block" style={{ color: "#333", fontSize: "18px" }}>
//                   {feature.heading}
//                 </strong>
//                 <p className="mt-2" style={{ fontSize: "16px", color: "#555" }}>
//                   {feature.description}
//                 </p>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>

//       <style jsx>{`
//         @media (max-width: 768px) {
//           h3 {
//             font-size: 24px;
//           }
//           li {
//             text-align: left;
//             padding: 15px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// export default WhyAttend;





// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//      ]
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>1. In-Depth Knowledge of 6-Axis Robotics:</strong> Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>2.Practical Skills:</strong> Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>3. Career Advancement:</strong> Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>4. Hands-On Training:</strong> Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions.This includes programming, simulation, and integration of robotic systems, 
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//             padding: 0;
//           }
//           li {
//             width: 100%; /* Boxes take up the full width */
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center',
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap',
//   justifyContent: 'space-between',
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '5px',
//   width: '(100%)',
//   padding: '10px',
//   // boxSizing: 'border-box',

// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '1px solid #ccc',
//   padding: '15px',
//   // borderRadius: '8px',
//   backgroundColor: '#f9f9f9',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   fontSize: '18px',
//   // margin: '10px auto',
//   textAlign: 'left',
// };

// export default DentalImplants;




// import React from 'react';

// const Services = () => {
//     const headingStyle = {
//       textAlign: 'center',
//       fontSize: '2em',
//       marginBottom: '30px',
//       marginTop: '44px', // Added margin top
//     };

//     const spanStyle = {
//         color: 'orange',
//     };

//     return (
//         <div>
//             <style>
//                 {`
//                 .service-card {
//                     padding: 20px;
//                     border: 1px solid #ccc;
//                     border-radius: 10px;
//                     background-color: #f9f9f9;
//                     flex: 1 1 calc(25% - 20px);
//                     max-width: calc(20% - 20px);
//                     transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
//                     text-align: center;
//                     position: relative;
//                     margin: 25px; /* Add space between cards */
//                     margin-top:44px;
//                 }

//                 .service-card:hover {
//                     transform: scale(1.05) rotate(-2deg);
//                     box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
//                     background: linear-gradient(135deg, rgb(124, 209, 230), #d1c4e9);
//                 }

//                 .service-card h1 {
//                     font-size: 20px;
//                     margin-bottom: 10px;
//                 }

//                 .service-card p {
//                     font-size: 14px;
//                     color: #666;
//                 }
//                 `}
//             </style>

//             <div className="row d-flex justify-content-center">
//                 <div className="col-md-10">
//                     {/* Title Section */}
//                     <h3 style={headingStyle}>
//                         WHAT YOU WILL <span style={spanStyle}>GAIN</span>
//                     </h3>

//                     {/* Cards Section */}
//                     <div
//                         className="row"
//                         style={{
//                             display: 'flex',
//                             flexWrap: 'wrap',
//                             gap: '20px',
//                             justifyContent: 'center',
//                             margin: '20px',
//                         }}
//                     >
//                         {/* Display only 4 service cards */}
//                         <div className="col-md-6 service-card">
//                             <h1>In-Depth Knowledge of 6-Axis Robotics:</h1>
//                             <p>
//                             Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Practical Skills:</h1>
//                             <p>
//                             Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Career Advancement:</h1>
//                             <p>
//                             Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1> Hands-On Training:</h1>
//                             <p>
//                             Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions.This includes programming, simulation, and integration of robotic systems,
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Services;




// import React from 'react';

// const Services = () => {
//     const headingStyle = {
//         textAlign: 'center',
//         fontSize: '2em',
//         marginBottom: '30px',
//         marginTop: '44px', // Added margin top
//     };

//     const spanStyle = {
//         color: 'orange',
//     };

//     return (
//         <div>
//             <style>
//                 {`
//                 .service-card {
//                     padding: 20px;
//                     border: 1px solid #ccc;
//                     border-radius: 10px;
//                     background-color: #f9f9f9;
//                     flex: 1 1 calc(25% - 20px);
//                     max-width: calc(20% - 20px);
//                     transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, color 0.3s;
//                     text-align: center;
//                     position: relative;
//                     margin: 25px; /* Add space between cards */
//                     margin-top: 44px;
//                     color: #333; /* Default text color */
//                 }

//                 .service-card:hover {
//                     transform: scale(1.05) rotate(-2deg);
//                     box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
//                     background-color: black; /* Background turns black */
//                     color: white; /* Text turns white */
//                 }

//                 .service-card:hover h1 {
//                     color: orange; /* Heading turns orange on hover */
//                 }

//                 .service-card h1 {
//                     font-size: 20px;
//                     margin-bottom: 10px;
//                     transition: color 0.3s; /* Smooth color transition for heading */
//                 }

//                 .service-card p {
//     font-size: 14px;
//     color: black; /* Default paragraph text color */
//     transition: color 0.3s; /* Smooth color transition for paragraph */
// }

// .service-card:hover p {
//     color: white; /* Paragraph text turns white on hover */
// }
//                 `}
//             </style>

//             <div className="row d-flex justify-content-center">
//                 <div className="col-md-10">
//                     {/* Title Section */}
//                     <h3 style={headingStyle}>
//                         WHAT YOU WILL <span style={spanStyle}>GAIN</span>
//                     </h3>

//                     {/* Cards Section */}
//                     <div
//                         className="row"
//                         style={{
//                             display: 'flex',
//                             flexWrap: 'wrap',
//                             gap: '20px',
//                             justifyContent: 'center',
//                             margin: '20px',
//                         }}
//                     >
//                         {/* Display only 4 service cards */}
//                         <div className="col-md-6 service-card">
//                             <h1>In-Depth Knowledge of 6-Axis Robotics:</h1>
//                             <p>
//                                 Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Practical Skills:</h1>
//                             <p>
//                                 Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Career Advancement:</h1>
//                             <p>
//                                 Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Hands-On Training:</h1>
//                             <p>
//                                 Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions. This includes programming, simulation, and integration of robotic systems.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Services;

// import React from 'react';

// const Services = () => {
//     const headingStyle = {
//         textAlign: 'center',
//         fontSize: '2em',
//         marginBottom: '30px',
//         marginTop: '44px', // Added margin top
//     };

//     const spanStyle = {
//         color: 'orange',
//     };

//     return (
//         <div>
//             <style>
//                 {`
//                 .service-card {
//                     padding: 20px;
//                     border: 2px solid #ccc; /* Default border */
//                     border-radius: 10px;
//                     background-color: #f9f9f9;
//                     flex: 1 1 calc(30% - 20px); /* Adjust width for wider cards */
//                     max-width: calc(30% - 20px); /* Set maximum width */
//                     transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, border-color 0.3s, border-width 0.3s;
//                     text-align: center;
//                     position: relative;
//                     margin: 5px; /* Add space between cards */
//                     color: #333; /* Default text color */
//                 }

//                 .service-card:hover {
//                     transform: scale(1.05) rotate(-2deg);
//                     box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
//                     background-color: black; /* Background turns black */
//                     color: white; /* Text turns white */
//                     border-color: yellow; /* Border turns yellow */
//                     border-width: 4px; /* Border becomes thicker */
//                 }

//                 .service-card:hover h1 {
//                     color: orange; /* Heading turns orange on hover */
//                 }

//                 .service-card h1 {
//                     font-size: 20px;
//                     margin-bottom: 10px;
//                     transition: color 0.3s; /* Smooth color transition for heading */
//                 }

//                 .service-card p {
//                     font-size: 14px;
//                     color: black; /* Default paragraph text color */
//                     transition: color 0.3s; /* Smooth color transition for paragraph */
//                 }

//                 .service-card:hover p {
//                     color: white; /* Paragraph text turns white on hover */
//                 }
//                 `}
//             </style>

//             <div className="row d-flex justify-content-center">
//                 <div className="col-md-10">
//                     {/* Title Section */}
//                     <h3 style={headingStyle}>
//                         WHAT YOU WILL <span style={spanStyle}>GAIN</span>
//                     </h3>

//                     {/* Cards Section */}
//                     <div
//                         className="row"
//                         style={{
//                             display: 'flex',
//                             flexWrap: 'wrap',
//                             // gap: '20px',
//                             justifyContent: 'center',
//                             margin: '20px',
//                         }}
//                     >
//                         {/* Display only 4 service cards */}
//                         <div className="col-md-6 service-card">
//                             <h1>In-Depth Knowledge of 6-Axis Robotics:</h1>
//                             <p>
//                                 Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Practical Skills:</h1>
//                             <p>
//                                 Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Career Advancement:</h1>
//                             <p>
//                                 Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.
//                             </p>
//                         </div>
//                         <div className="col-md-6 service-card">
//                             <h1>Hands-On Training:</h1>
//                             <p>
//                                 Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions. This includes programming, simulation, and integration of robotic systems.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Services;




// import React from 'react';

// const Services = () => {
//     const headingStyle = {
//         textAlign: 'center',
//         fontSize: '2em',
//         marginBottom: '30px',
//         marginTop: '44px', // Added margin top
//         fontFamily:'roboto',
//     };

//     const spanStyle = {
//         color: 'orange',
//     };

//     return (
//         <div>
//             <style>
//                 {`
//                 .service-card {
//                     padding: 20px;
//                     border: 2px solid #ccc; /* Default border */
                   
//                     background-color: #f9f9f9;
//                     flex: 1; /* Ensure all cards take up equal space */
//                     max-width: 25%; /* Each card takes up 25% width */
//                     transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, border-color 0.3s, border-width 0.3s;
//                     text-align: center;
//                     position: relative;
//                     margin: 5px; /* Add space between cards */
//                     color: #333; /* Default text color */
//                     margin-Top:33px;
//                 }

//                 .service-card:hover {
//                     transform: scale(1.05) rotate(-2deg);
//                     box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
//                     background-color: black; /* Background turns black */
//                     color: white; /* Text turns white */
//                     border-color: orange; /* Border turns yellow */
//                     border-width: 2px; /* Border becomes thicker */
//                 }

//                 .service-card:hover h1 {
//                     color: orange; /* Heading turns orange on hover */
//                 }

//                 .service-card h1 {
//                     font-size: 20px;
//                     margin-bottom: 10px;
//                     transition: color 0.3s; /* Smooth color transition for heading */
//                 }

//                 .service-card p {
//                     font-size: 17px;
//                     font-family:roboto;
//                     color: black; /* Default paragraph text color */
//                     transition: color 0.3s; /* Smooth color transition for paragraph */
                    
//                 }

//                 .service-card:hover p {
//                     color: white; /* Paragraph text turns white on hover */
//                 }

//                 .card-container {
//                     display: flex;
//                     flex-wrap: nowrap; /* Prevent wrapping */
//                     justify-content: space-between; /* Distribute cards evenly */
//                     gap: 10px; /* Space between cards */
//                     margin: 20px 0;
//                 }
//                 `}
//             </style>

//             <div className="row d-flex justify-content-center">
//                 <div className="col-md-10">
//                     {/* Title Section */}
//                     <h3 style={headingStyle}>
//                         WHAT YOU WILL <span style={spanStyle}>GAIN</span>
//                     </h3>

//                     {/* Cards Section */}
//                     <div className="card-container" >
//                         {/* Display all 4 service cards in one row */}
//                         <div className="service-card">
//                             <h1>In-Depth Knowledge of 6-Axis Robotics:</h1>
//                             <p>
//                                 Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.
//                             </p>
//                         </div>
//                         <div className="service-card">
//                             <h1>Practical Skills:</h1>
//                             <p>
//                                 Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.
//                             </p>
//                         </div>
//                         <div className="service-card">
//                             <h1>Career Advancement:</h1>
//                             <p>
//                                 Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.
//                             </p>
//                         </div>
//                         <div className="service-card">
//                             <h1>Hands-On Training:</h1>
//                             <p>
//                                 Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions. This includes programming, simulation, and integration of robotic systems.
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Services;


import React from 'react';

const Services = () => {
    const headingStyle = {
        textAlign: 'center',
        fontSize: '2em',
        marginBottom: '30px',
        marginTop: '44px', // Added margin top
        fontFamily: 'roboto',
    };

    const spanStyle = {
        color: 'orange',
    };

    return (
        <div>
            <style>
                {`
                .service-card {
                    padding: 20px;
                    border: 2px solid #ccc; /* Default border */
                    background-color: #f9f9f9;
                    flex: 1; /* Ensure all cards take up equal space */
                    max-width: 25%; /* Each card takes up 25% width on large screens */
                    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, border-color 0.3s, border-width 0.3s;
                    text-align: center;
                    position: relative;
                    margin: 5px; /* Add space between cards */
                    color: #333; /* Default text color */
                    margin-top: 33px;
                }

                .service-card:hover {
                    transform: scale(1.05) rotate(-2deg);
                    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
                    background-color: black; /* Background turns black */
                    color: white; /* Text turns white */
                    border-color: orange; /* Border turns orange */
                    border-width: 2px; /* Border becomes thicker */
                }

                .service-card:hover h1 {
                    color: orange; /* Heading turns orange on hover */
                }

                .service-card h1 {
                    font-size: 20px;
                    margin-bottom: 10px;
                    transition: color 0.3s; /* Smooth color transition for heading */
                }

                .service-card p {
                    font-size: 17px;
                    font-family: roboto;
                    color: black; /* Default paragraph text color */
                    transition: color 0.3s; /* Smooth color transition for paragraph */
                }

                .service-card:hover p {
                    color: white; /* Paragraph text turns white on hover */
                }

                .card-container {
                    display: flex;
                    flex-wrap: wrap; /* Allow wrapping on smaller screens */
                    justify-content: space-between; /* Distribute cards evenly */
                    gap: 10px; /* Space between cards */
                    margin: 20px 0;
                }

                /* Responsive Styles */
                @media (max-width: 1200px) {
                    .service-card {
                        max-width: 45%; /* Adjust card width for medium screens */
                    }
                }

                @media (max-width: 768px) {
                    .card-container {
                        flex-direction: column; /* Stack cards vertically on smaller screens */
                    }

                    .service-card {
                        max-width: 100%; /* Full-width cards on mobile */
                        margin: 10px 0; /* Add space between stacked cards */
                    }
                }

                @media (max-width: 480px) {
                    .service-card h1 {
                        font-size: 18px; /* Smaller font for headings */
                    }

                    .service-card p {
                        font-size: 15px; /* Smaller font for paragraphs */
                    }
                }
                `}
            </style>

            <div className="row d-flex justify-content-center" style={{width:'100%',}}>
                <div className="col-md-10">
                    {/* Title Section */}
                    <h3 style={headingStyle}>
                        WHAT YOU WILL <span style={spanStyle}>GAIN</span>
                    </h3>

                    {/* Cards Section */}
                    <div className="card-container">
                        {/* Display all 4 service cards */}
                        <div className="service-card">
                            <h1>In-Depth Knowledge of 6-Axis Robotics:</h1>
                            <p>
                                Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.
                            </p>
                        </div>
                        <div className="service-card">
                            <h1>Practical Skills:</h1>
                            <p>
                                Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.
                            </p>
                        </div>
                        <div className="service-card">
                            <h1>Career Advancement:</h1>
                            <p>
                                Develop skills for roles such as robotic engineer, automation specialist, and simulation programmer, opening up new opportunities in the growing field of robotics.
                            </p>
                        </div>
                        <div className="service-card">
                            <h1>Hands-On Training:</h1>
                            <p>
                                Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions. This includes programming, simulation, and integration of robotic systems.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;


