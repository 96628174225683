import React, { useState } from "react";

const LearningPathTFD = () => {
  const [toggleAllSections, setToggleAllSections] = useState(false);
  const [sectionToggle, setSectionToggle] = useState({});
  const [toggleNewModule, setToggleNewModule] = useState(false);
  const [newModuleToggle, setNewModuleToggle] = useState({});
  const [toggleExteraModule, setToggleExteraModule] = useState(false);
  const [exteraModuleToggle, setExteraModuleToggle] = useState({});
  const [toggleIsExteraModule, setToggleIsExteraModule] = useState(false);
  const [isExteraModuleToggle, setIsExteraModuleToggle] = useState({});
  const [toggleAdditionalModule, setToggleAdditionalModule] = useState(false);
  const [additionalModuleToggle, setAdditionalModuleToggle] = useState({});
  const [toggleExtraAdvancedModule, setToggleExtraAdvancedModule] = useState(false);
  const [extraAdvancedModuleToggle, setExtraAdvancedModuleToggle] = useState({});
  const [toggleAndLocalModule, setToggleAndLocalModule]= useState(false);
  const [AndLocalModuleToggle, setAndLocalModuleToggle]= useState({});
  const [toggleAndUlteraLocalModule, setToggleAndUlteraLocalModule]= useState(false);
  const [AndUlteraLocalModuleToggle, setAndUlteraLocalModuleToggle]= useState({});
  const listContent = [
    // Existing sections
    {
      id: 1,
      title: "Introduction to Java ",
      nestedContent: [
        { id: 1, text: "• 		History and features of Java.", dummyText: [] },
        { id: 2, text: "•	Java Development Kit (JDK), Java Runtime Environment (JRE) and JVM.", dummyText: [] },
        { id: 3, text: "• 	Setting up the development environment (IDE: IntelliJ IDEA, Eclipse).", dummyText: [] },
      ],
    },
    {
      id: 2,
      title: "Basic Syntax and Structure",
      nestedContent: [
        { id: 1, text: "• 		Writing and running a simple Java program.", dummyText: [] },
        { id: 2, text: "• 		Understanding classes, methods and objects.", dummyText: [] },
        { id: 3, text: "• 			Variables, data types and operators.", dummyText: [] },
       
      ],
    },
    {
        id: 3,
        title: "Control Flow Statements",
        nestedContent: [
          { id: 1, text: "• 	Conditional statements (if, else, switch).", dummyText: [] },
          { id: 2, text: "•  	Loops (for, while, do-while).", dummyText: [] },
         
        ],
      },
      {
        id: 4,
        title: "Introduction to Debugging",
        nestedContent: [
          { id: 1, text: "• 	Debugging tools and techniques in Java IDEs.", dummyText: [] },
         
         
        ],
      },
  ];

  const newModuleContent = [
    // New module sections
    {
      id: 1,
      title: "  Core OOP Concepts",
      nestedContent: [
        { id: 1, text: "• 		Classes and Objects.", dummyText: [] },
        { id: 2, text: "• 		Encapsulation, Inheritance, Polymorphism and Abstraction.", dummyText: [] },
       
      ],
    },
    {
      id: 2,
      title: " Advanced OOP Features",
      nestedContent: [
        { id: 1, text: "• 		Method overloading and overriding.", dummyText: [] },
        { id: 2, text: "• 		Abstract classes and interfaces.", dummyText: [] },
        { id: 3, text: "• 		Inner classes and anonymous classes.", dummyText: [] },
       
      ],
    },
    {
        id: 3,
        title: " DDesign Principles",
        nestedContent: [
          { id: 1, text: "• 		SOLID principles in Java.", dummyText: [] },
          { id: 2, text: "• 		Object-oriented design patterns.", dummyText: [] },
         
        ],
      },
      {
        id: 4,
        title: " Practical Exercises",
        nestedContent: [
          { id: 1, text: "• 			Implementing real-world scenarios using OOP.", dummyText: [] },
         
        ],
      },
      
     
      
  ];

  const exteraModuleContent = [
    // Extra module sections
    {
      id: 3,
      title: " String Manipulation",
      nestedContent: [
        { id: 1, text: "• 				String class and its methods.", dummyText: [] },
        { id: 2, text: "• 			String Builder and String Buffer.", dummyText: [] },
       
       
       
      ],
    },
    {
      id: 4,
      title: " Collections Framework",
      nestedContent: [
        { id: 1, text: "• 			Lists, Sets and Maps.", dummyText: [] },
        { id: 2, text: "• 			Iterators and Streams.", dummyText: [] },
        { id: 3, text: "• 			Sorting and searching in collections", dummyText: [] },
        
      ],
    },
    {
        id: 5,
        title: " Exception Handling",
        nestedContent: [
          { id: 1, text: "• 		Types of exceptions (checked and unchecked).", dummyText: [] },
          { id: 2, text: "• 	Try-catch blocks, finally and custom exceptions.", dummyText: [] },
          
        ],
      },
      {
        id: 6,
        title: " File Handling",
        nestedContent: [
          { id: 1, text: "• 		Reading and writing files using java.io.", dummyText: [] },
          { id: 2, text: "• 			Serialization and Deserialization.", dummyText: [] },
         
         
          
        ],
      },
      {
        id: 7,
        title: " Multithreading",
        nestedContent: [
          { id: 1, text: "• 			Thread lifecycle and creation.", dummyText: [] },
          { id: 2, text: "• 				Synchronization and thread safety.", dummyText: [] },
          { id: 3, text: "• 				 	Executors and concurrency utilities.", dummyText: [] },
         
         
        
        ],
      },
  ];

  const additionalModuleContent = [
    // Additional module sections
    {
      id: 5,
      title: "  Introduction to JDBC",
      nestedContent: [
        { id: 1, text: "•			JDBC architecture and drivers.", dummyText: [] },
        { id: 2, text: "•			Connecting to a database.", dummyText: [] },
      ],
    },
    {
      id: 6,
      title: " CRUD Operations",
      nestedContent: [
        { id: 1, text: "• 			Executing SQL queries from Java.", dummyText: [] },
        { id: 2, text: "•			Prepared statements and callable statements.", dummyText: [] },
      ],
    },
    {
        id: 7,
        title: " Transaction Management",
        nestedContent: [
          { id: 1, text: "• 		Commit and rollback operations.", dummyText: [] },
          { id: 2, text: "•		Save points and batch processing.", dummyText: [] },
        ],
      },
      {
        id: 8,
        title: "Practical Exercises",
        nestedContent: [
          { id: 1, text: "• 		Building a simple database-driven application.", dummyText: [] },
        
        ],
      },
      
      
  ];

  const isExteraModuleContent = [
    // Additional Module Sections
    {
      id: 7,
      title: " Java 8 Features",
      nestedContent: [
        { id: 1, text: "• 			Lambda expressions and functional interfaces.", dummyText: [] },
        { id: 2, text: "• 		Stream API for data processing.", dummyText: [] },
        { id: 3, text: "• 			Date and time API.", dummyText: [] },
      ],
    },
    {
      id: 8,
      title: "Annotations and Reflection",
      nestedContent: [
        { id: 1, text: "• 			Built-in and custom annotations.", dummyText: [] },
        { id: 2, text: "• 			Reflection API for dynamic programming.", dummyText: [] },
      ],
    },
    {
        id: 9,
        title: " Networking in Java",
        nestedContent: [
          { id: 1, text: "• 			Sockets and client-server communication.", dummyText: [] },
          { id: 2, text: "• 			HTTP and web service integration.", dummyText: [] },
        ],
      },
      
      {
        id: 10,
        title: " JavaFX",
        nestedContent: [
          { id: 1, text: "• 		Basics of JavaFX for GUI applications.", dummyText: [] },
          { id: 2, text: "• 			Event handling and layout management.", dummyText: [] },
        
        ],
      },
      
  ];

  const extraAdvancedModuleContent = [
    // Extra Advanced Module Sections
    {
      id: 9,
      title: "   Introduction to Java Web Development",
      nestedContent: [
        { id: 1, text: "•			Overview of Java EE and Jakarta EE.", dummyText: [] },
        { id: 2, text: "•			Understanding servlets and JSP.", dummyText: [] },
      ],
    },
    {
      id: 10,
      title: " Web Application Basics",
      nestedContent: [
        { id: 1, text: "• 				HTTP request-response model.", dummyText: [] },
        { id: 2, text: "• 			Session management and cookies.", dummyText: [] },
      ],
    },
    {
      id: 11,
      title: " Building RESTful APIs",
      nestedContent: [
        { id: 1, text: "• 			Introduction to JAX-RS.", dummyText: [] },
        { id: 2, text: "• 		Creating and consuming APIs.", dummyText: [] },
       
      ],
    },
    {
        id: 12,
        title: "  Practical Project",
        nestedContent: [
          { id: 1, text: "• 	Developing a simple web application with database integration.", dummyText: [] },
          
         
        ],
      },
  ];

  const andLocalModuleContent = [
    //ExtraAdvancedModuleSections
    {
      id: 13,
      title: " Spring Framework",
      nestedContent: [
        { id: 1, text: "		Overview of Spring Core", dummyText: [] },
        { id: 2, text: "•		Dependency injection and inversion of control.", dummyText: [] },
        { id: 3, text: "•			Spring Boot for rapid application development.", dummyText: [] },
      ],
    },
    {
      id: 14,
      title: " Hibernate ORM",
      nestedContent: [
        { id: 1, text: "• 			Basics of object-relational mapping.", dummyText: [] },
        { id: 2, text: "• 			Hibernate annotations and configuration.", dummyText: [] },
        { id: 3, text: "• 				Querying databases with Hibernate Query Language (HQL).", dummyText: [] },
      
      ],
    },
    {
      id: 15,
      title: "  Maven and Gradle",
      nestedContent: [
        { id: 1, text: "• 	Build automation tools and dependency management.", dummyText: [] },
       
      ],
    },
    {
        id: 16,
        title: " Testing Frameworks",
        nestedContent: [
          { id: 1, text: "• 	•	Unit testing with JUnit.", dummyText: [] },
          { id: 2, text: "• 		Mocking with Mockito..", dummyText: [] },
         
        ],
      },
    
  ];
  const andUlteraLocalModuleContent = [
    //ExtraAdvancedModuleSections
   
    {
        id: 17,
        title: "  Project Requirements:",
        nestedContent: [
          { id: 1, text: "• 					Design and develop a multi-module Java application.", dummyText: [] },
          { id: 2, text: "• 						Implement database connectivity and transaction management.", dummyText: [] },
          { id: 3, text: "• 							Build RESTful APIs and integrate with a front-end (optional).", dummyText: [] },
          { id: 4, text: "• 							Use Spring Boot and Hibernate for backend development.", dummyText: [] },
          { id: 5, text: "• 							Write unit tests for critical components.", dummyText: [] },
          { id: 6, text: "• 							Document and present the project.", dummyText: [] },
        ],
      },
    
  ];

  const toggleSection = (id, module = "existing") => {
    if (module === "existing") {
      setSectionToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "new") {
      setNewModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extera") {
      setExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "isextera") {
      setIsExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "additional") {
      setAdditionalModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extraAdvanced") {
      setExtraAdvancedModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    }else if (module === "andLocal"){
        setAndLocalModuleToggle((prev) => ({...prev,[id]: !prev[id]}));
   } else if (module === "andUlteraLocal"){
    setAndUlteraLocalModuleToggle((prev) => ({...prev,[id]: !prev[id]}));
  }
  };
  
  return (
    <div className="container my-5">
      <h2 className="fw-bold mb-4" style={{ color: "#000", fontFamily: "Roboto", textAlign: "center",marginTop:'-49px', }}>
        PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
      </h2>
      <br />
      
      {/* Existing Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAllSections(!toggleAllSections)}
      >
       Comprehensive Java Curriculum 
      </div>

      {toggleAllSections && (
        <div className="list-tab mt-3">
          {listContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id)}
              >
                {title}
                <span>{sectionToggle[id] ? "-" : "+"}</span>
              </div>
              {sectionToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* New Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleNewModule(!toggleNewModule)}
      >
       Object-Oriented Programming (OOP) in Java  
      </div>

      {toggleNewModule && (
        <div className="list-tab mt-3">
          {newModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "new")}
              >
                {title}
                <span>{newModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {newModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Extera Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExteraModule(!toggleExteraModule)}
      >
       : Core Java Concepts  
      </div>

      {toggleExteraModule && (
        <div className="list-tab mt-3">
          {exteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extera")}
              >
                {title}
                <span>{exteraModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {exteraModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Additional Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAdditionalModule(!toggleAdditionalModule)}
      >
          Java Database Connectivity (JDBC) 
      </div>

      {toggleAdditionalModule && (
        <div className="list-tab mt-3">
          {additionalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "additional")}
              >
                {title}
                <span>{additionalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {additionalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

<div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleIsExteraModule(!toggleIsExteraModule)}
      >
     Advanced Java Concepts 
      </div>

      {toggleIsExteraModule && (
        <div className="list-tab mt-3">
          {isExteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "isextera")}
              >
                {title}
                <span>{isExteraModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {isExteraModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {/* Extra Advanced Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExtraAdvancedModule(!toggleExtraAdvancedModule)}
      >
      Web Development with Java 
      </div>

      {toggleExtraAdvancedModule && (
        <div className="list-tab mt-3">
          {extraAdvancedModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extraAdvanced")}
              >
                {title}
                <span>{extraAdvancedModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {extraAdvancedModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
       <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAndLocalModule(!toggleAndLocalModule)}
      >
        : Frameworks and Tools  
      </div>

      {toggleAndLocalModule && (
        <div className="list-tab mt-3">
          {andLocalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "andLocal")}
              >
                {title}
                <span>{AndLocalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {AndLocalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAndUlteraLocalModule(!toggleAndUlteraLocalModule)}
      >
      Capstone Project   
      </div>

      {toggleAndUlteraLocalModule && (
        <div className="list-tab mt-3">
          {andUlteraLocalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "andUlteraLocal")}
              >
                {title}
                <span>{AndUlteraLocalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {AndUlteraLocalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LearningPathTFD;




