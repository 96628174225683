// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
// import logo from '../img/logo/HeadAllLogoIIALogo.png';
// // import IIALogo from '../img/IIA_LOGO_White-for-website_v3.png'
// import { Link } from 'react-router-dom';

// const Footer = () => {
//   return (
//     <footer>
//       <div className="container-fluid">
//         <div className="row">
//           {/* Logo and Description */}
//           <div className=" col-lg-3 logo d-flex align-items-center">
//             <div className="row">
//               <div className='col-md-12'>
//                 <div className="row" style={{ marginTop: "-191px", }}>
//                   {/* <div className="col-2">
//                     <img alt="logo" src={logo} style={{ maxHeight: '104px', paddingBottom: '20px' }} />
//                   </div> */}
//                   {/* <div className="col-10">
//                     <span style={{ padding: '5px', paddingTop: '10px' }}>
//                       <p style={{ fontSize: '19px', color: 'rgb(251 145 0)', fontWeight: '600', fontFamily: ' urbanist, serif' }} > INTERNATIONAL INSTITUTE<br></br> OF AUTOMATE</p>

//                     </span>
//                   </div> */}
//                   <div className="s-social">
//                   {/* Social Icons */}
//                   <a href="https://www.facebook.com/profile.php?id=61566000185428&mibextid=ZbWKwL" target="_blank"><span className="ti-facebook"></span></a>
//                   <a href="https://www.linkedin.com/feed/?trk=onboarding-landing" target="_blank"><span className="ti-linkedin"></span></a>
//                   <a href="https://www.instagram.com/info.iiautomate/profilecard/?igsh=ZTdocXp5ajNpaW5o"><span className="ti-instagram"></span></a>
//                   <a href="https://www.youtube.com/@IIAUTOMATE"><span className="ti-youtube"></span></a>
//                   <Link to="https://x.com/iiautomate?t=fIrwaO15RN7FiVsLMM8ujw&s=09" target="_blank">
//                     <span className="ti-twitter" style={{ fontSize: "20px" }}></span>
//                   </Link>
//                 </div>
//                 </div>

//               </div>
//               {/* <div className='col-md-12'>
//                 <img alt="logo" src={logo} style={{ maxHeight: '130px', paddingBottom: '20px' }} />
//               </div> */}
//               {/* <div className="col-md-12">
//                 <div style={{ color: "white", padding: '' }}></div>


//           </div>
//             </div> */}

// </div>

//           </div>
//            {/* Courses Section */}
//            <div className="col-lg-2 text-left mb-3">
//             <h5 className="heading"> COURSES</h5>
//             <div className="s-address">
//               <div className="detail">
//                 <Link to="/">Tool & Fixture Design</Link>
//               </div>
//               <div className="detail">
//                 <Link to="/">Virtual Robotics</Link>
//               </div>
//               <div className="detail">
//                 <Link to="/">Digital Facilities Solution</Link>
//               </div>
//             </div>
//           </div>

//           {/* Courses Section */}
//           <div className="col-lg-2 text-left mb-3">
//             <h5 className="heading"> COURSES</h5>
//             <div className="s-address">
//               <div className="detail">
//                 <Link to="/">Tool & Fixture Design</Link>
//               </div>
//               <div className="detail">
//                 <Link to="/">Virtual Robotics</Link>
//               </div>
//               <div className="detail">
//                 <Link to="/">Digital Facilities Solution</Link>
//               </div>
//             </div>
//           </div>

//           {/* Useful Links */}
//           <div className="col-lg-2 text-left mb-3">
//             <h5 className="heading"> USEFUL LINKS</h5>
//             <div className="s-address">
//               <div className="detail">
//                 <Link to="/">Home</Link>
//               </div>
//               <div className="detail">
//                 <Link to="/who-we-are">About Us</Link>
//               </div>
//               {/* <div className="detail">
//                 <Link to="/what-we-do">Courses</Link>
//               </div> */}

//               {/* <div className="detail">
//                 <Link to="/gellerypage">Placements</Link>
//               </div> */}

//               <div className="detail">
//                 <Link to="/contact">Contact Us</Link>
//               </div>
//             </div>
//           </div>

//           {/* Get In Touch */}
//           <div className="col-lg-2 mb-3">
//             <h5 className="heading"> OUR LOCATION</h5>
//             <div className="contact-info" style={{ color: '#fff' }}>
//               <div className="location mb-3">
//                 <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
//                 <> Hyderabad</>
//                 <p style={{ fontSize: '14px', margin: 0, textAlign: 'left' }}>#913, Manjeera Trinity Corporate, 9th floor, Office No.913, KPHB, Hyderabad, Telangana, India - 500085</p>
//               </div>
//               <div className="location mb-3">
//                 <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
//                 <> Chennai</>
//                 <p style={{ fontSize: '14px', margin: 0, textAlign: 'left' }}>13, Customs Colony, OMR, Oggiyamduraipakkam, Tamil Nadu - 600097</p>
//               </div>
//             </div>
//           </div>

//           {/* Our Location */}
//           <div className="col-lg-2 mb-3">
//             <h5 className="heading foot-her" style={{ color: '#313840' }}>Our Location</h5>
//             <div className="contact-info" style={{ color: '#fff' }}>
//               <div className="location mb-3">
//                 <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
//                 <> Bangalore</>
//                 <p style={{ fontSize: '14px', margin: 0, textAlign: 'left' }}>1596, 20th Main Road, Agara Village, 1st Sector, HSR Layout, Bengaluru, Karnataka, <br /> india - 560102</p>
//               </div>
//               <div className="location mb-3">
//                 <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
//                 <> Pune</>
//                 <p style={{ fontSize: '14px', margin: 0, textAlign: 'left' }}>Suratwala Mark, 8th Floor, Office No. 810, Wakad Road, Pune, Maharashtra - 411057</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Subfooter */}
//         <div className="row mt-3">
//           <div className="col-12 text-center">
//             <div className="subfooter">
//               <div className="copyright">
//                 <p>Copyright © 2024, All Rights Reserved To <span style={{ fontWeight: '700' }}> IIA </span> Powered by <span style={{ fontWeight: '700' }}> Envision</span></p>
//               </div>
//               <a className="terms" href="#" style={{ color: '#fff' }}>
//                 Terms of Use | Privacy Environmental Policy
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;




// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
// import { faFacebook, faLinkedin, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
// import { Link } from 'react-router-dom';
// import logo from '../img/logo/HeadAllLogoIIALogo.png';

// const Footer = () => {
//   const [showLanguageModal, setShowLanguageModal] = useState(false);

//   const handleLanguageClick = () => {
//     setShowLanguageModal(true);
//   };

//   // Function to close the modal
//   const handleCloseModal = () => {
//     setShowLanguageModal(true);
//   };
//   return (
//     <footer style={styles.footer}>
//       <div className="container-fluid">
//         {/* Social Icons */}
//         <div style={styles.socialIcons}>
//           <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faFacebook} style={styles.icon} />
//           </a>
//           <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faLinkedin} style={styles.icon} />
//           </a>
//           <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faInstagram} style={styles.icon} />
//           </a>
//           <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faYoutube} style={styles.icon} />
//           </a>
//           <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faTwitter} style={styles.icon} />
//           </a>
//         </div>

//         {/* Footer Content */}
//         <div style={styles.row}>
//         <div style={styles.column}>
//             <h5 style={styles.heading}>Aobout</h5>
//             {/* <hr style={styles.line} /> */}
//             <ul style={styles.list}>
//               <li><Link to="/">Home</Link></li>
//               <li><Link to="/who-we-are">About Us</Link></li>
//               <li><Link to="/contact">Contact Us</Link></li>
//             </ul>
//           </div>
//           <div style={styles.column}>
//             <h5 style={styles.heading}>Discovery Iia</h5>
//             {/* <hr style={styles.line} /> */}
//             <ul style={styles.list}>
//               <li><Link to="/">Gate the app</Link></li>
//               <li><Link to="/">Teach on Iia</Link></li>
//               <li><Link to="/">Plans and Pricing</Link></li>
//               <li><Link to="/">Help and Support</Link></li>
//             </ul>
//           </div>
//           {/* Courses */}
//           <div style={styles.column}>
//             <h5 style={styles.heading}>COURSES</h5>
//             {/* <hr style={styles.line} /> */}
//             <ul style={styles.list}>
//               <li><Link to="/">Tool & Fixture Design</Link></li>
//               <li><Link to="/">Virtual Robotics</Link></li>
//               <li><Link to="/">Digital Facilities Solution</Link></li>
//             </ul>
//           </div>

//           {/* Useful Links */}
//           {/* <div style={styles.column}>
//             <h5 style={styles.heading}>USEFUL LINKS</h5>

//             <ul style={styles.list}>
//               <li><Link to="/">Home</Link></li>
//               <li><Link to="/who-we-are">About Us</Link></li>
//               <li><Link to="/contact">Contact Us</Link></li>
//             </ul>
//           </div> */}

//           {/* Location */}
//           <div style={styles.column}>
//             <h5 style={styles.heading}>OUR LOCATION</h5>
//             {/* <hr style={styles.line} /> */}
//             <ul style={styles.list}>
//               <li>Hyderabad</li>
//               <li>Chennai</li>
//               <li>Bangalore</li>
//               <li>Pune</li>
//             </ul>
//           </div>
//         </div>

//         <div style={styles.subfooter}>
//   {/* Logo on the left */}
//   <div style={styles.logoContainer}>
//     <img
//       src={logo} // Replace with the path to your logo
//       alt="Logo"
//       style={styles.logo}
//     />
//   </div>

//   {/* Center text */}
//   <div style={styles.subfooterText}>
//     <p>
//       Copyright © 2024, All Rights Reserved To <strong>IIA</strong> Powered by <strong>Envision</strong>
//     </p>
//     <a href="#" style={{ color: '#fff' }}>
//       Terms of Use | Privacy Environmental Policy
//     </a>
//   </div>

//   {/* Language icon on the right */}
//   <div style={styles.languageContainer}>
//         <button onClick={handleLanguageClick} style={styles.languageButton}>
//           🌐
//         </button>
//       </div>

//       {/* Language Modal */}
//       {showLanguageModal && (
//         <div style={modalStyles.overlay}>
//           <div style={modalStyles.modal}>
//             <h3 style={modalStyles.title}>Select Language</h3>
//             <ul style={modalStyles.languageList}>
//               <li style={modalStyles.languageItem}>English</li>
//               <li style={modalStyles.languageItem}>Hindi</li>
//               <li style={modalStyles.languageItem}>Spanish</li>
//               <li style={modalStyles.languageItem}>French</li>
//               <li style={modalStyles.languageItem}>German</li>
//               <li style={modalStyles.languageItem}>Chinese</li>
//             </ul>
//             <button onClick={handleCloseModal} style={modalStyles.closeButton}>
//               Close
//             </button>
//             </div>
//         </div>
//       )}
//     </footer>



// const styles = {
//   footer: {
//     backgroundColor: '#313840',
//     color: '#fff',
//     padding: '20px 0',
//     fontSize: '16px',
//     fontFamily: "Roboto",
//     fontWeight:'300',
//   },
//   socialIcons: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     marginBottom: '10px',
//   },
//   icon: {
//     fontSize: '20px',
//     color: '#fff',
//     margin: '0 10px',
//     cursor: 'pointer',
//   },
//   row: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     flexWrap: 'wrap',
//     marginBottom: '20px',
//   },
//   column: {
//     flex: '1',
//     // margin: ' 5px',
//     minWidth: '200px',
//   },
//   heading: {
//     fontSize: '18px',
//     fontWeight: '400',
//     marginBottom: '10px',
//   },
//   line: {
//     border: 'none',
//     borderBottom: '1px solid #fff',
//     marginBottom: '25px',
//   },
//   list: {
//     listStyle: 'none',
//     padding: 0,
//   },
//   listItem: {
//     marginBottom: '5px',
//   },
//   subfooter: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     backgroundColor: '#000',
//     padding: '10px 20px',
//     color: '#fff',
//   },
//   logoContainer: {
//     flex: 1,
//     textAlign: 'left',
//   },
//   logo: {
//     height: '40px', // Adjust the height as needed
//   },
//   subfooterText: {
//     flex: 2,
//     textAlign: 'center',
//   },
//   languageContainer: {
//     flex: 1,
//     textAlign: 'right',
//   },
//   languageButton: {
//     background: 'transparent',
//     border: 'none',
//     color: '#fff',
//     cursor: 'pointer',
//     fontSize: '20px',
//     marginleft:'20px',
//   },
// };



// export default Footer;



import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../img/logo/HeadAllLogoIIALogo.png";

const Footer = () => {
  const [showLanguageModal, setShowLanguageModal] = useState(false);

  // Open Language Modal
  const handleLanguageClick = () => {
    setShowLanguageModal(true);
  };

  // Close Language Modal
  const handleCloseModal = () => {
    setShowLanguageModal(false);
  };

  return (
    <footer style={styles.footer}>
      <div className="container-fluid">
        {/* Social Icons */}
        {/* <div style={styles.socialIcons}>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} style={styles.icon} />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} style={styles.icon} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} style={styles.icon} />
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} style={styles.icon} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} style={styles.icon} />
          </a>
        </div> */}

        {/* Footer Links */}
        <div style={styles.row}>
        <div style={styles.column}>
            <h5 style={styles.heading}>Discovery IIA</h5>
            <ul style={styles.list}>
            <li>  <a href="https://about.iiautomate.com/" target="_blank" rel="noopener noreferrer">
               About Us
                </a></li>
                <li><Link to="/">program</Link></li>
                <li><Link to="/">Jobs</Link></li>
                <li><Link to="/">Teach on IIA</Link></li>
                <li><Link to="/">Speakers</Link></li>
                <li>
                <a href="https://dashboard.iiautomate.com/" target="_blank" rel="noopener noreferrer">
                  Dashboard
                </a>
              </li>
                 <li><Link to="/">Get the app</Link></li>
          
           
            </ul>
          </div>
          <div style={styles.column}>
            <h5 style={styles.heading}>Mechanical Courses</h5>
            <ul style={styles.list}>
           
                <li><Link to="/">Virtual Robotic Simulation</Link></li>
                <li><Link to="/">Tool & Fixture Design</Link></li>
                <li><Link to="/">Digital Factory Solutions</Link></li>
                
            </ul>
          </div>
          {/* <div style={styles.column}>
            <h5 style={styles.heading}>About</h5>
            <ul style={styles.list}>
            
              <li>  <a href="https://about.iiautomate.com/" target="_blank" rel="noopener noreferrer">
               About Us
                </a></li>
              <li>
                <a href="https://dashboard.iiautomate.com/" target="_blank" rel="noopener noreferrer">
                  Dashboard
                </a>
              </li>
              <li><Link to="/contact">Contact Us</Link></li>


            </ul>
          </div> */}
          <div style={styles.column}>
            <h5 style={styles.heading}>IT Cources</h5>
            <ul style={styles.list}>
            
                <li><Link to="/">Java Full Stack</Link></li>
                <li><Link to="/">Python Full Stack</Link></li>
                <li><Link to="/">React.js</Link></li>
                <li><Link to="/">Flutter App Development</Link></li>
                
                
          
           
            </ul>
          </div>
          <div style={styles.column}>
            <h5 style={styles.heading}>Jose</h5>
            <ul style={styles.list}>
              <li><Link to="/">Career Foundation Skills</Link></li>
              <li><Link to="/">Soft Skills Development</Link></li>
              <li><Link to="/">Digital Presence Skills </Link></li>
              

            </ul>
          </div>
          {/* <div style={styles.column}>
            <h5 style={styles.heading}>Our Location</h5>
            <ul style={styles.list}>
              <li>Hyderabad</li>
              
            </ul>
          </div> */}
        </div>
        <div style={styles.socialIcons}>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} style={styles.icon} />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} style={styles.icon} />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} style={styles.icon} />
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} style={styles.icon} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} style={styles.icon} />
          </a>
        </div>
        <hr style={styles.line} />
        {/* Subfooter */}
        <div className="ffft" style={styles.subfooter}>
          {/* Logo */}
          <div className="" style={styles.logoTextContainer}>
            <div style={styles.logoContainer}>
              <img src={logo} alt="Logo" style={styles.logo} />
            </div>

            <p style={styles.logoText}>INTERNATIONAL INSTITUTE OF AUTOMATE</p>
          </div>



          {/* Center Text */}
          <div style={styles.subfooterText}>
            <p>
              Copyright © 2024, All Rights Reserved To <strong>IIA</strong> {" "}
              <strong></strong>
           
            <a href="#" style={{ color: "#fff" }}>
              Terms of Use | Privacy Environmental Policy
            </a>
            </p>
          </div>

          {/* Language Button */}
          <div style={styles.languageContainer}>
            <button onClick={handleLanguageClick} style={styles.languageButton}>
              🌐
            </button>
          </div>
        </div>

        {/* Language Modal */}
        {showLanguageModal && (
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              <h3 style={modalStyles.title}>Select Language</h3>
              <ul style={modalStyles.languageList}>
                <li style={modalStyles.languageItem}>English</li>
                <li style={modalStyles.languageItem}>Hindi</li>
                <li style={modalStyles.languageItem}>Spanish</li>
                <li style={modalStyles.languageItem}>French</li>
                <li style={modalStyles.languageItem}>German</li>
                <li style={modalStyles.languageItem}>Chinese</li>
              </ul>
              <button onClick={handleCloseModal} style={modalStyles.closeButton}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

const styles = {

  footer: {
    backgroundColor: '#313840',
    color: '#fff',
    padding: '20px 0',
    fontSize: '16px',
    fontFamily: "Roboto",
    fontWeight: '300',
    
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  },
  icon: {
    fontSize: '20px',
    color: '#fff',
    margin: '0 10px',
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  column: {
    flex: '1',
    // margin: ' 5px',
    minWidth: '200px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: '400',
    marginBottom: '10px',
    color:'orange',
  },
  line: {
    border: 'none',
    borderBottom: '1px solid #fff',
    marginBottom: '0px',
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    marginBottom: '5px',
  },
  subfooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#313840',
    padding: '10px 20px',
    color: '#fff',
  },
  logoContainer: {
    flex: 1,
    textAlign: 'left',
  },
  logo: {
    height: '40px', // Adjust the height as needed
  },
  logoTextContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  subfooterText: {
    flex: 2,
    textAlign: 'center',
    fontFamily: 'roboto',

  },
  languageContainer: {
    flex: 1,
    textAlign: 'right',
  },
  languageButton: {
    background: 'transparent',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '30px',
    marginRight: '20px',
  },
};

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
    width: "300px",
    textAlign: "center",
  },
  title: {
    fontSize: "20px",
    marginBottom: "15px",
  },
  languageList: {
    listStyle: "none",
    padding: 0,
    margin: "10px 0",
  },
  languageItem: {
    margin: "5px 0",
    padding: "3px 10px",
    cursor: "pointer",
    borderRadius: "4px",
    backgroundColor: "#313840",
    textAlign: "center",
  },
  closeButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    borderRadius: "4px",
  },
};

export default Footer;


