// Import React and the image
import React from "react";
import './BtnPage.css';
import bannerImage from "../img/aaaservices/personResume.jpeg";
// import bannerImage from "../img/aaaservices/bannerImageForPlacement.jpg";
import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';
const PlacementsInBanner = () => {
    const bannerStyle = {
        height: "100vh", // Full screen height
        width: "100%", // Full width
        display: "flex", // Flexbox for centering content
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${bannerImage})`, // Use imported image
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        overflow: "hidden",
    };

    const overlayStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        // Adjust opacity for contrast
        zIndex: 1,
    };

    const contentStyle = {
        position: "relative",
        zIndex: 2, // Ensure it is above the overlay
        textAlign: "center",
        color: "#fff", // White text for contrast
    };

    const headingStyle = {
        fontSize: "3rem",
        margin: 0,
    };

    const paragraphStyle = {
        fontSize: "1.5rem",
        margin: "10px 0 20px",
    };

    const buttonStyle = {
        display: "inline-block",
        padding: "10px 20px",
        fontSize: "1rem",
        color: "#fff",
        backgroundColor: "#ff5722", // Button color
        border: "none",
        borderRadius: "5px",
        textDecoration: "none",
        cursor: "pointer",
        transition: "background-color 0.3s",
    };

    const buttonHoverStyle = {
        backgroundColor: "#e64a19", // Hover color
    };

    return (
        <div style={bannerStyle} className="banner-image">
            <div style={overlayStyle}></div>
            <div style={contentStyle}>
                <div className="row" style={{ marginTop: '240px', width:'50%' }}>
                    <div className="col-lg-8 bannerheadtwo" style={{ padding: '10px',marginBottom:'130px' }}>
                        <div>
                            <h1 style={{ fontWeight: '700', padding: '10px',color:'black' }} className="">
                                Our  <span style={{ fontSize: '40px', color: '#fb9100' }}>Placements</span>
                            </h1>
                        </div>
                        <div className="under-the-line"></div>
                        <div className="bannerhaedthreeone">
                            {/* <h4 className="bannerhaedthree mb-3" style={{ width: '80%' }}>
                      Gain Your Certificate With Envision And I-Robotics
                        </h4> */}
                            <p style={{ fontSize: '21px',color:"black" }} className="">
                                Empowering Your Future: With our dedicated placement support, we bridge the gap between your skills and the career of your dreams, ensuring you step confidently into the professional world.
                            </p>
                        </div>
                        <div
                            className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrd"
                            style={{ width: '72%' }}
                        >
                            <div className="col-auto">
                                <button
                                    type="button"
                                    className="btn  brittle   ps-4 pe-4 custom-button"
                                    style={{ marginTop: '20px', backgroundColor: 'black' }}
                                >
                                    Enroll now
                                </button>
                            </div>
                            <div className="col-auto">
                                <button
                                    type="button"
                                    className="btn  brittle   ps-4 pe-4 custom-button"
                                    style={{ marginTop: '20px', backgroundColor: 'black' }}
                                    onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = brochure;
                                        link.download = 'Brochure.pdf';
                                        link.click();
                                    }}
                                >
                                    Download Brochure
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PlacementsInBanner;
