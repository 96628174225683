




















import React, { useState } from 'react';
import robotics from "../img/robotics hands working.mp4";

const LearningPathDFS = () => {
    const [toggle, setToggle] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiBody = {
            firstName: formData.name,
            emailId: formData.email,
            contactNumber: formData.phone,
        };

        try {
            const response = await fetch(
                "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(apiBody),
                }
            );

            if (response.ok) {
                alert("Your details have been submitted successfully!");
            } else {
                alert("There was an issue submitting your details.");
            }
        } catch (error) {
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    {/* <div className="p-4 bg-white shadow rounded mb-4">
                        <h2 style={{ fontWeight: "bold" }}>
                            Tools & Fixture Designs{" "}
                            <span style={{ color: "#ffa500", fontWeight: "800", fontFamily: 'Roboto' }}>
                                Course Details
                            </span>
                        </h2>
                        <p style={{ fontFamily: 'Roboto' }}>
                            This course is tailored for aspiring engineers and tech enthusiasts eager to explore robotics in a virtual environment. It covers robotic simulation, and control systems with hands-on projects to build practical skills. Ideal for students and professionals aiming to excel in robotics and automation industries.
                        </p>
                    </div> */}


                </div>

                <div className="col-lg-4">
                    {/* <div>
                        <video
                            src={robotics}
                            controls
                            style={{
                                width: '100%',
                                borderRadius: '10px',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                                marginBottom: '20px',
                            }}
                        />
                    </div> */}

                    {/* <div
                        style={{
                            width: '100%',
                            minHeight: '500px',
                            maxHeight: '90vh',
                            overflowY: 'auto',
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                        }}
                      >
                        <h2 style={{ textAlign: 'center', width: '100%', fontFamily: 'Roboto' }}>Enquiry Form</h2>
                        <p style={{ marginBottom: '30px', fontFamily: 'Roboto' }}>Fill in your details and we’ll get back to you shortly.</p>

                        <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <div style={{ marginBottom: '10px' }}>
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                    required
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                            <div style={{ marginBottom: '10px' }}>
                                <textarea
                                    placeholder="Your Message"
                                    rows="4"
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                ></textarea>
                            </div>

                            <button
                                type="submit"
                                style={{
                                    width: '100%',
                                    padding: '12px',
                                    backgroundColor: '#495057',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                }}
                            >
                                Submit
                            </button>
                        </form>
                    </div> */}
                </div>
                <h2 className="fw-bold mb-4" style={{ color: '#000', fontFamily: 'Roboto', width: '100%', textAlign: 'center' }}>
                Learning <span style={{color:'rgb(251 145 0)'}}>Path</span> 
                </h2>

                <div className="list-tab" style={{ width: '100%', padding: 0 }}>
                {[{
                                    id: 1, title: "Introduction To Digital Facility ", content: "The Digital Facility enhances flexibility, reduces downtime, improves product quality, and supports better decision-making by providing real-time insights into the entire production process."
                                }, {
                                    id: 2, title: "Input Study of Scan Data", content: "Input study of scan data is crucial for leveraging 3D scan technology in Digital Factory Solutions. It enables manufacturers to create virtual environments, optimize workflows, and enhance efficiency, safety, and compliance through accurate processing and integration."
                                }, {
                                    id: 3, title: "Converting Scan Data to 3D", content: "It enhances accuracy, reduces manual effort, and accelerates product development, enabling more precise manufacturing. As digital technologies evolve, efficient data processing will continue to drive innovation and competitiveness in modern industries"
                                }, {
                                    id: 4, title: "3D to 2D Conversion", content: "This process transforms detailed 3D models into clear, standardized 2D drawings. This is essential for manufacturing, documentation, and compliance. By utilizing modern CAD tools, this process ensures precision, boosts productivity and facilitates collaboration across teams."
                                }, {
                                    id: 5, title: "2D Layout Simulation", content: "It allows manufacturers to design, test, and optimize factory layouts. This process identifies inefficiencies, improves material flow, and enhances labor productivity. Additionally, it saves time, reduces costs, and ensures layouts support operational efficiency, safety, and scalability."
                                }, {
                                    id: 6, title: "Factory Layout and Planning in", content: "Factory Layout and Planning optimize manufacturing by incorporating simulation, modeling, and analysis tools. These processes improve material flow, productivity, and safety while reducing costs. Leveraging digital technologies fosters efficiency, flexibility, and scalability, ensuring success in dynamic industrial environments."
                                }].map(({ id, title, content }) => (
                                    <div key={id} className="mb-3">
                                        <div
                                            className="p-3 bg-light rounded cursor-pointer"
                                            onClick={() => updateToggle(id)}
                                            style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                                        >
                                            <span className="fw-bold" style={{ fontFamily: 'Roboto' }}>{toggle === id ? '-' : '+'} {title}</span>
                                        </div>
                                        {toggle === id && (
                                            <div className="mt-2 px-4">
                                                <p style={{ fontFamily: 'Roboto' }}>{content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                </div>

            </div>
        </div>
    );
};

export default LearningPathDFS;
