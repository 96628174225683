// import React from 'react';

// const WhyAttend = () => {
//   const features = [
//     "Master Cutting-Edge Technology: Learn to design, test, and optimize robotic systems using advanced simulation tools like RobotStudio, V-REP, and RoboDK.",
//     "Real-World Applications: Discover how 6-axis robots are used in industries such as manufacturing, healthcare, and aerospace for tasks like welding, painting, and assembly.",
//     "Cost-Effective Learning: Experience virtual simulation without the need for costly physical hardware, allowing you to practice and refine your skills in a risk-free environment.",
//     "Industry Insights: Stay ahead with knowledge on AI, IoT, and digital twin integration in robotic systems."
//   ];

//   return (
//     <div className="container-fluid px-0">
//       <h3
//         style={{
//           fontWeight: "bold",
//           color: "#000",
//           textAlign: "center",
//           marginTop: "67px",
//         }}
//       >
//         WHY YOU SHOULD <span style={{ color: "orange" }}>ATTEND</span>
//       </h3>
//       <div
//         className="col-12 mx-0"
//         style={{ textAlign: "justify", fontSize: "18px" }}
//       >
//         {features.map((feature, index) => (
//           <div className="col-12 mb-3" key={index}>
//             <div
//               className="key-feature-box p-3 bg-white shadow rounded"
//               style={{ border: "1px solid grey" }}
//             >
//               <li style={{ listStyleType: "none" }}>{feature}</li>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default WhyAttend;





// import React from 'react';

// const WhyAttend = () => {
//   const features = [
//     {
//       heading: "Master Cutting-Edge Technology",
//       description:
//         "Learn to design, test, and optimize robotic systems using advanced simulation tools like RobotStudio, V-REP, and RoboDK.",
//     },
//     {
//       heading: "Real-World Applications",
//       description:
//         "Discover how 6-axis robots are used in industries such as manufacturing, healthcare, and aerospace for tasks like welding, painting, and assembly.",
//     },
//     {
//       heading: "Cost-Effective Learning",
//       description:
//         "Experience virtual simulation without the need for costly physical hardware, allowing you to practice and refine your skills in a risk-free environment.",
//     },
//     {
//       heading: "Industry Insights",
//       description:
//         "Stay ahead with knowledge on AI, IoT, and digital twin integration in robotic systems.",
//     },
//   ];

//   return (
//     <div className="container my-12" style={{ fontFamily: "Roboto ,sans serif" }}>
//       <div className="row justify-content-center">
//         <div className="col-lg-8">
//           <h3
//             style={{
//               fontWeight: "bold",
//               color: "#000",
//               textAlign: "center",
//               marginBottom: "40px",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               marginLeft: '350px',
//               fontSize:'30px',

//             }}
//           >
//             WHY YOU SHOULD <span style={{ color: "orange" }}>ATTEND</span>
//           </h3>


//           <ul
//             style={{
//               listStyleType: "none",
//               padding: 0,
//               display: "flex",
//               flexDirection: "column", // Arrange features in a column
//               gap: "20px", // Add spacing between list items
//               justifyContent: "center",
//               alignItems: "center", // Center-align list items

//             }}
//           >
//             {features.map((feature, index) => (
//               <li
//                 key={index}
//                 style={{
//                   padding: "20px",
//                   marginLeft: 'auto',
//                   borderRight: "5px solid blue", borderRight: "5px solid blue",
//                   marginBottom: "20px", // Add spacing between list items
//                   backgroundColor: "#f9f9f9", // Light background color
//                   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional subtle shadow
//                   width: "148%", // Make sure items occupy full width of the column
//                   textAlign: "center", // Center text within each item
//                   border: '1px solid',
//                 }}
//               >
//                 <strong style={{ color: "#333", fontSize: "18px" }}>
//                   {feature.heading}
//                 </strong>
//                 <p style={{ marginTop: "10px", fontSize: "16px", color: "#555" }}>
//                   {feature.description}
//                 </p>
//               </li>
//             ))}
//           </ul>
//         </div>

//         {/* Optional right column for future content */}
//         <div className="col-lg-4">
//           {/* Additional content can be placed here if needed */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WhyAttend;





// import React from "react";

// const WhyAttend = () => {
//   const features = [
//     {
//       heading: "Master Cutting-Edge Technology",
//       description:
//         "Learn to design, test, and optimize robotic systems using advanced simulation tools like RobotStudio, V-REP, and RoboDK.",
//     },
//     {
//       heading: "Real-World Applications",
//       description:
//         "Discover how 6-axis robots are used in industries such as manufacturing, healthcare, and aerospace for tasks like welding, painting, and assembly.",
//     },
//     {
//       heading: "Cost-Effective Learning",
//       description:
//         "Experience virtual simulation without the need for costly physical hardware, allowing you to practice and refine your skills in a risk-free environment.",
//     },
//     {
//       heading: "Industry Insights",
//       description:
//         "Stay ahead with knowledge on AI, IoT, and digital twin integration in robotic systems.",
//     },
//   ];

//   return (
//     <div className="container my-5" style={{ fontFamily: "Roboto, sans-serif" }}>
//       <div className="row justify-content-center">
//         <div className="col-12 col-lg-8">
//           <h3 className="text-center fw-bold mb-4" style={{ fontSize: "30px" }}>
//             WHY YOU SHOULD <span style={{ color: "orange" }}>ATTEND</span>
//           </h3>

//           <ul className="list-unstyled">
//             {features.map((feature, index) => (
//               <li
//                 key={index}
//                 className="p-3 mb-3 bg-light shadow-sm"
//                 style={{
//                   borderRight: "5px solid blue",
//                   border: "1px solid #ddd",
//                   textAlign: "center",
//                 }}
//               >
//                 <strong className="d-block" style={{ color: "#333", fontSize: "18px" }}>
//                   {feature.heading}
//                 </strong>
//                 <p className="mt-2" style={{ fontSize: "16px", color: "#555" }}>
//                   {feature.description}
//                 </p>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>

//       <style jsx>{`
//         @media (max-width: 768px) {
//           h3 {
//             font-size: 24px;
//           }
//           li {
//             text-align: left;
//             padding: 15px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// export default WhyAttend;




// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//       WHY YOU SHOULD <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>1. Master Cutting-Edge Technology</strong> Learn to design, test, and optimize robotic systems using advanced simulation tools like RobotStudio, V-REP, and RoboDK.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>2.Real-World Applications</strong> Discover how 6-axis robots are used in industries such as manufacturing, healthcare, and aerospace for tasks like welding, painting, and assembly.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>3. Cost-Effective Learning</strong> Experience virtual simulation without the need for costly physical hardware, allowing you to practice and refine your skills in a risk-free environment.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>4. Industry Insights</strong> Stay ahead with knowledge on AI, IoT, and digital twin integration in robotic systemsinnovations, and demands in robotics and automation. Learn from industry experts to bridge the gap between academic knowledge and real-world applications. 
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//             padding: 0;
//           }
//           li {
//             width: 100%; /* Boxes take up the full width */
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center',
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap',
//   justifyContent: 'space-between',
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '5px',
//   width: '(100%)',
//   padding: '10px',
//   // boxSizing: 'border-box',

// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '1px solid #ccc',
//   padding: '15px',
//   // borderRadius: '8px',
//   backgroundColor: '#f9f9f9',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   fontSize: '18px',
//   // margin: '10px auto',
//   textAlign: 'left',
// };

// export default DentalImplants;






// import React from "react";

// const HoverCircles = () => {
//   // Container styles
//   const containerStyle = {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "20px",
//     padding: "20px",
//     backgroundColor: "#f5f5f5",
//     boxSizing: "border-box",
//   };

//   // Box (circle) styles
//   const circleStyle = {
//     position: "relative",
//     width: "300px",
//     height: "300px",
//     borderRadius: "50%", // Makes it circular
//     backgroundColor: "#FFFFFF",
//     color: "orange",
//     fontSize: "14px",
//     fontWeight: "bold",
//     textAlign: "center",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #000",
//     transition: "all 0.3s ease",
//     cursor: "pointer",
//     boxSizing: "border-box",
//   };

//   // Hover effect styles
//   const hoverStyle = {
//     backgroundColor: "#007BFF",
//     color: "#FFFFFF",
//   };

//   // Content for circles
//   const circles = [
//     "Master Cutting-Edge Technology: Learn to design, test, and optimize robotic systems using RobotStudio, V-REP, and RoboDK.",
//     "Real-World Applications: Gain hands-on experience solving industry challenges with advanced robotics systems.",
//     "Collaborative Learning: Work in teams to design, simulate, and deploy innovative robotic solutions.",
//     "Career-Focused Training: Develop skills that employers seek, with tools used in professional robotics fields.",
//   ];

//   return (
//     <div style={containerStyle}>
//       {circles.map((text, index) => (
//         <div
//           key={index}
//           style={circleStyle}
//           onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
//           onMouseLeave={(e) => {
//             e.currentTarget.style.backgroundColor = circleStyle.backgroundColor;
//             e.currentTarget.style.color = circleStyle.color;
//           }}
//         >
//           {text}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default HoverCircles;



// import React from "react";

// const HoverCircles = () => {
//   // Container styles with grid
//   const containerStyle = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
//     gap: "64px",
//     justifyContent: "center",
//     padding: "20px",
//     // backgroundColor: "#f5f5f5",
//     boxSizing: "border-box",
//   };

//   // Circle styles
//   const circleStyle = {
//     position: "relative",
//     width: "100%",
//     height: "300px",
//     borderRadius: "100%", // Makes it circular
//     backgroundColor: "#FFFFFF",
//     color: "#000", // Orange text
//     fontSize: "14px",
//     fontWeight: "bold",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #000",
//     transition: "all 0.3s ease",
//     cursor: "pointer",
//     boxSizing: "border-box",
//   };

//   // Hover effect styles
//   const hoverStyle = {
//     backgroundColor: "#000", // Black background
//     color: "#FFFFFF", // White text
//     animation: "blink 1s infinite", // Blinking text
//   };

//   // Blinking effect via keyframes
//   // const blinkStyle = `
//   //   @keyframes blink {
//   //     50% { opacity: 0; }
//   //   }
//   // `;

//   // Circle content with headings
//   const circles = [
//     {
//       title: "Master Cutting-Edge Technology",
//       description: "Learn to design, test, and optimize robotic systems using <strong>RobotStudio</strong>, <strong>V-REP</strong>, and <strong>RoboDK</strong>.",
//     },
//     {
//       title: "Real-World Applications",
//       description: "Gain hands-on experience solving industry challenges with advanced <strong>robotics systems</strong>.",
//     },
//     {
//       title: "Collaborative Learning",
//       description: "Work in teams to design, simulate, and deploy innovative <strong>robotic solutions</strong>.",
//     },
//     {
//       title: "Career-Focused Training",
//       description: "Develop skills that employers seek, with tools used in professional <strong>robotics fields</strong>.",
//     },
//   ];

//   return (
//     <>
//       {/* Blinking effect styles */}
//       {/* <style>{blinkStyle}</style> */}

//       <div style={containerStyle}>
//         {circles.map((circle, index) => (
//           <div
//             key={index}
//             style={circleStyle}
//             onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor = circleStyle.backgroundColor;
//               e.currentTarget.style.color = "#000"; // Reset to orange text
//             }}
//           >
//             <h3 style={{ margin: "10px 0", fontSize: "25px", color: "orange" }}>
//               {circle.title}
//             </h3>
//             <p
//               style={{ fontSize: "14px", padding: "10px", margin: "0" }}
//               dangerouslySetInnerHTML={{ __html: circle.description }}
//             />
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };

// export default HoverCircles;



// import React from "react";

// const HoverCircles = () => {
//   // Container styles with grid
//   const containerStyle = {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
//     // gap: "60px",
//     justifyContent: "center",
//     padding: "40px",
//     boxSizing: "border-box",
//   };

//   // Circle styles with shadow
//   const circleStyle = {
//     position: "relative",
//     display:'none',
//     width: "80%",
//     height: "280px",
//     borderRadius: "50%", // Makes it circular
//     backgroundColor: "#FFFFFF",
//     color: "#000", // Black text
//     fontSize: "14px",
//     fontWeight: "bold",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #000",
//     boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)", // Shadow leaning to one side
//     transition: "all 0.3s ease",
//     cursor: "pointer",
//     boxSizing: "border-box",
//   };

//   // Hover effect styles
//   const hoverStyle = {
//     backgroundColor: "#000", // Black background
//     color: "#FFFFFF", // White text
//   };

//   // Title container styles
//   const titleContainerStyle = {
//     textAlign: "center",
//     marginBottom: "20px",
//     fontSize: "30px",
//     fontWeight: "bold",
//     color: "#FFA500", // Orange color
//   };

//   // Circle content with headings
//   const circles = [
//     {
//       title: "Master Cutting-Edge Technology",
//       description:
//         "Learn to design, test, and optimize robotic systems using <strong>RobotStudio</strong>, <strong>V-REP</strong>, and <strong>RoboDK</strong>.",
//     },
//     {
//       title: "Real-World Applications",
//       description:
//         "Gain hands-on experience solving industry challenges with advanced <strong>robotics systems</strong>.",
//     },
//     {
//       title: "Collaborative Learning",
//       description:
//         "Work in teams to design, simulate, and deploy innovative <strong>robotic solutions</strong>.",
//     },
//     {
//       title: "Career-Focused Training",
//       description:
//         "Develop skills that employers seek, with tools used in professional <strong>robotics fields</strong>.",
//     },
//   ];

//   return (
//     <div>
//       {/* Title above the circles */}
//       <div style={titleContainerStyle}>
//   <span style={{ color: "#000" }}>WHY YOU SHOULD</span>{" "}
//   <span style={{ color: "#FFA500" }}>ATTEND</span>
// </div>

//       {/* Circles Container */}
//       <div style={containerStyle}>        
//         {circles.map((circle, index) => (
//           <div
//             key={index}
//             style={circleStyle}
//             onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor = circleStyle.backgroundColor;
//               e.currentTarget.style.color = "#000";
//             }}
//           >
//             <h3 style={{ margin: "10px 0", fontSize: "18px", color: "orange" }}>
//               {circle.title}
//             </h3>
//             <p
//               style={{
//                 fontSize: "14px",
//                 padding: "10px",
//                 margin: "0",
//                 textAlign: "center",
//               }}
//               dangerouslySetInnerHTML={{ __html: circle.description }}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default HoverCircles;



// import React from "react";

// const HoverCircles = () => {
//   // Container styles with grid
//   const containerStyle = {
//     display: 'flex',
//     flexDirection: 'row', /* Change from 'column' to 'row' */
//     justifyContent: 'center', /* This will center the items horizontally */
//     alignItems: 'center', /* This will center the items vertically within the row */
//     padding: '0 15px',
//     gap: "20px", // Space between circles
//     width: '100%',
//     maxWidth: '1200px',
//     margin: '0 auto',
//   };

//   // Circle styles with shadow
//   const circleStyle = {
//     position: "relative",
//     width: "100%", // 100% width of the container, which will adjust based on grid
//     maxWidth: "300px", // Limit the max width of circles for better alignment
//     height: "280px", // Set fixed height for consistency
//     borderRadius: "50%", // Makes it circular
//     backgroundColor: "#FFFFFF",
//     color: "#000", // Black text
//     fontSize: "14px",
//     fontWeight: "bold",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #000",
//     boxShadow: "20px 20px 40px rgba(0, 0, 0, 0.9)", // Stronger shadow leaning to one side

//     transition: "all 0.3s ease",
//     cursor: "pointer",
//     boxSizing: "border-box",
//   };

//   // Hover effect styles
//   const hoverStyle = {
//     backgroundColor: "#000", // Black background
//     color: "#FFFFFF", // White text
//   };

//   // Title container styles
//   const titleContainerStyle = {
//     textAlign: "center",
//     marginBottom: "20px",
//     fontSize: "30px",
//     fontWeight: "bold",
//     color: "#FFA500", // Orange color
//   };

//   // Circle content with headings
//   const circles = [
//     {
//       title: "Master Cutting-Edge Technology",
//       description:
//         "Learn to design, test, and optimize robotic systems using <strong>RobotStudio</strong>, <strong>V-REP</strong>, and <strong>RoboDK</strong>.",
//     },
//     {
//       title: "Real-World Applications",
//       description:
//         "Gain hands-on experience solving industry challenges with advanced <strong>robotics systems</strong>.",
//     },
//     {
//       title: "Collaborative Learning",
//       description:
//         "Work in teams to design, simulate, and deploy innovative <strong>robotic solutions</strong>.",
//     },
//     {
//       title: "Career-Focused Training",
//       description:
//         "Develop skills that employers seek, with tools used in professional <strong>robotics fields</strong>.",
//     },
//   ];

//   return (
//     <div>
//       {/* Title above the circles */}
//       <div style={titleContainerStyle}>
//         <span style={{ color: "#000" }}>WHY YOU SHOULD</span>{" "}
//         <span style={{ color: "#FFA500" }}>ATTEND</span>
//       </div>

//       {/* Circles Container */}
//       <div style={containerStyle}>
//         {circles.map((circle, index) => (
//           <div
//             key={index}
//             style={circleStyle}
//             onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor = circleStyle.backgroundColor;
//               e.currentTarget.style.color = "#000";
//             }}
//           >
//             <h3 style={{ margin: "10px 0", fontSize: "20px", color: "orange" }}>
//               {circle.title}
//             </h3>
//             <p
//               style={{
//                 fontSize: "14px",
//                 padding: "10px",
//                 margin: "0",
//                 textAlign: "center",
//               }}
//               dangerouslySetInnerHTML={{ __html: circle.description }}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default HoverCircles;



// import React from "react";

// const HoverCircles = () => {
//   // Container styles with flexbox
//   const containerStyle = {
//     display: 'flex',
//     flexDirection: 'row', // Change from 'column' to 'row'
//     justifyContent: 'center', // This will center the items horizontally
//     alignItems: 'center', // This will center the items vertically within the row
//     padding: '0 15px',
//     gap: "20px", // Space between circles
//     margin:'20px',
//     width: '100%',
//     maxWidth: '1200px',
//     margin: '0 auto',
//   };

//   // Circle styles with shadow
//   const circleStyle = {
//     position: "relative",
//     width: "100%", // 100% width of the container, which will adjust based on grid
//     maxWidth: "300px", // Limit the max width of circles for better alignment
//     height: "280px", // Set fixed height for consistency
//     borderRadius: "50%", // Makes it circular
//     backgroundColor: "#FFFFFF",
//     color: "#000", // Black text
//     fontSize: "14px",
//     fontWeight: "bold",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #000",
//     boxShadow: "20px 20px 40px rgba(0, 0, 0, 0.6)", // Stronger shadow leaning to one side

//     transition: "all 0.3s ease",
//     cursor: "pointer",
//     boxSizing: "border-box",
//   };

//   // Hover effect styles
//   const hoverStyle = {
//     backgroundColor: "#000", // Black background
//     color: "#FFFFFF", // White text
//   };

//   // Title container styles
//   const titleContainerStyle = {
//     textAlign: "center",
//     marginBottom: "20px",
//     fontSize: "30px",
//     fontWeight: "bold",
//     color: "#FFA500", // Orange color
//   };

//   // Circle content with headings
//   const circles = [
//     {
//       title: "Master Cutting-Edge Technology",
//       description:
//         "Learn to design, test, and optimize robotic systems using RobotStudio V-REP and RoboDK.",
//     },
//     {
//       title: "Real-World Applications",
//       description:
//         "Gain hands-on experience solving industry challenges with advanced robotics systems.",
//     },
//     {
//       title: "Collaborative Learning",
//       description:
//         "Work in teams to design, simulate, and deploy innovative robotic solutions.",
//     },
//     {
//       title: "Career-Focused Training",
//       description:
//         "Develop skills that employers seek, with tools used in professional robotics fields.",
//     },
//   ];

//   return (
//     <div>
//       {/* Title above the circles */}
//       <div style={titleContainerStyle}>
//         <span style={{ color: "#000" }}>WHY YOU SHOULD</span>{" "}
//         <span style={{ color: "#FFA500" }}>ATTEND</span>
//       </div>

//       {/* Circles Container */}
//       <div style={containerStyle}>
//         {circles.map((circle, index) => (
//           <div
//             key={index}
//             style={circleStyle}
//             onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor = circleStyle.backgroundColor;
//               e.currentTarget.style.color = "#000";
//             }}
//           >
//             <h3 style={{ margin: "10px 0", fontSize: "20px", color: "orange" }}>
//               {circle.title}
//             </h3>
//             <p
//               style={{
//                 fontSize: "14px",
//                 padding: "10px",
//                 margin: "0",
//                 textAlign: "center",
//               }}
//               dangerouslySetInnerHTML={{ __html: circle.description }}
//             />
//           </div>
//         ))}
//       </div>

//       {/* Media Queries */}
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .container {
//             flex-direction: column; /* Stack circles vertically on mobile */
//           }

//           .circle {
//             max-width: 100%; /* Make the circle take full width on smaller screens */
//             height: 250px; /* Adjust the height for mobile */
            
//           }

//           .title {
//             font-size: 24px; /* Smaller font size for title */
//           }

//           h3 {
//             font-size: 22px; /* Adjust the size of the headings */
//           }

//           ul {
//             flex-direction: column;
//           }

//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }

//           div {
//             font-size: 16px; /* Adjust the font size for mobile */
//           }
//         }
          

//       `}</style>
//     </div>
//   );
// };

// export default HoverCircles;

// import React from "react";

// const HoverCircles = () => {
//   // Container styles with flexbox
//   const containerStyle = {
//     display: "flex",
//     flexDirection: "row", // Change from 'column' to 'row'
//     justifyContent: "center", // This will center the items horizontally
//     alignItems: "center", // This will center the items vertically within the row
//     padding: "0 15px",
//     gap: "20px", // Space between circles
//     width: "100%",
//     maxWidth: "1200px",
//     margin: "0 auto",
//   };

//   // Circle styles with shadow
//   const circleStyle = {
//     position: "relative",
//     width: "100%", // 100% width of the container, which will adjust based on grid
//     maxWidth: "300px", // Limit the max width of circles for better alignment
//     height: "280px", // Set fixed height for consistency
//     borderRadius: "50%", // Makes it circular
//     backgroundColor: "#FFFFFF",
//     color: "#000", // Black text
//     fontSize: "14px",
//     fontWeight: "bold",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #000",
//     boxShadow: "20px 20px 40px rgba(0, 0, 0, 0.6)", // Stronger shadow leaning to one side
//     transition: "all 0.3s ease",
//     cursor: "pointer",
//     boxSizing: "border-box",
//   };

//   // Hover effect styles
//   const hoverStyle = {
//     backgroundColor: "#000", // Black background
//     color: "#FFFFFF", // White text
//     border: "2px solid blue", // Change border to orange
//   };

//   // Title container styles
//   const titleContainerStyle = {
//     textAlign: "center",
//     marginBottom: "20px",
//     fontSize: "30px",
//     fontWeight: "bold",
//     color: "#FFA500", // Orange color
//   };

//   // Circle content with headings
//   const circles = [
//     {
//       title: "Master Cutting-Edge Technology",
//       description:
//         "Learn to design, test, and optimize robotic systems using RobotStudio V-REP and RoboDK.",
//     },
//     {
//       title: "Real-World Applications",
//       description:
//         "Gain hands-on experience solving industry challenges with advanced robotics systems.",
//     },
//     {
//       title: "Collaborative Learning",
//       description:
//         "Work in teams to design, simulate, and deploy innovative robotic solutions.",
//     },
//     {
//       title: "Career-Focused Training",
//       description:
//         "Develop skills that employers seek, with tools used in professional robotics fields.",
//     },
//   ];

//   return (
//     <div>
//       {/* Title above the circles */}
//       <div style={titleContainerStyle}>
//         <span style={{ color: "#000" }}>WHY YOU SHOULD</span>{" "}
//         <span style={{ color: "#FFA500" }}>ATTEND</span>
//       </div>

//       {/* Circles Container */}
//       <div style={containerStyle}>
//         {circles.map((circle, index) => (
//           <div
//             key={index}
//             style={circleStyle}
//             onMouseEnter={(e) =>
//               Object.assign(e.currentTarget.style, hoverStyle)
//             }
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor =
//                 circleStyle.backgroundColor;
//               e.currentTarget.style.color = "#000";
//               e.currentTarget.style.border = circleStyle.border; // Reset border
//             }}
//           >
//             <h3
//               style={{
//                 margin: "10px 0",
//                 fontSize: "20px",
//                 color: "orange",
//               }}
//             >
//               {circle.title}
//             </h3>
//             <p
//               style={{
//                 fontSize: "14px",
//                 padding: "10px",
//                 margin: "0",
//                 textAlign: "center",
//               }}
//               dangerouslySetInnerHTML={{ __html: circle.description }}
//             />
//           </div>
//         ))}
//       </div>

//       {/* Media Queries */}
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .container {
//             flex-direction: column; /* Stack circles vertically on mobile */
//           }

//           .circle {
//             max-width: 100%; /* Make the circle take full width on smaller screens */
//             height: 250px; /* Adjust the height for mobile */
//           }

//           .title {
//             font-size: 24px; /* Smaller font size for title */
//           }

//           h3 {
//             font-size: 22px; /* Adjust the size of the headings */
//           }

//           ul {
//             flex-direction: column;
//           }

//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }

//           div {
//             font-size: 16px; /* Adjust the font size for mobile */
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// export default HoverCircles;




// import React from "react";

// const HoverCircles = () => {
//   // Container styles with flexbox
//   const containerStyle = {
//     display: "flex",
//     flexDirection: "row", // Change from 'column' to 'row'
//     justifyContent: "center", // This will center the items horizontally
//     alignItems: "center", // This will center the items vertically within the row
//     padding: "0 15px",
//     gap: "20px", // Space between circles
//     width: "100%",
//     maxWidth: "1200px",
//     margin: "0 auto",
//   };

//   // Circle styles with shadow (no rotation here)
//   const circleStyle = {
//     position: "relative",
//     width: "100%", // 100% width of the container, which will adjust based on grid
//     maxWidth: "300px", // Limit the max width of circles for better alignment
//     height: "280px", // Set fixed height for consistency
//     borderRadius: "50%", // Makes it circular
//     backgroundColor: "#FFFFFF",
//     color: "#000", // Black text
//     fontSize: "14px",
//     fontWeight: "bold",
//     textAlign: "center",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #000",
//     boxShadow: "20px 20px 40px rgba(0, 0, 0, 0.6)", // Stronger shadow leaning to one side
//     transition: "background-color 0.3s ease, color 0.3s ease, border 0.3s ease", // Apply only color transitions, remove rotation
//     cursor: "pointer",
//     boxSizing: "border-box",
//     transform: "none", // Ensure there's no transformation applied (i.e., no spinning)
//   };

//   // Hover effect styles (no rotation effect here)
//   const hoverStyle = {
//     backgroundColor: "#000", // Black background
//     color: "#FFFFFF", // White text
//     border: "2px solid blue", // Change border to blue
//   };

//   // Title container styles
//   const titleContainerStyle = {
//     textAlign: "center",
//     marginBottom: "20px",
//     fontSize: "30px",
//     fontWeight: "bold",
//     color: "#FFA500", // Orange color
//   };

//   // Circle content with headings
//   const circles = [
//     {
//       title: "Master Cutting-Edge Technology",
//       description:
//         "Learn to design, test, and optimize robotic systems using RobotStudio V-REP and RoboDK.",
//     },
//     {
//       title: "Real-World Applications",
//       description:
//         "Gain hands-on experience solving industry challenges with advanced robotics systems.",
//     },
//     {
//       title: "Collaborative Learning",
//       description:
//         "Work in teams to design, simulate, and deploy innovative robotic solutions.",
//     },
//     {
//       title: "Career-Focused Training",
//       description:
//         "Develop skills that employers seek, with tools used in professional robotics fields.",
//     },
//   ];

//   return (
//     <div>
//       {/* Title above the circles */}
//       <div style={titleContainerStyle}>
//         <span style={{ color: "#000",fontFamily:'roboto', }}>WHY YOU SHOULD</span>{" "}
//         <span style={{ color: "#FFA500" ,fontFamily:'roboto'}}>ATTEND</span>
//       </div>

//       {/* Circles Container */}
//       <div style={containerStyle}>
//         {circles.map((circle, index) => (
//           <div
//             key={index}
//             style={circleStyle}
//             onMouseEnter={(e) =>
//               Object.assign(e.currentTarget.style, hoverStyle)
//             }
//             onMouseLeave={(e) => {
//               e.currentTarget.style.backgroundColor =
//                 circleStyle.backgroundColor;
//               e.currentTarget.style.color = "#000";
//               e.currentTarget.style.border = circleStyle.border; // Reset border
//             }}
//           >
//             <h3
//               style={{
//                 margin: "10px 0",
//                 fontSize: "20px",
//                 color: "orange",
//                 fontFamily:'roboto',
//               }}
//             >
//               {circle.title}
//             </h3>
//             <p
//               style={{
//                 fontSize: "14px",
//                 padding: "10px",
//                 margin: "0",
//                 textAlign: "center",
//               }}
//               dangerouslySetInnerHTML={{ __html: circle.description }}
//             />
//           </div>
//         ))}
//       </div>

//       {/* Media Queries */}
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .container {
//             flex-direction: column; /* Stack circles vertically on mobile */
//           }

//           .circle {
//             max-width: 100%; /* Make the circle take full width on smaller screens */
//             height: 250px; /* Adjust the height for mobile */
//           }

//           .title {
//             font-size: 24px; /* Smaller font size for title */
//           }

//           h3 {
//             font-size: 22px; /* Adjust the size of the headings */
//           }

//           ul {
//             flex-direction: column;
//           }

//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }

//           div {
//             font-size: 16px; /* Adjust the font size for mobile */
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// export default HoverCircles;




import React from "react";

const HoverCircles = () => {
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap", // Allow wrapping of items to the next line
    justifyContent: "center",
    alignItems: "center",
    padding: "0 15px",
    gap: "20px",
    margin: "0 auto",
    width: "100%",
  };

  const circleStyle = {
    position: "relative",
    width: "100%",
    maxWidth: "280px", // Set a maximum width for each circle
    height: "280px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    color: "#000",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #000",
     boxShadow: "20px 20px 40px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s ease, color 0.3s ease, border 0.3s ease",
    cursor: "pointer",
    boxSizing: "border-box",
  };

  const hoverStyle = {
    backgroundColor: "#000",
    color: "#FFFFFF",
    border: "2px solid blue",
  };

  const titleContainerStyle = {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "30px",
    fontWeight: "bold",
    color: "#FFA500",
  };

  const circles = [
    {
      title: "Master Cutting-Edge Technology",
      description:
        "Learn to design, test and optimize robotic systems using RobotStudio V-REP and RoboDK.",
    },
    {
      title: "Real-World Applications",
      description:
        "Gain hands-on experience solving industry challenges with advanced robotics systems.",
    },
    {
      title: "Collaborative Learning",
      description:
        "Work in teams to design, simulate and deploy innovative robotic solutions.",
    },
    {
      title: "Career-Focused Training",
      description:
        "Develop skills that employers seek, with tools used in professional robotics fields.",
    },
  ];

  return (
    <div>
      <div style={titleContainerStyle}>
        <span style={{ color: "#000", fontFamily: "roboto" }}>WHY YOU SHOULD</span>{" "}
        <span style={{ color: "#FFA500", fontFamily: "roboto" }}>ATTEND</span>
      </div>

      <div style={containerStyle}>
        {circles.map((circle, index) => (
          <div
            key={index}
            style={circleStyle}
            onMouseEnter={(e) => Object.assign(e.currentTarget.style, hoverStyle)}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = circleStyle.backgroundColor;
              e.currentTarget.style.color = "#000";
              e.currentTarget.style.border = circleStyle.border;
            }}
          >
            <h3
              style={{
                margin: "10px 0",
                fontSize: "20px",
                color: "orange",
                fontFamily: "roboto",
              }}
            >
              {circle.title}
            </h3>
            <p
              style={{
                fontSize: "14px",
                padding: "10px",
                margin: "0",
                textAlign: "center",
              }}
              dangerouslySetInnerHTML={{ __html: circle.description }}
            />
          </div>
        ))}
      </div>

      <style jsx>{`
        @media (max-width: 1200px) {
          h3 {
            font-size: 18px;
          }

          p {
            font-size: 12px;
          }
        }

        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .circle {
            max-width: 90%; // Adjust the width for mobile
            height: 240px;
          }

          .title {
            font-size: 24px;
          }
        }

        @media (max-width: 480px) {
          h3 {
            font-size: 16px;
          }

          p {
            font-size: 12px;
          }

          .circle {
            height: 220px;
          }
        }
      `}</style>
    </div>
  );
};

export default HoverCircles;



