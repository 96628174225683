import React, { useState, useEffect } from 'react';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    if (!email || !password) {
      setError('Please fill in both fields.');
      return;
    }
    setError('');
  
    // API request
    try {
      const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identifier: email,
          password: password,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Login failed');
      }
  
      const data = await response.json();
      console.log('Login successful:', data);
      // Handle login success (e.g., redirect, store token, etc.)
    } catch (error) {
      console.error('Error during login:', error);
      setError('Login failed. Please check your credentials and try again.');
    }
  };
  

  useEffect(() => {
    document.body.style.backgroundColor = '#f0f4f8';
    window.scrollTo({ top: 0, behavior: 'smooth' });

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  return (
    <div className="container" style={{ marginTop: '200px' }}>
      <h2 style={{ textAlign: 'center' }}>Login Form</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="inputGroup">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
          />
        </div>
        <div className="inputGroup">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
        </div>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <button type="submit" className="button">Login</button>
        <div className="forgotPassword">
          <a href="/forgot-password" className="forgotPasswordLink">Forgot Password?</a>
        </div>
      </form>

      <style>{`
        .container {
          max-width: 400px;
          margin: 50px auto;
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          font-family: Arial, sans-serif;
          background-color: #fff;
        }

        .form {
          display: flex;
          flex-direction: column;
        }

        .inputGroup {
          margin-bottom: 15px;
          text-align: center;
        }

        .inputGroup label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        .input {
          width: 100%;
          padding: 8px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }

        .button {
          padding: 10px;
          background-color: #fb9100;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .button:hover {
          background-color: #0056b3;
        }

        .error {
          color: red;
          text-align: center;
        }

        .success {
          color: green;
          text-align: center;
        }

        .forgotPassword {
          text-align: center;
          margin-top: 10px;
        }

        .forgotPasswordLink {
          color: #007bff;
          text-decoration: none;
        }

        .forgotPasswordLink:hover {
          text-decoration: underline;
        }
      `}</style>
    </div>
  );
};

export default LoginForm;
