// import React, { useState } from "react";

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [nestedToggle, setNestedToggle] = useState({});

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const updateNestedToggle = (nestedId) => {
//         setNestedToggle((prevState) => ({
//             ...prevState,
//             [nestedId]: !prevState[nestedId],
//         }));
//     };

//     const listContent = [
//         {
//             id: 1,
//             title: "Fundamentals of Industrial Robotics ",
//             content: null,
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	Introduction to Industrial Robotics  ",
//                     dummyText: ["*		Overview of Robotics: Purpose, history, evolution, and applications.", "*	•	Types of Robots: Cartesian, SCARA, Articulated (6-Axis), Delta, and Cobots.", ],
//                 },
//                 {
//                     id: 2,
//                     text: "•	6-Axis Robots: Components and Functionality  ",
//                     dummyText: ["*		Mechanical Components: Base, arm, end effector, joints, motors, encoders.","*	Degrees of Freedom (DOF): Understanding 6-axis movement for precision tasks.","*	End Effectors: Types, applications, and hands-on selection exercises.",],
//                 },
//                  {
//                      id: 3,
//                      text: "• Core Robotics Concepts  ",
//                      dummyText: ["*	Kinematics and Dynamics: Forward/inverse kinematics basics.", "*	Work Envelopes and Safety: Optimal workspace design and simulation safety standards."],
//                  },
//             ],
//         },
//         {
//             id: 2,
//             title: "Introduction to Visual Simulation Software ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	 Software and User Interface:",
//                     dummyText: ["*		Software:  system requirements, and troubleshooting.", "*		UI Navigation: Toolbars, panels, 3D views, and workspace customization..",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	  Basics of 3D Modeling and Object Importing :",
//                     dummyText: ["*		3D Navigation: Understanding 3D coordinates and workspace.", "*		Factory Layout Design: Adding machines, robots, conveyors, and boundaries.", "•	Importing CAD Models: Integrating and modifying designs.",],
//                 },
//                 {
//                     id: 3,
//                     text: "•	 Simulation Principles ",
//                     dummyText: ["*		Overview of Simulation: Discrete, dynamic, and event-based models.", "	*		Simple Simulations: Basic robot movements (pick-and-place).", ],
//                 },
                
//             ],
//         },
//         {
//             id: 3,
//             title: "Robot Motion Programming and Simulation  ",
           
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	Motion Programming Techniques:",
//                     dummyText: ["*		Robot Positioning and Calibration: Configuring 6-axis robots.","*	Programming Movements: Linear, joint, circular, and trajectory planning." , "*	Hands-On Exercises: Complex pick-and-place tasks and motion optimization."],
//                 },
//                 {
//                     id: 2,
//                     text: "•	Sensor and Actuator Integration :",
//                     dummyText: ["*		Vision and Proximity Sensors: Simulating adaptive control and collision avoidance","*	Practical Applications: Real-time part recognition"],
//                 },
//                 {
//                     id: 3,
//                     text: "•	Task Sequencing and Multi-Robot Collaboration",
//                     dummyText: ["*		Sequential Programming: Multi-step tasks for single and multiple robots.","*	Collaborative Robotics: Synchronizing robots for coordinated tasks."],
//                 },
                
//             ],
//         },
//         {
//             id: 4,
//             title: " Advanced 3D Modeling and Assembly Systems  ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	1 Complex 3D Factory Layouts ",
//                     dummyText: ["*		Manufacturing: Assembly line optimization, workflow simulation.", "* 	 	Vertical Systems: Multi-level conveyor and system design",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 Assembly Line Automation:",
//                     dummyText: ["*			Assembly Process Design: Simulating robotic assembly tasks.","•	Collision Detection and Path Optimization: Resolving workspace issues and improving motion efficiency.",],
//                 },
               
//             ],
//         },
//         {
//             id: 5,
//             title: " Material Handling and Workflow Efficiency  ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Conveyor System Integration:",
//                     dummyText: ["*				Setup and Synchronization: Robot-conveyor integration for material handling.",],
//                 },
//                 {
//                     id: 2,
//                     text: "•		AGV and Palletizing Systems:",
//                     dummyText: ["*				Automated Guided Vehicles (AGVs): Path planning and workflow simulation.","*				Palletizing Systems: Automated stacking and material transport tasks.",],
//                 },
//                 {
//                     id: 3,
//                     text: "•		Workflow Efficiency and Resource Optimization:",
//                     dummyText: ["*					Bottleneck Analysis: Identifying and resolving process delays.","*				•	Energy and Resource Management: Efficient utilization of robotics systems.",],
//                 },
//             ]
//         },
//         {
//             id: 6,
//             title: " Process Optimization and Performance Analysis  ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Workflow Optimization:",
//                     dummyText: ["*				Real-Time Adjustments: Improving robot utilization and reducing idle time.",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Performance Metrics and Reports:",
//                     dummyText: ["*			•	Metrics Analysis: Cycle time, throughput, and energy consumption.","*	Report Generation: Creating detailed statistical and visual reports."],
//                 },
                
//             ]
//         },
//         {
//             id: 7,
//             title: " Capstone Project Objective:",

//             content:
//             "To apply all acquired skills in a comprehensive robotics simulation project.",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Project Requirements:",
//                     dummyText: ["*					Design a Factory Layout: Integrating robots, conveyors, AGVs, and workstations","*	Advanced Motion Programming: Simulate complex tasks (assembly, transport)","*	Sensor and Actuator Integration: Vision-based object recognition and path adjustments.","*	Workflow Optimization: Eliminate bottlenecks and enhance efficiency.","*	Performance Analysis: Evaluate metrics and generate reports.","*.	Presentation: Document and showcase project results."],
//                 },
                
                
//             ]
//         }
        
//     ];

//     return (
//         <div className="container my-5">
//             {/* <h2
//                 className="fw-bold mb-4"
//                 style={{ color: "#000", fontFamily: "Roboto", width: "100%", textAlign: "center" }}
//             >
//                 6-AXIS INDUSTRIAL  <span style={{ color: "rgb(251 145 0)" }}> TRAINING PROGRAM</span>
//             </h2> */}
           
//             <div className="list-tab" style={{ width: "100%", padding: 0 }}>
//                 {listContent.map(({ id, title, content, nestedContent }) => (
//                     <div key={id} className="mb-3" style={{ width: "100%" }}>
//                         <div
//                             className="p-3 bg-light rounded cursor-pointer d-flex justify-content-between align-items-center"
//                             onClick={() => updateToggle(id)}
//                             style={{
//                                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                                 width: "100%",
//                             }}
//                         >
//                             <span className="fw-bold" style={{ fontFamily: "Roboto", fontSize: "20px" }}>
//                                 {title}
//                             </span>
//                             <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>
//                         </div>
//                         {toggle === id && (
//                             <div className="mt-2 px-4">
//                                 {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
//                                 {nestedContent.map(({ id: nestedId, text, dummyText }) => (
//                                     <div key={nestedId} className="my-2">
//                                         <div
//                                             className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
//                                             onClick={() => updateNestedToggle(nestedId)}
//                                             style={{ border: "1px solid #ddd" }}
//                                         >
//                                             <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
//                                             <span style={{ fontSize: "18px" }}>
//                                                 {nestedToggle[nestedId] ? "-" : "+"}
//                                             </span>
//                                         </div>
//                                         {nestedToggle[nestedId] && (
//                                             <div className="mt-2 px-3">
//                                                 <ul style={{ fontFamily: "Roboto", color: "#555" }}>
//                                                     {dummyText.map((item, index) => (
//                                                         <li key={index}>{item}</li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;




import React, { useState, useEffect } from 'react';

const LearningPathTFD = () => {
    const [toggle, setToggle] = useState(null);
    const [nestedToggle, setNestedToggle] = useState({});
    const [data, setData] = useState([]);

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const updateNestedToggle = (nestedId) => {
        setNestedToggle((prevState) => ({
            ...prevState,
            [nestedId]: !prevState[nestedId],
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('your-api-url');
                const data = await response.json();
                setData(data); // Update the state with fetched data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const listContent = [
        {
            id: 1,
            title: " ABOUT INDUSTRIAL ROBOTICS",
            content: null,
            nestedContent: [],
        },
        {
                        id: 1,
                        title: "Fundamentals of Industrial Robotics ",
                        content: null,
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	Introduction to Industrial Robotics  ",
                                dummyText: ["*		Overview of Robotics: Purpose, history, evolution, and applications.", "*	•	Types of Robots: Cartesian, SCARA, Articulated (6-Axis), Delta, and Cobots.", ],
                            },
                            {
                                id: 2,
                                text: "•	6-Axis Robots: Components and Functionality  ",
                                dummyText: ["*		Mechanical Components: Base, arm, end effector, joints, motors, encoders.","*	Degrees of Freedom (DOF): Understanding 6-axis movement for precision tasks.","*	End Effectors: Types, applications, and hands-on selection exercises.",],
                            },
                             {
                                 id: 3,
                                 text: "• Core Robotics Concepts  ",
                                 dummyText: ["*	Kinematics and Dynamics: Forward/inverse kinematics basics.", "*	Work Envelopes and Safety: Optimal workspace design and simulation safety standards."],
                             },
                        ],
                    },
                    {
                        id: 2,
                        title: "Introduction to Visual Simulation Software ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	 Software and User Interface:",
                                dummyText: ["*		Software:  system requirements, and troubleshooting.", "*		UI Navigation: Toolbars, panels, 3D views, and workspace customization..",],
                            },
                            {
                                id: 2,
                                text: "•	  Basics of 3D Modeling and Object Importing :",
                                dummyText: ["*		3D Navigation: Understanding 3D coordinates and workspace.", "*		Factory Layout Design: Adding machines, robots, conveyors, and boundaries.", "•	Importing CAD Models: Integrating and modifying designs.",],
                            },
                            {
                                id: 3,
                                text: "•	 Simulation Principles ",
                                dummyText: ["*		Overview of Simulation: Discrete, dynamic, and event-based models.", "	*		Simple Simulations: Basic robot movements (pick-and-place).", ],
                            },
                            
                        ],
                    },
                    {
                        id: 3,
                        title: "Robot Motion Programming and Simulation  ",
                       
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	Motion Programming Techniques:",
                                dummyText: ["*		Robot Positioning and Calibration: Configuring 6-axis robots.","*	Programming Movements: Linear, joint, circular, and trajectory planning." , "*	Hands-On Exercises: Complex pick-and-place tasks and motion optimization."],
                            },
                            {
                                id: 2,
                                text: "•	Sensor and Actuator Integration :",
                                dummyText: ["*		Vision and Proximity Sensors: Simulating adaptive control and collision avoidance","*	Practical Applications: Real-time part recognition"],
                            },
                            {
                                id: 3,
                                text: "•	Task Sequencing and Multi-Robot Collaboration",
                                dummyText: ["*		Sequential Programming: Multi-step tasks for single and multiple robots.","*	Collaborative Robotics: Synchronizing robots for coordinated tasks."],
                            },
                            
                        ],
                    },
                    {
                        id: 4,
                        title: " Advanced 3D Modeling and Assembly Systems  ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	1 Complex 3D Factory Layouts ",
                                dummyText: ["*		Manufacturing: Assembly line optimization, workflow simulation.", "* 	 	Vertical Systems: Multi-level conveyor and system design",],
                            },
                            {
                                id: 2,
                                text: "•	 Assembly Line Automation:",
                                dummyText: ["*			Assembly Process Design: Simulating robotic assembly tasks.","•	Collision Detection and Path Optimization: Resolving workspace issues and improving motion efficiency.",],
                            },
                           
                        ],
                    },
                    {
                        id: 5,
                        title: " Material Handling and Workflow Efficiency  ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Conveyor System Integration:",
                                dummyText: ["*				Setup and Synchronization: Robot-conveyor integration for material handling.",],
                            },
                            {
                                id: 2,
                                text: "•		AGV and Palletizing Systems:",
                                dummyText: ["*				Automated Guided Vehicles (AGVs): Path planning and workflow simulation.","*				Palletizing Systems: Automated stacking and material transport tasks.",],
                            },
                            {
                                id: 3,
                                text: "•		Workflow Efficiency and Resource Optimization:",
                                dummyText: ["*					Bottleneck Analysis: Identifying and resolving process delays.","*				•	Energy and Resource Management: Efficient utilization of robotics systems.",],
                            },
                        ]
                    },
                    {
                        id: 6,
                        title: " Process Optimization and Performance Analysis  ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Workflow Optimization:",
                                dummyText: ["*				Real-Time Adjustments: Improving robot utilization and reducing idle time.",],
                            },
                            {
                                id: 2,
                                text: "•	 	Performance Metrics and Reports:",
                                dummyText: ["*			•	Metrics Analysis: Cycle time, throughput, and energy consumption.","*	Report Generation: Creating detailed statistical and visual reports."],
                            },
                            
                        ]
                    },
                    {
                        id: 7,
                        title: " Capstone Project Objective:",
            
                        content:
                        "To apply all acquired skills in a comprehensive robotics simulation project.",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Project Requirements:",
                                dummyText: ["*					Design a Factory Layout: Integrating robots, conveyors, AGVs, and workstations","*	Advanced Motion Programming: Simulate complex tasks (assembly, transport)","*	Sensor and Actuator Integration: Vision-based object recognition and path adjustments.","*	Workflow Optimization: Eliminate bottlenecks and enhance efficiency.","*	Performance Analysis: Evaluate metrics and generate reports.","*.	Presentation: Document and showcase project results."],
                            },
                            
                            
                        ]
                    }
                    
                ];

    return (
        <div className="container my-5">
            {/* <h2
                className="fw-bold mb-4"
                style={{
                    color: "#000",
                    fontFamily: "Roboto",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "-50px",
                }}
            >
                PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
            </h2> */}

            <div className="list-tab" style={{ width: "100%", padding: 0 }}>
                {listContent.map(({ id, title, content, nestedContent }, index) => (
                    <div key={id} className="mb-3" style={{ width: "100%" }}>
                        <div
                            className={`p-3 rounded cursor-pointer d-flex justify-content-between align-items-center ${
                                index === 0 ? "bg-white" : "bg-light"
                            }`}
                            onClick={() => index !== 0 && updateToggle(id)}
                            style={{
                                boxShadow: index === 0 ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "100%",
                                cursor: index === 0 ? "default" : "pointer",
                            }}
                        >
                            <span
                                className={`fw-bold`}
                                style={{
                                    fontFamily: "Roboto",
                                    fontSize: index === 0 ? "19px" : "20px",
                                    color: index === 0 ? "#0072FF" : undefined,
                                }}
                            >
                                {title}
                            </span>
                            {index !== 0 && (
                                <span style={{ fontSize: "20px" }}>
                                    {toggle === id ? "-" : "+"}
                                </span>
                            )}
                        </div>
                        {/* Add line below first item */}
                        {/* {index === 0 && (
                            <div
                                style={{
                                    height: "1px",
                                    backgroundColor: "orange",
                                    width: "20%",
                                     margin: "-13px",
                                     marginLeft: '50px',
                                }}
                            ></div>
                        )} */}
                        {toggle === id && index !== 0 && (
                            <div className="mt-2 px-4">
                                {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
                                {nestedContent.map(({ id: nestedId, text, dummyText }) => (
                                    <div key={nestedId} className="my-2">
                                        <div
                                            className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
                                            onClick={() => updateNestedToggle(nestedId)}
                                            style={{ border: "1px solid #ddd" }}
                                        >
                                            <span
                                                style={{
                                                    fontFamily: "Roboto",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                {text}
                                            </span>
                                            <span style={{ fontSize: "18px" }}>
                                                {nestedToggle[nestedId] ? "-" : "+"}
                                            </span>
                                        </div>
                                        {nestedToggle[nestedId] && (
                                            <div className="mt-2 px-3">
                                                <ul
                                                    style={{
                                                        fontFamily: "Roboto",
                                                        color: "#555",
                                                    }}
                                                >
                                                    {dummyText.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LearningPathTFD;

