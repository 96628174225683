// import React from 'react';
// import Catia from "../img/tierone-images/CatiaLogoImg-removebg-preview.png"; // Adjust file extension or name if needed
// // import Excel from "../img/logo/DigitalFacilitiesSolutionLogo/excel-app-logo.png";
// import siemens from "../img/tierone-images/SimanNxLogoImg-removebg-preview.png";
// import SolidWorks from "../img/tierone-images/Capture-removebg-preview.png";
// import creo from "../img/tierone-images/creo-removebg-preview.png";
// import DeskInventor from "../img/tierone-images/discount-removebg-preview.png";
// import autoCad from "../img/tierone-images/dscart-removebg-preview.png";
// import SolidEdge from "../img/tierone-images/solidage-removebg-preview.png";

// const ToolCoveredImages = () => {
//     return (
//         <div className="container mt-5" >
//             <div className="row " >
//                 <div className="col-11" >
//                     {/* Heading aligned to the left */}
//                     <h3 style={styles.heading}>Software <span style={spanStyle.subHeading}>Tools</span></h3>

//                     {/* Row for images */}
//                     <div className="row justify-content-center ">
//                         {/* First image */}
//                         <div className="col-md-2 col-4 d-flex justify-content-center align-items-center ms-5 me-5 mb-5" style={{backgroundColor:'#d1d1d1',margin:'10px 10px',borderRadius:'20px'}} >
//                             <img src={Catia} alt="Catia" className="img-fluid" style={{ height: '140px', padding:'10px'}} />
//                         </div>

//                         {/* Second image */}
//                         <div className="col-md-2 col-4 d-flex justify-content-center align-items-center ms-5 me-5 mb-5" style={{backgroundColor:'#d1d1d1',margin:'10px 10px',borderRadius:'20px'}}>
//                             <img src={siemens} alt="Siemens" className="img-fluid" style={{ height: '140px',padding:'10px' }} />
//                         </div>

//                         {/* Third image */}
//                         <div className="col-md-2 col-4 d-flex justify-content-center align-items-center ms-5 me-5 mb-5" style={{backgroundColor:'#d1d1d1',margin:'10px 10px',borderRadius:'20px'}}>
//                             <img src={SolidWorks} alt="Excel" className="img-fluid" style={{padding:'10px',height: '140px'}} />
//                         </div>   
                        
//                         <div className="col-md-2 col-4 d-flex justify-content-center align-items-center ms-5 me-5 mb-5" style={{backgroundColor:'#d1d1d1',margin:'10px 10px',borderRadius:'20px'}}>
//                             <img src={creo} alt="Excel" className="img-fluid" style={{ height: '140px',padding:'10px'}} />
//                         </div>
                        
//                         <div className="col-md-2 col-4 d-flex justify-content-center align-items-center ms-5 me-5 mb-5" style={{backgroundColor:'#d1d1d1',margin:'10px 10px',borderRadius:'20px'}}>
//                             <img src={DeskInventor} alt="Excel" className="img-fluid" style={{ height: '140px',padding:'10px'}} />
//                         </div>
                        
//                         <div className="col-md-2 col-4 d-flex justify-content-center align-items-center ms-5 me-5 mb-5" style={{backgroundColor:'#d1d1d1',margin:'10px 10px',borderRadius:'20px'}}>
//                             <img src={autoCad} alt="Excel" className="img-fluid" style={{padding:'10px',height: '140px'}} />
//                         </div>
                        
//                         <div className="col-md-2 col-4 d-flex justify-content-center align-items-center ms-5 me-5 mb-5" style={{backgroundColor:'#d1d1d1',margin:'10px 10px',borderRadius:'20px'}}>
//                             <img src={SolidEdge} alt="Excel" className="img-fluid" style={{padding:'10px',height: '140px'}} />
//                         </div>


//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // CSS-in-JS Styles
// const styles = {
//     heading: {
//         fontSize: '35px',
//         // padding: '30px',
//         textAlign: 'center', // Align heading to the left
//         marginBottom: '30px', // Add some space below the heading
//     },
// };

// const spanStyle = {
//     subHeading: {
//         color:'orange'
//     }
// }

// export default ToolCoveredImages;














import React from 'react';
import Slider from 'react-slick';
import Catia from "../img/tierone-images/CatiaLogoImg-removebg-preview.png"; 
import siemens from "../img/tierone-images/SimanNxLogoImg-removebg-preview.png";
import SolidWorks from "../img/tierone-images/Capture-removebg-preview.png";
import creo from "../img/tierone-images/creo-removebg-preview.png";
import DeskInventor from "../img/tierone-images/discount-removebg-preview.png";
import autoCad from "../img/tierone-images/dscart-removebg-preview.png";
import SolidEdge from "../img/tierone-images/solidage-removebg-preview.png";

// Settings for the Slick slider
const sliderSettings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000, // Change to your preferred speed
    arrows: false, // Enable next and previous arrows
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

const ToolCoveredImages = () => {
    return (
        <div className="container mt-5 pb-5">
            <div className="row">
                <div className="col-12">
                    {/* Heading aligned to the left */}
                    <h3 style={styles.heading}>Software <span style={spanStyle.subHeading}>Tools</span></h3>

                    {/* Slider component */}
                    <Slider {...sliderSettings}>
                        {/* Images in Slider */}
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={SolidWorks} alt="SolidWorks" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={creo} alt="Creo" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={siemens} alt="Siemens" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={DeskInventor} alt="DeskInventor" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={autoCad} alt="AutoCAD" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={Catia} alt="Catia" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={SolidEdge} alt="SolidEdge" className="img-fluid" style={styles.image} />
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

// CSS-in-JS Styles
const styles = {
    heading: {
        fontSize: '35px',
        textAlign: 'center',
        marginBottom: '30px',
    },
    imageContainer: {
        // Rounded corners
        padding: '30px', // Increased padding for better visibility of background
        margin: '10px', // Margin between images
        display: 'flex', // Ensure flex display for centering the content
        justifyContent: 'center', // Center the image inside the container
        alignItems: 'center', // Align items in the center
       
    },
    image: {
        height: '120px',               // Set image height
        backgroundColor: '#fff',       // Background color
        border: '1px solid #000',   // 2px solid yellow border
        margin: '0px 30px',            // Horizontal margin for spacing
        width: '90%',                   // Set width
         borderRadius: '20px',  
    },
};

// Style for the arrows
const arrowStyle = {
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    fontSize: '30px',
    color: '#000',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0 15px', // Increased padding for space around arrows
    transform: 'translateY(-50%)',
};

const spanStyle = {
    subHeading: {
        color: 'orange',
    }
};

// Applying custom styles for arrows using CSS in JS
const CustomArrow = ({ className, style, onClick }) => {
    return (
        <button
            className={className}
            style={{ ...style, ...arrowStyle }}
            onClick={onClick}
        />
    );
};

export default ToolCoveredImages;











