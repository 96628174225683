import React, { useState } from 'react';
import bannerImage from "../img/banners/02_T&F BANNER.jpg";
import './BtnPage.css';
import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';

const Outdoor = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    gender: '',
    message: '',
  });

  const handleEnrollClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
    // Add further logic for submission if needed (e.g., sending data to an API)
   
    closePopup();
  };

  return (
    <div className="hero-area overly-style-1 opacity-point-4">
      <img
        className="banner-image bddgh"
        src={bannerImage}
        alt="About AA Applications Banner"
        style={{ width: '100%', height: '530px' }}
      />

      <div style={{ color: '#fff', position: 'absolute', top: '100px', textAlign: 'left' }} className="about-databanner">
        <h1
          style={{ fontWeight: '700', padding: '10px', marginLeft: '70px', fontSize: '40px', color: 'black' }}
          className="bannerhead"
         >
          Certification on <span style={{ fontSize: '50px', color: '#fb9100' }}>Tools & Fixture Design</span>
        </h1>

        <div style={{ marginLeft: '60px' }}>
          <ul style={{ fontSize: '20px', fontWeight: '600', color: 'black' }}>
            <li>Master the art of designing high-precision BIW (Body in White) fixtures.</li>
            <li>Enhance efficiency and precision in industrial manufacturing processes.</li>
            <li>Learn to develop robust tools tailored for dynamic production needs.</li>
            <li>Optimize production workflows with cutting-edge design methodologies.</li>
            <li>Gain hands-on experience in creating critical industrial components.</li>
            <li>Prepare for competitive roles in modern manufacturing industries.</li>
          </ul>
        </div>

        <div
          className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrd"
          style={{ width: '72%' }}
            >
          <div className="col-auto">
            <button
              type="button"
              className="btn brittle ps-4 pe-4 custom-button"
              style={{ marginTop: '20px', backgroundColor: 'black' }}
              onClick={handleEnrollClick}
            >
              <a style={{ color: 'white' }} target="_blank" rel="noopener noreferrer">
              Enquiry
              </a>
            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn brittle ps-4 pe-4 custom-button"
              style={{ marginTop: '20px', backgroundColor: 'black' }}
              onClick={() => {
                const link = document.createElement('a');
                link.href = brochure;
                link.download = 'Brochure.pdf';
                link.click();
              }}
            >
              Download Brochure
            </button>
          </div>
        </div>

        {/* Popup Component */}
        {showPopup && (
          <>
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                zIndex: 1000,
                borderRadius: '10px',
                width: '500px', // Increased width
              }}
            >
              <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                <span
                  style={{
                    fontSize: '24px',
                    cursor: 'pointer',
                    color: '#555',
                  }}
                  onClick={closePopup}
                >
                  ✖
                </span>
              </div>

              <h3 style={{ textAlign: 'center' }}>Enrollment Details</h3>
              <p style={{ textAlign: 'center' }}>Please fill out the form to enroll.</p>

              {/* Registration Form */}
              <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                  }}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                  }}
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                  }}
                />
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                  }}
                />
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                  }}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <textarea
                  name="message"
                  placeholder="Your Message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    height: '50px',
                  }}
                ></textarea>
                <button
                  type="submit"
                  style={{
                    width: '100%',
                    padding: '12px',
                    backgroundColor: '#495057',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                >
                  Submit
                </button>
              </form>
            </div>

            {/* Overlay Background */}
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 999,
              }}
              onClick={closePopup}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Outdoor;
