

import React, { useState } from 'react';
import robotics from "../img/robotics hands working.mp4";

const LearningPathVR = () => {
    const [toggle, setToggle] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiBody = {
            firstName: formData.name,
            emailId: formData.email,
            contactNumber: formData.phone,
        };

        try {
            const response = await fetch(
                "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(apiBody),
                }
            );

            if (response.ok) {
                alert("Your details have been submitted successfully!");
            } else {
                alert("There was an issue submitting your details.");
            }
        } catch (error) {
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-lg-8">



                </div>

                <div className="col-lg-4">

                </div>
                <h2 className="fw-bold mb-4" style={{ color: '#000', fontFamily: 'Roboto', width: '100%', textAlign: 'center' }}>
                    Learning <span style={{ color: 'rgb(251 145 0)' }}>Path</span>
                </h2>

                <div className="list-tab" style={{ width: '100%', padding: 0 }}>
                    {[
                        {
                            id: 1,
                            title: "9:00 AM - 10:30 AM | Session 1: Introduction to Industrial Robotics",
                            content: (
                                <ul>
                                    <li>Basics of Industrial Robotics:</li>
                                    <li>Components of a 6-Axis Robot:</li>
                                    <li>Key Concepts:</li>
                                    <li>Interactive Example:</li>
                                </ul>
                            )
                        },
                        {
                            id: 2, title: "10:45 AM - 12:30 PM | Session 2: Virtual Simulation Environment Overview", content: (
                                <ul>
                                    <li>Introduction to Tools:</li>
                                    <li>Walkthrough of the Interface:</li>
                                    <li>Hands-On Activity:</li>
                                    <li>Benefits for Students:</li>
                                </ul>
                            )
                        },
                        {
                            id: 3, title: "1:30 PM - 5:30PM | Programming Basics and Hands-On Exercise 1", content: (
                                <ul>
                                    <li>Robot Programming Basics:</li>
                                    <li>Hands-On Exercise:</li>
                                    <li>Student Benefits:</li>
                                    <li>Benefits for Students:</li>
                                </ul>
                            )
                        },
                        { id: 4, title: "Day 2: Advanced Simulation, Troubleshooting and Application", },
                        {
                            id: 5, title: "9:00 AM - 10:30 AM | Advanced Robotic Programming", content: (
                                <ul>
                                    <li>Advanced Techniques:</li>
                                    <li>Interactive Programming:</li>

                                </ul>
                            )
                        },
                        {
                            id: 6, title: "10:30 AM - 12:30 PM | Simulation of Complex Robotic Tasks", content: (
                                <ul>
                                    <li>Task Simulation:</li>
                                    <li>
                                        Student Benefits:
                                    </li>

                                </ul>
                            )
                        },

                        { id: 7, title: "1:30 PM - 5:30 PM | Troubleshooting and Applications", content: (
                            <ul>
                                <li>Troubleshooting Common Issues:</li>
                                <li>
                                Real-World Case Studies:
                                </li>
                                <li>
                                Certificate Distribution
                                </li>

                            </ul>
                        )
                    },


                        { id: 8, title: "Q&A Session",  content: (
                            <ul>
                                <li>Job Prospects:</li>
                                <li>
                                Workshop Takeaways
                                </li>
                             

                            </ul>
                        )},
                       
                    ].map(({ id, title, content }) => (
                        <div key={id} className="mb-3">
                            <div
                                className="p-3 bg-light rounded cursor-pointer"
                                onClick={() => updateToggle(id)}
                            >
                                <span className="fw-bold" style={{ fontSize: '20px', fontWeight: 'bold' }}>{toggle === id ? '-' : '+'} {title}</span>
                            </div>
                            {toggle === id && (
                                <div className="mt-2 px-4" style={{ textAlign: 'justify', backgroundColor: '#f8f9fa', color: '#000' }}>
                                    <p style={{ textAlign: 'justify' }}>{content}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default LearningPathVR;
