import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import picture1 from "../img/banner/Picture1-removebg-preview (1).png";
// import picture1 from "../img/logo/Picture1.jpg";
import picture2 from "../img/logo/Picture2.png";

const OurCoPartneres = () => {
    return (
        <div>
            <div style={{ background: "white" }}>
                <div
                    className="section-full  content-inner-1 what-we-do overlay-black-dark bg-img-fix"
                    id="services"
                >
                    <div className="container">
                        <div className="section-head text-center text-white">
                            <h1 style={{ fontSize: "48px", lineHeight: "1.3",color:'black' }} >
                                Our <span style={{ color: "#F58634" }}>Partners</span>
                            </h1>
                            {/* <h2 className="text-uppercase" style={{ color: "#544395", }}>Our <span style={{ color: " #F58634" }}>Partners</span></h2> */}
                            {/* <div className="dlab-separator bg-primary"></div> */}
                        </div>
                        <div className="section-content">
                            <div className="container mb-5">
                                <div className="row">
                                    <div className="col-lg-6 ow-event-post" >
                                        <div className="ow-event-info conheight " style={{ borderLeft: '1px solid #eae9f9', borderTop: '1px solid #eae9f9',margin:'0px 13px',borderRadius:'20px',boxShadow:'7px 7px 7px #c3c3d3',backgroundColor:'#3a3b40' }}>
                                            <div
                                                style={{ padding: "16px", textAlign: "center" }}
                                            >
                                                <img
                                                    src={picture1}
                                                    alt=""
                                                    style={{ width: "300px", height: "100px" }}

                                                    className="patnerone-imge"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 ow-event-post patner-secondata" >
                                        <div className="ow-event-info conheight " style={{ borderLeft: '1px solid #eae9f9', borderTop: '1px solid #eae9f9',margin:'0px 13px',borderRadius:'20px',boxShadow:'7px 7px 7px #c3c3d3',backgroundColor:'#3a3b40'   }}>
                                            <div
                                                style={{ padding: "16px", textAlign: "center" }}
                                            >
                                                <img
                                                    src={picture2}
                                                    alt=""
                                                    style={{ width: "340px", height: "100px" }}
                                                    className="patnerone-imge"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurCoPartneres;
