import { Tabs, Tab, Form, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure axios is installed
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
} from '@mui/material';

const Enroll = () => {
  const [activeTab, setActiveTab] = useState('registration'); // Track the active tab (registration or enquiry)
  const [activeStage, setActiveStage] = useState('registration'); // Track the active stage
  const [optionss, setOptionss] = useState([]);
  const [availableSubFields, setAvailableSubFields] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const [offlinerobotics, setOfflinerobotics] = useState(null);
  const [filteredData, setFilteredData] = useState(datasource);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [error, setError] = useState(null);
  const [courseduration, setCoursess] = useState([]);
  const [options, setOptions] = useState([]); 
  const [options1, setOptions1] = useState([]); 
  const [showWebDevDropdown, setShowWebDevDropdown] = useState(false);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [date, setDate] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false); 
  const [showMechanicalDropdown, setShowMechanicalDropdown] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [attendanceData, setAttendanceData] = useState(null);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [currentStage, setCurrentStage] = useState('stage1');
  const attendedTimeMinutes = attendanceData?.attendedTime;
  const attendedTimeHours = attendedTimeMinutes ? (attendedTimeMinutes / 60).toFixed(2) : 0;
  const remainingTimeMinutes = attendanceData?.remainingTime;
  const remainingTimeHours = remainingTimeMinutes ? (remainingTimeMinutes / 60).toFixed(2) : 0;
  const handleTabSelect = (stage) => {
    setActiveStage(stage); // Update activeStage state
    console.log('Active Stage:', stage); // Debugging output
  };


  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.get(
          "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/sources"
        );
        if (Array.isArray(response.data)) {
          setOptions(response.data); // Populate options with the API response
        } else {
          console.error("Unexpected API response format");
        }
      } catch (error) {
        console.error("Error fetching sources:", error.message);
      }
    };

    fetchSources();
  }, []);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.get(
          "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/cate"
        );
        if (Array.isArray(response.data)) {
          setOptions1(response.data); // Populate options with the API response
        } else {
          console.error("Unexpected API response format");
        }
      } catch (error) {
        console.error("Error fetching sources:", error.message);
      }
    };

    fetchSources();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert the string to a Date object
    const day = String(date.getDate()).padStart(2, '0'); // Get day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month with leading zero
    const year = date.getFullYear(); // Get full year
    return `${day}-${month}-${year}`; // Return formatted date
  };


  function convertMinutesToTime(totalMinutes) {
    // Calculate hours
    const hours = Math.floor(totalMinutes / 60);

    // Calculate remaining minutes
    const minutes = Math.floor(totalMinutes % 60);

    // Calculate seconds
    const seconds = Math.round((totalMinutes - Math.floor(totalMinutes)) * 60);

    // Pad hours, minutes, and seconds with a leading zero if they are single digits
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    // Return the formatted time
    return `${paddedHours} : ${paddedMinutes} : ${paddedSeconds} `;
  }

  const handleStageSelect = (stage) => {
    setCurrentStage(stage);
  };


  const handlePopUp4 = () => {
    setIsPopupOpen3(false);
    setIsPopupOpen4(true); // Open the popup
    setIsPopupOpen5(false); // Open the popup
  };

  const handlePopUp2 = () => {
    // alert()
    setIsPopupOpen2(true); // Open the popup
  };


  const handleSearch = () => {
    const selectedMonth = document.getElementById("monthSelect").value;
    const selectedYear = document.getElementById("yearSelect").value;

    const filtered = datasource.filter((row) => {
      const rowDate = new Date(row.date); // Assuming `row.date` is in ISO format (e.g., "2024-11-29")

      const matchesDate = date ? row.date === date : true;
      const matchesMonth = selectedMonth ? rowDate.getMonth() + 1 == selectedMonth : true; // Months are 0-indexed
      const matchesYear = selectedYear ? rowDate.getFullYear() == selectedYear : true;

      return matchesDate && matchesMonth && matchesYear;
    });

    setFilteredData(filtered);
  };



  const handlePopUp3 = () => {
    setIsPopupOpen4(false);
    setIsPopupOpen3(true); // Open the popup
    setIsPopupOpen5(false)
  };


  const handlePopUp5 = () => {
    setIsPopupOpen3(false);
    setIsPopupOpen4(false); // Open the popup
    setIsPopupOpen5(true); // Open the popup

  }

  const handleCheckboxClick = () => {
    const newValue = !isTermsAccepted;
    setIsTermsAccepted(newValue);


  };

  const handleChange1 = (e) => {
    setFormData({ ...formData, mode: e.target.value });
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleModalSubmit = () => {
    setIsSubmitClicked(true); // Enable Pay Now button
    setShowModal(false); // Hide modal
  };

  const handleFieldChange = async (e) => {
    const { name, value } = e.target;

    if (name === "duration") {
      // Update the formData with the selected course duration
      setFormData({ ...formData, duration: value });

      console.log("Fetching timings for duration:", value);

      await fetchTime(value); // Fetch timings based on selected course duration
    } else {
      const selectedField = value;
      let subFields = [];

      // Set subFields based on the selected course
      // if (selectedField === "mechanical-engineering") {
      //   subFields = [
      //     { value: "mech1", label: "9AM-6PM" },
      //   ];
      // } else if (selectedField === "technotalks") {
      //   subFields = [
      //     { value: "tech1", label: "9AM-1PM" },
      //     { value: "tech2", label: "2PM-6PM" },
      //   ];
      // }

      setAvailableSubFields(subFields);
      setFormData({ ...formData, course: selectedField });

      // Reset subfield when main field changes

      // Fetch price for the selected course
      try {
        const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=${selectedField}`);
        if (!response.ok) {
          throw new Error("Failed to fetch price data");
        }
        const result = await response.json();
        setOfflinerobotics(result);
        setFormData((prev) => ({ ...prev, coursePrice: result })); // Update price in formData
      } catch (error) {
        setError(error);
      }
    }
  };

  const fetchTime = async (time) => {

    try {
      const response = await fetch(`http://10.10.20.9:7071/IIA-Website-1.0.0/register/timings?courseDuration=${time}`);
      if (!response.ok) {
        throw new Error("Failed to fetch timings");
      }
      const result = await response.json();

      // Map the response to a more usable format
      const subFieldOptions = result.map((item) => ({
        value: item,  // Keep the original value
        label: item.replace(/_/g, ' ').substring(1), // Replace underscores and remove leading underscore
      }));

      setAvailableSubFields(subFieldOptions);
    } catch (error) {
      setError(error);
    }
  };


  const [formData, setFormData] = useState({
    source: '',
    sourceName: '',
    courses: '',
    duration: '_160_HR',
    timings: '_9AM_6PM',
    mode: '',
    branches: '',
    coursePrice: '10' // Include coursePrice if it's part of the form
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page


    var options = {
      key: "rzp_test_rA2UalGsJwTy9v",
      key_secret: "RG7A51JvDSuFfhUzkGVbpAtn",
      amount: 3 * 100,
      currency: "INR",
      captured: true,
      payment_capture: true,
      name: "International Institute Of Automate",
      handler: function (response) {
        alert(
          "Thanks For Your Intrest \n Your Payment has been successfully completed"
        );
        handlePopUp();

      },

      notes: {
        address: "International Institute Of Automate",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();



    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }
    // Log the userId to check its value before submission
    console.log("User ID:", userId);

    // Prepare post data
    const postData = {
      source: formData.source,
      sourceName: formData.sourceName,
      courses: formData.courses.replace(/\s/g, ''), // Remove spaces if needed
      courseDuration: formData.duration,
      timings: formData.timings,
      mode: formData.mode,
      branches: formData.branches,
      registrationFees: formData.coursePrice // Include coursePrice in postData
    };

    // Log post data before making the API call
    console.log("Post Data:", JSON.stringify(postData, null, 2));

    // Check for missing required fields
    const missingFields = Object.keys(postData).filter(key => !postData[key]);

    if (missingFields.length > 0) {
      console.error('Missing required fields:', missingFields);
      alert(`Please fill in the following fields: ${missingFields.join(', ')}`);
      return; // Prevent submission if required fields are empty
    }

    try {
      const response = await fetch(
        `http://10.10.20.9:7071/IIA-Website-1.0.0/course/addcourse/${userId}`, // Ensure userId is appended correctly
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        }
      );

      if (!response.ok) {
        // Log the response for debugging
        const errorData = await response.text(); // Get response body as text
        throw new Error(`HTTP error! Status: ${response.status}, Response: ${errorData}`);
      }

      const data = await response.json();
      console.log('API Response:', data);
      alert('Data added successfully!'); // Success alert
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form: ' + error.message);
    }
  };

  const handlePopUp = () => {

    setIsPopupOpen(true); // Open the popup
  };


  return (
    <div className="custom-tabs-container" style={{ margin: '50px auto', maxWidth: '1100px', marginTop: '100px' }}>
  {/* Top Navigation Tabs - Using <ul> for the tab structure */}
  <ul
  className="custom-tabs d-flex justify-content-center"
  style={{
    listStyleType: 'none',
    height: '58px',
    padding: '8px 0',
    borderRadius: '5px',
    marginBottom: '0px',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '2px solid white',
  }}
>
  <li
    onClick={() => handleTabSelect('registration')}
    style={{
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '14px',
      backgroundColor: activeStage === 'registration' ? 'gray' : '#e1d9d9',
      color: activeStage === 'registration' ? 'orange' : 'black',
      borderRadius: '5px',
      marginRight: '15px',
      textAlign: 'center',
      flexWrap: 'wrap',
    }}
  >
    REGISTRATION
  </li>
  <li
    onClick={() => handleTabSelect('enquiry')}
    style={{
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '14px',
      backgroundColor: activeStage === 'enquiry' ? 'gray' : '#e1d9d9',
      color: activeStage === 'enquiry' ? 'orange' : 'black',
      borderRadius: '5px',
      textAlign: 'center',
    }}
  >
    ENROLL
  </li>
</ul>


  {/* Conditionally render content based on activeTab */}
  {(activeStage  === 'enquiry') && (
    <>
      {/* Registration Stages */}
      <ul
        className="stage-tabs d-flex justify-content-center"
        style={{
          listStyleType: 'none', // Remove the default list styling
          // background: '#65646A',
          padding: '8px',
          borderRadius: '5px',
          fontSize:'14px',
          height:'58px',
          marginBottom: '0px',
          flexWrap: 'wrap',
        }}
      >
        {['stage1', 'stage2', 'stage3', 'stage4', 'stage5'].map((stage) => (
          <li
            key={stage}
            onClick={() => handleStageSelect(stage)}
            style={{
              padding: '10px 20px',
              cursor: 'pointer',
              fontWeight: 'bold',
              marginRight: '40px',
              color: '#F58938', // Active stage text color
              backgroundColor: currentStage === stage ? 'gray' : '#e1d9d9',
              color: currentStage === stage ? 'orange' : 'black',
              
              borderRadius: '5px',
              marginRight: '15px',
              textAlign: 'center',
            }}
          >
            {`STAGE ${stage.split('stage')[1]}`}
          </li>
        ))}
      </ul>

      {/* Form Content */}
      <div
        style={{
          backgroundColor: '#ffffff', // White card
          padding: '30px',
          borderRadius: '10px',
        }}
      >
        
      </div>

      {activeStage === 'stage2' && (

<div>

<h2
  style={{
    textAlign: "center",
    color: "#5B4B99",
    paddingBottom: "50px",
    paddingTop: "30px",
  }}
>
  Terms And Conditions
</h2>
<div className="row" style={{ paddingBottom: '25px', width: "70%", margin: "0 auto" }}>
  <p style={{ textAlign: "justify" }}>
    By submitting the IIA Automate course payment form, you agree to the terms and conditions
    outlined. Payment is required in full upon registration, and refunds are only available
    within 14 days of payment, provided course materials have not been accessed. Course access
    is granted after successful payment and will remain available for the specified duration.
  </p>
  <p style={{ textAlign: "justify" }}>
    All course materials are the intellectual property of IIA Automate and may not be reproduced
    without permission. Participants must adhere to professional conduct during the course.
    Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
    right to update these terms at any time.
  </p>
  <p style={{ textAlign: "justify" }}>
    Any breach of these terms and conditions may result in the immediate termination of course access
    without refund. IIA Automate retains the right to revoke access if it is determined that the terms
    have been violated, including but not limited to sharing login credentials or course materials
    with unauthorized individuals. Participants are expected to complete the course independently, and
    any form of academic dishonesty will not be tolerated.
  </p>

  <p style={{ textAlign: "justify" }}>
    The course content is designed for educational purposes only, and IIA Automate does not guarantee
    specific career outcomes or certifications upon completion. While we strive to provide accurate and
    up-to-date information, we are not liable for any errors or omissions in the course materials. It is
    the participant's responsibility to ensure they meet any technical or hardware requirements needed
    to complete the course.
  </p>


  <div className="checkbox-container" style={{ display: "flex", alignItems: "center" }}>
    <input
      type="checkbox"
      id="terms"
      checked={isTermsAccepted}
      onClick={handleModalSubmit} // Use the new handleModalSubmit function
      required
      style={{ display: "none" }}
    />
    <div
      onClick={handleCheckboxClick}
      style={{
        cursor: "pointer",
        width: "15px",
        height: "15px",
        border: "2px solid green",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isTermsAccepted ? "green" : "white",
        marginTop: "-6px",
      }}
    >
      {isTermsAccepted && (
        <FontAwesomeIcon icon={faCheckSquare} style={{ color: "white" }} />
      )}
    </div>
    <label htmlFor="terms" style={{ marginLeft: "10px", cursor: "default" }}>
      I agree to the{" "}
      <span
        style={{ color: "blue", cursor: "pointer" }}

      >
        Terms and Conditions
      </span>
    </label>


  </div>

</div>


<button
  className="custom-btn "
  
  disabled={!isTermsAccepted} // Disable the button if terms are not accepted

  style={{
    marginLeft: '50%',
    backgroundColor: isTermsAccepted ? "#f6954e" : "lightgray", // Green if enabled, light gray if disabled
    color: isTermsAccepted ? "white" : "black", // White text if enabled, dark gray if disabled
    cursor: isTermsAccepted ? "pointer" : "not-allowed", // Pointer if enabled, not-allowed if disabled
    border: "none", // Optional: remove border if you want
    padding: "10px 20px", // Optional: padding for the button
    borderRadius: "5px", // Optional: rounded corners
    transition: "background-color 0.3s ease", // Smooth transition for color change
  }}

>
  Next
</button>
</div>

)}


{/* / */}







      
    </>
  )}




  




{(activeStage  === 'registration') && (
 <form onSubmit={handleSubmit} style={{marginTop:'20px'}} className="donate-form">
 <div className="row">
   {/* First Row - 5 Fields */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Source*</Form.Label> */}
     <Form.Select
        aria-label="Select Source"
        name="source"
        style={{ height: "36px", width: "100%" }}
        value={formData.source}
        onChange={handleChange}
        required
      >
        <option value="">Source*</option>
        {options.length > 0 &&
          options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
      </Form.Select>
   </div>

   {/* Source Name */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Source Name*</Form.Label> */}
     <Form.Control
       type="text"
       style={{ height: '36px', width: '100%' }}
       placeholder="Source Name"
       className="custom-input"
       name="sourceName"
       value={formData.sourceName}
       onChange={handleChange}
       required
     />
   </div>

   {/* Courses */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Courses*</Form.Label> */}
     <Form.Select
       aria-label="Select Course"
       name="courses"
       style={{ height: '36px', width: '100%' }}
       value={formData.courses}
       onChange={handleChange}
       required
     >
       <option value="">Courses*</option>
       <option value="ToolandFixtureDesign">Tool & Fixture Design</option>
       <option value="Offline Robotics">Offline Robotics</option>
       <option value="Online Robotics">Online Robotics</option>
       <option value="Java">Java</option>
       <option value="React Js">React Js</option>
       <option value="Flutter">Flutter</option>
     </Form.Select>
   </div>

   {/* Category */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Category*</Form.Label> */}
     <Form.Select
       aria-label="Select Category"
       name="category"
       style={{ height: '36px', width: '100%' }}
       value={formData.category}
       onChange={handleChange}
       required
     >
       <option value="">Category*</option>
       {options1.length > 0 &&
          options1.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
     </Form.Select>
   </div>

   {/* Duration */}
  
 </div>

 <div className="row">
 <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Courses Duration*</Form.Label> */}
     <Form.Select
       aria-label="Select Duration"
       name="duration"
       style={{ height: '36px', width: '100%' }}
       value={formData.duration}
       onChange={handleFieldChange}
       required
     >
       <option value="">Courses Duration*</option>
       {courseduration.map((duration, index) => (
         <option key={index} value={duration}>
           {duration.replace(/_/g, ' ')}
         </option>
       ))}
     </Form.Select>
   </div>
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Date*</Form.Label> */}
     <Form.Select
       aria-label="Select Date"
       name="timings"
       style={{ height: '36px', width: '100%' }}
       value={formData.timings}
       onChange={(e) => setFormData({ ...formData, timings: e.target.value })}
       required
     >
       <option value="">Date*</option>
       {availableSubFields.map((subField) => (
         <option key={subField.value} value={subField.value}>
           {subField.label}
         </option>
       ))}
     </Form.Select>
   </div>

   {/* Time */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Time*</Form.Label> */}
     <Form.Select
       aria-label="Select Time"
       name="timings"
       style={{ height: '36px', width: '100%' }}
       value={formData.timings}
       onChange={(e) => setFormData({ ...formData, timings: e.target.value })}
       required
     >
       <option value="">Time*</option>
       {availableSubFields.map((subField) => (
         <option key={subField.value} value={subField.value}>
           {subField.label}
         </option>
       ))}
     </Form.Select>
   </div>

   {/* Mode */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Mode*</Form.Label> */}
     <Form.Select
       aria-label="Select Mode"
       name="mode"
       style={{ height: '36px', width: '100%' }}
       value={formData.mode}
       onChange={handleChange}
       required
     >
       <option value="">Mode*</option>
       <option value="Offline">OFFLINE</option>
       <option value="Online">ONLINE</option>
     </Form.Select>
   </div>

   </div>


 {/* Second Row - 5 Fields */}
 <div className="row">
   {/* Date */}
  

   {/* Branches */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Branches*</Form.Label> */}
     <Form.Select
       aria-label="Select Branch"
       name="Location"
       style={{ height: '36px', width: '100%' }}
       value={formData.Location}
       onChange={handleChange}
       required
     >
       <option value="">Location</option>
       <option value="Hyderabad">Hyderabad</option>
       <option value="Bangloore">Bangloore</option>
       <option value="Chennai">Chennai</option>
       <option value="Pune">Pune</option>
     </Form.Select>
   </div>

   {/* Registration Fee */}
   <div className="col-md-3 col-sm-12 mb-3">
     {/* <Form.Label className="form-label">Registration Fee*</Form.Label> */}
     <Form.Control
       type="text"
       style={{ height: '36px', width: '100%' }}
       placeholder={offlinerobotics?.coursePrice || 10}
       value={formData.coursePrice || ""}
       required
     />
   </div>
 </div>

 {/* Submit Button */}
 <div style={{marginTop:'10px'}} className="row">
   <div className="col-12 d-flex justify-content-center">
     <button type="submit" className="btn btn-primary" style={{ width: '200px' }}>
       PAY NOW
     </button>
   </div>
 </div>
</form>
)}
  


</div>





  );

  
};

export default Enroll;
