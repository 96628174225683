


// import React, { useEffect, useState } from 'react';
// import image1 from '../img/logo/New folder/IMG-20241127-WA0001.jpg'; 
// import image2 from '../img/logo/New folder/IMG-20241127-WA0003.jpg'; 
// import image3 from '../img/logo/New folder/IMG-20241127-WA0002 (1).jpg';
// import { FaLinkedin, FaArrowLeft, FaArrowRight, FaQuoteLeft } from 'react-icons/fa'; // Import the quote icon
// import { borderLeftColor, fontFamily, maxWidth } from '@mui/system';
// import "./Newtestmonial.css";
// const Testimonial = () => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const testimonials = [
//     {
//       image: image1,
//       text: "As a trainer with 6 years of expertise in Virtual Robotics, my passion lies in bridging the gap between theoretical concepts and practical applications. My career has focused on developing robotic simulations, programming, and integrating advanced tools to design intelligent systems. I ensure that my trainees gain hands-on experience in creating real-world robotic models, preparing them for a future where automation and robotics redefine the industry landscape evolving field of robotics and automation.",
//       name: "G. Lokesh",
//       designation: " Automation Training Specialist",
//       linkedin: "https://www.linkedin.com/in/md-ghouse",
//     },
//     {
//       image: image2,
//       text: "With 15 years of extensive experience in Tools and Fixture Design, I bring a wealth of knowledge to every training session. My expertise lies in designing innovative tools and fixtures that align with industry standards, improving manufacturing accuracy, and reducing downtime. My sessions are structured to provide insights into problem-solving, 2D layout studies, and quality control measures, ensuring that learners leave with practical skills they can immediately apply in their careers practical skills they can immediately..",
//       name: "Riyaz Uddin",
//       designation: "Subject Matter Expert",
//       linkedin: "https://www.linkedin.com/in/saritha-komandla",
//     },
//     {
//       image: image3,
//       text: "With over 5 years of experience in the field of Digital Facility Solutions, I specialize in transforming traditional industrial processes into smart, efficient, and automated systems. My journey in this domain has been marked by implementing cutting-edge technologies and guiding teams in adopting digital solutions that enhance productivity and reduce operational costs. Training with me means diving deep into the core of digital facility design, understanding parametric modeling, and mastering innovative.",
//       name: "MD. Ghouse",
//       designation: "Technical Trainer",
//       linkedin: "https://www.linkedin.com/in/thirumal-rao",
//     },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
//     }, 3000); // Change every 3 seconds

//     return () => clearInterval(interval);
//   }, [testimonials.length]);

//   const containerStyle = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '20px',
//     overflow: 'hidden',
//     position: 'relative',
//     marginTop: '132px',
//     transition: 'all 0.3s ease',
//     height: '686px',
  
//   };

//   const styles = {
//     title: {
//       textAlign: 'center',
//       fontFamily: 'Roboto, sans-serif',
//       fontWeight: 700,
//       fontSize: '48px',
//       marginBottom: '-100px',
//       marginTop: '60px',
//     },
//     titleBlack: {
//       color: 'black',
//     },
//     titleOrange: {
//       color: 'orange',
//     },
//   };

//   const cardStyle = {
//     position: 'relative',
//     transition: 'transform 0.3s ease-in-out',
//     maxWidth: '400px',
//     textAlign: 'center',
//     backgroundColor: '#ffffff',
//     borderRadius: '10px',
//     padding: '20px',
//     margin: '0 20px',
//     boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//     borderWidth: '2px',
//     borderStyle: 'solid',
//     borderColor: '#029aa0',
//   };

//   const activeCardStyle = {
//     ...cardStyle,
//     transform: 'scale(1.1)', // Enlarge the center card
//   };

//   const imageStyle = {
//     width: '130px',
//     height: '130px',
//     borderRadius: '50%',
//     marginBottom: '10px',
//     border: '2px solid #029aa0',
//     transition: 'transform 0.3s ease',
//     marginTop: '-117px',
//     position: 'relative',
//   };

//   const textStyle = {
//     fontSize: '14px',
//     color: '#000',
//     lineHeight: '1.6',
//     marginBottom: '15px',
//     marginTop:'40px',
//   };

//   const buttonWrapperStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     marginTop: '10px',

//   };

//   const iconStyle = {
//     fontSize: '20px',
//     color: '#0077b5',
//     marginRight: '8px',
//   };

//   const buttonStyle = {
//     display: 'inline-block',
//     padding: '10px 15px',
//     fontSize: '14px',
//     fontWeight: 'bold',
//     color: '#ffffff',
//     backgroundColor: '#029aa0',
//     border: 'none',
//     borderRadius: '50px',
 
//     textDecoration: 'none',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   const buttonHoverStyle = {
//     backgroundColor: '#005582',
//   };

//   const quoteIconStyle = {
//     color: '#029aa0', // Color of the quote icon
//     fontSize: '20px', // Larger font size for better visibility
//     position: 'absolute', // Positioning it absolutely
//     top: '20px', // Adjust for positioning above the image
//     zIndex: 1, // Ensure it appears above other content
//   };

//   const leftQuoteIconStyle = {
//     ...quoteIconStyle,
//     left: '20px', // Positioning to the left of the image
//   };

//   const rightQuoteIconStyle = {
//     ...quoteIconStyle,
//     right: '20px', // Positioning to the right of the image
//   };

//   const designationStyle = {
//     fontSize: '25px',
//     color: '#888',
//     marginTop: '10px',
//     fontFamily: 'roboto',
//   };

//   return (
//     <div>
//       <h1 style={styles.title}>
//         <span style={styles.titleBlack}>What Our</span>{' '}
//         <span style={styles.titleOrange}>Trainers Say</span>
//       </h1>
//       <div style={containerStyle}>
//         {testimonials.map((testimonial, index) => (
//           <div
//             key={index}
//             style={index === activeIndex ? activeCardStyle : cardStyle}
//           >
//             <div style={{ position: 'relative' }}>
//               {/* Left quote icon */}
//               <FaQuoteLeft style={leftQuoteIconStyle} />
//               {/* Image */}
//               <img
//                 src={testimonial.image}
//                 alt={testimonial.name}
//                 style={imageStyle}
//               />
//               {/* Right quote icon */}
//               <FaQuoteLeft style={rightQuoteIconStyle} />
//             </div>
//             <p style={textStyle}>{testimonial.text}</p>
//             <div style={buttonWrapperStyle}>
//               <a
//                 href={testimonial.linkedin}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 style={{ textDecoration: 'none' }}
//               >
//                 <FaLinkedin style={iconStyle} />
//               </a>
//               <span style={buttonStyle}>{testimonial.name}</span>
//             </div>
//             <p style={designationStyle}>{testimonial.designation}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Testimonial;









// import React from 'react';
// import OwlCarousel from 'react-owl-carousel'; // Import OwlCarousel
// import image1 from '../img/logo/New folder/IMG-20241127-WA0001.jpg'; 
// import image2 from '../img/logo/New folder/IMG-20241127-WA0003.jpg'; 
// import image3 from '../img/logo/New folder/IMG-20241127-WA0002 (1).jpg';
// import { FaLinkedin, FaQuoteLeft } from 'react-icons/fa'; 
// import 'owl.carousel/dist/assets/owl.carousel.css'; // Import OwlCarousel styles
// import 'owl.carousel/dist/assets/owl.theme.default.css'; // Import OwlCarousel theme styles

// const Testimonial = () => {
//   const testimonials = [
//     {
//       image: image1,
//       text: "As a trainer with 6 years of expertise in Virtual Robotics, my passion lies in bridging the gap between theoretical concepts and practical applications. My career has focused on developing robotic simulations, programming, and integrating advanced tools to design intelligent systems. I ensure that my trainees gain hands-on experience in creating real-world robotic models, preparing them for a future where automation and robotics redefine the industry landscape evolving field of robotics and ",
//       name: "G. Lokesh",
//       designation: "Automation Training Specialist",
//       linkedin: "https://www.linkedin.com/in/md-ghouse",
//     },
//     {
//       image: image2,
//       text: "With 15 years of extensive experience in Tools and Fixture Design, I bring a wealth of knowledge to every training session. My expertise lies in designing innovative tools and fixtures that align with industry standards, improving manufacturing accuracy, and reducing downtime. My sessions are structured to provide insights into problem-solving, 2D layout studies, and quality control measures, ensuring that learners leave with practical skills they can immediately apply in their careers practical skills they can immediately",
//       name: "Riyaz Uddin",
//       designation: "Subject Matter Expert",
//       linkedin: "https://www.linkedin.com/in/saritha-komandla",
//     },
//     {
//       image: image3,
//       text: "With over 5 years of experience in the field of Digital Facility Solutions, I specialize in transforming traditional industrial processes into smart, efficient, and automated systems. My journey in this domain has been marked by implementing cutting-edge technologies and guiding teams in adopting digital solutions that enhance productivity and reduce operational costs. Training with me means diving deep into the core of digital facility design, understanding parametric modeling, and mastering innovative",
//       name: "MD. Ghouse",
//       designation: "Technical Trainer",
//       linkedin: "https://www.linkedin.com/in/thirumal-rao",
//     },
//   ];

//   const options = {
//     className: "owl-theme",
//     loop: true,
//     margin: 20,
//     nav: false, // Disable navigation arrows
//     autoplay: true, // Enable autoplay
//     autoplayTimeout: 3000, // Set autoplay interval (3 seconds)
//     autoplayHoverPause: true, // Pause on hover
//     dots: false, // Disable dots pagination
//     responsive: {
//       0: {
//         items: 1, // Show 1 card on mobile
//       },
//       600: {
//         items: 2, // Show 2 cards on tablets
//       },
//       1000: {
//         items: 3, // Show 3 cards on desktops
//       },
//     },
//   };

//   return (
//     <div style={{
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       height: '100vh',
//       padding: '0 20px', // Add padding on left and right
//     }}>
//       <div style={{
//         width: '100%',
//         maxWidth: '1200px',
//         maxHeight:'800px', // Limit the maximum width of the container
//         textAlign: 'center',
//         paddingTop: '60px',
//         marginTop: '-50px',
//       }}>
//         <h1 style={{
//           fontFamily: 'Roboto',
//           fontWeight: 700,
//           fontSize: '48px',
//           marginBottom: '40px',
//         }}>
//           <span style={{ color: 'black' }}>What Our</span>{' '}
//           <span style={{ color: 'orange' }}>Trainers Say</span>
//         </h1>

//         {/* Owl Carousel */}
//         <OwlCarousel {...options}>
//           {testimonials.map((testimonial, index) => (
//             <div key={index} className="testimonial-item" style={{
//               textAlign: 'center',
//               padding: '20px',
//               border: '2px solid #029aa0',
//               borderRadius: '10px',
//               boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//               height: '100%',
           
//               //  marginTop: '61px',
               
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               justifyContent: 'center',
//               backgroundColor: '#fff',
//               marginBottom: '40px',
//               marginTop:'100px', // Reduced margin to avoid excessive space
//             }}>
//               <div style={{ position: 'relative' }}>
//                 <FaQuoteLeft style={{ position: 'absolute', top: '40px', left: '128px', color: '#029aa0', fontSize: '20px' }} />
//                 <img
//                   src={testimonial.image}
//                   alt={testimonial.name}
//                   style={{
//                     width: '130px',
//                     height: '130px',
//                     borderRadius: '50%',
//                     border: '2px solid #029aa0',
//                     position: 'absolute',
//                     top: '-117px', 
//                     left: '50%',
//                     transform: 'translateX(-50%)',
//                     marginBottom: '10px',
//                   }}
//                 />
//                 <FaQuoteLeft style={{ position: 'absolute', top: '40px', right: '128px', color: '#029aa0', fontSize: '20px' }} />
//               </div>

//               <p style={{
//                 fontSize: '14px',
//                 color: '#000',
//                 lineHeight: '1.6',
//                 marginBottom: '15px',
//                 marginTop: '90px',
//               }}>
//                 {testimonial.text}
//               </p>

//               <div style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 marginTop: '10px',
//               }}>
//                 <a href={testimonial.linkedin} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
//                   <FaLinkedin style={{ fontSize: '20px', color: '#0077b5', marginRight: '8px' }} />
//                 </a>
//                 <span style={{
//                   display: 'inline-block',
//                   padding: '10px 15px',
//                   fontSize: '14px',
//                   fontWeight: 'bold',
//                   color: '#ffffff',
//                   backgroundColor: '#029aa0',
//                   borderRadius: '50px',
//                 }}>
//                   {testimonial.name}
//                 </span>
//               </div>

//               <p style={{
//                 fontSize: '25px',
//                 color: '#888',
//                 marginTop: '10px',
//                 fontFamily: 'Roboto',
//               }}>
//                 {testimonial.designation}
//               </p>
//             </div>
//           ))}
//         </OwlCarousel>
//       </div>
//     </div>
//   );
// };

// export default Testimonial;





import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import image1 from '../img/logo/New folder/IMG-20241127-WA0001.jpg';
import image2 from '../img/logo/New folder/IMG-20241127-WA0003.jpg';
import image3 from '../img/logo/New folder/IMG-20241127-WA0002 (1).jpg';
import { FaLinkedin, FaQuoteLeft } from 'react-icons/fa';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonial = () => {
  const testimonials = [
    {
      image: image1,
      text: "As a trainer with 6 years of expertise in Virtual Robotics, my passion lies in bridging the gap between theoretical concepts and practical applications. My career has focused on developing robotic simulations, programming, and integrating advanced tools to design intelligent systems. I ensure that my trainees gain hands-on experience in creating real-world robotic models, preparing them for a future where automation and robotics redefine the industry landscape evolving field of robotics and ",
      name: "G. Lokesh",
      designation: "Automation Training Specialist",
      linkedin: "https://www.linkedin.com/in/md-ghouse",
    },
    {
      image: image2,
      text: "With 15 years of extensive experience in Tool and Fixture Design, I bring a wealth of knowledge to every training session. My expertise lies in designing innovative tools and fixtures that align with industry standards, improving manufacturing accuracy and reducing downtime. My sessions are structured to provide insights into problem-solving, 2D layout studies, and quality control measures, ensuring that learners leave with practical skills they can immediately apply in their careers practical skills they can immediately",
      name: "Riyaz Uddin",
      designation: "Subject Matter Expert",
      linkedin: "https://www.linkedin.com/in/saritha-komandla",
    },
    {
      image: image3,
      text: "With over 5 years of experience in the field of Digital Facility Solutions, I specialize in transforming traditional industrial processes into smart, efficient, and automated systems. My journey in this domain has been marked by implementing cutting-edge technologies and guiding teams in adopting digital solutions that enhance productivity and reduce operational costs. Training with me means diving deep into the core of digital facility design, understanding parametric modeling, and mastering innovative",
      name: "MD. Ghouse",
      designation: "Technical Trainer",
      linkedin: "https://www.linkedin.com/in/thirumal-rao",
    },
  ];

  const options = {
    className: "owl-theme",
    loop: true,
    margin: 20,
    nav: false,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      padding: '0 20px',
    }}>
      <div style={{
        width: '100%',
        maxWidth: '1200px',
        textAlign: 'center',
        padding: '50px 0',
      }}>
        <h1 style={{
          fontFamily: 'Roboto',
          fontWeight: 700,
          fontSize: '36px',
          marginBottom: '40px',
        }}>
          <span style={{ color: 'black' }}>What Our</span>{' '}
          <span style={{ color: 'orange' }}>Trainers Say</span>
        </h1>
        <OwlCarousel {...options}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item" style={{
              padding: '20px',
              border: '1px solid #029aa0',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <div style={{ position: 'relative', marginBottom: '30px' }}>
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    border: '2px solid #029aa0',
                    marginBottom: '10px',
                  }}
                />
              </div>
              <p style={{
                fontSize: '14px',
                color: '#333',
                lineHeight: '1.6',
                marginBottom: '20px',
              }}>
                {testimonial.text}
              </p>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '10px',
              }}>
                <a href={testimonial.linkedin} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', marginRight: '10px' }}>
                  <FaLinkedin style={{ fontSize: '20px', color: '#0077b5' }} />
                </a>
                <span style={{
                  display: 'inline-block',
                  padding: '10px 15px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#ffffff',
                  backgroundColor: '#029aa0',
                  borderRadius: '50px',
                }}>
                  {testimonial.name}
                </span>
              </div>
              <p style={{
                fontSize: '16px',
                color: '#666',
                marginTop: '5px',
              }}>
                {testimonial.designation}
              </p>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Testimonial;
