import React, { useState,useEffect } from 'react';
import logo from "../img/logo/IIA_LogoHead.png";
import { useNavigate } from 'react-router-dom';
import backgroundImage from "../img/aaaservices/image1.png"; 



const SignUpEmp = () => {
    
  const [loginObj, setLoginObj] = useState({name: '', emailId: '', password: '',phoneNumber:'',organisation:''});
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate(); 
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [categories, setCategories] = useState([]); 
  const [selectedCategory, setSelectedCategory] = useState(""); 
  const [loading, setLoading] = useState(true); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginObj({ ...loginObj, [name]: value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission
    setError(''); // Clear previous errors
    setLoading(true); // Set loading to true when the request starts
  
    try {
      const response = await fetch(
        'https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/register',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            organisation: loginObj.organisation || '',
            name: loginObj.name,
            mobileNumber: loginObj.phoneNumber,
            emailId: loginObj.emailId,
            password: loginObj.password,
            role: 'User',
            categories: selectedCategory,
          }),
        }
      );
  
      const textResponse = await response.text(); // Extract the response message
      console.log('Response:', textResponse);
  
      if (!response.ok) {
        // Handle specific error messages
        if (
          textResponse === 'EmailId is Already in Use' ||
          textResponse === 'Mobile Number is Already in use'
        ) {
          setError(textResponse); // Display the specific error
          return; // Do not proceed further
        }
  
        throw new Error(textResponse || 'Failed to register. Please try again.');
      }
  
      if (textResponse === 'User Registered Successfully') {
        alert('Registration successful!');
        navigate('/logindashboard'); // Redirect only on successful registration
      } else {
        throw new Error(textResponse);
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'Something went wrong, please try again.');
    } finally {
      setLoading(false); // Ensure loading is stopped after the request
    }
  };
  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/categoryies');
        const data = await response.json();
        setCategories(data); // Since API returns an array of strings, directly set it
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories(); // Call the function to fetch data when the component mounts
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleBack = () => {
    
    navigate('/logindashboard')
  };
  
  
  

  return (
    <div className="login-body1" style={{
      backgroundImage: `url(${backgroundImage})`, // Use the imported background image
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    }}>
      <div className="login-container1">
        {/* Login Form Section */}
        <div className="form-section1">
          <div className="form-content1">
          <div className="form-header">
  {/* <button className="back-btn" onClick={handleBack}>
    <i className="fa fa-arrow-left" aria-hidden="true"></i> 
   
  </button> */}
  <img src={logo} alt="ACTUS Logo" className="logo1" />
</div>

            <h5>Sign Up</h5>
            <div className="form-group">
      {loading ? (
        <p>Loading categories...</p>
      ) : (
        <select
          name="options"
          required
          className="custom-dropdown1"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="" disabled>
            Category
          </option>
          {/* Dynamically render options from API data */}
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      )}
    </div>
            <form onSubmit={handleSignUp}>
            {/* <label>Name </label> */}
            <div style={{marginTop:'-15px'}} className="form-group">
                {/* <label>Email ID <span style={{ color: 'red' }}>*</span></label> */}
                <input
                  type="text"
                  name="organisation"
                  value={loginObj.organisation}
                  onChange={handleChange}
                  placeholder="Organization"
                  className="form-control"
                />
              </div>
            <div style={{marginTop:'-15px'}} className="form-group">
                {/* <label>Email ID <span style={{ color: 'red' }}>*</span></label> */}
                <input
                  type="text"
                  name="name"
                  value={loginObj.name}
                  onChange={handleChange}
                  required
                  placeholder="Enter Name *"
                  className="form-control"
                />
              </div>
              {/* <label>Email ID</label> */}
              <div style={{marginTop:'-15px'}} className="form-group">
                {/* <label>Email ID <span style={{ color: 'red' }}>*</span></label> */}
                <input
                  type="email"
                  name="emailId"
                  value={loginObj.emailId}
                  onChange={handleChange}
                  required
                  placeholder="Enter Email ID *"
                  className="form-control"
                />
              </div>

              <div style={{marginTop:'-15px'}} className="form-group">
                {/* <label>Email ID <span style={{ color: 'red' }}>*</span></label> */}
                <input
                  type="phoneNumber"
                  name="phoneNumber"
                  value={loginObj.phoneNumber}
                  onChange={handleChange}
                  required
                  placeholder="Mobile No *"
                  className="form-control"
                />
              </div>
              

              
              <div style={{marginTop:'-15px'}} className="form-group password-field">
  {/* <label>Password </label> */}
  <div className="input-container1">
    <input
      type={hidePassword ? 'password' : 'text'}
      name="password"
      value={loginObj.password}
      onChange={handleChange}
      required
      placeholder="Enter Password *"
      className="form-control"
    />
    <button 
      type="button" 
      className="toggle-password" 
      onClick={() => setHidePassword(prev => !prev)}
    >
      {hidePassword ? (
        <i className="fa fa-eye-slash" aria-hidden="true"></i> // Eye slash icon for hidden password
      ) : (
        <i className="fa fa-eye" aria-hidden="true"></i> // Eye icon for visible password
      )}
    </button>
  </div>
</div>

              <div className="form-footer1">
            
                <button type="submit" className="btn1 btn1-primary">
                  Signup
                </button>
              </div>
            </form>
            
            {error && <span className="error-text">{error}</span>}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default SignUpEmp ;
