

import React, { useEffect, useState, useRef } from 'react';
import ToolDesignBanner from './ToolDesignBanner';
import BannerDFS from './BannerDFS';
import BannerVR from './BannerVR';
import VirtualRoboticsPage from './VirtualRoboticsPage';
import DigitalFacilitiesSolutionPage from './DigitalFacilitiesSolutionPage';
import FlutterTabs from './FlutterTabs';
import 'react-image-lightbox/style.css'; // Import the lightbox styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Slider from 'react-slick';
import Whyattend from './Whyattend';
import { faHome, faHospital, faMicrochip } from '@fortawesome/free-solid-svg-icons';
import ProgramFeesAndSchedules from './ProgramFeesAndSchedules';
import KeyFuturesSectionVR from './KeyFuturesSectionVR';
import CertificationVR from './CertificationVR';
import LearningPathVR from './LearningPathVR';
import SkilledUniversitiesVR from './SkilledUniversitiesVR';
import ToolCoveredImgesVR from './ToolCoveredImgesVR';
import Informationtec from './Informationtec';
import Reactbanner from './Reactbanner';
import Toolfixturebanner from './Toolfixturebanner';
import Digitalfactory from './Digitalfactory';
import Pythonbanner from './Pythonbanner';
import Fluuterbanner from './Fluuterbanner';
import {
  faCogs,          // For Mechanical
  faIndustry,      // For Digital Factory
  faFire,
  faLaptopCode,          // Replacing Laptop Code with Fire (for Josh)
} from '@fortawesome/free-solid-svg-icons';

// import ProgramFeesAndSchedules from './ProgramFeesAndSchedules';
import KeyFuturesSectionDFS from './KeyFuturesSectionDFS';
import CertificationDFS from './CertificationDFS';
import LearningPathDFS from './LearningPathDFS';
import SkilledUniversitiesDFS from './SkilledUniversitiesDFS';
import ToolCoveredImgesDFS from './ToolCoveredImgesDFS';
import Vurtualroboticsdata from './Vurtualroboticsdata'
import ToolandFixtureData from './ToolandFixtureData'
import DigitalFactoryData from './DigitalFactoryData'

// import ProgramFeesAndSchedules from './ProgramFeesAndSchedules';
import KeyFuturesSection from './KeyFuturesSection';
import CertificationTFD from './CertificationTFD';
import LearningPathTFD from './LearningPathTFD';
import SkilledUniversities from './SkilledUniversities';
import ToolCoveredImges from './ToolCoveredImges';
import WorkshoapBanner from './WorkshoapBanner';
import Datavideos from './Datavideos';
import VertualroboticsBanner from './VertualroboticsBanner';

import Outdoor from './Outdoor'
import DigitalFacilityBanner from './DigitalFacilityBanner';
import VirtualroboticscertificationBanner from './VirtualroboticscertificationBanner';
import VirtualRobotsBannerInternship from './VirtualRobotsBannerInternship';
import VrCertificationcode from './VrCertificationcode';
import VrCertificationInternship from './VrCertificationInternship';
import LearningPathDommy from './LearningPathDommy';
import ToolAndFixtureworkshop from './ToolAndFixtureworkshop';
import ToolAndFixtureInternship from './ToolAndFixtureInternship';
import VirtualRoboticsSoftwaresBanners from './VirtualRoboticsSoftwaresBanners';
import SoftwaresCertificates from './SoftwaresCertificates';
import Module from './Module';
import ModuleB from './ModuleB';
import ModuleJose from './ModuleJose';
import ModuleCM from './ModuleCM';
import ModuleDM from './ModuleDM';
import ModuleR from './ModuleR';
import VirtualwebibarKeyfetures from './VirtualwebibarKeyfetures';
import ModuleFactory from './ModuleFactory';
import Digitalkey from'./Digitalkey';
import ToolfixtureModule from'./ToolfixtureModule';
import TollkeyFeatuare from'./TollkeyFeatuare';
import PythanKeyfeature from './PythanKeyfeature';
import PythonModule from './PythonModule';
import JavaModule from'./JavaModule';
import JavaKey from './JavaKey';


const PCMobileApplications = () => {
  const [toggle, setToggle] = useState(1);
  const [navbarHeight, setNavbarHeight] = useState(2); // Default navbar height
  const [tabVR, setTabVR] = useState(2);
  const [tabPages, setTabPages] = useState(1); // For new tab group
  const [mainTab, setMainTab] = useState(2);
  function updateTabsVR(num) {
    setTabVR(num);
  }
  const [activeButton, setActiveButton] = useState(null);
 

  const updateToggle = (id) => {
    setActiveButton(id);  // Set the clicked button as active
    setToggle(id);         // Set the toggle state
  };
  const updateMainTabs = (tabIndex) => {
    setMainTab(tabIndex);
  };




  // Create a ref for the show-the-content container
  const contentRef = useRef(null);

  // Function to update sub-tabs and scroll smoothly
  const handleSubTabClick = (tabNumber) => {
    updateSubTabs(tabNumber); // Your existing function to update sub-tabs

    // Scroll to the show-the-content container with an offset
    setTimeout(() => {
      if (contentRef.current) {
        const offset = 180; // Adjust this value to control how much of the top you want to show
        const elementPosition = contentRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    }, 0);
  };




  const updateSubTabs = (tabIndex) => {
    setSubTab(tabIndex);
  };

  const tabContentRef = useRef(null);

  const handleTabClick = (tabIndex) => {
    // Scroll to the respective tab content section
    if (tabContentRef.current) {
      tabContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };




  const [subTab, setSubTab] = useState(5);  // Default sub tab
  // Handle main tab change
  // const updateMainTabs = (tabId) => {
  //   setMainTab(tabId);
  //   scrollToTop(); // Scroll to top on main tab change
  // };

  // // Handle sub tab change
  // const updateSubTabs = (tabId) => {
  //   setSubTab(tabId);
  //   scrollToTop(); // Scroll to top on sub tab change
  // };

  // Scroll to top function




  
 
  const contentRefs = {
    tab5: useRef(null),
    tab2: useRef(null),
    tab3: useRef(null),
  };

  const scrollToTop = (tabId) => {
    if (contentRefs[tabId] && contentRefs[tabId].current) {
      contentRefs[tabId].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo(0, 0);

    // Calculate navbar height
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);

  const settingss = {
    dots: false, // Show dots for navigation
    infinite: true, // Enable infinite scrolling
    arrows: false,
    autoplay: true,
    speed: 500, // Transition speed
    slidesToShow: 3, // Number of items to show at once
    slidesToScroll: 1, // Number of items to scroll per action
    responsive: [
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          slidesToScroll: 1, // Scroll 1 slide at a time
        },
      },
    ],
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      {/* Hero Section */}
      <div className={toggle === 1 ? 'show-the-content' : 'dont-show-content'}>
        {/* <ToolDesignBanner /> */}
        {/* <VertualroboticsBanner /> */}
        {/* <Outdoor /> */}
      </div>

      <div className={toggle === 2 ? 'show-the-content' : 'dont-show-content'}>
        {/* <BannerDFS /> */}
        {/* <DigitalFacilityBanner /> */}
      </div>

      <div className={toggle === 3 ? 'show-the-content' : 'dont-show-content'}>
        {/* <BannerVR /> */}
        {/* <VertualroboticsBanner /> */}
      </div>

      {/* Sticky Icon Slider */}
      <div>
        {/* <div
          className="icon-slider-wrapper"
          style={{
           
            top: `${navbarHeight}px`,
            backgroundColor: '#f0f0f0 !important',

            zIndex: 997,
            height: '100px',
            width: '100%',
          }}
         >
          <Slider {...settings}>
        
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => updateToggle(1)}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  paddingBottom: '1rem',
                  color: toggle === 1 ? '#215a9d' : 'black',
                }}
              >
                <FontAwesomeIcon
                  icon={faCogs}
                  style={{
                    marginBottom: '5px',
                    fontSize: '24px',
                    color: toggle === 1 ? '#215a9d' : 'black',
                  }}
                />
                <p>Mechanical Engineering</p>
              </a>
            </div>

           
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => updateToggle(3)}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  color: toggle === 3 ? '#215a9d' : 'black',
                }}
              >
                <FontAwesomeIcon
                  icon={faLaptopCode}
                  style={{
                    marginBottom: '5px',
                    fontSize: '24px',
                    color: toggle === 3 ? '#215a9d' : 'black',
                  }}
                />
                <p>Information Technology </p>
              </a>
            </div>

          
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => updateToggle(2)}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  color: toggle === 2 ? '#215a9d' : 'black',
                }}
              >
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  style={{
                    marginBottom: '5px',
                    fontSize: '24px',
                    color: toggle === 2 ? '#215a9d' : 'black',
                  }}
                />
                <p>Personal Development</p>
              </a>
            </div>
          </Slider>
        </div> */}


        {/* Sticky Tabs Section */}
      </div>

      <div style={{ backgroundColor: 'white' }}>




        <div
          className="icon-slider-wrapper iconddd"
          style={{
            position: 'sticky',
            top: 102,
            backgroundColor: '#fff',
            zIndex: 997,
            height: '82px',
            width: '105%',
            maxWidth:'1234px',
            margin: '0 auto',
            justifyContent: 'space-between',
            alignItems:'center',
            // padding: '16 39px', 
         
            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Slider {...settings}>

            <div style={{ textAlign: 'center' }}>
            <a
      className="filter inform"
      onClick={() => updateToggle(3)}
      style={{
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        width:'400px',
        color: activeButton === 3 ? 'white' : 'white', // Orange text when inactive, white when active
        // fontFamily: 'roboto',
        fontSize: "20px",
        fontWeight: "800",
        padding: '10px 20px',
        borderRadius: '15px',
        backgroundColor: activeButton === 3 ? 'black' : 'blue', // Sky-blue background when inactive, blue when active
        transform: activeButton === 3 ? 'translateY(-5px)' : 'none', // Button lifted when active
        transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease', // Smooth transition
      }}
      onMouseEnter={(e) => {
        if (activeButton !== 3) { // Only apply hover effect if not active
          e.target.style.color = 'white'; // Change text color to white on hover
          e.target.style.backgroundColor = 'black'; // Change background to blue on hover
          e.target.style.transform = 'translateY(-5px)'; // Lift the button on hover
        }
      }}
      onMouseLeave={(e) => {
        if (activeButton !== 3) { // Only reset hover effect if not active
          e.target.style.color = 'white'; // Reset text color to orange
          e.target.style.backgroundColor = 'blue'; // Reset background color to sky blue
          e.target.style.transform = 'translateY(0)'; // Reset button position
        }
      }}
    >
     INFORMATION TECHNOLOGY
    </a>
            </div>


            {/* Virtual Robotics */}
            <div style={{ textAlign: 'center' }}>
            <a
      className="filter inform"
      onClick={() => updateToggle(2)}
      style={{
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        color: activeButton === 2 ? 'white' : 'white', // Orange text when inactive, white when active
        // fontFamily: 'roboto',
        fontSize: "20px",
        fontWeight: "800",
        width:'400px',
        padding: '10px 20px',
        borderRadius: '15px',
        backgroundColor: activeButton === 2 ? 'black' : 'blue', // Sky-blue background when inactive, blue when active
        transform: activeButton === 2 ? 'translateY(-5px)' : 'none', // Button lifted when active
        transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease', // Smooth transition
      }}
      onMouseEnter={(e) => {
        if (activeButton !== 2) { // Only apply hover effect if not active
          e.target.style.color = 'white'; // Change text color to white on hover
          e.target.style.backgroundColor = 'black'; // Change background to blue on hover
          e.target.style.transform = 'translateY(-5px)'; // Lift the button on hover
        }
      }}
      onMouseLeave={(e) => {
        if (activeButton !== 2) { // Only reset hover effect if not active
          e.target.style.color = 'white'; // Reset text color to orange
          e.target.style.backgroundColor = 'blue'; // Reset background color to sky blue
          e.target.style.transform = 'translateY(0)'; // Reset button position
        }
      }}
    >
      JOB ORIENTED SKILL ENACHMENTS
    </a>
            </div>



            <div style={{ textAlign: 'center' }}>
            <a
      className="filter inform"
      onClick={() => updateToggle(1)}
      style={{
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        color: activeButton === 1 ? 'white' : 'white', // Orange text when inactive, white when active
        // fontFamily: 'roboto',
        fontSize: "20px",
        fontWeight: "800",
        width:'400px',
        padding: '10px 20px',
        borderRadius: '15px',
        backgroundColor: activeButton === 1 ? 'black' : 'blue', // Sky-blue background when inactive, blue when active
        transform: activeButton === 1 ? 'translateY(-5px)' : 'none', // Button lifted when active
        transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease', // Smooth transition
      }}
      onMouseEnter={(e) => {
        if (activeButton !== 1) { // Only apply hover effect if not active
          e.target.style.color = 'white'; // Change text color to white on hover
          e.target.style.backgroundColor = 'black'; // Change background to blue on hover
          e.target.style.transform = 'translateY(-5px)'; // Lift the button on hover
        }
      }}
      onMouseLeave={(e) => {
        if (activeButton !== 1) { // Only reset hover effect if not active
          e.target.style.color = 'white'; // Reset text color to orange
          e.target.style.backgroundColor = 'blue'; // Reset background color to sky blue
          e.target.style.transform = 'translateY(0)'; // Reset button position
        }
      }}
    >
      MECHANICAL ENGINEERING 
    </a>
            </div>
          </Slider>
        </div>
        <div className={toggle === 3 ? 'show-the-content' : 'dont-show-content'}>
          {/* <div> */}

            <div className="reCreateTabs" style={{
              position: 'sticky',
              top:'172px',
              // top: `${navbarHeight + 2}px`,
              backgroundColor: '#fff',
               padding: '10px 0',
              zIndex: 996,
            }}>
              <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                
               

              </ul>
            </div>

            {/* Second Sticky Navbar */}
            <div
              style={{
                position: 'sticky',
                top: '172px', // Fixed under the first sticky navbar
                backgroundColor: '#fff',
                padding: '5px 0',
                zIndex: 998,
                width: '100%',
              }}
              className='itslider'
            >
              <ul
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  listStyle: 'none',
                  margin: 22, // Remove extra margin for precise spacing
                  padding: 0,
                  gap: '51px', // Add 20px gap between items
                  marginBottom: '2px',
                  flexWrap: 'wrap', // Allow wrapping for smaller screens
                }}
              >
               <li
      onClick={() => updateMainTabs(2)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 2 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 2 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 2 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 2 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 2) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 2) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
      JAVA
    </li>

    <li
      onClick={() => updateMainTabs(9)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 9 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 9 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 9 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 9 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 9) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 9) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                 REACT.JS
                </li>

                <li
      onClick={() => updateMainTabs(3)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 3 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 3 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 3 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 3 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 3) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 3) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                 PYTHON
                </li>
 <li
      onClick={() => updateMainTabs(4)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 4 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 4 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 4 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 4 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 3) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 4) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                 FLUTTER
                </li>
              </ul>
            </div>
          {/* </div> */}

          
          {/* 

          <div
            className="reCreateTabs"
            style={{
              position: 'sticky',
              top: `${navbarHeight + 100}px`,
              backgroundColor: '#fff',
              padding: '10px 0',
              zIndex: 996,

            }}
          >
            <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
              <li
                onClick={() => updateTabsVR(1)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 1 ? 'gray' : '#e1d9d9',
                  color: tabVR === 1 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                WEBINAR 
              </li>
               <li
                onClick={() => updateTabsVR(2)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 2 ? 'gray' : '#e1d9d9',
                  color: tabVR === 2 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                WORKSHOP
              </li> *
              <li
                onClick={() => updateTabsVR(3)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 3 ? 'gray' : '#e1d9d9',
                  color: tabVR === 3 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                CERTIFICATION
              </li>
              <li
                onClick={() => updateTabsVR(4)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 4 ? 'gray' : '#e1d9d9',
                  color: tabVR === 4 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                INTERNSHIP
              </li>
            </ul>
          </div>

           */}

          <div className={mainTab === 2 ? 'show-the-content' : 'dont-show-content'}>
            {/* <VertualroboticsBanner /> */}
            {/* <Informationtec/> */}
            {/* <KeyFuturesSectionVR /> */}
            <JavaKey/>
            <JavaModule/>

            <SkilledUniversitiesVR />
            <ToolCoveredImgesVR />
            {/* <Datavideos /> */}
          </div>
          <div className={mainTab === 9 ? 'show-the-content' : 'dont-show-content'}>
            {/* <VertualroboticsBanner /> */}
            {/* <Informationtec/> */}
            {/* <Reactbanner/> */}
            <KeyFuturesSectionVR />

            <SkilledUniversitiesVR />
            <ToolCoveredImgesVR />
            {/* <Datavideos /> */}
          </div>

          <div className={mainTab === 3 ? 'show-the-content' : 'dont-show-content'}>
            {/* <VertualroboticsBanner /> */}
            {/* <Pythonbanner/> */}
            {/* <KeyFuturesSectionVR /> */}
            <PythanKeyfeature/>
            <PythonModule/>
            <Whyattend />
           
            {/* <LearningPathVR /> */}
            <ProgramFeesAndSchedules />
            <SkilledUniversitiesVR />
            <ToolCoveredImgesVR />
            {/* <CertificationVR /> */}
          </div>

          {/* <div className={mainTab === 5 ? 'show-the-content' : 'dont-show-content'}>
            <VertualroboticsBanner />
            <KeyFuturesSectionVR />

            <SkilledUniversitiesVR />
            <ProgramFeesAndSchedules />
            <LearningPathVR />
          </div> */}

          <div className={mainTab === 4 ? 'show-the-content' : 'dont-show-content'}>
            {/* <VertualroboticsBanner /> */}
             {/* <Fluuterbanner/> */}
            <KeyFuturesSectionVR />
            <SkilledUniversitiesVR />
            <LearningPathVR />
          </div>

        </div>








        {/* Outdoor.js, PlacementCards.js, OurTeamsAdd.js, OurCoPartners.js, NewaboutwhychosesUS.js, 11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111 */}








        <div className={toggle === 2 ? 'show-the-content' : 'dont-show-content'}>
          <div>
            {/* Main Tabs */}
            <div className="reCreateTabs" style={{
              position: 'sticky',
              top: '172px',
              // top: `${navbarHeight + 100}px`,
              backgroundColor: '#fff',
              padding: '5px 0',
              zIndex: 998,
            }}>
              <ul style={{ display: 'flex', listStyle: 'none', margin: 22, padding: 0, marginBottom: 2, }}>
              
              <li
      onClick={() => updateMainTabs(2)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 2 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 2 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 2 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 2 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 2) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 2) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                 CAREER FOUNDATION SKILLS
                </li>
              

                <li
      onClick={() => updateMainTabs(3)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 3 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 3 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 3 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 3 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 3) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 3) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                  SOFT SKILLS DEVELOPMENT
                </li>


                <li
      onClick={() => updateMainTabs(4)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 4 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 4 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 4 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 4 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 4) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 4) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                   DIGITAL PRESENCE SKILLS
                </li>
              </ul>
            </div>

           

            {/* Content Sections - Main Tab 2 (Virtual Robotics) */}

            <>
              {/* {mainTab === 2 && subTab === 2 && (
                <div ref={contentRef} className="show-the-content">

                 
                  <KeyFuturesSectionVR />
                  <ModuleJose />
                 

                  <ProgramFeesAndSchedules />
                  <SkilledUniversitiesVR />
                  <ToolCoveredImgesVR />
                </div>
              )} */}
{/*               
              {mainTab === 2 && (
                <div ref={contentRef} className="show-the-content">
                  Virtual Robotics - Workshop
                  <WorkshoapBanner />
                  <Vurtualroboticsdata />
                  <KeyFuturesSectionVR />
                  <LearningPathVR />
                  <ProgramFeesAndSchedules />
                  <SkilledUniversitiesVR />
                  <ToolCoveredImgesVR />
                  <CertificationVR />
                </div>
              )} */}


              {mainTab === 4 && (
                <div ref={contentRef} className="show-the-content">
                
                  
                  
                  <KeyFuturesSectionVR />
                  <LearningPathVR />
                  <ProgramFeesAndSchedules />
                  <SkilledUniversitiesVR />
                  <ToolCoveredImgesVR />
                  <CertificationVR />
                  <VrCertificationcode />
                </div>
              )}
              {mainTab === 2 && (
                <div ref={contentRef} className="show-the-content">
                 
                  {/* <Vurtualroboticsdata /> */}
                  {/* <VirtualRobotsBannerInternship /> */}
                  <KeyFuturesSectionVR />
                  <ModuleJose />
                  <LearningPathVR />
                  <ProgramFeesAndSchedules />
                  <SkilledUniversitiesVR />
                  <ToolCoveredImgesVR />
                  {/* <VrCertificationInternship /> */}
                </div>
              )}

            </>



            {mainTab === 3 && (
              <>
                {subTab === 5 && (
                  <div className="show-the-content">

                    {/* <DigitalFacilityBanner /> */}
                    <KeyFuturesSectionDFS />
                    <LearningPathDFS />
                    <ProgramFeesAndSchedules />
                    <SkilledUniversitiesDFS />
                    <ToolCoveredImgesDFS />
                  </div>
                )}


{/*                 
                {subTab === 17 && (
                  <div className="show-the-content">

                    <DigitalFacilityBanner />
                    <KeyFuturesSectionDFS />
                    <LearningPathDFS />
                    <ProgramFeesAndSchedules />
                    <SkilledUniversitiesDFS />
                    <ToolCoveredImgesDFS />

                    <CertificationDFS />
                  </div>
                )} */}
                {/* {subTab === 7 && (
                  <div className="show-the-content">

                    <DigitalFacilityBanner />
                    <KeyFuturesSectionDFS />
                    <LearningPathDFS />
                    <ProgramFeesAndSchedules />
                    <SkilledUniversitiesDFS />
                    <ToolCoveredImgesDFS />

                    <CertificationDFS />
                  </div>
                )} */}
                {/* {subTab === 4 && (
                  <div className="show-the-content">

                    <DigitalFacilityBanner />
                    <KeyFuturesSectionDFS />
                    <LearningPathDFS />
                    <ProgramFeesAndSchedules />
                    <SkilledUniversitiesDFS />
                    <ToolCoveredImgesDFS />

                    <CertificationDFS />
                  </div>
                )} */}
              </>
            )}
          </div>

        </div>






        {/* 11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111 */}




        <div className={toggle === 1 ? 'show-the-content' : 'dont-show-content'}>
          <div>

            <div className="reCreateTabs" style={{
              position: 'sticky',
              top: `${navbarHeight + 2}px`,
              backgroundColor: '#fff',
              // padding: '10px 0',
              zIndex: 996,
              
            }}>
              <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                
               

              </ul>
            </div>

            {/* Second Sticky Navbar */}
            <div
              style={{
                position: 'sticky',
                top: '172px', // Fixed under the first sticky navbar
                backgroundColor: '#ffff',
                // boxShadow: '222px 2px 5px rgba(0, 0, 0, 0.1)', // Correct box-shadow
                padding: '5px 0',
                zIndex: 997,
                width: '100%',
              }}
              className='firstlickslider'
            >
              <ul
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  listStyle: 'none',
                  margin: 22, // Remove extra margin for precise spacing
                  padding: 0,
                  gap: '20px', // Add 20px gap between items
                  marginBottom: '2px',
                  flexWrap: 'wrap', // Allow wrapping for smaller screens
                }}
              >
               <li
      onClick={() => updateMainTabs(2)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 2 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 2 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 2 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 2 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 2) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 2) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
      VIRTUAL SIMULATION
    </li>

    <li
      onClick={() => updateMainTabs(5)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 5 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 5 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 5 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 5 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 2) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 5) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                  TOOL & FIXTURE DESIGN
                </li>

                <li
      onClick={() => updateMainTabs(3)}
      style={{
        fontSize: "20px",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 30px",
        backgroundColor: mainTab === 3 ? "orange" : "#000", // Active tab has white background
        color: mainTab === 3 ? "black" : "orange", // Active tab has black text color
        cursor: "pointer",
        borderRadius: "15px",
        marginBottom: "10px", // Add spacing for wrapped items
        position: "relative",
        transition: "all 0.3s ease", // Smooth transition for all changes
        transform: mainTab === 3 ? "translateY(-5px)" : "translateY(0)", // Button moves up when selected
        zIndex: mainTab === 3 ? 1 : 0, // Ensure the active tab stays on top
      }}
      onMouseEnter={(e) => {
        // Change background and text color when hovered, if not active
        if (mainTab !== 3) {
          e.target.style.backgroundColor = "orange";
          e.target.style.color = "black";
          e.target.style.transform = "translateY(-5px)"; // Move the button up when hovered
        }
      }}
      onMouseLeave={(e) => {
        // Reset to original colors if not active
        if (mainTab !== 3) {
          e.target.style.backgroundColor = "#000"; // Reset to original background
          e.target.style.color = "orange"; // Reset text color
          e.target.style.transform = "translateY(0)"; // Reset button position
        }
      }}
    >
                  DIGITAL FACTORY SOLUTION
                </li>
              </ul>
            </div>







            <div
              style={{
                position: "sticky",
                top: "172px", // Fixed under the first sticky navbar
                // backgroundColor: '#e1d9d9',
                padding: "5px 0",
                zIndex: 997,
                width: "100%",
              }}
              className='secondslickslider'
             >
              <Slider {...settingss}>
                <div>
                  <li
                    onClick={() => updateMainTabs(2)}
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px 30px",
                      backgroundColor: mainTab === 2 ? "black" : "#000",
                      color: mainTab === 2 ? "orange" : "orange",
                      cursor: "pointer",
                      borderRadius: "15px",
                      marginBottom: "10px", // Add spacing for wrapped items
                    }}
                  >
                    VIRTUAL SIMULATION
                  </li>
                </div>

                <div>
                  <li
                    onClick={() => updateMainTabs(5)}
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px 30px",
                      backgroundColor: mainTab === 5 ? "black" : "#000",
                      color: mainTab === 5 ? "orange" : "orange",
                      cursor: "pointer",
                      borderRadius: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    TOOL & FIXTURE DESIGN
                  </li>
                </div>

                <div>
                  <li
                    onClick={() => updateMainTabs(3)}
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px 30px",
                      backgroundColor: mainTab === 3 ? "black" : "#000",
                      color: mainTab === 3 ? "orange" : "orange",
                      cursor: "pointer",
                      borderRadius: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    DIGITAL FACTORY SOLUTION
                  </li>
                </div>
              </Slider>
            </div>






            <div className="reCreateTabs  dfs all-data-tabs" style={{
              position: 'sticky',
              top: `${navbarHeight + 100}px`,
              backgroundColor: '#fff',
              // padding: '10px 0',
              zIndex: 998,
            }}>
              <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                {/* <li
                  onClick={() => handleSubTabClick(5)}
                  style={{
                    fontSize: '20px',
                    fontWeight: '800',
                    padding: '10px 50px',
                    backgroundColor: subTab === 5 ? 'gray' : '#e1d9d9',
                    color: subTab === 5 ? 'orange' : 'black',
                    cursor: 'pointer',
                    borderRadius: '15px',
                  }}
                >
                  WEBINAR
                </li> */}
                {/* <li
                  onClick={() => handleSubTabClick(6)}
                  style={{
                    fontSize: '20px',
                    fontWeight: '800',
                    padding: '10px 50px',
                    backgroundColor: subTab === 6 ? 'gray' : '#e1d9d9',
                    color: subTab === 6 ? 'orange' : 'black',
                    cursor: 'pointer',
                    borderRadius: '15px',
                  }}
                >
                  WORKSHOP
                </li> */}
                {/* <li
                  onClick={() => handleSubTabClick(7)}
                  style={{
                    fontSize: '20px',
                    fontWeight: '800',
                    padding: '10px 50px',
                    backgroundColor: subTab === 7 ? 'gray' : '#e1d9d9',
                    color: subTab === 7 ? 'orange' : 'black',
                    cursor: 'pointer',
                    borderRadius: '15px',
                  }}
                >
                  CERTIFICATION
                </li> */}
                {/* <li
                  onClick={() => handleSubTabClick(4)}
                  style={{
                    fontSize: '20px',
                    fontWeight: '800',
                    padding: '10px 50px',
                    backgroundColor: subTab === 4 ? 'gray' : '#e1d9d9',
                    color: subTab === 4 ? 'orange' : 'black',
                    cursor: 'pointer',
                    borderRadius: '15px',
                  }}
                >
                  INTERNSHIP
                </li> */}


              </ul>
            </div>

            {/* Content Sections - Main Tab 5 (Tool and Fixture Design) */}


            {/* Content Sections - Main Tab 2 (Virtual Robotics) */}

            {mainTab === 2 && subTab === 5 && (
              <div ref={contentRef} className="show-the-content">
                {/* Virtual Robotics - Webinar */}
                {/* <VertualroboticsBanner /> */}

                <VirtualwebibarKeyfetures />
                {/* <ModuleCM/> */}
                <ModuleR />
                <ModuleDM />
                {/* <ModuleR/> */}
                <Module />
                <ModuleB />
                {/* <LearningPathDommy /> */}
                <Whyattend />
                {/* <KeyFuturesSectionVR /> */}
                {/* <LearningPathVR /> */}
                {/* <LearningPathDommy /> */}
                <ProgramFeesAndSchedules />
                <SkilledUniversitiesVR />
                <ToolCoveredImgesVR />
              </div>
            )}
            {mainTab === 5 && (
              <div ref={contentRef} className="show-the-content">
                {/* Virtual Robotics - Workshop */}
                {/* <WorkshoapBanner /> */}
                {/* <Vurtualroboticsdata /> */}
                {/* <Toolfixturebanner/> */}
                {/* <VirtualwebibarKeyfetures /> */}
                <TollkeyFeatuare/>
               <ToolfixtureModule/>
                {/* <LearningPathDommy /> */}
                <Whyattend />
                <ProgramFeesAndSchedules />
                <SkilledUniversitiesVR />
                <ToolCoveredImgesVR />
                {/* <CertificationVR /> */}
              </div>
            )}
            {mainTab === 3 && (
              <div ref={contentRef} className="show-the-content">
                {/* Virtual Robotics - Certification */}
                {/* <VirtualroboticscertificationBanner /> */}
                {/* <Digitalfactory/> */}
                {/* <Vurtualroboticsdata /> */}
                {/* < VirtualwebibarKeyfetures /> */}
                <Digitalkey/>
                <ModuleFactory/>
                {/* <LearningPathDommy /> */}
                <Whyattend />
                <ProgramFeesAndSchedules />
                <SkilledUniversitiesVR />
                <ToolCoveredImgesVR />
                {/* <CertificationVR /> */}
                {/* <VrCertificationcode /> */}
              </div>
            )}
            {mainTab === 2 && subTab === 4 && (
              <div ref={contentRef} className="show-the-content">
                {/* Virtual Robotics - Internship */}
                {/* <Vurtualroboticsdata /> */}
                <VirtualRobotsBannerInternship />
                <KeyFuturesSectionVR />
                <LearningPathVR />
                <ProgramFeesAndSchedules />
                <SkilledUniversitiesVR />
                <ToolCoveredImgesVR />
                <VrCertificationInternship />
              </div>
            )}





            {/* Content Sections - Main Tab 3 (Digital Factory Solution) */}

          </div>

        </div>



      </div>

      <style>
        {`
            .show-the-content {
              display: block;
            }

            .dont-show-content {
              display: none;
            }

            h1, h2, h3, h4, h5, h6, p {
              font-family: urbanist, serif;
            }

            .reCreateTabs ul {
              display: flex;
              justify-content: center;
              list-style: none;
              padding: 0;
              margin: 0;
              flex-wrap: wrap;
            }

            .reCreateTabs ul li {
              display: inline-block;
              padding: 10px 20px;
              font-size: 16px;
              background-color: black;
              border-radius: 10px;
              color: white;
              margin: 5px;
              cursor: pointer;
              text-align: center;
            }

            .reCreateTabs ul li:hover {
              color: orange;
              background-color: gray;
            }

            /* Responsive Styles */
            @media (max-width: 768px) {
              .reCreateTabs ul li {
                font-size: 14px;
                padding: 8px 15px;
              }
            }

            @media (max-width: 480px) {
              .reCreateTabs ul li {
                font-size: 12px;
                padding: 6px 10px;
              }

              .reCreateTabs ul {
                flex-direction: column;
                // align-items: center;
              }
            }

             @media (max-width: 600px) {
    .reCreateTabs ul {
      flex-direction: column; /* Stack tabs vertically on small screens */
      padding: 0; /* Remove any extra padding */
    }

    .reCreateTabs ul li {
      font-size: 18px !important; /* Make the font smaller */
      padding: 16px 15px !important; /* Reduce padding to make the tab smaller */
    }
  }

ul {
  list-style-type: none; /* Removes bullet points */
  margin: 0; /* Removes any default margin */
  padding: 0; /* Removes any default padding */
}


              
        `}
      </style>
    </div>

  );
};

export default PCMobileApplications;
