import React, { useState } from "react";

const LearningPathTFD = () => {
  const [toggleAllSections, setToggleAllSections] = useState(false);
  const [sectionToggle, setSectionToggle] = useState({});
  const [toggleNewModule, setToggleNewModule] = useState(false);
  const [newModuleToggle, setNewModuleToggle] = useState({});
  const [toggleExteraModule, setToggleExteraModule] = useState(false);
  const [exteraModuleToggle, setExteraModuleToggle] = useState({});
  const [toggleIsExteraModule, setToggleIsExteraModule] = useState(false);
  const [isExteraModuleToggle, setIsExteraModuleToggle] = useState({});
  const [toggleAdditionalModule, setToggleAdditionalModule] = useState(false);
  const [additionalModuleToggle, setAdditionalModuleToggle] = useState({});
  const [toggleExtraAdvancedModule, setToggleExtraAdvancedModule] = useState(false);
  const [extraAdvancedModuleToggle, setExtraAdvancedModuleToggle] = useState({});
  const [toggleAndLocalModule, setToggleAndLocalModule]= useState(false);
  const [AndLocalModuleToggle, setAndLocalModuleToggle]= useState({});
  const [toggleAndUlteraLocalModule, setToggleAndUlteraLocalModule]= useState(false);
  const [AndUlteraLocalModuleToggle, setAndUlteraLocalModuleToggle]= useState({});
  const listContent = [
    // Existing sections
    {
      id: 1,
      title: "Getting Started with Python",
      nestedContent: [
        { id: 1, text: "• 	History and features of Python.", dummyText: [] },
        { id: 2, text: "•	Setting up the development environment (Anaconda, VS Code, PyCharm).", dummyText: [] },
        { id: 3, text: "• 	Writing and running Python scripts", dummyText: [] },
      ],
    },
    {
      id: 2,
      title: "Basic Syntax and Structure",
      nestedContent: [
        { id: 1, text: "• 	Basic Syntax and Structure.", dummyText: [] },
        { id: 2, text: "• 		Writing and executing simple programs.", dummyText: [] },
       
      ],
    },
    {
        id: 3,
        title: "Control Flow Statements",
        nestedContent: [
          { id: 1, text: "• 	Conditional statements (if, elif, else).", dummyText: [] },
          { id: 2, text: "•  Loops (for, while) and loop control statements (break, continue, pass).", dummyText: [] },
         
        ],
      },
      {
        id: 4,
        title: "Introduction to Debugging",
        nestedContent: [
          { id: 1, text: "• 	Conditional statements (if, elif, else).", dummyText: [] },
          { id: 2, text: "•  Loops (for, while) and loop control statements (break, continue, pass).", dummyText: [] },
         
        ],
      },
  ];

  const newModuleContent = [
    // New module sections
    {
      id: 1,
      title: " Lists, Tuples and Sets",
      nestedContent: [
        { id: 1, text: "• 	Basics of engineering drawings and symbols.", dummyText: [] },
        { id: 2, text: "• 	Geometric Dimensioning and Tolerancing (GD&T).", dummyText: [] },
       
      ],
    },
    {
      id: 2,
      title: " Introduction to CAD Tools",
      nestedContent: [
        { id: 1, text: "• 	Creating and manipulating lists, tuples and sets.", dummyText: [] },
        { id: 2, text: "• 	List comprehensions and set operations.", dummyText: [] },
       
      ],
    },
    {
        id: 3,
        title: " Dictionaries",
        nestedContent: [
          { id: 1, text: "• 	Key-value pairs and dictionary methods.", dummyText: [] },
          { id: 2, text: "• 	Nested dictionaries and dictionary comprehensions.", dummyText: [] },
         
        ],
      },
      {
        id: 4,
        title: " Strings and File Handling",
        nestedContent: [
          { id: 1, text: "• 		String manipulation and formatting.", dummyText: [] },
          { id: 2, text: "• 		Reading and writing files.", dummyText: [] },
          { id: 3, text: "• 	Handling CSV, JSON and XML files.", dummyText: [] },
         
        ],
      },
      {
        id: 5,
        title: " Practical Exercises",
        nestedContent: [
          { id: 1, text: "• 		Solving problems using data structures.", dummyText: [] },
          
         
        ],
      },
     
      
  ];

  const exteraModuleContent = [
    // Extra module sections
    {
      id: 3,
      title: " Functions in Python",
      nestedContent: [
        { id: 1, text: "• 			Defining and calling functions.", dummyText: [] },
        { id: 2, text: "• 			Function arguments (positional, keyword, default and variable-length).", dummyText: [] },
        { id: 3, text: "• 			Lambda functions and higher-order functions.", dummyText: [] },
       
       
      ],
    },
    {
      id: 4,
      title: " Modules and Packages",
      nestedContent: [
        { id: 1, text: "• 		Importing and using standard libraries..", dummyText: [] },
        { id: 2, text: "• 		Creating and using custom modules and packages.", dummyText: [] },
        
      ],
    },
    {
        id: 5,
        title: " Error and Exception Handling",
        nestedContent: [
          { id: 1, text: "• 	Try-except blocks and custom exceptions.", dummyText: [] },
          { id: 2, text: "• 	Best practices for error handling.", dummyText: [] },
          
        ],
      },
      {
        id: 6,
        title: "Practical Exercises",
        nestedContent: [
          { id: 1, text: "• 	Building reusable functions and modular programs.", dummyText: [] },
         
          
        ],
      },
  ];

  const additionalModuleContent = [
    // Additional module sections
    {
      id: 5,
      title: "  Core OOP Concepts",
      nestedContent: [
        { id: 1, text: "•		Classes, objects and methods.", dummyText: [] },
        { id: 2, text: "•		Encapsulation, inheritance, polymorphism and abstraction.", dummyText: [] },
      ],
    },
    {
      id: 6,
      title: "Advanced OOP Features",
      nestedContent: [
        { id: 1, text: "• 		Magic methods and operator overloading.", dummyText: [] },
        { id: 2, text: "•		Static and class methods.", dummyText: [] },
      ],
    },
    {
        id: 7,
        title: "Design Principles",
        nestedContent: [
          { id: 1, text: "• 	SOLID principles in Python.", dummyText: [] },
          { id: 2, text: "•	Introduction to design patterns.", dummyText: [] },
        ],
      },
      {
        id: 8,
        title: "Practical Exercises",
        nestedContent: [
          { id: 1, text: "• 	Building real-world applications using OOP.", dummyText: [] },
        
        ],
      },
      
      
  ];

  const isExteraModuleContent = [
    // Additional Module Sections
    {
      id: 7,
      title: "Introduction to Data Analysis",
      nestedContent: [
        { id: 1, text: "• 		Overview of NumPy for numerical computations.", dummyText: [] },
        { id: 2, text: "• 	Pandas for data manipulation and analysis.", dummyText: [] },
      ],
    },
    {
      id: 8,
      title: " Data Visualization",
      nestedContent: [
        { id: 1, text: "• 		Matplotlib and Seaborn for plotting and visualizations.", dummyText: [] },
        { id: 2, text: "• 		Creating interactive dashboards with Plotly.", dummyText: [] },
      ],
    },
    {
        id: 9,
        title: " Data Cleaning and Preprocessing",
        nestedContent: [
          { id: 1, text: "• 		Handling missing data and outliers.", dummyText: [] },
          { id: 2, text: "• 		Data transformation techniques.", dummyText: [] },
        ],
      },
      
      {
        id: 10,
        title: " Practical Exercises",
        nestedContent: [
          { id: 1, text: "• 	Analyzing and visualizing real-world datasets.", dummyText: [] },
        
        ],
      },
      
  ];

  const extraAdvancedModuleContent = [
    // Extra Advanced Module Sections
    {
      id: 9,
      title: "  Introduction to Web Development",
      nestedContent: [
        { id: 1, text: "•		Overview of Flask and Django frameworks.", dummyText: [] },
        { id: 2, text: "•		Setting up a web server and routing.", dummyText: [] },
      ],
    },
    {
      id: 10,
      title: " Backend Development",
      nestedContent: [
        { id: 1, text: "• 			Handling forms and user input.", dummyText: [] },
        { id: 2, text: "• 		Connecting to databases (SQLite, PostgreSQL).", dummyText: [] },
      ],
    },
    {
      id: 11,
      title: " Building RESTful APIs",
      nestedContent: [
        { id: 1, text: "• 			Introduction to Flask-RESTful and Django REST Framework.", dummyText: [] },
        { id: 2, text: "• 		Creating and consuming APIs.", dummyText: [] },
       
      ],
    },
    {
        id: 12,
        title: "  Practical Project",
        nestedContent: [
          { id: 1, text: "• 	Developing a simple web application with user authentication and database integration.", dummyText: [] },
          
         
        ],
      },
  ];

  const andLocalModuleContent = [
    //ExtraAdvancedModuleSections
    {
      id: 13,
      title: "Introduction to Machine Learning",
      nestedContent: [
        { id: 1, text: "	Overview of supervised and unsupervised learning.", dummyText: [] },
        { id: 2, text: "•	Setting up Scikit-learn.", dummyText: [] },
      ],
    },
    {
      id: 14,
      title: "  Data Preparation for ML",
      nestedContent: [
        { id: 1, text: "• 		Feature scaling and encoding", dummyText: [] },
        { id: 2, text: "• 		Feature scaling and encoding 7.3 Building ML Models", dummyText: [] },
        { id: 3, text: "• 			Regression, classification and clustering algorithms.", dummyText: [] },
        { id: 4, text: "• 			Evaluating model performance.", dummyText: [] },
      ],
    },
    {
      id: 15,
      title: "  Practical Exercises",
      nestedContent: [
        { id: 1, text: "• 		Building and testing ML models with real-world datasets.", dummyText: [] },
       
      ],
    },
    
  ];
  const andUlteraLocalModuleContent = [
    //ExtraAdvancedModuleSections
    {
      id: 16,
      title: "Introduction to Automation",
      nestedContent: [
        { id: 1, text: "	Working with the os and shutil modules.", dummyText: [] },
        { id: 2, text: "•		File and directory management.", dummyText: [] },
      ],
    },
    {
      id: 17,
      title: "  Web Scraping",
      nestedContent: [
        { id: 1, text: "• 			Introduction to BeautifulSoup and Scrapy.", dummyText: [] },
        { id: 2, text: "• 			Extracting data from websites.", dummyText: [] },
       
      ],
    },
    {
        id: 18,
        title: "  Web Scraping",
        nestedContent: [
          { id: 1, text: "• 				Introduction to BeautifulSoup and Scrapy.", dummyText: [] },
          { id: 2, text: "• 				Extracting data from websites..", dummyText: [] },
         
        ],
      },
    {
      id: 18,
      title: "  Task Scheduling and Automation Tools",
      nestedContent: [
        { id: 1, text: "• 			Using schedule and apscheduler for task automation.", dummyText: [] },
        { id: 2, text: "• 				Practical examples of automating workflows.", dummyText: [] },
       
      ],
    },
    {
        id: 19,
        title: "  Capstone Project ",
        nestedContent: [
          { id: 1, text: "• 				Design and develop a Python-based application (e.g., web app, data analysis tool, or ML model).", dummyText: [] },
          { id: 2, text: "• 					Implement OOP principles and modular programming.", dummyText: [] },
          { id: 3, text: "• 						Use data visualization libraries for reporting.", dummyText: [] },
          { id: 4, text: "• 						Integrate with APIs or databases.", dummyText: [] },
          { id: 5, text: "• 						Automate a process or workflow.", dummyText: [] },
          { id: 6, text: "• 						Document and present the project.", dummyText: [] },
        ],
      },
    
  ];

  const toggleSection = (id, module = "existing") => {
    if (module === "existing") {
      setSectionToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "new") {
      setNewModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extera") {
      setExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "isextera") {
      setIsExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "additional") {
      setAdditionalModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extraAdvanced") {
      setExtraAdvancedModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    }else if (module === "andLocal"){
        setAndLocalModuleToggle((prev) => ({...prev,[id]: !prev[id]}));
   } else if (module === "andUlteraLocal"){
    setAndUlteraLocalModuleToggle((prev) => ({...prev,[id]: !prev[id]}));
  }
  };
  
  return (
    <div className="container my-5">
      <h2 className="fw-bold mb-4" style={{ color: "#000", fontFamily: "Roboto", textAlign: "center",marginTop:'-49px', }}>
        PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
      </h2>
      <br />
      
      {/* Existing Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAllSections(!toggleAllSections)}
      >
        Introduction to Python 
      </div>

      {toggleAllSections && (
        <div className="list-tab mt-3">
          {listContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id)}
              >
                {title}
                <span>{sectionToggle[id] ? "-" : "+"}</span>
              </div>
              {sectionToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* New Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleNewModule(!toggleNewModule)}
      >
      Python Data Structures 
      </div>

      {toggleNewModule && (
        <div className="list-tab mt-3">
          {newModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "new")}
              >
                {title}
                <span>{newModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {newModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Extera Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExteraModule(!toggleExteraModule)}
      >
       Functions and Modules 
      </div>

      {toggleExteraModule && (
        <div className="list-tab mt-3">
          {exteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extera")}
              >
                {title}
                <span>{exteraModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {exteraModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Additional Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAdditionalModule(!toggleAdditionalModule)}
      >
          Object-Oriented Programming (OOP) in Python
      </div>

      {toggleAdditionalModule && (
        <div className="list-tab mt-3">
          {additionalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "additional")}
              >
                {title}
                <span>{additionalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {additionalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

<div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleIsExteraModule(!toggleIsExteraModule)}
      >
     Data Analysis and Visualization 
      </div>

      {toggleIsExteraModule && (
        <div className="list-tab mt-3">
          {isExteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "isextera")}
              >
                {title}
                <span>{isExteraModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {isExteraModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {/* Extra Advanced Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExtraAdvancedModule(!toggleExtraAdvancedModule)}
      >
      Web Development with Python 
      </div>

      {toggleExtraAdvancedModule && (
        <div className="list-tab mt-3">
          {extraAdvancedModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extraAdvanced")}
              >
                {title}
                <span>{extraAdvancedModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {extraAdvancedModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
       <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAndLocalModule(!toggleAndLocalModule)}
      >
        Machine Learning with Python  
      </div>

      {toggleAndLocalModule && (
        <div className="list-tab mt-3">
          {andLocalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "andLocal")}
              >
                {title}
                <span>{AndLocalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {AndLocalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAndUlteraLocalModule(!toggleAndUlteraLocalModule)}
      >
       Automation and Scripting  
      </div>

      {toggleAndUlteraLocalModule && (
        <div className="list-tab mt-3">
          {andUlteraLocalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "andUlteraLocal")}
              >
                {title}
                <span>{AndUlteraLocalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {AndUlteraLocalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LearningPathTFD;




