
// import React from 'react';
// import daf from '../img/logos-to-add/daf.PNG'
// import escad from '../img/logos-to-add/escad.PNG'
// import fft from '../img/logos-to-add/fft.PNG'
// import ford from '../img/logos-to-add/ford.PNG'
// import introsy from '../img/logos-to-add/introsy.PNG'
// import it8 from '../img/logos-to-add/it8.PNG'
// import Jaguar from '../img/logos-to-add/Jaguar-company.PNG'
// import lt from '../img/logos-to-add/lt.PNG'
// import mldk from '../img/logos-to-add/mldk.PNG'
// import tata from '../img/logos-to-add/tata.PNG'
// import tyseekar from '../img/logos-to-add/tyseekar.PNG'
// import vdl from '../img/logos-to-add/vdl.PNG'
// import TataTechTier2 from '../img/tierone-images/TataTechTier2.png'
// import EbzTire1 from '../img/tierone-images/EbzTire1.png'
// import SkodaRakdom from '../img/tierone-images/SkodaRakdomORA.png'
// import ComauRakdom from '../img/tierone-images/ComauRakdom.png'
// import VolvoRakdom from '../img/tierone-images/VolvoRakdom.png'
// import psaGroupRakdom from '../img/tierone-images/psaGroupRakdom.png'



// const FastrackYourCarrier = () => {
//     return (
//         <div>

//             <div   >



//                 <div className="row d-flex justify-content-center" style={{ margin: '50px', marginBottom: '140px' }}>
//                     <div className='col-lg-12' style={{ paddingBottom: '30px', marginBottom: '80px' }}>
//                         <h2 style={{ textAlign: 'Center', fontSize: '48px', }} className='title-datass fastrack'>Fastrack <span style={{ color: '#f7941d', }}>Your Career</span> </h2>
//                         <p style={{ textAlign: 'center', }} className='fastparagrap'>With IIA, unlock your remarkable career transition, Unprecedent career kick-starting and join top-tier companies worldwide.
//                         </p>
//                     </div>
//                     <div className="col-lg-3">


//                         <div className="row fastractlearner">
//                             <h3 style={{ textAlign: 'left', marginLeft: '-36px' }} className='fastrack-head'>Learner's Achivements</h3>
//                             <div className="col-lg-12" style={{ border: '1px solid #d5d6dd', boxShadow: '7px 7px 7px rgb(216 220 247)', margin: '5px -37px', borderLeft: '4px solid blue', padding: '40px', marginBottom: '10px', marginTop: '-30px' }}>
//                                 <p style={{ fontSize: '30px' }}>  Average Salary </p>
//                                 <p style={{ fontSize: '50px', color: 'blue', fontWeight: '700' }}>70%</p>
//                             </div>
//                             <div className="col-lg-12" style={{ border: '1px solid #d5d6dd', boxShadow: '7px 7px 7px rgb(216 220 247)', margin: '5px -37px', borderLeft: '4px solid blue', padding: '40px', marginBottom: '10px', marginTop: '10px' }}>
//                                 <p style={{ fontSize: '30px' }}> Maximum salary hike </p>
//                                 <p style={{ fontSize: '50px', color: 'blue', fontWeight: '700' }}>150%</p>
//                             </div>
//                             <div className="col-lg-12" style={{ border: '1px solid #d5d6dd', boxShadow: '7px 7px 7px rgb(216 220 247)', margin: '5px -37px', borderLeft: '4px solid blue', padding: '40px', marginBottom: '10px', marginTop: '10px' }}>
//                                 <p style={{ fontSize: '30px' }}>Hiring Partners</p>
//                                 <p style={{ fontSize: '50px', color: 'blue', fontWeight: '700' }}>2900+</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-7" style={{ display: 'flex', padding: '20px', border: '1px solid #d5d6dd', boxShadow: '7px 7px 7px rgb(216 220 247)' }}>
//                         <div className="row">
//                             <div className="col-12">
//                                 <h3 style={{ textAlign: 'left' }} className='fastrack-alumini'>Top Industry Employers</h3>
//                             </div>


//                             <div className="row">
//                                 {[ // Creating rows for companies with headers
//                                     {
//                                         title: 'OEM`S',
//                                         logos: [ford, tata, daf, Jaguar, SkodaRakdom, VolvoRakdom],
//                                     },
//                                     {
//                                         title: 'TIER-1',
//                                         logos: [fft, vdl, tyseekar, escad, EbzTire1, psaGroupRakdom],
//                                     },
//                                     {
//                                         title: 'TIER-2',
//                                         logos: [it8, introsy, lt, mldk, TataTechTier2, ComauRakdom],
//                                     },
//                                 ].map((row, rowIndex) => (
//                                     <div
//                                         key={rowIndex}
//                                         className="col-lg-4"
//                                         style={{ marginBottom: '20px' }}
//                                     >
//                                         <h4 style={{ textAlign: 'center', marginBottom: '15px' }}>
//                                             {row.title}
//                                         </h4>
//                                         <div className="d-flex flex-column align-items-center">
//                                             {row.logos.map((logo, logoIndex) => (
//                                                 <div key={logoIndex} style={{ marginBottom: '20px',marginRight:'80px',marginLeft:'20px' }}>
//                                                     <img
//                                                         src={logo}
//                                                         alt="Company logo"
//                                                         style={{
//                                                             height: '70px',
//                                                             maxWidth: '100%',
//                                                             objectFit: 'contain',
//                                                         }}
//                                                     />
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default FastrackYourCarrier;



import React, { useState } from 'react';
import daf from '../img/logos-to-add/daf.PNG';
import escad from '../img/logos-to-add/escad.PNG';
import fft from '../img/logos-to-add/fft.PNG';
import ford from '../img/logos-to-add/ford.PNG';
import introsy from '../img/logos-to-add/introsy.PNG';
import it8 from '../img/logos-to-add/it8.PNG';
import Jaguar from '../img/logos-to-add/Jaguar-company.PNG';
import lt from '../img/tierone-images/l&t.909266653aee54006e3d.png';
import mldk from '../img/logos-to-add/mldk.PNG';
import tata from '../img/logos-to-add/tata.PNG';
import tyseekar from '../img/tierone-images/tyseekar.c7f2621b65de76ae2566.png';
import vdl from '../img/logos-to-add/vdl.PNG';
import TataTechTier2 from '../img/aaaservices/tata.png';
import EbzTire1 from '../img/tierone-images/EbzTire1.png';
import SkodaRakdom from '../img/tierone-images/skooda.png';
import ComauRakdom from '../img/tierone-images/ComauRakdom.png';
import VolvoRakdom from '../img/tierone-images/VolvoRakdom.png';
import psaGroupRakdom from '../img/tierone-images/psaGroupRakdom.png';

const FastrackYourCarrier = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '120px',
        height: '120px',
        border: '2px solid #d5d6dd',
        // borderColor:'black',
        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '50%',
        margin: '10px',
        overflow: 'hidden',
        backgroundColor: '#fff',
        transition: 'transform 0.3s ease',
        marginBottom: '100px',
    };

    const imgStyle = {
        width: '85%',
        height: '200px',
        objectFit: 'contain',
    };

    return (
        <div>
            <div className="row d-flex justify-content-center fstract" style={{ marginBottom: '100px',width:'100%' }}>
                <div className=" forMarginGiven col-lg-12" style={{ paddingBottom: '30px', marginBottom: '80px',marginTop:'40px' }}>

                    <h2 style={{ textAlign: 'center', fontSize: '48px',  }} >
                        Fastrack <span style={{ color: '#f7941d' }}>Your Career</span>
                    </h2>

                    
                    <p style={{ textAlign: 'center',fontSize:'22px',fontFamily:'roboto',fontWeight:'400', }} className="fastparagrap">
                        With IIA, unlock your remarkable career transition, unprecedented career kick-starting and join top-tier companies worldwide.
                    </p>
                </div>

                <div className=" forTheWidth col-xl-3" style={{ width: "200px" }}>
                    <div
                        className="row fastractlearner"
                        style={{
                            padding: '30px',
                            border: '1px solid #d5d6dd',
                            height: '790px',
                            boxShadow: '0px 7px 7px rgb(216, 220, 247)',
                            fontFamily: 'lato sans,serif',
                        }}
                    >
                        <h3
                            style={{
                                textAlign: 'center',
                                // marginLeft: '-36px',
                                fontWeight: 'bold',
                                color: '#333',
                                fontFamily:'roboto',
                               fontSize:'28px',
                               marginTop:'-80px',
                            }}
                            className="fastrack-head"
                        >
                            Learner's Achievements
                        </h3>

                        <div className="col-lg-12 achievement-box">
                            <p style={{ fontSize: '30px', margin: '0', fontFamily: 'lato sans,serif', }}>Average Salary</p>
                            <p
                                style={{
                                    fontSize: '50px',
                                    color: 'blue',
                                    fontWeight: '800',
                                    fontFamily: 'lato sans,serif',
                                }}
                            >
                                70%
                            </p>
                        </div>

                        <div className="col-lg-12 achievement-box">
                            <p style={{ fontSize: '30px', margin: '0', fontFamily: 'lato sans,serif', }}>Maximum Salary Hike</p>
                            <p
                                style={{
                                    fontSize: '50px',
                                    color: 'blue',
                                    fontWeight: '800',
                                    fontFamily: 'lato sans,serif',
                                }}
                            >
                                150%
                            </p>
                        </div>

                        <div className="col-lg-12 achievement-box">
                            <p style={{ fontSize: '30px', margin: '0', fontFamily: 'lato sans,serif', }}>Hiring Partners</p>
                            <p
                                style={{
                                    fontSize: '50px',
                                    color: 'blue',
                                    fontWeight: '800',
                                    fontFamily: 'lato sans,serif',
                                }}
                            >
                                2900+
                            </p>
                        </div>
                    </div>

                    {/* Internal CSS */}
                    <style>
                        {`
                            .achievement-box {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid #d5d6dd;
                                box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.1);
                                border-radius: 10px;
                                padding: 40px;
                                margin: 10px 0;
                                min-height: 50px; 
                                background-color: #fff;
                                transition: transform 0.3s ease, box-shadow 0.3s ease;
                            }

                            .achievement-box:hover {
                                transform: scale(1.05);
                                box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
                            }

                            /* Media Query for Mobile Devices */
                            @media (max-width: 768px) {
                                .fastractlearner {
                                    padding: 15px;
                                }

                                .achievement-box {
                                    padding: 20px;
                                    min-height: 150px;
                                    margin: 10px 0;
                                }

                                .achievement-box p {
                                    font-size: 24px;
                                }

                                .achievement-box p:last-child {
                                    font-size: 36px;
                                }

                                h3.fastrack-head {
                                    font-size: 24px;
                                    margin-left: 0;
                                    text-align: center;
                                }
                            }

                            /* Media Query for Very Small Screens */
                            @media (max-width: 480px) {
                                .achievement-box {
                                    padding: 15px;
                                    min-height: 130px;
                                }

                                .achievement-box p {
                                    font-size: 20px;
                                }

                                .achievement-box p:last-child {
                                    font-size: 28px;
                                }

                                h3.fastrack-head {
                                    font-size: 20px;
                                    text-align: center;
                                }
                            }
                        `}
                    </style>
                   

                </div>
                <div className="col-xl-7" style={{ border: '1px solid #d5d6dd', boxShadow: '7px 7px 7px rgb(216, 220, 247)' }}>
                    <div className="row">
                        <div className="col-12">
                            <h3 style={{ textAlign: 'center',fontSize:'25px',fontFamily:'roboto', }} className="fastrack-alumini">Top Industry Employers</h3>
                        </div>
                        <div className="row" style={{ fontFamily: 'lato sans,serif', }}>
                            {[
                                { title: 'OEM’S', logos: [ford, tata, daf, Jaguar, SkodaRakdom, VolvoRakdom] },
                                { title: 'TIER-1', logos: [fft, vdl, tyseekar, escad, EbzTire1, psaGroupRakdom] },
                                { title: 'TIER-2', logos: [it8, introsy, lt, mldk, TataTechTier2, ComauRakdom] },
                            ].map((row, rowIndex) => (
                                <div key={rowIndex} className="col-lg-4" style={{ marginBottom: '20px' }}>
                                    <h4 style={{ textAlign: 'center', marginBottom: '15px' }}>{row.title}</h4>
                                    <div className="d-flex flex-wrap justify-content-center">
                                        {row.logos.map((logo, logoIndex) => (
                                            <div
                                                className='styleContainerer'
                                                key={logoIndex}
                                                style={{
                                                    ...containerStyle,
                                                    transform: hoveredIndex === `${rowIndex}-${logoIndex}` ? 'scale(1.1)' : 'scale(1)',
                                                }}
                                                onMouseEnter={() => setHoveredIndex(`${rowIndex}-${logoIndex}`)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <img src={logo} alt="Company logo" style={imgStyle} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FastrackYourCarrier;

