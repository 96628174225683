import React, { useEffect, useState } from 'react';
import bannerImage from '../img/banner/647570.jpg';
import abouthome from '../img/banner/Hospitality1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import ServicesCards from './ServicesCards';
import Slicksider from "./Slicksider"
const MobileApp = () => {
  const [navbarHeight, setNavbarHeight] = useState(93); // Default navbar height
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs once when the component mounts
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };


  return (
    <div>
      <div>
        <div className="hero-area overly-style-1 opacity-point-4">
          {/* Replace video with image */}
          <img
            className="banner-image"
            src={bannerImage}
            alt="About AA Applications Banner"
            style={{ width: '100%', height: 'auto' }}
          />

          <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
            <h1 style={{ color: "#000", fontSize: '48px',fontFamily: 'Raqupine Regular' }}>SHOWCASE INTERIORS</h1>
            {/* Uncomment these if needed */}
            {/* <h1 className="title" data-aos="fade-up" data-aos-delay="200" style={{ color: '#fff' }}>Co Working</h1> */}
            {/* <p data-aos="fade-up" data-aos-delay="300">Quisque at justo sagittis, semper lacus a, iaculis tellus. Fusce tempor, leo vel iaculis aliquet,</p> */}
            {/* <a href="project.html" className="btn btn-primary btn-hover-dark" data-aos="fade-up" data-aos-delay="400">VIEW PROJECT</a> */}
          </div>
        </div>
      </div>



      {/* Sticky Icon Slider */}
      <div>
        <div
          className="icon-slider-wrapper"
          style={{
            position: "sticky",
            top: `${navbarHeight}px`,
            backgroundColor: "#def2f7",
            zIndex: 1000,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            height: "100px",
            width:"100%",
          }}
        >
          <Slider {...settings}>
            {/* Residential Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/it-services"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>RESIDENTIAL INTERIORS</p>
              </a>
            </div>
            {/* Commercial Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/web-mobile-applications"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>COMMERCIAL INTERIORS</p>
              </a>
            </div>
            {/* Hospitality Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/gaming"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHospital} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>HOSPITALITY INTERIORS</p>
              </a>
            </div>
            {/* Showcase Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/snowcsc"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faTree} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>SHOWCASE INTERIORS</p>
              </a>
            </div>


            {/* Specialty Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/Specialty"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>SPECIALTY INTERIORS</p>
              </a>
            </div>

            {/* Specialty Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/outdoor"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>OUTDOOR INTERIOPRS</p>
              </a>
            </div>
          </Slider>
        </div>

        <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div className="subheading" style={{ color: '#1c1d1d', fontSize: '48px', textAlign: 'center', marginBottom: '11px' }}>
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      SHOWCASE <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  {/* <div className="heading">Best Interior is Our Passion</div> */}
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> Showcase Spaces are designed to captivate and engage visitors, showcasing your products or art in the best possible light. With flexible layouts and modular displays, we ensure that your space adapts to changing needs while maintaining visual appeal. From car showrooms to art galleries, we incorporate advanced lighting, innovative shelving, and cutting-edge technology to enhance the visitor experience. Our designs focus on both aesthetics and functionality,

                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      . </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> providing a seamless flow of traffic while highlighting key features. Let us help you create an environment that leaves a lasting impression on your audience.  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <ServicesCards/> */}
      </div>
    </div>
  )
}

export default MobileApp