// import React from "react";
// import RightArrow from "../img/icon/placement-right-arrow.svg"; // Ensure this file exists in the specified path.
// import FileInvoice from "../img/icon/file-invoice-solid.svg"; // Ensure this file exists in the specified path.
// import GraduationCap from "../img/icon/graduation-cap-solid.svg"; // Ensure this file exists in the specified path.
// import Stopwatch from "../img/icon/stopwatch-solid.svg"; // Ensure this file exists in the specified path.
// import UserGraduate from "../img/icon/user-graduate-solid.svg"; // Ensure this file exists in the specified path.

// const ClearPlan = () => {
//   return (
//     <div style={{ backgroundColor: "rgb(195 197 205)", color: "#000", padding: "20px" }}>
//       <div className="text-center">
//         <h1>
//           Begin Your <span style={{ color: "#007bff" }}>Success Story</span>
//         </h1>
//         <p style={{ color: "#000" }}>Driving Your Career Success with a Clear Plan</p>
//       </div>

//       <div className="d-flex justify-content-center align-items-center">
//         {/* 1st Icon */}
//         <div className="text-center mx-3">
//           <div
//             style={{
//               backgroundColor: "rgb(171 175 181)",
//               borderRadius: "10px",
//               padding: "40px",
//               display: "inline-block",
//             }}
//           >
//             {/* Enrollment Icon */}
//             <img
//               src={FileInvoice}
//               alt="Course Enrollment Icon"
//               style={{ width: "60px", height: "60px" }}
//             />
//           </div>
//           <p style={{ color: "#000" }}>1. Course Enrollment</p>
//         </div>

//         {/* Arrow */}
//         <img
//           src={RightArrow}
//           alt="Arrow"
//           style={{ margin: "0 5px", width: "100px", height: "200px" }}
//         />

//         {/* 2nd Icon */}
//         <div className="text-center mx-3">
//           <div
//             style={{
//               backgroundColor: "rgb(171 175 181)",
//               borderRadius: "10px",
//               padding: "40px",
//               display: "inline-block",
//             }}
//           >
//             {/* Training Icon */}
//             <img
//               src={Stopwatch}
//               alt="Course Training Icon"
//               style={{ width: "60px", height: "60px" }}
//             />
//           </div>
//           <p style={{ color: "#000" }}>
//             2. Course Training
//             <br />
//             <span style={{ fontSize: "14px", color: "#000" }}>
//               (Mock Tests & Interviews)
//             </span>
//           </p>
//         </div>

//         {/* Arrow */}
//         <img
//           src={RightArrow}
//           alt="Arrow"
//           style={{ margin: "0 5px", width: "100px", height: "200px" }}
//         />

//         {/* 3rd Icon */}
//         <div className="text-center mx-3">
//           <div
//             style={{
//               backgroundColor: "rgb(171 175 181)",
//               borderRadius: "10px",
//               padding: "40px",
//               display: "inline-block",
//             }}
//           >
//             {/* Resume Icon */}
//             <img
//               src={GraduationCap}
//               alt="Resume Building Icon"
//               style={{ width: "60px", height: "60px" }}
//             />
//           </div>
//           <p style={{ color: "#000" }}>3. Resume Building</p>
//         </div>

//         {/* Arrow */}
//         <img
//           src={RightArrow}
//           alt="Arrow"
//           style={{ margin: "0 5px", width: "100px", height: "200px" }}
//         />

//         {/* 4th Icon */}
//         <div className="text-center mx-3">
//           <div
//             style={{
//               backgroundColor: "rgb(171 175 181)",
//               borderRadius: "10px",
//               padding: "40px",
//               display: "inline-block",
//             }}
//           >
//             {/* Placement Icon */}
//             <img
//               src={UserGraduate}
//               alt="Placement Support Icon"
//               style={{ width: "60px", height: "60px" }}
//             />
//           </div>
//           <p style={{ color: "#000" }}>4. Placement Support</p>
//         </div>
//       </div>

//       <div className="text-center mt-4">
//         <button
//           style={{
//             backgroundColor: "#007bff",
//             color: "#000",
//             border: "none",
//             padding: "15px 30px",
//             fontSize: "16px",
//             borderRadius: "5px",
//           }}
//         >
//           Enroll Now
//         </button>
//         <p style={{ marginTop: "15px", fontSize: "16px", color: "#000" }}>
//           Have questions? Call us at{" "}
//           <span style={{ color: "#007bff" }}>+91 7036500024</span>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ClearPlan;

















import React from "react";
import RightArrow from "../img/icon/placement-right-arrow.svg"; // Ensure this file exists in the specified path.
import FileInvoice from "../img/icon/file-invoice-solid.svg"; // Ensure this file exists in the specified path.
import GraduationCap from "../img/icon/graduation-cap-solid.svg"; // Ensure this file exists in the specified path.
import Stopwatch from "../img/icon/stopwatch-solid.svg"; // Ensure this file exists in the specified path.
import UserGraduate from "../img/icon/user-graduate-solid.svg"; // Ensure this file exists in the specified path.

const ClearPlan = () => {
  const steps = [
    { id: 1, icon: FileInvoice, text: "Course Enrollment" },
    { id: 2, icon: Stopwatch, text: "Course Training " },
    { id: 3, icon: GraduationCap, text: "Resume Building" },
    { id: 4, icon: UserGraduate, text: "Placement Support" },
  ];

  return (
    <div style={{ backgroundColor: "#f7f7f9", color: "#000", padding: "20px" }}>
      <div className="text-center">
        <h1>
          Begin Your <span style={{ color: "#007bff" }}>Success Story</span>
        </h1>
        <p style={{ color: "#000" }}>Driving Your Career Success with a Clear Plan</p>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        {steps.map((step, index) => (
          <React.Fragment key={step.id}>
            <div
              className="text-center mx-3"
              style={{
                cursor: "pointer",
                transition: "transform 0.3s",
              }}
              onMouseEnter={(e) => {
                const container = e.currentTarget;
                const text = container.querySelector("p");
                text.style.fontSize = "18px";
                text.style.fontWeight = "bold";
              }}
              onMouseLeave={(e) => {
                const container = e.currentTarget;
                const text = container.querySelector("p");
                text.style.fontSize = "16px";
                text.style.fontWeight = "normal";
              }}
            >
              <div
                style={{
                  backgroundColor: "rgb(171 175 181)",
                  borderRadius: "10px",
                  padding: "40px",
                  display: "inline-block",
                }}
              >
                <img
                  src={step.icon}
                  alt={`${step.text} Icon`}
                  style={{
                    width: "60px",
                    height: "60px",
                  }}
                />
              </div>
              <p style={{ color: "#000", fontSize: "16px", margin: "10px 0" }}>{step.text}</p>
            </div>
            {index < steps.length - 1 && (
              <img
                src={RightArrow}
                alt="Arrow"
                style={{
                  margin: "0 5px",
                  width: "100px",
                  height: "200px",
                  filter: "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="text-center mt-4">
        <button
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            padding: "15px 30px",
            fontSize: "16px",
            borderRadius: "5px",
          }}
        >
          Enroll Now
        </button>
        <p style={{ marginTop: "15px", fontSize: "16px", color: "#000" }}>
          Have questions? Call us at{" "}
          <span style={{ color: "#007bff" }}>+91 7036500024</span>
        </p>
      </div>
    </div>
  );
};

export default ClearPlan;
