// import React, { useState } from 'react';

// const JobPortal = () => {
//   // Sample data for jobs
//   const jobs = [
//     { title: "Mechanical Engineer", category: "Mechanical", company: "ABC Corp", location: "New York", salary: "$60,000" },
//     { title: "Software Developer", category: "IT", company: "XYZ Ltd", location: "California", salary: "$90,000" },
//     { title: "Design Engineer", category: "Mechanical", company: "LMN Pvt Ltd", location: "Texas", salary: "$75,000" },
//     { title: "Network Engineer", category: "IT", company: "Tech Innovators", location: "Florida", salary: "$80,000" }
//   ];

//   const [searchTerm, setSearchTerm] = useState('');
  
//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   return (
//     <div className="job-portal-container">
     
//       <div className="categories">
//         <button className="category-btn">MECHANICAL ENGINEERING</button>
//         <button className="category-btn">INFORMATION TECHNOLOGY</button>
//       </div>

//       <div className="job-listings">
//         {jobs.filter(job => job.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
//                             job.category.toLowerCase().includes(searchTerm.toLowerCase())
//         ).map((job, index) => (
//           <div key={index} className="job-card">
//             <h3>{job.title}</h3>
//             <p><strong>Category:</strong> {job.category}</p>
//             <p><strong>Company:</strong> {job.company}</p>
//             <p><strong>Location:</strong> {job.location}</p>
//             <p><strong>Salary:</strong> {job.salary}</p>
//           </div>
//         ))}
//       </div>

     
//       {/* Internal CSS */}
//       <style jsx>{`
//         /* General Styles */
//         * {
//           margin: 0;
//           padding: 0;
//           box-sizing: border-box;
//         }

//         body {
//           font-family: Arial, sans-serif;
//           background-color: #f4f4f4;
//         }

//         .job-portal-container {
//           width: 100%;
//           max-width: 1200px;
//           margin: 0 auto;
//           padding: 20px;
//           margin-top:90px;
//         }

//         /* Header Styles */
//         .header {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           background-color: #3e64ff;
//           padding: 10px;
//           color: white;
//         }

//         .header h1 {
//           font-size: 24px;
//         }

//         .search-bar input {
//           padding: 10px;
//           width: 300px;
//           font-size: 16px;
//           border: 1px solid #ccc;
//           border-radius: 5px;
//         }

//         /* Category Button Styles */
//         .categories {
//           margin: 20px 0;
//           text-align: center;
//         }

//         .category-btn {
//           background-color: #3e64ff;
//           color: white;
//           padding: 10px 20px;
//           border: none;
//           border-radius: 5px;
//           font-size: 16px;
//           cursor: pointer;
//           margin: 5px;
//         }

//         .category-btn:hover {
//           background-color: #2a4bb6;
//         }

//         /* Job Listing Styles */
//         .job-listings {
//           display: grid;
//           grid-template-columns: 1fr 1fr 1fr;
//           gap: 20px;
//         }

//         .job-card {
//           background-color: white;
//           padding: 15px;
//           border-radius: 8px;
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//         }

//         .job-card h3 {
//           font-size: 20px;
//           margin-bottom: 10px;
//         }

//         .job-card p {
//           margin: 5px 0;
//         }

//         .job-card strong {
//           color: #3e64ff;
//         }

//         /* Footer Styles */
//         .footer {
//           text-align: center;
//           margin-top: 50px;
//           padding: 10px;
//           background-color: #3e64ff;
//           color: white;
//         }

//         /* Responsive Design */
//         @media (max-width: 768px) {
//           .job-listings {
//             grid-template-columns: 1fr 1fr;
//           }

//           .search-bar input {
//             width: 100%;
//           }
//         }

//         @media (max-width: 480px) {
//           .job-listings {
//             grid-template-columns: 1fr;
//           }

//           .header h1 {
//             font-size: 18px;
//           }

//           .search-bar input {
//             width: 100%;
//             margin-top: 10px;
//           }

//           .category-btn {
//             width: 100%;
//             margin: 5px 0;
//           }
//         }
//       `}</style>
//     </div>
//   );
// }

// export default JobPortal;









// import React, { useState } from 'react';
// import logo from '../img/Picture1.png';
// const RecommendedJobs = () => {
//     const [isModalOpen, setIsModalOpen] = useState(false);

//   // Function to handle opening the modal
//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   // Function to handle closing the modal
//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div style={{ display: 'flex', flexDirection: 'row', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
//       {/* Sidebar */}
//       <div style={{ height:'500px', backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px',fontFamily:'roboto',fontSize:'16px',marginTop:'119px',backgroundColor:'blue',color:'white' }}>
//         <h4>Jobs</h4>
//         <div style={{ marginBottom: '20px' }}>
//           <h6>Working schedule</h6>
//           <ul style={{ listStyleType: 'none', padding: 0 }}>
//             <li><input type="checkbox" checked readOnly /> Full time</li>
//             <li><input type="checkbox" checked readOnly /> Part time</li>
//             <li><input type="checkbox" readOnly /> Internship</li>
//             <li><input type="checkbox" checked readOnly /> Project work</li>
//             <li><input type="checkbox" readOnly /> Volunteering</li>
//           </ul>
//         </div>
//         <div>
//           <h6>Employment type</h6>
//           <ul style={{ listStyleType: 'none', padding: 0 }}>
//             <li><input type="checkbox" checked readOnly /> Full day</li>
//             <li><input type="checkbox" checked readOnly /> Flexible schedule</li>
//             <li><input type="checkbox" readOnly /> Shift work</li>
//             <li><input type="checkbox" checked readOnly /> Distant work</li>
//             <li><input type="checkbox" readOnly /> Shift method</li>
//           </ul>
//         </div>
//       </div>

//       {/* Main Content */}
//       <div style={{ width: '80%', marginLeft: '20px',maxWidth:'1200px', }}>
//         {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//           <h2>Recommended jobs</h2>
//           <span>386</span>
//         </div> */}

//         {/* Job Cards */}
//         <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px',marginTop:'90px' }}>
//           {/* Job 1 */}
//           <div style={{ width: 'calc(33% - 20px)', backgroundColor: '#ffe6d8', padding: '20px', borderRadius: '8px' }}>
//           <div
//   style={{
//     display: 'flex', // Enable flexbox
//     justifyContent: 'center', // Center horizontally
//     alignItems: 'center', // Center vertically
//     // Make the container take up the full viewport height
//     // Optional: Light background for contrast
//   }}
// >
//   <div
//     className="date"
//     style={{
//       color: 'blue',
//       backgroundColor: 'white', // Set background color to white
//       borderRadius: '8px', // Add border radius
//       boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add box shadow
//       padding: '10px 20px', // Add padding for spacing
//       textAlign: 'center', // Center align text inside the box
//     }}
//   >
//     <p>4 Feb, 2023</p>
//   </div>
// </div>
// <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//       {/* Logo Image */}
//       <img
//         src={logo}
//         alt="Logo"
//         style={{
//           width: '162px', // Adjust the width as needed
//           height: '80px', // Adjust the height as needed
//           objectFit: 'contain',
//         }}
//       />
     
      
//     </div>
//             <h4>Senior UI/UX Designer</h4>
//             <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: '10px' }}>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Part time</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Senior level</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Distant</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Project work</span>
//             </div>
            
//             <button style={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px',marginTop:'20px' }}>Details</button>
//           </div>

//           {/* Job 2 */}
//           <div style={{ width: 'calc(33% - 20px)', backgroundColor: '#d7f6ec', padding: '20px', borderRadius: '8px' }}>
//           <div
//   style={{
//     display: 'flex', // Enable flexbox
//     justifyContent: 'center', // Center horizontally
//     alignItems: 'center', // Center vertically
//     // Make the container take up the full viewport height
//     // Optional: Light background for contrast
//   }}
// >
//   <div
//     className="date"
//     style={{
//       color: 'blue',
//       backgroundColor: 'white', // Set background color to white
//       borderRadius: '8px', // Add border radius
//       boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add box shadow
//       padding: '10px 20px', // Add padding for spacing
//       textAlign: 'center', // Center align text inside the box
//     }}
//   >
//     <p>4 Feb, 2023</p>
//   </div>
// </div>
// <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//       {/* Logo Image */}
//       <img
//         src={logo}
//         alt="Logo"
//         style={{
//           width: '162px', // Adjust the width as needed
//           height: '80px', // Adjust the height as needed
//           objectFit: 'contain',
//         }}
//       />
     
      
//     </div>
            
//             <h4>Junior UI/UX Designer</h4>
//             <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: '10px' }}>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Full time</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Junior level</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Distant</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Project work</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Flexible Schedule</span>
//             </div>
            
//             <button style={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px',marginTop:'20px' }}>Details</button>
//           </div>

//           <div style={{ width: 'calc(33% - 20px)', backgroundColor: '#d7f6ec', padding: '20px', borderRadius: '8px' }}>
//           <div
//   style={{
//     display: 'flex', // Enable flexbox
//     justifyContent: 'center', // Center horizontally
//     alignItems: 'center', // Center vertically
//     // Make the container take up the full viewport height
//     // Optional: Light background for contrast
//   }}
// >
//   <div
//     className="date"
//     style={{
//       color: 'blue',
//       backgroundColor: 'white', // Set background color to white
//       borderRadius: '8px', // Add border radius
//       boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add box shadow
//       padding: '10px 20px', // Add padding for spacing
//       textAlign: 'center', // Center align text inside the box
//     }}
//   >
//     <p>4 Feb, 2023</p>
//   </div>
// </div>
// <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//       {/* Logo Image */}
//       <img
//         src={logo}
//         alt="Logo"
//         style={{
//           width: '162px', // Adjust the width as needed
//           height: '80px', // Adjust the height as needed
//           objectFit: 'contain',
//         }}
//       />
     
      
//     </div>
//             <h4>Junior UI/UX Designer</h4>
//             <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: '10px' }}>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Full time</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Junior level</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Distant</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Project work</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Flexible Schedule</span>
//             </div>
            
//             <button style={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px',marginTop:'20px' }}>Details</button>
//           </div>
//           <div style={{ width: 'calc(33% - 20px)', backgroundColor: '#d7f6ec', padding: '20px', borderRadius: '8px' }}>
//           <div
//   style={{
//     display: 'flex', // Enable flexbox
//     justifyContent: 'center', // Center horizontally
//     alignItems: 'center', // Center vertically
//     // Make the container take up the full viewport height
//     // Optional: Light background for contrast
//   }}
// >
//   <div
//     className="date"
//     style={{
//       color: 'blue',
//       backgroundColor: 'white', // Set background color to white
//       borderRadius: '8px', // Add border radius
//       boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add box shadow
//       padding: '10px 20px', // Add padding for spacing
//       textAlign: 'center', // Center align text inside the box
//     }}
//   >
//     <p>4 Feb, 2023</p>
//   </div>
// </div>
// <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//       {/* Logo Image */}
//       <img
//         src={logo}
//         alt="Logo"
//         style={{
//           width: '162px', // Adjust the width as needed
//           height: '80px', // Adjust the height as needed
//           objectFit: 'contain',
//         }}
//       />
     
      
//     </div>
//             <h4>Junior UI/UX Designer</h4>
//             <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: '10px' }}>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Full time</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Junior level</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Distant</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Project work</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Flexible Schedule</span>
//             </div>
            
//             <button style={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px',marginTop:'20px'}}>Details</button>
//           </div>
//           <div style={{ width: 'calc(33% - 20px)', backgroundColor: '#d7f6ec', padding: '20px', borderRadius: '8px' }}>
            
//           <div
//   style={{
//     display: 'flex', // Enable flexbox
//     justifyContent: 'center', // Center horizontally
//     alignItems: 'center', // Center vertically
//     // Make the container take up the full viewport height
//     // Optional: Light background for contrast
//   }}
// >
//   <div
//     className="date"
//     style={{
//       color: 'blue',
//       backgroundColor: 'white', // Set background color to white
//       borderRadius: '8px', // Add border radius
//       boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add box shadow
//       padding: '10px 20px', // Add padding for spacing
//       textAlign: 'center', // Center align text inside the box
//     }}
//   >
//     <p>4 Feb, 2023</p>
//   </div>
// </div>

// <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//       {/* Logo Image */}
//       <img
//         src={logo}
//         alt="Logo"
//         style={{
//           width: '162px', // Adjust the width as needed
//           height: '80px', // Adjust the height as needed
//           objectFit: 'contain',
//         }}
//       />
     
      
//     </div>
//             <h4>Junior UI/UX Designer</h4>
//             <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: '10px' }}>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Full time</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Junior level</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Distant</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Project work</span>
//               <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Flexible Schedule</span>
//             </div>
            
//             <button style={{ backgroundColor: '#000', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px',marginTop:'20px'}}>Details</button>
//           </div>
//           <div style={{ width: 'calc(33% - 20px)', backgroundColor: '#d7f6ec', padding: '20px', borderRadius: '8px' }}>

//           <div>
//           <div
//   style={{
//     display: 'flex', // Enable flexbox
//     justifyContent: 'center', // Center horizontally
//     alignItems: 'center', // Center vertically
//     // Make the container take up the full viewport height
//     // Optional: Light background for contrast
//   }}
// >
//   <div
//     className="date"
//     style={{
//       color: 'blue',
//       backgroundColor: 'white', // Set background color to white
//       borderRadius: '8px', // Add border radius
//       boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add box shadow
//       padding: '10px 20px', // Add padding for spacing
//       textAlign: 'center', // Center align text inside the box
//     }}
//   >
//     <p>4 Feb, 2023</p>
//   </div>
// </div> 
//           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//       {/* Logo Image */}
//       <img
//         src={logo}
//         alt="Logo"
//         style={{
//           width: '162px', // Adjust the width as needed
//           height: '80px', // Adjust the height as needed
//           objectFit: 'contain',
//         }}
//       />
     
      
//     </div>
//       <h4>Junior UI/UX Designer</h4>
//       <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: '10px' }}>
//         <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Junior level</span>
//         <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Distant</span>
//         <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Project work</span>
//         <span style={{ backgroundColor: '#fff', padding: '5px 10px', borderRadius: '5px' }}>Flexible Schedule</span>
//       </div>
//       <button
//         style={{
//           backgroundColor: '#000',
//           color: '#fff',
//           padding: '10px 20px',
//           border: 'none',
//           borderRadius: '5px',
//           marginTop: '20px',
//         }}
//         onClick={openModal}
//       >
//         Details
//       </button>

//       {/* Modal */}
//       {isModalOpen && (
//   <div
//     style={{
//       position: 'fixed',
//       top: '0',
//       left: '0',
//       right: '0',
//       bottom: '0',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//     }}
//     onClick={closeModal} // Close modal when clicking outside
//   >
//     <div
//       style={{
//         backgroundColor: '#fff',
//         padding: '20px',
//         borderRadius: '8px',
//         width: '80%',
//         maxWidth: '600px',
//         position: 'relative',
//       }}
//       onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside it
//     >
//       {/* Date and Logo Section */}
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flexDirection: 'column', // Stack date and logo vertically
//           marginBottom: '20px',
//         }}
//       >
//         {/* Date */}
//         <div
//           style={{
//             color: 'blue',
//             backgroundColor: 'white',
//             borderRadius: '8px',
//             boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//             padding: '10px 20px',
//             textAlign: 'center',
//           }}
//         >
//           <p>4 Feb, 2023</p>
//         </div>

//         {/* Logo */}
//         <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
//           <img
//             src={logo}
//             alt="Logo"
//             style={{
//               width: '162px', // Adjust the width as needed
//               height: '80px', // Adjust the height as needed
//               objectFit: 'contain',
//             }}
//           />
//         </div>
//       </div>

//       {/* Description */}
//       <h3>Job Description</h3>
//       <p>
//         This is the detailed description of the Junior UI/UX Designer position. It includes job
//         responsibilities, qualifications, and expectations.
//       </p>

//       {/* Scrollable List */}
//       <div
//   style={{
//     maxHeight: '200px', // Set the max height of the container
//     overflowY: 'auto', // Enable vertical scrolling
//     paddingRight: '10px', // Optional, to avoid content touching the scrollbar
//     textAlign: 'left', // Align text to the left
//   }}
// >
//   <div className="job-details" style={{ alignItems:'flex-start'}}>
//     {/* <h4 >Job Title: <span>Software Engineer</span></h4> */}
//     <p>Role: <span>Backend Developer</span></p>
//     <p>Experience: <span>2-4 Years</span></p>
//     <p>CTC: <span>₹8,00,000 - ₹12,00,000</span></p>
//     <p>Location: <span>Hyderabad</span></p>
//     <p>Company: <span>Envision.IS</span></p>
//     <p>Description: <span>Responsible for building and maintaining backend services.</span></p>
//     <p>Skills Required: <span>Node.js, Express, MongoDB, AWS Express, MongoDB, AWS</span></p>
//   </div>

//   <button 
//     style={{
//       backgroundColor: 'blue',
//       color: 'white',
//       border: 'none',
//       padding: '10px 20px',
//       textAlign: 'center',
//       display: 'block',
//       margin: '20px auto',
//       fontSize: '16px',
//       cursor: 'pointer',
//       borderRadius: '5px'
//     }} 
//     onMouseOver={(e) => e.target.style.backgroundColor = 'darkblue'} 
//     onMouseOut={(e) => e.target.style.backgroundColor = 'blue'}
//   >
//     Enquiry Now
//   </button>
// </div>


//       {/* Card-like Details */}
      

           
//           </div>
//         </div>
//       )}
//     </div>
          

          
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default RecommendedJobs;







import React, { useState,useEffect } from "react";
import logo from "../img/Picture1.png"; // Update this path with your actual logo path
import AdvertisementShow from "./Advertisment";

const JobPortal = () => {
  const [filter, setFilter] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Tracks the total pages
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [cartJobs, setCartJobs] = useState([]); // Jobs stored in cart

  // const jobs = [
  //   {
  //     id: 1,
  //     date: "4 Feb, 2023",
  //     title: "Junior UI/UX Designer",
  //     tags: ["Experience","5-6 LPA", "Mode","Junior level", "Distant", "Project work", "Flexible Schedule"],
  //     description: "This is the detailed description of the Junior UI/UX Designer position.",
  //   },
  //   {
  //     id: 2,
  //     date: "10 Jan, 2023",
  //     title: "Backend Developer",
  //     tags: ["Mid level", "Onsite", "Full-time", "Competitive Salary"],
  //     description: "This is the detailed description of the Backend Developer position.",
  //   },
  //   {
  //     id: 3,
  //     date: "15 Mar, 2023",
  //     title: "Frontend Developer",
  //     tags: ["Senior level", "Remote", "Part-time", "Equity"],
  //     description: "This is the detailed description of the Frontend Developer position.",
  //   },
  //   {
  //     id: 4,
  //     date: "1 Apr, 2023",
  //     title: "Project Manager",
  //     tags: ["Mid level", "Hybrid", "Full-time", "Great Benefits"],
  //     description: "This is the detailed description of the Project Manager position.",
  //   },
  //   {
  //     id: 5,
  //     date: "20 May, 2023",
  //     title: "Data Scientist",
  //     tags: ["Expert level", "Remote", "Contract", "High Pay"],
  //     description: "This is the detailed description of the Data Scientist position.",
  //   },
  //   {
  //     id: 6,
  //     date: "30 June, 2023",
  //     title: "QA Engineer",
  //     tags: ["Junior level", "Onsite", "Internship", "Training Provided"],
  //     description: "This is the detailed description of the QA Engineer position.",
  //   },
  //   {
  //       id: 7,
  //       date: "30 June, 2023",
  //       title: "fronted Developer",
  //       tags: ["Junior level", "Onsite", "Internship", "Training Provided"],
  //       description: "This is the detailed description of the QA Engineer position.",
  //     },
  //     {
  //       id: 8,
  //       date: "30 June, 2023",
  //       title: "QA Engineer",
  //       tags: ["Junior level", "Onsite", "Internship", "Training Provided"],
  //       description: "This is the detailed description of the QA Engineer position.",
  //     },
  // ];

  const openModal = async (jobId) => {
    try {
      const response = await fetch(`http://10.10.20.9:7071/IIA-Website-1.0.0/jobpostings/getdata/${jobId}`);
      if (response.ok) {
        const data = await response.json();
        setSelectedJob(data); // Set the fetched job data
        setIsModalOpen(true); // Open the modal
      } else {
        console.error("Failed to fetch job details.");
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
    // setSelectedJob(job);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  // const filteredJobs = filter === "All" ? jobs : jobs.filter((job) => job.tags.includes(filter));

  

  const fetchWithTimeout = async (url, options = {}, timeout = 5000) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    
    const response = await fetch(url, { ...options, signal: controller.signal });
    clearTimeout(id);
    return response;
  };
  
  const fetchJobs = async (page = 1) => {
    setLoading(true);
    try {
      const response = await fetchWithTimeout(
        `http://10.10.20.9:7071/IIA-Website-1.0.0/jobpostings/getall/${page}`
      );
      const data = await response.json();
      
      setJobs(data);
      setTotalPages(Math.ceil(data.total / data.limit));
      
      const nextPage = page + 1;
      const responseNextPage = await fetchWithTimeout(
        `http://10.10.20.9:7071/IIA-Website-1.0.0/jobpostings/getall/${nextPage}`
      );
      const dataNextPage = await responseNextPage.json();
      
      if (dataNextPage  && dataNextPage.length > 0) {
        console.log(`Page ${nextPage} exists, fetching next jobs.`);
        setTimeout(() => {
          fetchJobs(nextPage);
        }, 100);
      } else {
        console.log(`Page ${nextPage} does not exist.`);
      }
    } catch (error) {
      console.error("Failed to fetch jobs:", error);
    }
    setLoading(false);
  };
  
  
  
  // Use useEffect to log jobs whenever they are updated
  useEffect(() => {
    console.log("Updated Jobs:", jobs);
  }, [jobs]); // This will log the jobs every time they are updated
  
  
  // To verify the updated jobs data, use a useEffect
 
  
  useEffect(() => {
    fetchJobs();
  },);

  const filteredJobs = 
  filter === "All"
    ? jobs // Show all data without filtering
    : (jobs || []).filter((job) =>
        job?.workMode?.toLowerCase() === filter?.toLowerCase()
      );



      if (filteredJobs && filteredJobs.length > 0) {
        // alert()
        filteredJobs.forEach((job) => {
          console.log("Job ID:", job.jobId); // Log jobId of each filtered job
        });
      } else {
        console.log("No jobs found for the given filter.");
      }
      

      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 200 &&
          !loading &&
          currentPage < totalPages
        ) {
          setCurrentPage((prevPage) => prevPage + 1); // Increment page number
        }
      };
    
      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, [loading, currentPage, totalPages]);
    

  return (
    <div style={{marginRight:'300px'}}>

<div
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px", // Adds space between the buttons
    marginTop: "156px",
  }}
>
  {/* <button
    className="category-btn"
    style={{
      fontFamily: "Roboto",
      fontSize: "20px",
      border: "1px solid transparent",
      borderRadius: "20px",
      backgroundColor: "blue",
      color: "white",
      width: "290px",
      height: "50px",
    }}
  >
    MECHANICAL ENGINEERING
  </button>

  <button
    className="category-btn"
    style={{
      fontFamily: "Roboto",
      fontSize: "20px",
      border: "1px solid transparent",
      borderRadius: "20px",
      backgroundColor: "blue",
      color: "white",
      width: "290px",
      height: "50px",
    }}
  >
    INFORMATION TECHNOLOGY
  </button> */}
</div>

   
    <div style={{ display: "flex", padding: "20px", margin: "0 auto", }}>
        
        <div className="categories">
       
      </div>
      
      {/* Sidebar */}
      <div
        style={{
          width: "250px",
          height:'446px',
          marginRight: "20px",
          padding: "20px",
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h4>Filter Jobs</h4>
        <button
          onClick={() => setFilter("All")}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            border: "none",
            borderRadius: "5px",
            backgroundColor: filter === "All" ? "blue" : "#e0e0e0",
            color: filter === "All" ? "#fff" : "#000",
            cursor: "pointer",
          }}
        >
          All
        </button>
        <button
          onClick={() => setFilter("Remote")}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            border: "none",
            borderRadius: "5px",
            backgroundColor: filter === "Remote" ? "blue" : "#e0e0e0",
            color: filter === "Remote" ? "#fff" : "#000",
            cursor: "pointer",
          }}
        >
          Remote
        </button>
        <button
          onClick={() => setFilter("Hybrid")}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            border: "none",
            borderRadius: "5px",
            backgroundColor: filter === "Hybrid" ? "blue" : "#e0e0e0",
            color: filter === "Hybrid" ? "#fff" : "#000",
            cursor: "pointer",
          }}
        >
          Hybrid
        </button>
        <button
          onClick={() => setFilter("Work_From_Office")}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            margin: "10px 0",
            border: "none",
            borderRadius: "5px",
            backgroundColor: filter === "Work_From_Office" ? "blue" : "#e0e0e0",
            color: filter === "Work_From_Office" ? "#fff" : "#000",
            cursor: "pointer",
          }}
        >
          Onsite
        </button>
      </div>

      {/* Cards Grid */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "15px",
          flex: 1,
        }}
      >
         {filteredJobs?.map((job) => (
          <div
            key={job.id}
            style={{
              backgroundColor: "#d7f6ec",
              padding: "15px",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <div
              style={{
                color: "blue",
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "5px 10px",
                marginBottom: "15px",
              }}
            >
              <p>{job.postingDate}</p>
            </div>

            <img
              src={logo}
              alt="Logo"
              style={{
                width: "120px",
                height: "60px",
                objectFit: "contain",
                marginBottom: "10px",
              }}
            />

            <h4 style={{fontSize:'14px'}}>{job.jobTitle}</h4>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              
                <span
                
                  style={{
                    backgroundColor: "#fff",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {job.ctcRange}
                </span>


                <span
                
                style={{
                  backgroundColor: "#fff",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                {job.location}
              </span>
        
            </div>

            <button
              style={{
                backgroundColor: "#000",
                color: "#fff",
                padding: "10px 15px",
                border: "none",
                borderRadius: "5px",
                marginTop: "15px",
                cursor: "pointer",
              }}
              onClick={() => openModal(job.jobId)}
            >
              Details
            </button>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && selectedJob && (
        <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={closeModal} // Close modal when clicking outside
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '8px',
                width: '80%',
                maxWidth: '600px',
                position: 'relative',
              }}
              onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside it
            >
              {/* Date and Logo Section */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column', // Stack date and logo vertically
                  marginBottom: '20px',
                }}
              >
                {/* Date */}
                <div
                  style={{
                    color: 'blue',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    padding: '10px 20px',
                    textAlign: 'center',
                  }}
                >
                  <p>{selectedJob.postingDate}</p>
                </div>
        
                {/* Logo */}
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      width: '162px', // Adjust the width as needed
                      height: '80px', // Adjust the height as needed
                      objectFit: 'contain',
                    }}
                  />
                </div>
              </div>
        
              {/* Description */}
              <h3>Job Description</h3>
              <p>
              {selectedJob.jobDescription}
              </p>
        
              {/* Scrollable List */}
              <div
          style={{
            maxHeight: '200px', // Set the max height of the container
            overflowY: 'auto', // Enable vertical scrolling
            paddingRight: '10px', // Optional, to avoid content touching the scrollbar
            textAlign: 'left', // Align text to the left
          }}
        >
          <div className="job-details" style={{ alignItems:'flex-start'}}>
            {/* <h4 >Job Title: <span>Software Engineer</span></h4> */}
            <p>Role: <span>{selectedJob.jobRole}</span></p>
            <p>Experience: <span>{selectedJob.experience}</span></p>
            <p>CTC: <span>{selectedJob.ctcRange}</span></p>
            <p>Location: <span>{selectedJob.location}</span></p>
            <p>Company: <span>{selectedJob.organisation}</span></p>
            <p>Description: <span>{selectedJob.jobDescription}</span></p>
            <p>Skills Required: <span>{selectedJob.jobDescription}</span></p>
          </div>
        
          <button 
            style={{
              backgroundColor: 'blue',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              textAlign: 'center',
              display: 'block',
              margin: '20px auto',
              fontSize: '16px',
              cursor: 'pointer',
              borderRadius: '5px'
            }} 
            onMouseOver={(e) => e.target.style.backgroundColor = 'darkblue'} 
            onMouseOut={(e) => e.target.style.backgroundColor = 'blue'}
          >
            Enquiry Now
          </button>
        </div>
        
        
              {/* Card-like Details */}
              
        
                   
                </div>
              </div>
             )}

    </div>
    <AdvertisementShow/>
    </div>
   
  );
  
};





export default JobPortal;



