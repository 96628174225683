// import React from "react";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import officeColleagueOne from '../img/officeColleagueOne.jpg';
// import './OurCommitments.css'
// // import MissionValuesVisionCombined from '../img/banner/values.jpg';



// // import OurValuesHandPik from '../img/banner/values.jpg';
// // import ourVisionCardRed1 from '../img/banner/Vision.png';
// // import ourMissionCardRed2 from '../img/banner/Mission.png';


// import OurValuesHandPik from '../img/banner/VS.jpg';
// import ourVisionCardRed1 from '../img/banner/VL.jpg';
// import ourMissionCardRed2 from '../img/banner/MISSION.jpg';

// // import OurValuesHandPik from '../img/banner/OurValues.png';
// // import ourVisionCardRed1 from '../img/banner/OurVision.png';
// // import ourMissionCardRed2 from '../img/banner/OurMission.png';




// const OurCommitments = () => {
//   return (
//     <div style={{ marginBottom: '50px' }}>
//       <div className="text-center">
//         <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
//           Our <span style={{ color: "#f7941d" }}>Commitments</span>

//         </h1>
//       </div>
//       <div className="wrapper  dklmn">
//         <div className="row d-flex justify-content-center">
//           <div className="col-lg-3" style={{ margin: '20px 40px' }}>
//             <div className="card">
//               <img src={ourVisionCardRed1} alt="Our Vision" />
//               <div className="info" style={{
//                 display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
//                 justifyContent: 'center', height: '100%'
//               }}>

//                 <p style={{ fontSize: '16px' }}> To bridge the gap between academia and industry by providing cutting-edge training, fostering leadership, and empowering individuals to achieve career success while contributing to society.</p>

//               </div>
//             </div>
//           </div>

//           <div className="col-lg-3" style={{ margin: '20px 40px' }}>
//             <div className="card">
//               <img src={ourMissionCardRed2} alt="Our Mission" />
//               <div className="info" style={{
//                 display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
//                 justifyContent: 'center', height: '100%'
//               }}>

//                 <p style={{ fontSize: '16px' }}>To make industry-oriented, affordable education accessible to all, transforming individuals into skilled, innovative, and socially responsible professionals.</p>

//               </div>
//             </div>
//           </div>

//           <div className="col-lg-3" style={{ margin: '20px 40px' }}>
//             <div className="card">
//               <img src={OurValuesHandPik} alt="Our Values" />
//               <div className="info" style={{
//                 display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
//                 justifyContent: 'center', height: '100%'
//               }}>
//                 <p style={{ fontSize: '16px' }}>Our values emphasize the holistic development of individuals and the transformative impact IIA aims to achieve in manufacturing, automation, and IT education.</p>
//               </div>

//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// };

// export default OurCommitments;























// import React from "react";


// const OurCommitments = () => {
//   return (
//     <div>
//       <div className="row d-flex justify-content-center">
//         <div className="col-md-2" style={{border:'1px solid black',padding:'20px',paddingTop:'5px'}}>
//           <h1 style={{textAlign:'center'}}>Our Vision</h1>
//           <p style={{padding:'0px 20px', paddingBottom:'40px'}}>To bridge the gap between academia and industry by providing cutting-edge training, fostering leadership, and empowering individuals to achieve career success while contributing to society.</p>
//         </div>
//         <div className="col-md-2">
//           <h1>Our Mission</h1>
//           <p>To make industry-oriented, affordable education accessible to all, transforming individuals into skilled, innovative, and socially responsible professionals.</p>
//         </div>
//         <div className="col-md-2">
//           <h1>Our Values</h1>
//           <p>Our values emphasize the holistic development of individuals and the transformative impact IIA aims to achieve in manufacturing, automation, and IT education.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OurCommitments;






// import React from "react";

// const MissionVisionValues = () => {
//   const containerStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "30px",
//     // backgroundColor: "#f9f9f9",
//     padding: "0px 20px",
//     flexWrap: "wrap",
//   };

//   const cardStyle = {
//     position: "relative",
//     width: "100%",
//     maxWidth: "370px",
//     padding: "30px 20px",
//     borderRadius: "20px",
//     backgroundColor: "#fff",
//     boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//     textAlign: "center",
//     overflow: "hidden",
//     transition: "transform 0.3s ease, box-shadow 0.3s ease",
//     paddingBottom:'80px',
//     minHeight:'450px'
    
//   };

//   const headerStyle = (color) => ({
//     backgroundColor: color,
//     height: "50px",
//     borderTopLeftRadius: "20px",
//     borderTopRightRadius: "20px",
//   });

//   const iconStyle = {
//     fontSize: "40px",
//     margin: "20px 0",
//     transition: "transform 0.3s ease",
//   };

//   const cardHoverStyle = {
//     transform: "translateY(-10px)",
//     boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
//   };

//   const iconHoverStyle = {
//     transform: "translateY(-10px)",
//   };

//   const textHoverStyle = {
//     fontWeight: "bold", // Make the text bold on hover
//     fontSize: "24px",   // Increase the font size
//     transition: "all 0.3s ease", // Smooth transition for font change
//   };

//   // Text content for each card
//   const cardContent = [
//     {
//       title: "Mission",
//       iconClass: "fa-bullseye",
//       description: "To make industry-oriented, affordable education accessible to all, transforming individuals into skilled, innovative, and socially responsible professionals."
//     },
//     {
//       title: "Vision",
//       iconClass: "fa-lightbulb",
//       description: "To bridge the gap between academia and industry by providing cutting-edge training, fostering leadership, and empowering individuals to achieve career success while contributing to society."
//     },
//     {
//       title: "Values",
//       iconClass: "fa-balance-scale",
//       description: "Our values emphasize the holistic development of individuals and the transformative impact IIA aims to achieve in manufacturing, automation, and IT education."
//     },
//   ];

//   return (
//     <div>
//       <div className="text-center">
//         <h1
//           style={{
//             color: "#000",
//             fontSize: "48px",
//             display: "inline-block",
//             position: "relative",
//             fontFamily: "Urbanist",
//             // backgroundColor: "#f9f9f9",
            
//           }}
//         >
//           Our <span style={{ color: "Orange" }}>Commitments</span>
          
//         </h1>
//       </div>

//       <div style={containerStyle}>
//         {cardContent.map((card, index) => (
//           <div
//             key={card.title}
//             style={{
//               position: "relative",
//               ...cardStyle,
//             }}
//             onMouseEnter={(e) => {
//               e.currentTarget.style.transform = cardHoverStyle.transform;
//               e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow;
//               e.currentTarget.querySelector("i").style.transform = iconHoverStyle.transform;
//               e.currentTarget.querySelector("h3").style = textHoverStyle; // Apply text hover effect
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.transform = "none";
//               e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
//               e.currentTarget.querySelector("i").style.transform = "none";
//               e.currentTarget.querySelector("h3").style = {}; // Reset text style
//             }}
//           >
//             <div
//               style={headerStyle(
//                 index === 0 ? "black" : index === 1 ? "#4936ff" : "#fb9100"
//               )}
//             ></div>
//             <i
//               style={iconStyle}
//               className={`fa ${card.iconClass}`}
//             ></i>
//             <h3>{card.title}</h3>
//             <p>{card.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default MissionVisionValues;






































import React from "react";

const MissionVisionValues = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
    padding: "0px 20px",
    flexWrap: "wrap",
  };

  const cardStyle = {
    position: "relative",
    width: "100%",
    maxWidth: "370px",
    padding: "30px 20px",
    borderRadius: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    textAlign: "center",
    overflow: "hidden",
    paddingBottom: "80px",
    minHeight: "400px",
    border: "1px solid rgb(206 206 207)",

    maxHeight: "300px",
  };

  const iconStyle = {
    fontSize: "70px",
    margin: "20px 0",
    color: "#fb9100",
  };

  // Text content for each card
  const cardContent = [
    {
      title: "Mission",
      iconClass: "fa-bullseye",
      description:
        "To make industry-oriented, affordable education accessible to all, transforming individuals into skilled, innovative, and socially responsible professionals.",
    },
    {
      title: "Vision",
      iconClass: "fa-lightbulb",
      description:
        "To bridge the gap between academia and industry by providing cutting-edge training, fostering leadership, and empowering individuals to achieve career success while contributing to society.",
    },
    {
      title: "Values",
      iconClass: "fa-balance-scale",
      description:
        "Our values emphasize the holistic development of individuals and the transformative impact IIA aims to achieve in manufacturing, automation, and IT education.",
    },
  ];

  return (
    <div>
      <div className="text-center">
        <h1
          style={{
            color: "#000",
            fontSize: "46px",
            display: "inline-block",
            position: "relative",
            fontFamily: "Urbanist",
          }}
        >
          Our <span style={{ color: "Orange" }}>Commitments</span>
        </h1>
      </div>

      <div style={containerStyle}>
        {cardContent.map((card) => (
          <div key={card.title} style={cardStyle}>
            <i style={iconStyle} className={`fa ${card.iconClass}`}></i>
            <h3 style={{ marginBottom: "0px" }}>{card.title}</h3>
            <p style={{ textAlign: "justify", padding: "25px" }}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MissionVisionValues;
