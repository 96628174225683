// import React, { useState, useEffect } from 'react';

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [nestedToggle, setNestedToggle] = useState({});
//     const [data, setData] = useState([]);

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const updateNestedToggle = (nestedId) => {
//         setNestedToggle((prevState) => ({
//             ...prevState,
//             [nestedId]: !prevState[nestedId],
//         }));
//     };

//     // Correct the typo here from 'ususeEffect' to 'useEffect'
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch('your-api-url');
//                 const data = await response.json();
//                 setData(data); // Update the state with fetched data
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
//         fetchData();
//     }, []);
    
      
       
//     const listContent = [
        
//         {
//             id: 1,
//             title: "Core Mechanical Engineering Principles ",
//             content: null,
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Thermodynamics  ",
//                     dummyText: ["*	Laws of Thermodynamics: Overview of the 1st and 2nd laws","*•	Heat and Work Interaction: Basics and applications in engineering."	],
//                 },
//                 { 
//                     id: 2,
//                     text: "•		 Fluid Mechanics  ",
//                     dummyText: ["*				Properties of Fluids: Viscosity, density, and compressibility.", "*			Fundamentals of Fluid Statics and Dynamics: Bernoulli’s equation and practical applications.", "*	Job Opportunities, Roles, and Packages for Fresher.",],
//                 },
                
//                  {
//                     id: 3,
//                      text: "• Engineering Mechanics ",
//                      dummyText: ["*	Force Systems and Equilibrium: Basic concepts of statics","*	Kinematics and Kinetics: Motion, velocity, and acceleration."],
//                  },
//                  {
//                     id: 4,
//                      text: "•  Strength of Materials  ",
//                      dummyText: ["*		Stress and Strain: Elasticity, Hooke’s Law.","*	Applications: Basics of buckling, torsion, and structural safety."],
//                  },
//             ],
//         },
//         {
//             id: 2,
//             title: " Manufacturing Processes   ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	 	Casting and Molding ",
//                     dummyText: ["*			Overview of sand, die, and investment casting..", "*Common defects and their impact on quality.",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Welding Processes Introduction to arc, MIG, and TIG welding techniques",
//                     dummyText: [,],
//                 },
//                 {
//                     id: 3,
//                     text: "•	 	Metal Forming Techniques ",
//                     dummyText: ["*	Forging, rolling, and extrusion: Methods and applications.","*	Cold vs. Hot Forming: Differences and use cases."],
//                 },
//                 {
//                     id: 4,
//                     text: "•	 	Material Removal Processes  ",
//                     dummyText: ["*		Conventional Methods: Turning, milling, and grinding.","*		Advanced Techniques: Basics of EDM (Electrical Discharge Machining)."],
//                 },
                
//             ],
//         },
//         {
//             id: 3,
//             title: "Mechanical Design and Automation  ",
           
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Design and Drafting ",
//                     dummyText: ["*			Mechanical Design Basics: Understanding the design process and factors of safety.", "*		Engineering Drawing: Overview of 2D/3D projections, sectional views, and assembly drawings.", ],
//                 },
//                 {
//                     id: 2,
//                     text: "•		Automation and Industrial Processes  ",
//                     dummyText: ["*			Understanding Automation: Applications in modern industries.","*		PLC and CNC Basics: Introduction to programming and their role in precision and efficiency.",],
//                 },
                
               
//             ],
//         },
//         {
//             id: 4,
//             title: "Materials and Mechanisms   ",
//          dummyText: [,],

//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Material Science and Selection ",
//                      dummyText: ["*	Properties of Materials: Mechanical and thermal properties.","*	Composite Materials: Overview of types and their applications.","*	Composite Materials: Overview of types and their applications."],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Kinematics of Machinery  ",
//                     dummyText: ["*				Introduction to Kinematics: Linkages, joints, and motion types.","	*	Mechanism Design: Automotive wipers, robotic arms, and other case studies.",],
//                 },
                
//             ],
//         },
        
       
//     ];

//     return (
//         <div className="container my-5">
//             {/* <h2
//                 className="fw-bold mb-4"
//                 style={{ color: "#000", fontFamily: "Roboto", width: "100%", textAlign: "center",marginTop:'-50px', }}
//             >
//                 PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
//             </h2> */}

//             <div className="list-tab" style={{ width: "100%", padding: 0 }}>
//                 {listContent.map(({ id, title, content, nestedContent }) => (
//                     <div key={id} className="mb-3" style={{ width: "100%" }}>
//                         <div
//                             className="p-3 bg-light rounded cursor-pointer d-flex justify-content-between align-items-center"
//                             onClick={() => updateToggle(id)}
//                             style={{
//                                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                                 width: "100%",
//                             }}
//                         >
//                             <span className="fw-bold" style={{ fontFamily: "Roboto", fontSize: "20px" }}>
//                                 {title}
//                             </span>
//                             <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>
//                         </div>
//                         {toggle === id && (
//                             <div className="mt-2 px-4">
//                                 {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
//                                 {nestedContent.map(({ id: nestedId, text, dummyText }) => (
//                                     <div key={nestedId} className="my-2">
//                                         <div
//                                             className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
//                                             onClick={() => updateNestedToggle(nestedId)}
//                                             style={{ border: "1px solid #ddd" }}
//                                         >
//                                             <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
//                                             <span style={{ fontSize: "18px" }}>
//                                                 {nestedToggle[nestedId] ? "-" : "+"}
//                                             </span>
//                                         </div>
//                                         {nestedToggle[nestedId] && (
//                                             <div className="mt-2 px-3">
//                                                 <ul style={{ fontFamily: "Roboto", color: "#555" }}>
//                                                     {dummyText.map((item, index) => (
//                                                         <li key={index}>{item}</li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;





import React, { useState, useEffect } from 'react';

const LearningPathTFD = () => {
    const [toggle, setToggle] = useState(null);
    const [nestedToggle, setNestedToggle] = useState({});
    const [data, setData] = useState([]);

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const updateNestedToggle = (nestedId) => {
        setNestedToggle((prevState) => ({
            ...prevState,
            [nestedId]: !prevState[nestedId],
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('your-api-url');
                const data = await response.json();
                setData(data); // Update the state with fetched data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    
    const listContent = [
        {
            id: 0,
            title: " INTRODUCTION TO MECHANICAL ENGINEERING",
            content: null,
            nestedContent: [],
        },
        {
            id: 1,
            title: "Core Mechanical Engineering Principles ",
            content: null,
            nestedContent: [
                {
                    id: 1,
                    text: "•		Thermodynamics  ",
                    dummyText: ["*	Laws of Thermodynamics: Overview of the 1st and 2nd laws", "*•	Heat and Work Interaction: Basics and applications in engineering."],
                },
                { 
                    id: 2,
                    text: "•		 Fluid Mechanics  ",
                    dummyText: ["*				Properties of Fluids: Viscosity, density, and compressibility.", "*			Fundamentals of Fluid Statics and Dynamics: Bernoulli’s equation and practical applications.", "*	Job Opportunities, Roles, and Packages for Fresher.",],
                },
                {
                    id: 3,
                    text: "• Engineering Mechanics ",
                    dummyText: ["*	Force Systems and Equilibrium: Basic concepts of statics","*	Kinematics and Kinetics: Motion, velocity, and acceleration."],
                },
                {
                    id: 4,
                    text: "•  Strength of Materials  ",
                    dummyText: ["*		Stress and Strain: Elasticity, Hooke’s Law.","*	Applications: Basics of buckling, torsion, and structural safety."],
                },
            ],
        },
        {
            id: 2,
            title: " Manufacturing Processes   ",
            nestedContent: [
                {
                    id: 1,
                    text: "•	 	Casting and Molding ",
                    dummyText: ["*			Overview of sand, die, and investment casting..", "*Common defects and their impact on quality.",],
                },
                {
                    id: 2,
                    text: "•	 	Welding Processes Introduction to arc, MIG, and TIG welding techniques",
                    dummyText: [,],
                },
                {
                    id: 3,
                    text: "•	 	Metal Forming Techniques ",
                    dummyText: ["*	Forging, rolling, and extrusion: Methods and applications.","*	Cold vs. Hot Forming: Differences and use cases."],
                },
                {
                    id: 4,
                    text: "•	 	Material Removal Processes  ",
                    dummyText: ["*		Conventional Methods: Turning, milling, and grinding.","*		Advanced Techniques: Basics of EDM (Electrical Discharge Machining)."],
                },
            ],
        },
        {
            id: 3,
            title: "Mechanical Design and Automation  ",
            nestedContent: [
                {
                    id: 1,
                    text: "•		Design and Drafting ",
                    dummyText: ["*			Mechanical Design Basics: Understanding the design process and factors of safety.", "*		Engineering Drawing: Overview of 2D/3D projections, sectional views, and assembly drawings.",],
                },
                {
                    id: 2,
                    text: "•		Automation and Industrial Processes  ",
                    dummyText: ["*			Understanding Automation: Applications in modern industries.","*		PLC and CNC Basics: Introduction to programming and their role in precision and efficiency.",],
                },
            ],
        },
        {
            id: 4,
            title: "Materials and Mechanisms   ",
            dummyText: [,],
            nestedContent: [
                {
                    id: 1,
                    text: "•		Material Science and Selection ",
                    dummyText: ["*	Properties of Materials: Mechanical and thermal properties.","*	Composite Materials: Overview of types and their applications.","*	Composite Materials: Overview of types and their applications."],
                },
                {
                    id: 2,
                    text: "•	 	Kinematics of Machinery  ",
                    dummyText: ["*				Introduction to Kinematics: Linkages, joints, and motion types.","	*	Mechanism Design: Automotive wipers, robotic arms, and other case studies.",],
                },
            ],
        },
    ];

    return (
        <div className="container my-5">
            <div className="list-tab" style={{ width: "100%", padding: 0 }}>
                
            {listContent.map(({ id, title, content, nestedContent }, index) => (
                    <div key={id} className="mb-3" style={{ width: "100%" }}>
                        <div
                            className={`p-3 rounded cursor-pointer d-flex justify-content-between align-items-center`}
                            onClick={() => updateToggle(id)}
                            style={{
                                width: "100%",
                                boxShadow: id === 0 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                backgroundColor: id === 0 ? 'transparent' : '#f8f9fa', // Make 0 background transparent
                            }}
                        >
                            <span
                                className={`fw-bold`}
                                style={{
                                    fontFamily: "Roboto",
                                    fontSize: index === 0 ? "19px" : "20px",
                                    color: index === 0 ? "#0072FF" : undefined,
                                }}
                            >
                                {title}
                            </span>
                            {index !== 0 && (
                                <span style={{ fontSize: "20px" }}>
                                    {toggle === id ? "-" : "+"}
                                </span>
                            )}
                        </div>
                        {/* Add line below first item */}
                        {/* {index === 0 && (
                            <div
                                style={{
                                    height: "1px",
                                    backgroundColor: "orange",
                                    width: "20%",
                                     margin: "-13px",
                                     marginLeft: '50px',
                                }}
                            ></div>
                        )} */}
                                {/* {title}
                            </span>
                            {id !== 0 && <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>}
                        </div>
                        {id === 0 && toggle === id && (
                            <div className="mt-2" style={{ textAlign: "center" }}>
                                <div style={{ width: "35px", height: "2px", backgroundColor: "#555", margin: "10px auto" }} />
                            </div>
                        )} */}
                        
                        {toggle === id && id !== 0 && (
                            <div className="mt-2 px-4">
                                {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
                                {nestedContent.map(({ id: nestedId, text, dummyText }) => (
                                    
                                    <div key={nestedId} className="my-2">
                                        <div
                                            className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
                                            onClick={() => updateNestedToggle(nestedId)}
                                            style={{ border: "1px solid #ddd" }}
                                        >
                                            <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
                                            <span style={{ fontSize: "18px" }}>
                                                {nestedToggle[nestedId] ? "-" : "+"}
                                            </span>
                                        </div>
                                        {nestedToggle[nestedId] && (
                                            <div className="mt-2 px-3">
                                                <ul style={{ fontFamily: "Roboto", color: "#555" }}>
                                                    {dummyText.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LearningPathTFD;

