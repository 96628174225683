import React from 'react';
// import aboutUsImage from '../img/aaaservices/EnvisionGroupLogoDigramPartII.png'; // Import the image
import aboutUsImage from '../img/aaaservices/aboutUsRakdom.jpg'; // Import the image

const AboutEnvisionBrief = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container">
                <div className="row align-items-center">

                    

                    {/* About Content */}
                    <div className="col-lg-7 col-md-12 mb-4 mb-lg-0">
                        <div className="container" style={{ textAlign: 'center' }}>
                            <div className="row" style={{ paddingBottom: '20px' }}>
                                <div className="container">
                                    <h1
                                       
                                    >
                                        About <span style={{ color: "#f7941d" }}>EnvisionIS Group</span>
                                        <span
                                            // style={{
                                            //     position: 'absolute',
                                            //     bottom: '-8px',
                                            //     left: '50%',
                                            //     transform: 'translateX(-50%)',
                                            //     width: '50%',
                                            //     height: '2px',
                                            //     backgroundColor: '#000',
                                            // }}
                                        />
                                    </h1>
                                </div>
                            </div>
                            <h2
                                style={{
                                    color: 'rgb(16, 16, 14)',
                                    fontSize: '22px',
                                    fontWeight: '900',
                                    fontFamily: 'urbanist',
                                    // textAlign:'left'
                                }}
                            >
                                Empowering Industries, Individuals, and Communities.
                            </h2>
                            <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                                Established in 2012, EnvisionIS Group is a forward-thinking conglomerate delivering excellence across engineering, IT, education, and business consulting, Our companies include:
                            </p><br/>
                            <ul style={{textAlign:'left',fontWeight:'700'}}>
                                <li>Envision Integrated Services (EIS)  </li>
                                <li>International Institute of Automate (IIA) </li>
                                <li>Envision Business Coworking Center (EBC)</li>
                            </ul><br/>
                            <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                                With a steadfast commitment to expertise, innovation, and passion, we strive to create meaningful opportunities that enable success and growth.
                            </p>
                            <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                                At EnvisionIS Group, we don’t just deliver services—we build pathways for a brighter future.
                            </p>
                        </div>
                    </div>
                    {/* About Image */}
                    <div className="col-lg-5 col-md-12">
                        <div className="about-text text-center">
                            <img src={aboutUsImage} alt="About Us" className="img-fluid rounded" style={{marginTop:'105px'}}/>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    );
};

export default AboutEnvisionBrief;
