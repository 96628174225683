import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Cart = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm('service_qa3zbmm', 'template_cezc1ah', e.target, 'BpJS--NtwuBSXs9NC')
      .then((result) => {
        console.log(result.text);
        alert('Success! Your message has been sent.');
        setIsSubmitting(false);
      }, (error) => {
        console.log(error.text);
        alert('Error! Your message could not be sent. Please try again later.');
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <style>
        {`
          body {
      background-color: #f0f2f5; /* Light background color */
      margin: 0;
      padding: 0;
    }

          .cart--cart-page-container--BEx2F {
            padding: 20px;
            font-family: Arial, sans-serif;
            background-color: #f5f5f5;
          }

          .cart--title-section--S7XdX h1 {
            font-size: 2rem;
            margin-bottom: 20px;
            color: #333;
          }

          .shopping-item--container--8dCap {
            display: flex;
            align-items: center;
            background: #fff;
            padding: 15px;
            border-radius: 5px;
            margin-bottom: 20px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .shopping-item--image-wrapper--axQKA img {
            border-radius: 5px;
            margin-right: 15px;
          }

          .shopping-item--course-title--fHyfq a {
            color: #007bff;
            text-decoration: none;
            font-size: 1.2rem;
          }

          .shopping-item--course-title--fHyfq a:hover {
            text-decoration: underline;
          }

          .shopping-item--instructors--nARja {
            font-size: 0.9rem;
            color: #666;
          }

          .shopping-item--badges--ylLzU .ribbon-module--ribbon--vVul- {
            background-color: #ffea00;
            color: #333;
            padding: 3px 10px;
            border-radius: 3px;
            font-weight: bold;
          }

          .shopping-item--rating--311vG {
            margin-top: 10px;
            display: flex;
            align-items: center;
          }

          .shopping-item--price-container--kLVfs {
            margin-left: auto;
            text-align: right;
          }

          .shopping-item--price-text--9T8LT {
            font-size: 1.5rem;
            color: #28a745;
            font-weight: bold;
          }

          .shopping-item--vertical-actions--rdgf2 button {
            background: none;
            border: none;
            color: #007bff;
            cursor: pointer;
            margin-right: 10px;
            font-size: 0.9rem;
          }

          .total-price--total-checkout-container--vVoOI {
            background-color: #fff;
            padding: 15px;
            border-radius: 5px;
            text-align: right;
            margin-top: 20px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .checkout-button--checkout-button--X6zla {
            background-color: #007bff;
            color: #fff;
            padding: 12px 25px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
          }

          .checkout-button--checkout-button--X6zla:hover {
            background-color: #0056b3;
          }
        `}
      </style>

      <div className="cart--cart-page-container--BEx2F" style={{marginTop:'200px'}}>
        <div className="cart--title-section--S7XdX">
          <h1>Shopping Cart</h1>
        </div>

        <div className="shopping-item--container--8dCap">
          <div className="shopping-item--image-wrapper--axQKA">
            <img
              src="https://img-c.udemycdn.com/course/240x135/1754098_e0df_3.jpg"
              alt="Course"
              height="68"
              width="120"
            />
          </div>
          <div>
            <h3 className="shopping-item--course-title--fHyfq">
              <a href="#">The Virtual Robotics Course: </a>
            </h3>
            <div className="shopping-item--instructors--nARja">By 365 Careers</div>
          </div>

          <div className="shopping-item--price-container--kLVfs">
            <div className="shopping-item--price-text--9T8LT">₹649</div>
            <div><s>₹3,999</s></div>
          </div>
        </div>

        <div className="total-price--total-checkout-container--vVoOI">
          <div>Total: ₹649</div>
          <button className="checkout-button--checkout-button--X6zla">Checkout</button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
