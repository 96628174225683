import React from 'react';
import ClearPlan from './ClearPlan'
import PlacementsInBanner from './PlacementsInBanner'
import PlacementFAQs from './PlacementFAQs'
import TestimonyOfStudents from './TestimonyOfStudents'
import PlacementCards from './PlacementCards'

const PlacementWeProvide = () => {
    return(
        <div>

            <PlacementsInBanner/>
            <ClearPlan/>
            {/* <TestimonyOfStudents/> */}
            <PlacementCards/>
            <PlacementFAQs/>
        </div>
    );
};
export default PlacementWeProvide;