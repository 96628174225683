import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import bannerImage from "../img/aaaservices/V1 copy.jpg";
import bannerImages from "../img/virtualroboticsbanners/V2 copy.jpg";
import bannerImagese from "../img/virtualroboticsbanners/V3 copy.jpg";
import bannerImageses from "../img/banner/V4 copy.jpg";
import bannerfour from "../img/virtualroboticsbanners/V4 copy.jpg";
import sixdays from "../img/virtualroboticsbanners/6-days-removebg-preview.png";

import fivedays from "../img/virtualroboticsbanners/fivedays-removebg-preview.png";
import fourdays from "../img/virtualroboticsbanners/fourdays-removebg-preview.png";
import './BtnPage.css';
import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import clockIcon from '../img/aaaservices/LEFT.gif';
import { useNavigate } from 'react-router-dom';
// const VertualroboticsBanner = () => {
//   // Carousel settings for autoplay
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  arrows: true,
};


const Gaming = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [expiryDate, setExpiryDate] = useState(null);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  // Fetch the next Saturday's date from the API
  const fetchNextSaturday = async () => {
    try {
      const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/dates/nextSaturday");
      if (response.ok) {
        const data = await response.json();
        setExpiryDate(new Date(data.nextSaturday));
      } else {
        console.error("Failed to fetch next Saturday's date");
      }
    } catch (error) {
      console.error("Error fetching next Saturday's date:", error);
    }
  };

  // Update the timer every second
  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const difference = expiryDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        // Timer expired, fetch the next Saturday again
        fetchNextSaturday();
      }
    };

    if (expiryDate) {
      const timerId = setInterval(updateTimer, 1000);
      return () => clearInterval(timerId);
    }
  }, [expiryDate]);

  // Fetch next Saturday's date on component mount
  useEffect(() => {
    fetchNextSaturday();
  }, []);

  // Get userId from localStorage
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const Banner1 = () => (
   <div
      className="hero-area overly-style-1 opacity-point-4"

   
     >
      <div className="slide-content">
        <div className="banner-container" style={{ position: 'relative' }}>

          {/* Image added above the content */}
          <img
            className="banner-image firstbannerheight"
            src={bannerImage}
            alt="Banner"
            style={{ width: '100%', height: '400px' }}
          />

          {/* Content section */}



          <div
            style={{
              color: '#fff',
              position: 'absolute',
              top: '85px',
              textAlign: 'left',
            }}
            className="about-databanner"
          >
            <p
              style={{
                fontWeight: 'bold',
                padding: '10px',
                marginLeft: '0px',
                fontSize: '40px',
                color: '#fff',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead axisdata"
             >
              <span style={{ color: '#01ffd9', }}>WEBINAR PROGRAM</span><br />
            
            </p>
            <p className='six-axis'>  6-Axis Industrial Robotic</p>
            <br />
            <br />
            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '40px',
              color: '#fff',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatas">
              Virtual Simulation
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '0px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axiduration">
              Duration : 1 HOUR
            </p>


            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdate">
              Date  <span style={{ marginLeft: '50px' }} className='timedatass'>    : 4:01:2025:11:00 AM</span>
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axismode">
              Mode  <span style={{ marginLeft: '40px' }} className='modedatas'>    : Online</span>
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatass">
              <div>
                {/* Price  
           <span style={{ marginLeft: '50px' }}>
           : 99/- <span style={{ color: '#01ffd9' }}>Free</span>
             </span> */}
                {/* Rating */}
                <div style={{ display: 'flex', alignItems: 'center' }} className='ratingstar'>
                  {/* Numeric Rating */}
                  <span style={{ fontSize: '25px', fontWeight: 'bold', marginRight: '10px', }}>4.6</span>

                  {/* Stars */}
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    {[...Array(5)].map((_, index) => (
                      <div
                        key={index}
                        style={{
                          width: '20px',
                          height: '20px',
                          background:
                            index < 4
                              ? '#FFD700'
                              : index === 4
                                ? 'linear-gradient(to right, #FFD700 50%, #FFFFFF 50%)'
                                : '#FFFFFF',
                          clipPath:
                            'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                          marginRight: '2px',
                        }}
                      />
                    ))}
                  </div>

                  {/* Total Number of Ratings */}
                  <span style={{ fontSize: '25px', color: '#01ffd9' }}>(510)</span>
                </div>

              </div>
            </p>






            <br />



            <br />
            <br />
            <br />
            <span style={{ backgroundColor: 'white', color: 'white' }}>
              <p
                style={{
                  minWidth: '300px',
                  fontWeight: 'bold',
                  padding: '30px',
                  marginTop: '50px',
                  fontSize: '40px',
                  marginLeft: '-500px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif',
                }}
                className="bannerhead datamains"
              >

                Special Offer

              </p>



              <p style={{
                fontWeight: 'bold',
                padding: '30px',
                marginLeft: '86px',
                fontSize: '22px',
                color: '#fff',
                marginTop: '-75px',
                marginLeft: '454px',
                fontFamily: 'sans-serif'
              }}
                className="bannerhead get-free"
              >
                Get Free Training On
              </p>
              <br />
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginTop: '-75px',
                  marginLeft: '454px',
                  fontSize: '20px',
                  minWidth: '490px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead delmiasofte"
              >
                Process Simulate / Delmia Software
              </p>
              <br></br>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginTop: '-75px',
                  marginLeft: '454px',
                  fontSize: '20px',
                  color: '#fff',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead souseregis"
              >
                For the First 25 Course Registrants
              </p>

            </span>







            <br />


            {/* Centering and improving the "2 hour Webinar" text */}
            <div style={{ marginLeft: '350px', marginTop: '-150px' }} className='timecolucations'>
              <div
                style={{
                  display: 'flex',
                  height: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '-1000px',
                  marginTop: '0px',
                  marginRight: '1000px',
                  marginBottom: '30px',
                  marginTop: '-90px',
                }}
              >
                <h2
                  style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'sans-serif',
                    marginLeft: '497px',
                    marginTop: '-590px',
                    borderRadius: '8px',
                    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)',
                  }}
                >

                  <br />

                  <div
                    style={{
                      fontSize: '30px',
                      marginTop: '44px',
                      marginBottom: '20px',
                    }}
                  >

                    <br />
                    <div style={{ marginTop: '20px' }}></div>
                  </div>
                </h2>
              </div>


              <div style={styles.container}>
                <div style={styles.timerBox} className='timerBox'>

                  <div style={styles.countdownContainer}>
                    <div style={styles.timeBlock}>
                      <div style={styles.timeValue} className='timeValue'>
                        {String(Math.min(timeLeft.days, 2)).padStart(2, '')}1
                      </div>
                      <div style={styles.label}>DAYS</div>
                    </div>
                    <div style={styles.timeBlock}>
                      <div style={styles.timeValue} className='timeValue'>{String(timeLeft.hours).padStart(2, '0')}</div>
                      <div style={styles.label}>HOURS</div>
                    </div>
                    <div style={styles.timeBlock}>
                      <div style={styles.timeValue} className='timeValue'>{String(timeLeft.minutes).padStart(2, '0')}</div>
                      <div style={styles.label}>MINUTES</div>
                    </div>
                    <div style={styles.timeBlock}>
                      <div style={styles.timeValue} className='timeValue'>{String(timeLeft.seconds).padStart(2, '0')}</div>
                      <div style={styles.label}>SECONDS</div>
                    </div>

                  </div>
                  <div style={styles.timeBlock}>
                    <div
                      style={{
                        // display: 'flex',
                        marginTop: '-110px',
                        marginLeft: '280px',
                        alignItems: 'center',
                        justifyContent: 'center', // Keeps the text centered
                        gap: '4px',
                        position: 'relative',
                      }}
                    >

                      <img
                        src={clockIcon}
                        alt="Clock Icon"
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                          position: 'absolute',
                          right: '-100px', // Adjust the value as needed
                        }}
                        className='bannertimerclock'
                      />
                    </div>

                  </div>




                </div>

              </div>
            </div>

          </div>



          <div className='shape dddsa'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
                marginBottom: '30px',
              }}
            >
              <h2
                style={{
                  fontSize: '40px',
                  fontWeight: 'bold',
                  color: '#fff',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  marginLeft: '497px',
                  marginTop: '-579px',
                  borderRadius: '8px',
                  // textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)',
                }}

              >

                <br />
                <div
                  style={{
                    fontSize: '30px', // Smaller font size for the additional text
                    marginTop: '44px',
                    marginBottom: '20px', // Adds space between the two lines
                  }}
                >
                  2 Hours Webinar 99/Free
                  <br />
                  <div style={{ marginTop: '20px' }}>On 14:12:2024: 11:00:AM</div>
                </div>
              </h2>
            </div>


          
          </div>





          <div
            className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center dataofenrollregist"
            style={{ width: '100%', marginTop: "-50px", marginLeft: '-106px' }}
          >
            <div className="col-12 col-md-auto mb-2 mb-md-0">
              <button
                type="button"
                className="btn brittle ps-4 pe-4 custom-button w-100"
                style={{
                  height: '40px',
                  backgroundColor: 'red',
                  color: 'white',
                }}
                onClick={() => {
                  // Define the cart item object
                  const cartItem = {
                    type: "WEBINAR PROGRAM",
                    course:"Digital Factory Solution",
                    price: 99,
                    bannerImage: bannerImage,
                    userId: userId,
                    date: "21/12/2024",
                    time: "11:00 AM",
                  };

                  let allCarts = JSON.parse(localStorage.getItem("carts")) || {};
                  if (!allCarts[userId]) {
                    allCarts[userId] = [];
                  }
                  allCarts[userId].push(cartItem);
                  localStorage.setItem("carts", JSON.stringify(allCarts));
                  window.location.href = "/shoppingcart";
                }}
              >
                Enquiry Now
              </button>
            </div>
            <div className="col-12 col-md-auto">
              <button
                type="button"
                className="btn brittle ps-4 pe-4 custom-button w-100"
                style={{
                  backgroundColor: 'red',
                  height: '40px',
                }}
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = brochure;
                  link.download = 'Brochure.pdf';
                  link.click();
                }}
              >
                Brochure
              </button>
            </div>
          </div>

        </div>
      </div>


    </div>

  );
  const Banner4 = () => (
    <div className="hero-area overly-style-1 opacity-point-4"
  
     >
      <div className="slide-content">
        <div className="banner-container" style={{ position: 'relative' }}>

          {/* Image added above the content */}
          <img
            className="banner-image bddghtes"
            src={bannerImageses}
            alt="Banner"
            style={{ width: '100%', height: '400px' }}
          />

          {/* Content section */}



          <div
            style={{
              color: '#fff',
              position: 'absolute',
              top: '85px',
              textAlign: 'left',
            }}
            className="about-databanner"
          >
            <p
              style={{
                fontWeight: 'bold',
                padding: '10px',
                marginLeft: '0px',
                fontSize: '40px',
                color: '#fff',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead placedaaxis"
            >
              <span style={{ color: '#01ffd9', }}>100% PLACEMENT PROGRAM </span>   <br />
              
            </p>
            <p style={{
                fontWeight: 'bold',
                padding: '10px',
                marginLeft: '0px',
                marginTop:'-17px',
                fontSize: '40px',
                color: '#fff',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead fourthaxis">6-Axis Industrial Robotic</p>
            <br />
            <br />
            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '40px',
              color: '#fff',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatas">
              Virtual Simulation
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '0px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatas">
              {/* Duration : 3 :MONTH */}
            </p>


            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead directplacements">
              DIRECT PLACEMENT PROGRAM
              <span style={{ marginLeft: '50px' }}></span>
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead firstbreak"
            >  Looking For Your First Break?
              <span style={{ marginLeft: '40px' }}></span>
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead dirctkeyss">
              <div>

                <span style={{ marginLeft: '0px' }}>
                  Direct Placement Is The Key! <span style={{ color: '#01ffd9' }}></span>
                </span>
                {/* Rating */}
                

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }} className='fourthbanner'>
                  {/* Numeric Rating */}
                  <span style={{ fontSize: '25px', fontWeight: 'bold', marginRight: '10px', }}>4.4</span>

                  {/* Stars */}
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    {[...Array(5)].map((_, index) => (
                      <div
                        key={index}
                        style={{
                          width: '20px',
                          height: '20px',
                          background:
                            index < 4
                              ? '#FFD700'
                              : index === 4
                                ? 'linear-gradient(to right, #FFD700 50%, #FFFFFF 50%)'
                                : '#FFFFFF',
                          clipPath:
                            'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                          marginRight: '2px',
                        }}
                      />
                    ))}
                  </div>

                  {/* Total Number of Ratings */}
                  <span style={{ fontSize: '25px', color: '#01ffd9' }}>(408)</span>
                </div>
            </p>



            {/* <p style={{
        fontWeight: 'bold',
        padding: '10px',
        marginLeft: '0px',
        fontSize: '40px',
        color: '#fff',
        fontFamily: 'sans-serif'
      }} className="bannerhead simulationdta">Simulation</p> */}

            <br />

            {/* <h1
        style={{
          fontWeight: '700',
          marginLeft:'-28px',
          padding: '40px',
          fontSize: '22px',
          color: '#fff',
          fontFamily: 'sans-serif'
        }}
        className="bannerhead datamain"
      >
        Certification Program
      </h1> */}

            {/* <div style={styles.expiryContainer}>
              <div style={styles.expiryText}>Last Date: 26st December, 2024</div>
            </div> */}

            <br />
            <br />
            <br />
            <span style={{ backgroundColor: 'white', color: 'white' }}>
              <p
                style={{
                  minWidth: '300px',
                  fontWeight: 'bold',
                  padding: '30px',
                  marginTop: '0px',
                  fontSize: '40px',
                  marginLeft: '-500px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif',
                }}
                className="bannerhead datamains"
              >

                {/* Special Offer */}

              </p>




              <br />

              <br></br><br></br><br></br><br></br><br></br>

              <p style={{
                fontWeight: 'bold',
                padding: '30px',
                marginLeft: '86px',
                fontSize: '22px',
                color: '#fff',
                marginTop: '-70px',
                marginLeft: '515px',
                fontFamily: 'sans-serif'
              }}
                className="bannerhead placementprgromdata"
              >
                Get Free Training On
              </p>
              <br></br>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginLeft: '34px',
                  fontSize: '20px',
                  minWidth: '490px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead simdf"
              >
                Process Simulate / Delmia Software
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginLeft: '42px',
                  fontSize: '20px',
                  color: '#fff',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead simdf"
              >
                {/* For the First 25 Course Registrants ! */}
              </p>

            </span>







            <br />


            {/* Centering and improving the "2 hour Webinar" text */}
            <div style={{ marginLeft: '350px', marginTop: '-150px' }}>
              <div
                style={{
                  display: 'flex',
                  height: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '-1000px',
                  marginTop: '0px',
                  marginRight: '1000px',
                  marginBottom: '30px',
                  marginTop: '-90px',
                }}
              >
                <h2
                  style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'sans-serif',
                    marginLeft: '497px',
                    marginTop: '-590px',
                    borderRadius: '8px',
                    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)',
                  }}
                >

                  <br />

                  <div
                    style={{
                      fontSize: '30px', // Smaller font size for the additional text
                      marginTop: '44px',
                      marginBottom: '20px', // Adds space between the two lines
                    }}
                  >

                    <br />
                    <div style={{ marginTop: '20px' }}></div>
                  </div>
                </h2>
              </div>




            </div>









            <div
              className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrdsss"
              style={{ width: '56%', marginLeft: '350px' }}
            >
              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <button
                  type="button"
                  className="btn brittle ps-4 pe-4 custom-button w-100"
                  style={{
                    height: '40px',
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                  onClick={() => {
                    // Define the cart item object
                    const cartItem = {
                      type: "100 Percent PLACEMENT PROGRAM",
                      course:"Digital Factory Solution",
                      price: 99,
                      bannerImage: bannerImage,
                      userId: userId,
                      date: "21/12/2024",
                      time: "11:00 AM",
                    };

                    let allCarts = JSON.parse(localStorage.getItem("carts")) || {};
                    if (!allCarts[userId]) {
                      allCarts[userId] = [];
                    }
                    allCarts[userId].push(cartItem);
                    localStorage.setItem("carts", JSON.stringify(allCarts));
                    window.location.href = "/shoppingcart";
                  }}
                >
                  Enquiry Now
                </button>
              </div>
              <div className="col-12 col-md-auto">
                <button
                  type="button"
                  className="btn brittle ps-4 pe-4 custom-button w-100"
                  style={{
                    backgroundColor: 'red',
                    height: '40px',
                  }}
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = brochure;
                    link.download = 'Brochure.pdf';
                    link.click();
                  }}
                >
                  Brochure
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  );



  // Banner 2 Component
  const Banner2 = () => (
    <div className="hero-area overly-style-1 opacity-point-4"
  

    >
    <div className="slide-content">
      <div className="banner-container" style={{ position: 'relative' }}>

        {/* Image added above the content */}
        <img
          className="banner-image secondbannerhe"
          src={bannerImages}
          alt="Banner"
          style={{ width: '100%', height: '400px' }}
        />

        {/* Content section */}



        <div
          style={{
            color: '#fff',
            position: 'absolute',
            top: '85px',
            textAlign: 'left',
          }}
          className="about-databanner"
         >
          <p
            style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '-50px',
              fontSize: '40px',
              color: '#fff',
              fontFamily: 'sans-serif'
            }}
            className="bannerhead secondscrol"
          >
            <span style={{ color: '#01ffd9', }}>TRAINING PLACEMENT PROGRAM</span><br />
            6-Axis Industrial robotic{' '}
          </p>
          <br />
          <br />
          <p style={{
            fontWeight: 'bold',
            padding: '10px',
            marginLeft: '0px',
            fontSize: '40px',
            color: '#fff',
            fontFamily: 'sans-serif'
          }}
            className="bannerhead axisdatas">
            Virtual Simulation
          </p>

          <p style={{
            fontWeight: 'bold',
            padding: '10px',
            marginLeft: '0px',
            fontSize: '25px',
            color: '#fff',
            marginTop: '0px',
            fontFamily: 'sans-serif'
          }}
            className="bannerhead montdur">
            Duration : 3 MONTH
          </p>


          <p style={{
            fontWeight: 'bold',
            padding: '10px',
            marginLeft: '0px',
            fontSize: '25px',
            color: '#fff',
            marginTop: '-10px',
            fontFamily: 'sans-serif'
          }}
            className="bannerhead refundata">
            50% Fee Will Be Refunded <br /><span style={{ marginLeft: '50px', fontSize: '30px', color: 'rgb(1 255 217)', }}> </span>
          </p>

          <p style={{
            fontWeight: 'bold',
            padding: '10px',
            marginLeft: '0px',
            fontSize: '25px',
            color: '#fff',
            marginTop: '-60px',
            fontFamily: 'sans-serif'
          }}
            className="bannerhead notplacedata">
            If You  Are Not Placed  <span style={{ marginLeft: '30px' }}></span>
          </p>

          <p style={{
            fontWeight: 'bold',
            padding: '10px',
            marginLeft: '0px',
            fontSize: '25px',
            color: '#fff',
            marginTop: '-10px',
            fontFamily: 'sans-serif'
          }}
            className="bannerhead reviewdata">
            <div>
              {/* Price  
         <span style={{ marginLeft: '50px' }}>
          : 99/- <span style={{ color: '#01ffd9' }}>Free</span>
         </span> */}
              {/* Rating */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* Numeric Rating */}
                <span style={{ fontSize: '25px', fontWeight: 'bold', marginRight: '10px', }}>4.6</span>

                {/* Stars */}
                <div style={{ display: 'flex', marginRight: '10px' }}>
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      style={{
                        width: '20px',
                        height: '20px',
                        background:
                          index < 4
                            ? '#FFD700'
                            : index === 4
                              ? 'linear-gradient(to right, #FFD700 50%, #FFFFFF 50%)'
                              : '#FFFFFF',
                        clipPath:
                          'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                        marginRight: '2px',
                      }}
                    />
                  ))}
                </div>

                {/* Total Number of Ratings */}
                <span style={{ fontSize: '25px', color: '#01ffd9' }}>(412)</span>
              </div>

            </div>
          </p>





    
          <br />

        

        

          <br />
          <br />
          <br />
          <span style={{ backgroundColor: 'white', color: 'white' }}>
            <p
              style={{
                minWidth: '300px',
                fontWeight: 'bold',
                padding: '30px',
                marginTop: 'px',
                fontSize: '40px',
                marginLeft: '-500px',
                color: '#01ffd9',
                fontFamily: 'sans-serif',
              }}
              className="bannerhead datamains"
            >



            </p>

            <br />

            <br></br><br></br><br></br><br></br>

            <p style={{
              fontWeight: 'bold',
              padding: '30px',
              marginLeft: '86px',
              fontSize: '22px',
              color: '#fff',
              marginTop: '-50px',
              marginLeft: '480px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead getoffered"
            >
              Get Free Training On
            </p>
            <br></br>
            <p
              style={{
                fontWeight: 'bold',
                padding: '30px',
                marginTop: '-75px',
                marginLeft: '454px',
                fontSize: '20px',
                minWidth: '490px',
                color: '#01ffd9',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead processsimul"
            >
              Process Simulate / Delmia Software
            </p>
            <p
              style={{
                fontWeight: 'bold',
                padding: '30px',
                marginLeft: '42px',
                fontSize: '20px',
                color: '#fff',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead simdf"
            >
              {/* For the First 25 Course Registrants ! */}
            </p>







            
            <br></br>
          

          </span>







          <br />

          <div style={{ marginLeft: '350px', marginTop: '-150px' }}>
            <div
              style={{
                display: 'flex',
                height: '30px',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '-1000px',
                marginTop: '0px',
                marginRight: '1000px',
                marginBottom: '30px',
                marginTop: '-90px',
              }}
            >
              <h2
                style={{
                  fontSize: '40px',
                  fontWeight: 'bold',
                  color: '#fff',
                  textAlign: 'center',
                  fontFamily: 'sans-serif',
                  marginLeft: '497px',
                  marginTop: '-590px',
                  borderRadius: '8px',
                  textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)',
                }}
              >

                <br />

                <div
                  style={{
                    fontSize: '30px', // Smaller font size for the additional text
                    marginTop: '44px',
                    marginBottom: '20px', // Adds space between the two lines
                  }}
                >

                  <br />
                  <div style={{ marginTop: '20px' }}></div>
                </div>
              </h2>
            </div>

            <div
              style={{
                // display: 'flex',
                marginTop: '-150px',
                marginLeft: '280px',
                alignItems: 'center',
                justifyContent: 'center', // Keeps the text centered
                gap: '4px',
                position: 'relative',
              }}
            >

            </div>
            {/* <div style={styles.label}>SECONDS</div> */}



          </div>









          <div
            className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure datahahha"
            style={{ width: '56%', marginLeft: '350px', }}
           >
            <div className="col-12 col-md-auto mb-2 mb-md-0">
              <button
                type="button"
                className="btn brittle ps-4 pe-4 custom-button w-100"
                style={{
                  height: '40px',
                  backgroundColor: 'red',
                  color: 'white',
                }}
                onClick={() => {
                  // Define the cart item object
                  const cartItem = {
                    type: "TRAINING PLACEMENT PROGRAM",
                    price: 99,
                    bannerImage: bannerImage,
                    userId: userId,
                    date: "21/12/2024",
                    time: "11:00 AM",
                  };

                  let allCarts = JSON.parse(localStorage.getItem("carts")) || {};
                  if (!allCarts[userId]) {
                    allCarts[userId] = [];
                  }
                  allCarts[userId].push(cartItem);
                  localStorage.setItem("carts", JSON.stringify(allCarts));
                  window.location.href = "/shoppingcart";
                }}
              >
                Enquiry Now
              </button>
            </div>
            <div className="col-12 col-md-auto">
              <button
                type="button"
                className="btn brittle ps-4 pe-4 custom-button w-100"
                style={{
                  backgroundColor: 'red',
                  height: '40px',
                }}
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = brochure;
                  link.download = 'Brochure.pdf';
                  link.click();
                }}
              >
                Brochure
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
  );

  // Banner 3 Component
  const Banner3 = () => (
    <div className="hero-area overly-style-1 opacity-point-4"


      >
      <div className="slide-content">
        <div className="banner-container" style={{ position: 'relative' }}>

          {/* Image added above the content */}
          <img
            className="banner-image thirdbannervirtual"
            src={bannerImagese}
            alt="Banner"
            style={{ width: '100%', height: '400px' }}
          />

          {/* Content section */}



          <div
            style={{
              color: '#fff',
              position: 'absolute',
              top: '85px',
              textAlign: 'left',
            }}
            className="about-databanner"
          >
            <p
              style={{
                fontWeight: 'bold',
                padding: '10px',
                marginLeft: '0px',
                fontSize: '40px',
                color: '#fff',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead internshipsasis"
            >
              <span style={{ color: '#01ffd9', }}>INTERNSHIP PLACEMENT PROGRAM </span>   <br />
             
            </p>
            <p className='thirdsixaxies'> 6-Axis Industrial Robotic</p>
            <br />
            <br />
            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '40px',
              color: '#fff',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatas">
              Virtual Simulation
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '0px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead interndute">
              Duration : 3 :MONTHS
            </p>


            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
             }}
              className="bannerhead montlystipend">
              Monthly Stipend Provided During
              <span style={{ marginLeft: '50px' }}></span>
            </p>
            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '-470px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
             }}
             className='intershipdatathired'>Internship ,Followed By Placement</p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatas"
            >
              <span style={{ marginLeft: '40px' }}></span>
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-50px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead revieinterhing">
              <div>

                {/* Rating */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* Numeric Rating */}
                  <span style={{ fontSize: '25px', fontWeight: 'bold', marginRight: '10px', }}>4.4</span>

                  {/* Stars */}
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    {[...Array(5)].map((_, index) => (
                      <div
                        key={index}
                        style={{
                          width: '20px',
                          height: '20px',
                          background:
                            index < 4
                              ? '#FFD700'
                              : index === 4
                                ? 'linear-gradient(to right, #FFD700 50%, #FFFFFF 50%)'
                                : '#FFFFFF',
                          clipPath:
                            'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                          marginRight: '2px',
                        }}
                      />
                    ))}
                  </div>

                  {/* Total Number of Ratings */}
                  <span style={{ fontSize: '25px', color: '#01ffd9' }}>(408)</span>
                </div>

              </div>
            </p>



            {/* <p style={{
            fontWeight: 'bold',
            padding: '10px',
            marginLeft: '0px',
            fontSize: '40px',
            color: '#fff',
            fontFamily: 'sans-serif'
          }} className="bannerhead simulationdta">Simulation</p> */}

            <br />

            {/* <h1
            style={{
              fontWeight: '700',
              marginLeft:'-28px',
              padding: '40px',
              fontSize: '22px',
              color: '#fff',
              fontFamily: 'sans-serif'
            }}
            className="bannerhead datamain"
          >
            Certification Program
          </h1> */}

            {/* <div style={styles.expiryContainer}>
                  <div style={styles.expiryText}>Last Date: 26st December, 2024</div>
                </div> */}

            <br />
            <br />
            <br />
            <span style={{ backgroundColor: 'white', color: 'white' }}>
              <p
                style={{
                  minWidth: '300px',
                  fontWeight: 'bold',
                  padding: '30px',
                  marginTop: '0px',
                  fontSize: '40px',
                  marginLeft: '-500px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif',
                }}
                className="bannerhead datamains"
              >

                {/* Special Offer */}

              </p>




              <br />

              <br></br><br></br><br></br><br></br>

              <p style={{
                fontWeight: 'bold',
                padding: '30px',
                marginLeft: '86px',
                fontSize: '22px',
                color: '#fff',
                marginTop: '-80px',
                marginLeft: '484px',
                fontFamily: 'sans-serif'
              }}
                className="bannerhead get-freetraing"
              >
                Get Free Training On
              </p>
              <br></br>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginLeft: '34px',
                  fontSize: '20px',
                  minWidth: '490px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead simdf"
              >
                Process Simulate / Delmia Software
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginLeft: '42px',
                  fontSize: '20px',
                  color: '#fff',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead simdf"
              >
                {/* For the First 25 Course Registrants ! */}
              </p>

            </span>
            {/* <div
                  style={{
                    fontSize: '30px', // Smaller font size for the additional text
                    marginTop: '4px',
                    marginBottom: '0px', // Adds space between the two lines
                  }}
                >
                  2 Hours Webinar 99/Free
                  <br />
                  <div style={{ marginTop: '20px' }}>On 14:12:2024: 11:00:AM</div>
                </div> */}







            <br />
            {/* <div className="d-flex justify-content-center">
            <img
              className="banner-image bddghss"
              src={calender}
              alt="Banner"
              style={{ width: '40%', height: '180px', marginTop: '-330px', marginLeft: '630px' }}
            />
          </div> */}

            {/* Centering and improving the "2 hour Webinar" text */}
            <div style={{ marginLeft: '350px', marginTop: '-150px' }}>
              <div
                style={{
                  display: 'flex',
                  height: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '-1000px',
                  marginTop: '0px',
                  marginRight: '1000px',
                  marginBottom: '30px',
                  marginTop: '-90px',
                }}
              >
                <h2
                  style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'sans-serif',
                    marginLeft: '497px',
                    marginTop: '-590px',
                    borderRadius: '8px',
                    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)',
                  }}
                >

                  <br />

                  <div
                    style={{
                      fontSize: '30px', // Smaller font size for the additional text
                      marginTop: '44px',
                      marginBottom: '20px', // Adds space between the two lines
                    }}
                  >

                    <br />
                    <div style={{ marginTop: '20px' }}></div>
                  </div>
                </h2>
              </div>





            </div>









            <div
              className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrdsss"
              style={{ width: '56%', marginLeft: '350px' }}
            >
              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <button
                  type="button"
                  className="btn brittle ps-4 pe-4 custom-button w-100"
                  style={{
                    height: '40px',
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                  onClick={() => {
                    // Define the cart item object
                    const cartItem = {
                      type: "INTERNSHIP PLACEMENT PROGRAM",
                      price: 99,
                      bannerImage: bannerImage,
                      userId: userId,
                      date: "21/12/2024",
                      time: "11:00 AM",
                    };

                    let allCarts = JSON.parse(localStorage.getItem("carts")) || {};
                    if (!allCarts[userId]) {
                      allCarts[userId] = [];
                    }
                    allCarts[userId].push(cartItem);
                    localStorage.setItem("carts", JSON.stringify(allCarts));
                    window.location.href = "/shoppingcart";
                  }}
                >
                  Enquiry Now
                </button>
              </div>
              <div className="col-12 col-md-auto">
                <button
                  type="button"
                  className="btn brittle ps-4 pe-4 custom-button w-100"
                  style={{
                    backgroundColor: 'red',
                    height: '40px',
                  }}
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = brochure;
                    link.download = 'Brochure.pdf';
                    link.click();
                  }}
                >
                  Brochure
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );

  const styles = {
    container: {
      marginTop: '-100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      maxWidth: '100%',
      textAlign: 'center',
      margin: '0px auto',
    },
    expiryContainer: {
      marginBottom: '10px',
    },
    expiryText: {
      color: 'white',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    timerBox: {
      // backgroundColor: 'rgba(34, 34, 34, 0.8)',
      padding: '20px',
      borderRadius: '10px',
      textAlign: 'center',
      marginTop: '-110px',
      marginLeft: '-50px',
      width: '90%',
      maxWidth: '400px',
    },
    countdownContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      marginBottom: '10px',
    },
    timeBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '5px',
    },
    timeValue: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#222',
      padding: '10px',
      borderRadius: '5px',
    },
    label: {
      color: 'white',
      marginTop: '5px',
      fontSize: '0.75rem',
    },
    registerButton: {
      backgroundColor: '#000',
      color: 'white',
      fontSize: '1rem',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '10px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      width: '100%',
      maxWidth: '250px',
    },
  };
  useEffect(() => {
    // Calculate tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to tomorrow's date
    tomorrow.setHours(11, 0, 0, 0); // Set to midnight

    const updateTimer = () => {
      const now = new Date();
      const difference = tomorrow - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 }); // Timer expired
      }
    };

    const timerId = setInterval(updateTimer, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const totalSeconds =
          prevTime.days * 24 * 60 * 60 +
          prevTime.hours * 60 * 60 +
          prevTime.minutes * 60 +
          prevTime.seconds;

        const newTotalSeconds = totalSeconds - 1;
        const newDays = Math.floor(newTotalSeconds / (24 * 60 * 60));
        const newHours = Math.floor((newTotalSeconds % (24 * 60 * 60)) / (60 * 60));
        const newMinutes = Math.floor((newTotalSeconds % (60 * 60)) / 60);
        const newSeconds = newTotalSeconds % 60;

        return {
          days: newDays,
          hours: newHours,
          minutes: newMinutes,
          seconds: newSeconds,
        };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  const handleRedirect = () => {
    navigate('/login-form'); // Replace '/login-form' with your target route
  };

  return (
    <div className="hero-area overly-style-1 opacity-point-4"
      style={{marginTop:'100px'}}
     >
      <div className="slide-content">
        <div className="banner-container" style={{ position: 'relative' }}>

          {/* Image added above the content */}
          <img
            className="banner-image bddghtes"
            src={bannerImageses}
            alt="Banner"
            style={{ width: '100%', height: '400px' }}
          />

          {/* Content section */}



          <div
            style={{
              color: '#fff',
              position: 'absolute',
              top: '85px',
              textAlign: 'left',
            }}
            className="about-databanner"
          >
            <p
              style={{
                fontWeight: 'bold',
                padding: '10px',
                marginLeft: '0px',
                fontSize: '40px',
                color: '#fff',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead placedaaxis"
            >
              <span style={{ color: '#01ffd9', }}>100% PLACEMENT PROGRAM </span>   <br />
              
            </p>
            <p style={{
                fontWeight: 'bold',
                padding: '10px',
                marginLeft: '0px',
                marginTop:'-17px',
                fontSize: '40px',
                color: '#fff',
                fontFamily: 'sans-serif'
              }}
              className="bannerhead fourthaxis">6-Axis Industrial Robotic</p>
            <br />
            <br />
            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '40px',
              color: '#fff',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatas">
              Virtual Simulation
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '0px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead axisdatas">
              {/* Duration : 3 :MONTH */}
            </p>


            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead directplacements">
              DIRECT PLACEMENT PROGRAM
              <span style={{ marginLeft: '50px' }}></span>
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead firstbreak"
            >  Looking For Your First Break?
              <span style={{ marginLeft: '40px' }}></span>
            </p>

            <p style={{
              fontWeight: 'bold',
              padding: '10px',
              marginLeft: '0px',
              fontSize: '25px',
              color: '#fff',
              marginTop: '-10px',
              fontFamily: 'sans-serif'
            }}
              className="bannerhead dirctkeyss">
              <div>

                <span style={{ marginLeft: '0px' }}>
                  Direct Placement Is The Key! <span style={{ color: '#01ffd9' }}></span>
                </span>
                {/* Rating */}
                

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }} className='fourthbanner'>
                  {/* Numeric Rating */}
                  <span style={{ fontSize: '25px', fontWeight: 'bold', marginRight: '10px', }}>4.4</span>

                  {/* Stars */}
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    {[...Array(5)].map((_, index) => (
                      <div
                        key={index}
                        style={{
                          width: '20px',
                          height: '20px',
                          background:
                            index < 4
                              ? '#FFD700'
                              : index === 4
                                ? 'linear-gradient(to right, #FFD700 50%, #FFFFFF 50%)'
                                : '#FFFFFF',
                          clipPath:
                            'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                          marginRight: '2px',
                        }}
                      />
                    ))}
                  </div>

                  {/* Total Number of Ratings */}
                  <span style={{ fontSize: '25px', color: '#01ffd9' }}>(408)</span>
                </div>
            </p>



            {/* <p style={{
        fontWeight: 'bold',
        padding: '10px',
        marginLeft: '0px',
        fontSize: '40px',
        color: '#fff',
        fontFamily: 'sans-serif'
      }} className="bannerhead simulationdta">Simulation</p> */}

            <br />

            {/* <h1
        style={{
          fontWeight: '700',
          marginLeft:'-28px',
          padding: '40px',
          fontSize: '22px',
          color: '#fff',
          fontFamily: 'sans-serif'
        }}
        className="bannerhead datamain"
      >
        Certification Program
      </h1> */}

            {/* <div style={styles.expiryContainer}>
              <div style={styles.expiryText}>Last Date: 26st December, 2024</div>
            </div> */}

            <br />
            <br />
            <br />
            <span style={{ backgroundColor: 'white', color: 'white' }}>
              <p
                style={{
                  minWidth: '300px',
                  fontWeight: 'bold',
                  padding: '30px',
                  marginTop: '0px',
                  fontSize: '40px',
                  marginLeft: '-500px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif',
                }}
                className="bannerhead datamains"
              >

                {/* Special Offer */}

              </p>




              <br />

              <br></br><br></br><br></br><br></br><br></br>

              <p style={{
                fontWeight: 'bold',
                padding: '30px',
                marginLeft: '86px',
                fontSize: '22px',
                color: '#fff',
                marginTop: '-70px',
                marginLeft: '515px',
                fontFamily: 'sans-serif'
              }}
                className="bannerhead placementprgromdata"
              >
                Get Free Training On
              </p>
              <br></br>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginLeft: '34px',
                  fontSize: '20px',
                  minWidth: '490px',
                  color: '#01ffd9',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead simdf"
              >
                Process Simulate / Delmia Software
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '30px',
                  marginLeft: '42px',
                  fontSize: '20px',
                  color: '#fff',
                  fontFamily: 'sans-serif'
                }}
                className="bannerhead simdf"
              >
                {/* For the First 25 Course Registrants ! */}
              </p>

            </span>







            <br />


            {/* Centering and improving the "2 hour Webinar" text */}
            <div style={{ marginLeft: '350px', marginTop: '-150px' }}>
              <div
                style={{
                  display: 'flex',
                  height: '30px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '-1000px',
                  marginTop: '0px',
                  marginRight: '1000px',
                  marginBottom: '30px',
                  marginTop: '-90px',
                }}
              >
                <h2
                  style={{
                    fontSize: '40px',
                    fontWeight: 'bold',
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'sans-serif',
                    marginLeft: '497px',
                    marginTop: '-590px',
                    borderRadius: '8px',
                    textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)',
                  }}
                >

                  <br />

                  <div
                    style={{
                      fontSize: '30px', // Smaller font size for the additional text
                      marginTop: '44px',
                      marginBottom: '20px', // Adds space between the two lines
                    }}
                  >

                    <br />
                    <div style={{ marginTop: '20px' }}></div>
                  </div>
                </h2>
              </div>




            </div>









            <div
              className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrdsss"
              style={{ width: '56%', marginLeft: '350px' }}
            >
              <div className="col-12 col-md-auto mb-2 mb-md-0">
                <button
                  type="button"
                  className="btn brittle ps-4 pe-4 custom-button w-100"
                  style={{
                    height: '40px',
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                  onClick={() => {
                    // Define the cart item object
                    const cartItem = {
                      type: "100 Percent PLACEMENT PROGRAM",
                      price: 99,
                      bannerImage: bannerImage,
                      userId: userId,
                      date: "21/12/2024",
                      time: "11:00 AM",
                    };

                    let allCarts = JSON.parse(localStorage.getItem("carts")) || {};
                    if (!allCarts[userId]) {
                      allCarts[userId] = [];
                    }
                    allCarts[userId].push(cartItem);
                    localStorage.setItem("carts", JSON.stringify(allCarts));
                    window.location.href = "/shoppingcart";
                  }}
                >
                  Enquiry Now
                </button>
              </div>
              <div className="col-12 col-md-auto">
                <button
                  type="button"
                  className="btn brittle ps-4 pe-4 custom-button w-100"
                  style={{
                    backgroundColor: 'red',
                    height: '40px',
                  }}
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = brochure;
                    link.download = 'Brochure.pdf';
                    link.click();
                  }}
                >
                  Brochure
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>



  );
};

export default Gaming;


