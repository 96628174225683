import React from 'react';
import video from '../img/robotics hands workingVideo.mp4';

const MainBanner = () => {
  return (
    <div
      className="hero-area overly-style-1 opacity-point-4"
      style={{
        height: '1100px',
        width: '100%',
        zIndex:'1',
        position: 'relative', // Ensure relative positioning for z-index to work
        overflow: 'hidden',
      }}
    >
      {/* Background Video */}
      <video
        className="banner-video"
        src={video}
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '70%',
          objectFit: 'cover', // Ensures the video covers the area
          zIndex: -1, // Keeps the video behind content
        }}
      ></video>

      {/* Overlay Content */}
      <div
        className="hero-content-1 hero-content-position"
        style={{
          position: 'relative', // Ensure content is positioned relative to parent
          color: '#fff',
          zIndex: 1, // Keeps content above the video
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Center content vertically
          alignItems: 'center', // Center content horizontally
          height: '100%', // Take full height of the banner
        }}
      >
        <h1
          style={{
            color: '#fff',
            fontFamily: 'Urbanist, serif',
            fontWeight: 700,
            letterSpacing: '9px',
            marginTop:'-393px'
          }}
          className='mainbanner-content'
        >
         "Welcome to IIA–Where Innovation Meets Opportunity"

        </h1>
       <h3 style={{
            color: '#fff',
            // width:'50%',
            textAlign:'center',
            // marginTop:'50px',
            fontSize:'20px',
            fontWeight:'400 !important'
          }} className='banner-second-data'>Believe in unlocking the power of innovation and to create new opportunities for growth and ultimate success.
       </h3>
       
      </div>
    </div>
  );
};

export default MainBanner;



// import React from 'react';
// import video from '../img/robotics hands workingVideo.mp4';

// const MainBanner = () => {
//   return (
//     <div
//       className="hero-area overly-style-1 opacity-point-4"
//       style={{
//         height: '950px',
//         width: '100%',
//         zIndex: '1',
//         position: 'relative', // Ensure relative positioning for z-index to work
//         overflow: 'hidden',
//       }}
//     >
//       {/* Background Video */}
//       <video
//         className="banner-video"
//         src={video}
//         autoPlay
//         loop
//         muted
//         playsInline
//         style={{
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//           objectFit: 'cover', // Ensures the video covers the area
//           zIndex: -1, // Keeps the video behind content
//         }}
//       ></video>

//       {/* Overlay Content - Right Side */}
//       <div
//         className="hero-content-1 hero-content-position"
//         style={{
//           position: 'absolute', // Absolute position for top-right corner alignment
//           top: '250px', // Adjust this value as needed
//           right: '20px', // Right-side content
//           color: '#fff',
//           zIndex: 1, // Keeps content above the video
//           textAlign: 'right', // Aligns text to the right
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'flex-start', // Adjust vertical alignment
//           alignItems: 'flex-end', // Aligns content to the right
//         }}
//       >
//         <h1
//           style={{
//             color: '#fff',
//             fontFamily: 'Urbanist, serif',
//             fontWeight: 700,
//             letterSpacing: '9px',
//             width: '50%',
//             textAlign: 'center',
//           }}
//         >
//           "Welcome to IIA–Where Innovation Meets Opportunity"
//         </h1>
//         <h3
//           style={{
//             color: '#fff',
//             width: '50%',
//             textAlign: 'center',
//             fontSize: '16px',
//             fontWeight: '400',
//           }}
//         >
//           Believe in unlocking the power of innovation, and to create new
//           opportunities for growth and ultimate success.
//         </h3>
//       </div>

//       {/* Overlay Content - Left Side */}
//       {/* <div
//         className="hero-content-2 hero-content-position"
//         style={{
//           position: 'absolute', // Absolute position for top-left corner alignment
//           top: '450px', // Adjust this value as needed
//           left: '20px', // Left-side content
//           color: '#fff',
//           zIndex: 1, // Keeps content above the video
//           textAlign: 'left', // Aligns text to the left
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'flex-start', // Adjust vertical alignment
//           alignItems: 'flex-start', // Aligns content to the left
//         }}
//        >
//         <h1
//           style={{
//             color: '#fff',
//             fontFamily: 'Urbanist, serif',
//             fontWeight: 700,
//             letterSpacing: '9px',
//             width: '50%',
//             textAlign: 'center',
//           }}
//         >
//           "Welcome to IIA–Where Innovation Meets Opportunity"
//         </h1>
//         <h3
//           style={{
//             color: '#fff',
//             width: '50%',
//             textAlign: 'center',
//             fontSize: '16px',
//             fontWeight: '400',
//           }}
//         >
//           Believe in unlocking the power of innovation, and to create new
//           opportunities for growth and ultimate success.
//         </h3>
//       </div> */}
//       <div
//   className="hero-content-2 hero-content-position"
//   style={{
//     position: 'absolute', // Absolute position for top-left corner alignment
//     top: '450px', // Adjust this value as needed
//     left: '20px', // Left-side content
//     color: '#fff',
//     zIndex: 1, // Keeps content above the video
//     textAlign: 'left', // Aligns text to the left
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'flex-start', // Adjust vertical alignment
//     alignItems: 'flex-start', // Aligns content to the left
//   }}
// >
//   <ul
//     style={{
//       listStyleType: 'disc', // Adds bullet points for the list
//       paddingLeft: '20px', // Indentation for the list items
//     }}
//   >
//     <li
//       style={{
//         color: '#fff',
//         fontFamily: 'Urbanist, serif',
//         fontWeight: 700,
//         letterSpacing: '9px',
//         fontSize: '18px', // Adjusts font size for list item
//         marginBottom: '10px', // Adds spacing between list items
//       }}
//     >
//       "Welcome to IIA–Where Innovation Meets Opportunity"
//     </li>
//     <li
//       style={{
//         color: '#fff',
//         fontSize: '16px', // Adjusts font size for the description
//         fontWeight: '400',
//         marginBottom: '10px', // Adds spacing between list items
//       }}
//     >
//       Believe in unlocking the power of innovation, and to create new
//       opportunities for growth and ultimate success.
//     </li>
//   </ul>
// </div>

//     </div>
//   );
// };

// export default MainBanner;

