

// import React, { useState, useEffect } from 'react';

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [nestedToggle, setNestedToggle] = useState({});
//     const [data, setData] = useState([]);

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const updateNestedToggle = (nestedId) => {
//         setNestedToggle((prevState) => ({
//             ...prevState,
//             [nestedId]: !prevState[nestedId],
//         }));
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch('your-api-url');
//                 const data = await response.json();
//                 setData(data); // Update the state with fetched data
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
//         fetchData();
//     }, []);
    
//     const listContent = [
//         {
//             id: 0,
//             title: " INTRODUCTION TO MECHANICAL ENGINEERING",
//             content: null,
//             nestedContent: [],
//         },
//         {
//             id: 1,
//             title: "Core Mechanical Engineering Principles ",
//             content: null,
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Thermodynamics  ",
//                     dummyText: ["*	Laws of Thermodynamics: Overview of the 1st and 2nd laws", "*•	Heat and Work Interaction: Basics and applications in engineering."],
//                 },
//                 { 
//                     id: 2,
//                     text: "•		 Fluid Mechanics  ",
//                     dummyText: ["*				Properties of Fluids: Viscosity, density, and compressibility.", "*			Fundamentals of Fluid Statics and Dynamics: Bernoulli’s equation and practical applications.", "*	Job Opportunities, Roles, and Packages for Fresher.",],
//                 },
//                 {
//                     id: 3,
//                     text: "• Engineering Mechanics ",
//                     dummyText: ["*	Force Systems and Equilibrium: Basic concepts of statics","*	Kinematics and Kinetics: Motion, velocity, and acceleration."],
//                 },
//                 {
//                     id: 4,
//                     text: "•  Strength of Materials  ",
//                     dummyText: ["*		Stress and Strain: Elasticity, Hooke’s Law.","*	Applications: Basics of buckling, torsion, and structural safety."],
//                 },
//             ],
//         },
//         {
//             id: 2,
//             title: " Manufacturing Processes   ",
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	 	Casting and Molding ",
//                     dummyText: ["*			Overview of sand, die, and investment casting..", "*Common defects and their impact on quality.",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Welding Processes Introduction to arc, MIG, and TIG welding techniques",
//                     dummyText: [,],
//                 },
//                 {
//                     id: 3,
//                     text: "•	 	Metal Forming Techniques ",
//                     dummyText: ["*	Forging, rolling, and extrusion: Methods and applications.","*	Cold vs. Hot Forming: Differences and use cases."],
//                 },
//                 {
//                     id: 4,
//                     text: "•	 	Material Removal Processes  ",
//                     dummyText: ["*		Conventional Methods: Turning, milling, and grinding.","*		Advanced Techniques: Basics of EDM (Electrical Discharge Machining)."],
//                 },
//             ],
//         },
//         {
//             id: 3,
//             title: "Mechanical Design and Automation  ",
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Design and Drafting ",
//                     dummyText: ["*			Mechanical Design Basics: Understanding the design process and factors of safety.", "*		Engineering Drawing: Overview of 2D/3D projections, sectional views, and assembly drawings.",],
//                 },
//                 {
//                     id: 2,
//                     text: "•		Automation and Industrial Processes  ",
//                     dummyText: ["*			Understanding Automation: Applications in modern industries.","*		PLC and CNC Basics: Introduction to programming and their role in precision and efficiency.",],
//                 },
//             ],
//         },
//         {
//             id: 4,
//             title: "Materials and Mechanisms   ",
//             dummyText: [,],
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Material Science and Selection ",
//                     dummyText: ["*	Properties of Materials: Mechanical and thermal properties.","*	Composite Materials: Overview of types and their applications.","*	Composite Materials: Overview of types and their applications."],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Kinematics of Machinery  ",
//                     dummyText: ["*				Introduction to Kinematics: Linkages, joints, and motion types.","	*	Mechanism Design: Automotive wipers, robotic arms, and other case studies.",],
//                 },
//             ],
//         },
//     ];

//     return (
//         <div className="container my-5">
//             <div className="list-tab" style={{ width: "100%", padding: 0 }}>
                
//             {listContent.map(({ id, title, content, nestedContent }, index) => (
//                     <div key={id} className="mb-3" style={{ width: "100%" }}>
//                         <div
//                             className={`p-3 rounded cursor-pointer d-flex justify-content-between align-items-center`}
//                             onClick={() => updateToggle(id)}
//                             style={{
//                                 width: "100%",
//                                 boxShadow: id === 0 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
//                                 backgroundColor: id === 0 ? 'transparent' : '#f8f9fa', // Make 0 background transparent
//                             }}
//                         >
//                             <span
//                                 className={`fw-bold`}
//                                 style={{
//                                     fontFamily: "Roboto",
//                                     fontSize: index === 0 ? "19px" : "20px",
//                                     color: index === 0 ? "#0072FF" : undefined,
//                                 }}
//                             >
//                                 {title}
//                             </span>
//                             {index !== 0 && (
//                                 <span style={{ fontSize: "20px" }}>
//                                     {toggle === id ? "-" : "+"}
//                                 </span>
//                             )}
//                         </div>
//                         {/* Add line below first item */}
//                         {/* {index === 0 && (
//                             <div
//                                 style={{
//                                     height: "1px",
//                                     backgroundColor: "orange",
//                                     width: "20%",
//                                      margin: "-13px",
//                                      marginLeft: '50px',
//                                 }}
//                             ></div>
//                         )} */}
//                                 {/* {title}
//                             </span>
//                             {id !== 0 && <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>}
//                         </div>
//                         {id === 0 && toggle === id && (
//                             <div className="mt-2" style={{ textAlign: "center" }}>
//                                 <div style={{ width: "35px", height: "2px", backgroundColor: "#555", margin: "10px auto" }} />
//                             </div>
//                         )} */}
                        
//                         {toggle === id && id !== 0 && (
//                             <div className="mt-2 px-4">
//                                 {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
//                                 {nestedContent.map(({ id: nestedId, text, dummyText }) => (
                                    
//                                     <div key={nestedId} className="my-2">
//                                         <div
//                                             className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
//                                             onClick={() => updateNestedToggle(nestedId)}
//                                             style={{ border: "1px solid #ddd" }}
//                                         >
//                                             <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
//                                             <span style={{ fontSize: "18px" }}>
//                                                 {nestedToggle[nestedId] ? "-" : "+"}
//                                             </span>
//                                         </div>
//                                         {nestedToggle[nestedId] && (
//                                             <div className="mt-2 px-3">
//                                                 <ul style={{ fontFamily: "Roboto", color: "#555" }}>
//                                                     {dummyText.map((item, index) => (
//                                                         <li key={index}>{item}</li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;








// import React, { useState } from 'react';

// const LearningPathTFD = () => {
//     const [toggleAllSections, setToggleAllSections] = useState(false); // Track visibility of all sections
//     const [sectionToggle, setSectionToggle] = useState({}); // Track visibility of individual sections

//     // Mocking fetched data for the sections
//     const listContent = [
//         {
//             id: 1,
//             title: "What is a Digital Factory",
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "• Definition and key principles.",
                    

//                     dummyText: [
                       
//                     ],
//                 },
//                 {
//                     id: 2,
//                     text: "\u2022 Evolution from traditional to smart manufacturing.",
//                     dummyText: [
//                         ,
//                     ],
//                 },
//                 {
//                     id: 3,
//                     text: "\u2022 Role in Industry 4.0 and beyond.",
//                     dummyText: [, ],
                        
                   
//                 },
                
//             ],
        
//         },
//         {
//             id: 2,
//             title: " Importance of Digital Factory Solutions",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "\u2022•	Enhancing productivity and efficiency.",
//                     dummyText: [
                        
//                     ],
//                 },
//                 {
//                     id: 2,
//                     text: "\u2022•		Real-time monitoring and control..",
//                     dummyText: [
                        
//                     ],
//                 },
//                 {
//                     id: 3,
//                     text: "\u2022•		Data-driven decision-making and predictive analytics..",
//                     dummyText: [
                        
//                     ],
//                 },
//             ],
//         },
//         {
//             id: 4,
//             title: " Digital Twin Technology",
//             nestedContent: [
//                 { id: 1, text: "\u2022 Definition and applications.", dummyText: [] },
//                 { id: 2, text: "\u2022Creating virtual replicas of physical systems", dummyText: [] },
                
//                 { id: 3, text: "\u2022 	Benefits: Predictive maintenance, process optimization.", dummyText: [] },
               
//             ],
//         },
//         {	
//             id: 5,
//             title: "IoT and Connectivity",
          
//             nestedContent: [
//                 { id: 1, text: "\u2022 •	Role of IoT in smart manufacturing.", dummyText: [] },
//                 { id: 2, text: "\u2022 •	Sensors, actuators and real-time data collection.", dummyText: [] },
//                 { id: 2, text: "\u2022 •	Protocols and standards for connectivity.", dummyText: [] },
//             ],
//         },
//         {	
//             id: 6,
//             title: "Automation and Robotics",
          
//             nestedContent: [
//                 { id: 1, text: "\u2022 •		Integration of robotics in digital factories.", dummyText: [] },
//                 { id: 2, text: "\u2022 •		Collaborative robots (Cobots) and AGVs.", dummyText: [] },
//                 { id: 2, text: "\u2022 •		Use cases: Material handling, assembly lines", dummyText: [] },
//             ],
//         },
//         {	
//             id: 7,
//             title: "Data Analytics and AI",
          
//             nestedContent: [
//                 { id: 1, text: "\u2022 •			Data collection, processing and visualization..", dummyText: [] },
//                 { id: 2, text: "\u2022 •		Machine learning for predictive and prescriptive analytics.", dummyText: [] },
//                 { id: 2, text: "\u2022 •			Case studies: AI-driven decision-making in manufacturing.", dummyText: [] },
//             ],
//         },
//     ];

//     const toggleSection = (id) => {
//         setSectionToggle((prev) => ({ ...prev, [id]: !prev[id] }));
//     };

//     return (
//         <div className="container my-5">
//             <h2
//                 className="fw-bold mb-4"
//                 style={{
//                     color: "#000",
//                     fontFamily: "Roboto",
//                     width: "100%",
//                     textAlign: "center",
//                     marginTop: "-50px",
//                 }}
//                 // onClick={() => setToggleAllSections(!toggleAllSections)} // Toggle visibility of all sections
                
//             >
//                 PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
//             </h2> <br/><br/><br/>
//             <div className="container my-5">
//             <div
//                 className="fw-bold mb-4 p-3 bg-light rounded"
//                 style={{
//                     color: "blue",
//                     fontFamily: "Roboto",
//                     width: "100%",
//                     textAlign: "left",
//                     fontSize:'20px',
//                     marginTop: "-50px",
//                     cursor: "pointer",
//                     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 onClick={() => setToggleAllSections(!toggleAllSections)} // Toggle visibility of all sections
//             >
//                Introduction to Digital Factory Solutions
//             </div>

//             {toggleAllSections && (
//                 <div className="list-tab mt-3" style={{ width: "100%", padding: 0 }}>
//                     {listContent.map(({ id, title, content, nestedContent }) => (
//                         <div key={id} className="mb-3" style={{ width: "100%" }}>
//                             <div
//                                 className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
//                                 style={{
//                                     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                                     width: "100%",
//                                     fontFamily: "Roboto",
//                                     fontSize: "18px",
//                                     fontWeight: "bold",
//                                     cursor: "pointer",
//                                 }}
//                                 onClick={() => toggleSection(id)}
//                             >
//                                 {title}
//                                 <span>{sectionToggle[id] ? '-' : '+'}</span>
//                             </div>
//                             {sectionToggle[id] && (
//                                 <div className="mt-2 px-4">
//                                     {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
//                                     {nestedContent && nestedContent.length > 0 && (
//                                         <div>
//                                             {nestedContent.map(({ id: nestedId, text, dummyText }) => (
//                                                 <div key={nestedId} className="my-2">
//                                                     <div
//                                                         className="p-2 bg-white rounded"
//                                                         style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}
//                                                     >
//                                                         {text}
//                                                     </div>
//                                                     <div className="mt-2 px-3">
//                                                         <ul style={{ fontFamily: "Roboto", color: "#555" }}>
//                                                             {dummyText.map((item, index) => (
//                                                                 <li key={index}>{item}</li>
//                                                             ))}
//                                                         </ul>
//                                                     </div>
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     )}
//                                 </div>
//                             )}
//                         </div>
//                     ))}
//                 </div>
//             )}
//         </div>
//         </div>
//     );
// };

// export default LearningPathTFD;




// import React, { useState } from "react";

// const LearningPathTFD = () => {
//   const [toggleAllSections, setToggleAllSections] = useState(false);
//   const [sectionToggle, setSectionToggle] = useState({});

//   const [toggleNewModule, setToggleNewModule] = useState(false);
//   const [newModuleToggle, setNewModuleToggle] = useState({});

//   const [isNewModuleVisible, setIsNewModuleVisible] = useState(false);
//   const [newModuleState, setNewModuleState] = useState({});


//   const listContent = [
//     // Existing sections
//     {
//       id: 1,
//       title: "What is a Digital Factory",
//       nestedContent: [
//         { id: 1, text: "• Definition and key principles.", dummyText: [] },
//         { id: 2, text: "• Evolution from traditional to smart manufacturing.", dummyText: [] },
//         { id: 3, text: "• Role in Industry 4.0 and beyond.", dummyText: [] },
//       ],
//     },
//     {
//       id: 2,
//       title: "Importance of Digital Factory Solutions",
//       nestedContent: [
//         { id: 1, text: "• Enhancing productivity and efficiency.", dummyText: [] },
//         { id: 2, text: "• Real-time monitoring and control.", dummyText: [] },
//         { id: 3, text: "• Data-driven decision-making and predictive analytics.", dummyText: [] },
//       ],
//     },
//   ];

//   const newModuleContent = [
//     // New module sections
//     {
//       id: 1,
//       title: "Advanced Digital Manufacturing",
//       nestedContent: [
//         { id: 1, text: "• Smart factory systems integration.", dummyText: [] },
//         { id: 2, text: "• Advanced robotics in manufacturing.", dummyText: [] },
//         { id: 3, text: "• Case studies of successful implementation.", dummyText: [] },
//       ],
//     },
//     {
//       id: 2,
//       title: "Future Trends in Digital Factories",
//       nestedContent: [
//         { id: 1, text: "• AI-driven manufacturing insights.", dummyText: [] },
//         { id: 2, text: "• Expansion of IoT connectivity.", dummyText: [] },
//         { id: 3, text: "• Sustainability in digital manufacturing.", dummyText: [] },
//       ],
//     },
//   ];
//   const exteraModuleContent = [
//     // New module sections
//     {
//       id: 3,
//       title: "Advanced Digital Manufacturing",
//       nestedContent: [
//         { id: 1, text: "• Smart factory systems integration.", dummyText: [] },
//         { id: 2, text: "• Advanced robotics in manufacturing.", dummyText: [] },
//         { id: 3, text: "• Case studies of successful implementation.", dummyText: [] },
//       ],
//     },
//     {
//       id: 4,
//       title: "Future Trends in Digital Factories",
//       nestedContent: [
//         { id: 1, text: "• AI-driven manufacturing insights.", dummyText: [] },
//         { id: 2, text: "• Expansion of IoT connectivity.", dummyText: [] },
//         { id: 3, text: "• Sustainability in digital manufacturing.", dummyText: [] },
//       ],
//     },
//   ];

//   const isexteraModuleContent = [
//     // New module sections
//     {
//       id: 1,
//       title: "Advanced Digital Manufacturing",
//       nestedContent: [
//         { id: 1, text: "• Smart factory systems integration.", dummyText: [] },
//         { id: 2, text: "• Advanced robotics in manufacturing.", dummyText: [] },
//         { id: 3, text: "• Case studies of successful implementation.", dummyText: [] },
//       ],
//     },
//     {
//       id: 2,
//       title: "Future Trends in Digital Factories",
//       nestedContent: [
//         { id: 1, text: "• AI-driven manufacturing insights.", dummyText: [] },
//         { id: 2, text: "• Expansion of IoT connectivity.", dummyText: [] },
//         { id: 3, text: "• Sustainability in digital manufacturing.", dummyText: [] },
//       ],
//     },
//   ];

//   const toggleSection = (id, module = "existing") => {
//     if (module === "existing") {
//       setSectionToggle((prev) => ({ ...prev, [id]: !prev[id] }));
//     } else {
//       setNewModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
//     }
//   };

//   return (
//     <div className="container my-5">
//       <h2
//         className="fw-bold mb-4"
//         style={{
//           color: "#000",
//           fontFamily: "Roboto",
//           width: "100%",
//           textAlign: "center",
//           marginTop: "-50px",
//         }}
//       >
//         PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
//       </h2>
//       <br />
//       <br />
//       <div className="container my-5">
//         {/* First Module */}
//         <div
//           className="fw-bold mb-4 p-3 bg-light rounded"
//           style={{
//             color: "blue",
//             fontFamily: "Roboto",
//             fontSize: "20px",
//             cursor: "pointer",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//           }}
//           onClick={() => setToggleAllSections(!toggleAllSections)}
//         >
//           Introduction to Digital Factory Solutions
//         </div>

//         {toggleAllSections && (
//           <div className="list-tab mt-3">
//             {listContent.map(({ id, title, nestedContent }) => (
//               <div key={id} className="mb-3">
//                 <div
//                   className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
//                   style={{
//                     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                     fontFamily: "Roboto",
//                     fontSize: "18px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => toggleSection(id)}
//                 >
//                   {title}
//                   <span>{sectionToggle[id] ? "-" : "+"}</span>
//                 </div>
//                 {sectionToggle[id] && (
//                   <div className="mt-2 px-4">
//                     {nestedContent.map(({ id: nestedId, text }) => (
//                       <div key={nestedId} className="my-2">
//                         <div
//                           className="p-2 bg-white rounded"
//                           style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}
//                         >
//                           {text}
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 )}
//               </div>
//             ))}
//           </div>
//         )}

//         {/* Second Module */}
//         <div
//           className="fw-bold mb-4 p-3 bg-light rounded mt-5"
//           style={{
//             color: "green",
//             fontFamily: "Roboto",
//             fontSize: "20px",
//             cursor: "pointer",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//           }}
//           onClick={() => setToggleNewModule(!toggleNewModule)}
//         >
//           Advanced Digital Manufacturing
//         </div>

//         {toggleNewModule && (
//           <div className="list-tab mt-3">
//             {newModuleContent.map(({ id, title, nestedContent }) => (
//               <div key={id} className="mb-3">
//                 <div
//                   className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
//                   style={{
//                     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                     fontFamily: "Roboto",
//                     fontSize: "18px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => toggleSection(id, "new")}
//                 >
//                   {title}
//                   <span>{newModuleToggle[id] ? "-" : "+"}</span>
//                 </div>
//                 {newModuleToggle[id] && (
//                   <div className="mt-2 px-4">
//                     {nestedContent.map(({ id: nestedId, text }) => (
//                       <div key={nestedId} className="my-2">
//                         <div
//                           className="p-2 bg-white rounded"
//                           style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}
//                         >
//                           {text}
//                         </div>
                        
//                       </div>
//                     ))}
//                   </div>
//                 )}
//               </div>
//             ))}
            
//           </div>
//         )}
//       </div>
     
//     </div>
   
   
//   );
// };


// export default LearningPathTFD;





import React, { useState } from "react";

const LearningPathTFD = () => {
  const [toggleAllSections, setToggleAllSections] = useState(false);
  const [sectionToggle, setSectionToggle] = useState({});
  const [toggleNewModule, setToggleNewModule] = useState(false);
  const [newModuleToggle, setNewModuleToggle] = useState({});
  const [toggleExteraModule, setToggleExteraModule] = useState(false);
  const [exteraModuleToggle, setExteraModuleToggle] = useState({});
  const [toggleIsExteraModule, setToggleIsExteraModule] = useState(false);
  const [isExteraModuleToggle, setIsExteraModuleToggle] = useState({});
  const [toggleAdditionalModule, setToggleAdditionalModule] = useState(false);
  const [additionalModuleToggle, setAdditionalModuleToggle] = useState({});
  const [toggleExtraAdvancedModule, setToggleExtraAdvancedModule] = useState(false);
  const [extraAdvancedModuleToggle, setExtraAdvancedModuleToggle] = useState({});
  const [toggleAndLocalModule, setToggleAndLocalModule]= useState(false);
  const [andLocalModuleToggle, setAndLocalModuleToggle]= useState({});
  const listContent = [
    // Existing sections
    {
      id: 1,
      title: "What is a Digital Factory",
      nestedContent: [
        { id: 1, text: "• Definition and key principles.", dummyText: [] },
        { id: 2, text: "• Evolution from traditional to smart manufacturing.", dummyText: [] },
        { id: 3, text: "• Role in Industry 4.0 and beyond.", dummyText: [] },
      ],
    },
    {
      id: 2,
      title: "Importance of Digital Factory Solutions",
      nestedContent: [
        { id: 1, text: "• Enhancing productivity and efficiency.", dummyText: [] },
        { id: 2, text: "• Real-time monitoring and control.", dummyText: [] },
        { id: 3, text: "• Data-driven decision-making and predictive analytics.", dummyText: [] },
      ],
    },
  ];

  const newModuleContent = [
    // New module sections
    {
      id: 1,
      title: "Digital Twin Technology",
      nestedContent: [
        { id: 1, text: "• 	Definition and applications.", dummyText: [] },
        { id: 2, text: "• 	Creating virtual replicas of physical systems.", dummyText: [] },
        { id: 3, text: "• 	Benefits: Predictive maintenance, process optimization.", dummyText: [] },
      ],
    },
    {
      id: 2,
      title: " IoT and Connectivity",
      nestedContent: [
        { id: 1, text: "• 	Role of IoT in smart manufacturing.", dummyText: [] },
        { id: 2, text: "• 	Sensors, actuators and real-time data collection.", dummyText: [] },
        { id: 3, text: "• 	Protocols and standards for connectivity.", dummyText: [] },
      ],
    },
    {
        id: 3,
        title: " Automation and Robotics",
        nestedContent: [
          { id: 1, text: "• 		Integration of robotics in digital factories.", dummyText: [] },
          { id: 2, text: "• 		Collaborative robots (Cobots) and AGVs.", dummyText: [] },
          { id: 3, text: "• 		Use cases: Material handling, assembly lines.", dummyText: [] },
        ],
      },
      {
        id: 4,
        title: " Data Analytics and AI",
        nestedContent: [
          { id: 1, text: "• 		Data collection, processing and visualization.", dummyText: [] },
          { id: 2, text: "• 		Machine learning for predictive and prescriptive analytics", dummyText: [] },
          { id: 3, text: "• 		Case studies: AI-driven decision-making in manufacturing.", dummyText: [] },
        ],
      },
  ];

  const exteraModuleContent = [
    // Extra module sections
    {
      id: 3,
      title: " Overview of Common Tools",
      nestedContent: [
        { id: 1, text: "• 	Siemens Tecnomatix: Digital manufacturing and process simulation", dummyText: [] },
        { id: 2, text: "• 	Dassault DELMIA: Collaborative manufacturing operations.", dummyText: [] },
        { id: 3, text: "• PTC ThingWorx: IoT platform for smart factories.", dummyText: [] },
        { id: 4, text: "• 	Autodesk Fusion 360: Cloud-based design and manufacturing.", dummyText: [] },
      ],
    },
    {
      id: 4,
      title: " Hands-On Practice",
      nestedContent: [
        { id: 1, text: "• 	Setting up a virtual factory layout.", dummyText: [] },
        { id: 2, text: "• 	Real-time monitoring and control using IoT platforms.", dummyText: [] },
        { id: 3, text: "•	Data visualization and analytics with selected software.", dummyText: [] },
      ],
    },
  ];

  const additionalModuleContent = [
    // Additional module sections
    {
      id: 5,
      title: "Smart Manufacturing",
      nestedContent: [
        { id: 1, text: "•	Automated production lines and workflow optimization.", dummyText: [] },
        { id: 2, text: "•	Use of digital twins for real-time monitoring and adjustments.", dummyText: [] },
      ],
    },
    {
      id: 6,
      title: "Supply Chain Integration",
      nestedContent: [
        { id: 1, text: "• 	End-to-end visibility and traceability.", dummyText: [] },
        { id: 2, text: "•	Demand forecasting and inventory management.", dummyText: [] },
      ],
    },
    {
        id: 7,
        title: "Quality Control and Assurance",
        nestedContent: [
          { id: 1, text: "• 		Real-time defect detection using AI and IoT.", dummyText: [] },
          { id: 2, text: "•		Process validation and compliance tracking..", dummyText: [] },
        ],
      },
      {
        id: 8,
        title: "4 Energy Management",
        nestedContent: [
          { id: 1, text: "• 	Monitoring and reducing energy consumption.", dummyText: [] },
          { id: 2, text: "•		Sustainable manufacturing practices.", dummyText: [] },
        ],
      },
  ];

  const isExteraModuleContent = [
    // Additional Module Sections
    {
      id: 7,
      title: "Automation in Digital Factories",
      nestedContent: [
        { id: 1, text: "• Integration of automated processes.", dummyText: [] },
        { id: 2, text: "• Efficiency improvements and error reduction.", dummyText: [] },
      ],
    },
    {
      id: 8,
      title: "Data-Driven Manufacturing Insights",
      nestedContent: [
        { id: 1, text: "• Big data usage in manufacturing.", dummyText: [] },
        { id: 2, text: "• Real-time analytics for decision-making.", dummyText: [] },
      ],
    },
  ];

  const extraAdvancedModuleContent = [
    // Extra Advanced Module Sections
    {
      id: 9,
      title: "Planning and Strategy",
      nestedContent: [
        { id: 1, text: "•	Identifying needs and defining objectives.", dummyText: [] },
        { id: 2, text: "•	Selecting the right technologies and tools.", dummyText: [] },
      ],
    },
    {
      id: 10,
      title: " Integration with Existing Systems",
      nestedContent: [
        { id: 1, text: "• 	Challenges in legacy system integration..", dummyText: [] },
        { id: 2, text: "• 	Ensuring interoperability and scalability.", dummyText: [] },
      ],
    },
    {
      id: 11,
      title: "  Change Management",
      nestedContent: [
        { id: 1, text: "• 	Training employees for digital transformation..", dummyText: [] },
        { id: 2, text: "• 	Overcoming resistance to change and ensuring adoption.", dummyText: [] },
      ],
    },
  ];
  const andLocalModuleContent = [
    // Extra Advanced Module Sections
    {
      id: 9,
      title: "Simulation Exercises",
      nestedContent: [
        { id: 1, text: "•		Design a virtual factory layout", dummyText: [] },
        { id: 2, text: "•		Simulate production workflows and identify bottlenecks.", dummyText: [] },
      ],
    },
    {
      id: 10,
      title: " Case Studies",
      nestedContent: [
        { id: 1, text: "• 		Analysis of successful digital factory implementations.", dummyText: [] },
        { id: 2, text: "• 	Lessons learned and best practices", dummyText: [] },
      ],
    },
    {
      id: 11,
      title: "  Group Discussions and Q&A",
      nestedContent: [
        { id: 1, text: "• 		Identifying opportunities for digital transformation in participants’ industries.", dummyText: [] },
        { id: 2, text: "• 		Addressing challenges and exploring innovative solutions.", dummyText: [] },
      ],
    },
    {
      id: 11,
      title: " Capstone Project",
      nestedContent: [
        { id: 1, text: "• 			Design a digital twin for a manufacturing process.", dummyText: [] },
        { id: 2, text: "• 			Integrate IoT devices for real-time data collection and monitoring.", dummyText: [] },
        { id: 3, text: "• 			Optimize production workflows using analytics tools.", dummyText: [] },
        { id: 4, text: "• 				Implement a predictive maintenance model using AI.", dummyText: [] },
        { id: 5, text: "• 				Generate detailed reports on process efficiency and ROI.", dummyText: [] },
        { id: 6, text: "• 					Present the project findings and recommendations", dummyText: [] },
      ],
    },	
  ];

  const toggleSection = (id, module = "existing") => {
    if (module === "existing") {
      setSectionToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "new") {
      setNewModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extera") {
      setExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "isextera") {
      setIsExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "additional") {
      setAdditionalModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extraAdvanced") {
      setExtraAdvancedModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    }else if (module === "andLocal"){
      setAndLocalModuleToggle((prev) => ({...prev,[id]: !prev[id]}));
    }
  };

  return (
    <div className="container my-5">
      <h2 className="fw-bold mb-4" style={{ color: "#000", fontFamily: "Roboto", textAlign: "center" }}>
        PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
      </h2>
      <br />
      
      {/* Existing Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAllSections(!toggleAllSections)}
      >
        Introduction to Digital Factory Solutions
      </div>

      {toggleAllSections && (
        <div className="list-tab mt-3">
          {listContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id)}
              >
                {title}
                <span>{sectionToggle[id] ? "-" : "+"}</span>
              </div>
              {sectionToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* New Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleNewModule(!toggleNewModule)}
      >
       Components of a Digital Factory
      </div>

      {toggleNewModule && (
        <div className="list-tab mt-3">
          {newModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "new")}
              >
                {title}
                <span>{newModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {newModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Extera Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExteraModule(!toggleExteraModule)}
      >
        Software Tools for Digital Factories
      </div>

      {toggleExteraModule && (
        <div className="list-tab mt-3">
          {exteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extera")}
              >
                {title}
                <span>{exteraModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {exteraModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Additional Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAdditionalModule(!toggleAdditionalModule)}
      >
       Applications of Digital Factory Solutions
      </div>

      {toggleAdditionalModule && (
        <div className="list-tab mt-3">
          {additionalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "additional")}
              >
                {title}
                <span>{additionalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {additionalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

{/* <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAdditionalModule(!toggleIsExteraModule)}
      >
       Applications of Digital Factory Solutions
      </div> */}

      {toggleIsExteraModule && (
        <div className="list-tab mt-3">
          {isExteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "additional")}
              >
                {title}
                <span>{isExteraModuleContent[id] ? "-" : "+"}</span>
              </div>
              {isExteraModuleContent[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {/* Extra Advanced Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExtraAdvancedModule(!toggleExtraAdvancedModule)}
      >
        Implementation of Digital Factory Solutions
      </div>

      {toggleExtraAdvancedModule && (
        <div className="list-tab mt-3">
          {extraAdvancedModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extraAdvanced")}
              >
                {title}
                <span>{extraAdvancedModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {extraAdvancedModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
       <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAndLocalModule(!toggleAndLocalModule)}
      >
        Practical Exercises and Case Studies 
      </div>

      {toggleAndLocalModule && (
        <div className="list-tab mt-3">
          {andLocalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "andLocal")}
              >
                {title}
                <span>{andLocalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {andLocalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LearningPathTFD;




