import React, { useState } from "react";
import robotics from "../img/robotics hands working.mp4"; // Add your video path here

const KeyFuturesSection = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiBody = {
            firstName: formData.name,
            emailId: formData.email,
            contactNumber: formData.phone,
        };

        try {
            const response = await fetch(
                "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(apiBody),
                }
            );

            if (response.ok) {
                alert("Your details have been submitted successfully!");
            } else {
                alert("There was an issue submitting your details.");
            }
        } catch (error) {
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="container mt-5">
            <div className="row d-flex align-items-start">
                {/* Course Details Section */}
                <div className="col-md-7">
                    <div className="p-4 bg-white shadow rounded mb-4">
                        <h2 style={{ fontWeight: "bold" }}>
                            Tool And Fixture Design{" "}
                            <span style={{ color: "#ffa500", fontWeight: "800" }}></span>
                        </h2>
                        <p style={{ paddingBottom: '20px', textAlign: 'justify' }}>
                            This course is designed for aspiring mechanical engineers. It covers Fixture Design, 3D Modeling, and 2D Detailing with live industry projects for hands-on experience. You’ll learn key design skills used in the manufacturing sector, from creating models to detailing technical drawings. Perfect for professionals looking to advance in manufacturing engineering.
                        </p>
                    </div>


                </div>

                {/* Video Section */}
                <div className="col-md-5">
                    <div style={{ marginBottom: "20px" }}>
                        <video
                            src={robotics}
                            controls
                            style={{
                                width: "97%",
                                borderRadius: "10px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                            }}
                        />
                    </div>
                </div>
                <div className="container-fluid px-0">
                    <h3 style={{ fontWeight: "bold", color: "#000", textAlign: "center", marginTop: '67px' }}>
                        Key <span style={{ color: 'orange' }}>Features</span>
                    </h3>
                    <div className="col-12 mx-0" style={{ textAlign: "justify" }}>
                        {[
                            "Receive a prestigious certificate endorsed by IIA and I-Robotics, validating your expertise in tools and fixture design.",
                            "Hands-On Learning with Capstone Projects.",
                            "Get hands-on experience with state-of-the-art technologies like CAD (SolidWorks, AutoCAD), CNC programming, and FEA software.",
                            "Build your resume and enhance your visibility to top recruiters with personalized career guidance and job placement support from IIA.",
                            "Attend interactive sessions conducted by leading experts in the field, including faculty from renowned institutions and top engineers from I-Robotics.",
                            "Live Q&A and Mentorship.",
                        ].map((feature, index) => (
                            <div className="col-12 mb-3" key={index}>
                                <div
                                    className="key-feature-box p-3 bg-white shadow rounded"
                                    style={{ border: "1px solid grey", width: "100%" }}
                                >
                                    <li style={{ listStyleType: "none", borderRadius: "50px" }}>
                                        {feature}
                                    </li>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Full Page Section Below Key Features */}


            {/* Additional styling */}
            <style>
                {`
                    .key-feature-box {
                        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
                        color: black;
                        border-radius: 25px;
                    }
                    .key-feature-box:hover {
                        transform: scale(1.02);
                        color: white;
                    }

                    .key-feature-box::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.9);
                        opacity: 0;
                        transition: opacity 0.3s ease-in-out;
                        pointer-events: none;
                        border-radius: 25px;
                    }

                    .key-feature-box:hover::before {
                        opacity: 2;
                    }

                    .key-feature-box li {
                        z-index: 1;
                        position: relative;
                    }
                `}
            </style>
        </div>
    );
};

export default KeyFuturesSection;
