// import React, { useState } from "react";

// const SkilledUniversities = () => {
//     const [showMore, setShowMore] = useState(false);

//     // Toggle visibility of hidden features
//     const toggleShowMore = () => {
//         setShowMore(!showMore);
//     };


// return (

//     <div className="container "> {/* Adjusted column width to 11 */}

//         <div className="row  d-flex justify-content-center" style={{marginTop:'150px'}}>
//             <div className="col-md-12">
//                 <h4 style={{ fontWeight: '900',paddingBottom:'50px',textAlign:'center', fontSize:'35px',textTransform:'initial', width:'90%' }}>Top <span style={{color:'orange', fontSize:'35px'}}>Skills Covered</span></h4>


// <ul className="col-md-12 d-flex flex-wrap justify-content-left">
//     <li className="col-md-4">
//         <p className="tick-mark">BIW Fixture Design Principles</p>
//     </li>
//     <li className="col-md-4">
//         <p className="tick-mark">Conceptual Design Techniques</p>
//     </li>
//     <li className="col-md-4">
//         <p className="tick-mark">3D Finish Requirements</p>
//     </li>
//     <li className="col-md-4">
//         <p className="tick-mark">2D Detailing Essentials</p>
//     </li>
//     <li className="col-md-4">
//         <p className="tick-mark">Clamping and Positioning Mechanics</p>
//     </li>
//     <li className="col-md-4">
//         <p className="tick-mark">Cylinder Calculation and Gripper Design</p>
//     </li>
//     <li className="col-md-4">
//         <p className="tick-mark">Tool Testing and Validation Procedures</p>
//     </li>
// </ul>
//             </div>
//         </div>
//         <style>
//             {`
//                 /* Common styles for tick-mark items */
//                 .tick-mark {
//                     font-size: 16px;
//                     text-align: justify;
//                     text-indent: -28px;
//                     margin-bottom: 20px;
//                     padding-left: 40px;
//                     position: relative;
//                     font-family: 'Roboto';
//                 }

//                 /* Tick mark styling */
//                 .tick-mark::before {
//                     content: "✓";
//                     color: #164485;
//                     font-weight: bold;
//                     margin-right: 10px;
//                     padding: 5px;
//                     border-radius: 50%;
//                     display: inline-block;
//                     position: absolute;
//                     left: 0;
//                     top: 0;
//                     transform: translateY(-5%);
//                     line-height: 1.2;
//                     font-size: 20px;
//                 }

//                 /* Flexbox layout tweaks */
//                 ul {
//                     list-style: none;
//                     padding: 0;
//                     margin: 0;
//                 }

//                 li {
//                     margin-bottom: 10px;
//                 }

//                 @media (max-width: 768px) {
//                     .tick-mark {
//                         font-size: 14px;
//                     }
//                 }
//             `}
//         </style>
//     </div>



// );
// };

// export default SkilledUniversities;




// import React, { useState } from "react";

// const SkilledUniversities = () => {
//     const [showMore, setShowMore] = useState(false);

//     // Toggle visibility of hidden features
//     const toggleShowMore = () => {
//         setShowMore(!showMore);
//     };

//     const skills = [
//         "BIW Fixture Design Principles",
//         "Conceptual Design Techniques",
//         "3D Finish Requirements",
//         "2D Detailing Essentials",
//         "Clamping and Positioning Mechanics",
//         "Cylinder Calculation and Gripper Design",
//         "Tool Testing and Validation Procedures",
//     ];

//     return (
//         <div className="container">
//             <div className="row d-flex justify-content-center" style={{ marginTop: "150px" }}>
//                 <div className="col-md-12">
//                     <h4 style={{ fontWeight: "900", paddingBottom: "50px", textAlign: "center", fontSize: "35px", textTransform: "initial", width: "90%" }}>
//                         Top <span style={{ color: "orange", fontSize: "35px" }}>Skills Covered</span>
//                     </h4>
//                     <div className="row">
//                         {skills.map((skill, index) => (
//                             <div key={index} className="col-md-4 mb-4">
//                                 <div className="skill-container">
//                                     <p className="skill-text">
//                                         <span className="tick-mark"></span> {skill}
//                                     </p>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//             <style>
//                 {`
//                     /* Styling for the skill container */
//                     .skill-container {
//                         padding: 20px;
//                         background-color: #f9f9f9;
//                         border: 1px solid orange;
//                         border-radius: 8px;
//                         transition: all 0.3s ease;
//                         display: flex;
//                         align-items: center;
//                         justify-content: flex-start;
//                     }

//                     /* Hover effect on skill container */
//                     .skill-container:hover {
//                         background-color: #f1f1f1;
//                         box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
//                         transform: translateY(-3px);
//                     }

//                     /* Styling for skill text */
//                     .skill-text {
//                         font-size: 18px;
//                         text-align: justify;
//                         text-indent: -28px;
//                         margin-bottom: 20px;
//                         padding-left: 40px;
//                         position: relative;
//                         font-family: 'urbanist';
//                         font-weight: 900;
//                     }

//                     /* Styling for the tick mark */
//                     .tick-mark {
//                         color: #164485;
//                         font-weight: bold;
//                         margin-right: -33px;
//                     }

//                     /* Responsive Design */
//                     @media (max-width: 768px) {
//                         .skill-text {
//                             font-size: 14px;
//                         }

//                         .col-md-4 {
//                             flex: 0 0 100%;
//                             max-width: 100%;
//                         }
//                     }
//                 `}
//             </style>
//         </div>
//     );
// };

// export default SkilledUniversities;























// import React from "react";

// import {

//   FaDraftingCompass,
//   FaCube,
//   FaRulerCombined,
//   FaGripLines,
//   FaCalculator,
//   FaCheckCircle,
//   FaLightbulb,
// } from "react-icons/fa"; // Importing icons from react-icons

// const SkilledUniversitiesVR = () => {
//   const skills = [
//     { icon: <FaDraftingCompass />, text: "BIW Fixture Design Principles" }, // Represents precision and design
//     { icon: <FaLightbulb />, text: "Conceptual Design Techniques" }, // Highlights creativity and concepts
//     { icon: <FaCube />, text: "3D Finish Requirements" }, // Represents 3D modeling or components
//     { icon: <FaRulerCombined />, text: "2D Detailing Essentials" }, // Related to measurements and detailing
//     { icon: <FaGripLines />, text: "Clamping and Positioning Mechanics" }, // Suggests alignment and mechanics
//     { icon: <FaCalculator />, text: "Cylinder Calculation and Gripper Design" }, // Emphasizes calculations and tools
//     { icon: <FaCheckCircle />, text: "Tool Testing and Validation Procedures" }, // Indicates testing and validation
//   ];


//   return (
//     <div className="container">
//       <div className="row " style={{ marginTop: "77px" }}>
//         <div className="col-md-12">
//           <h4 style={{ fontWeight: "900", paddingBottom: "50px", textAlign: "center", fontSize: "35px", textTransform: "initial", width: "90%" }}>
//             Top <span style={{ color: "orange", fontSize: "35px" }}>Skills Covered</span>
//           </h4>
//           <ul className="skills-grid">
//             {skills.map((skill, index) => (
//               <li key={index} className="skill-item">
//                 <div className="skill-icon">{skill.icon}</div>
//                 <p className="skill-text">{skill.text}</p>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//       <style>
//         {`
//           .skills-grid {
//             display: grid;
//             grid-template-columns: repeat(3, 1fr);
//             gap: 20px;
//             list-style: none;
//             padding: 0;
//             margin: 0;
//             width:395;
//           }

//           .skill-item {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             flex-direction: column;
//             padding: 20px;
//             background-color: #f5f5f5;
//             border: 2px solid #ffc107;
//             border-radius: 15px;
//             text-align: center;
//             transition: transform 0.3s ease, box-shadow 0.3s ease;
//             box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//           }

//           .skill-item:hover {
//             transform: scale(1.05);
//             box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
//             border-top: 7px solid orange;
//             border-right: 4px solid orange;
//           }

//           .skill-icon {
//             font-size: 30px;
//             color: #164485;
//             margin-bottom: 10px;
//           }

//           .skill-text {
//             font-size: 16px;
//             font-family: 'Roboto', sans-serif;
//             color: #333;
//           }

//           /* Responsive for mobile view */
//           @media (max-width: 768px) {
//             .skills-grid {
//               grid-template-columns: repeat(2, 1fr);
//               gap: 15px;
//             }
//             .skill-text {
//               font-size: 14px;
//             }
//           }

//           @media (max-width: 576px) {
//             .skills-grid {
//               grid-template-columns: 1fr;
//             }
//             .skill-item {
//               padding: 15px;
//             }
//             .skill-icon {
//               font-size: 24px;
//             }
//             .skill-text {
//               font-size: 13px;
//             }
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default SkilledUniversitiesVR;
























import React from "react";
import {
  FaDraftingCompass,
  FaCube,
  FaRulerCombined,
  FaGripLines,
  FaCalculator,
  FaCheckCircle,
  FaLightbulb,
} from "react-icons/fa";

const SkilledUniversities = () => {
  const skills = [
    { icon: <FaDraftingCompass />, text: "BIW Fixture Design Principles" },
    { icon: <FaLightbulb />, text: "Conceptual Design Techniques" },
    { icon: <FaCube />, text: "3D Finish Requirements" },
    { icon: <FaRulerCombined />, text: "2D Detailing Essentials" },
    { icon: <FaGripLines />, text: "Clamping and Positioning Mechanics" },
    { icon: <FaCalculator />, text: "Cylinder Calculation and Gripper Design" },
    { icon: <FaCheckCircle />, text: "Tool Testing and Validation Procedures" },
  ];

  return (
    <div className="container">
      <div className="row" style={{ marginTop: "77px" }}>
        <div className="col-md-12">
          <h4
            style={{
              fontWeight: "900",
              paddingBottom: "50px",
              textAlign: "center",
              fontSize: "35px",
              textTransform: "initial",
              width: "100%",
            }}
          >
            Top <span style={{ color: "orange", fontSize: "35px" }}>Skills Covered</span>
          </h4>
          <ul className="skills-grid">
            {skills.map((skill, index) => (
              <li
                key={index}
                className={`skill-item ${index === 6 ? "centered" : ""}`}
              >
                <div className="skill-icon">{skill.icon}</div>
                <p className="skill-text">{skill.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <style>
        {`
    .skills-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Default: three columns */
      gap: 20px;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .skill-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      background-color: #f5f5f5;
      border: 2px solid #ffc107;
      border-radius: 15px;
      text-align: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .skill-item:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
      border-top: 7px solid orange;
      border-right: 4px solid orange;
    }

    .skill-icon {
      font-size: 30px;
      color: #164485;
      margin-bottom: 10px;
    }

    .skill-text {
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      color: #333;
    }

    /* Center the last item in the last row */
    .centered {
      grid-column: 2 / 3; /* Place the last container in the middle column */
    }

    /* Responsive for tablet view */
    @media (max-width: 768px) {
      .skills-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }
      .centered {
        grid-column: 1 / 3; /* Center the container across two columns */
      }
      .skill-text {
        font-size: 14px;
      }
    }

    /* Responsive for mobile view */
    @media (max-width: 576px) {
      .skills-grid {
        grid-template-columns: 1fr; /* Single column for mobile view */
      }
      .centered {
        grid-column: auto; /* Reset column positioning */
      }
      .skill-item {
        padding: 15px;
      }
      .skill-icon {
        font-size: 24px;
      }
      .skill-text {
        font-size: 13px;
      }
    }
  `}
      </style>
    </div>
  );
};

export default SkilledUniversities;
