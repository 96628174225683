


import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation

import 'owl.carousel/dist/assets/owl.carousel.css'; // Import Owl Carousel styles
import 'owl.carousel'; // Import Owl Carousel functionality
import emailjs from 'emailjs-com'; // Import EmailJS
// import logo from '../img/logo/IIA_LOGO_White_v3.png';
// import logo1 from '../img/logo/IIA_LOGO_White_v3.png';
import { useNavigate } from "react-router-dom";
import logo from '../img/logo/HeadAllLogoIIALogo.png';
import { FaShoppingCart } from 'react-icons/fa';
const Nav = () => {
  const [navbarBackground, setNavbarBackground] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // For toggle
  const location = useLocation(); // Get current location
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // const handleLogin = () => {
  //   // After logging in, set isLoggedIn to true (you can replace this with actual login logic)
  //   setIsLoggedIn(true);
  // };


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown visibility
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    navigate("/logindashboard");
    // Log out logic and set isLoggedIn to false
    setIsLoggedIn(false);
  };


  useEffect(() => {
    const interval = setInterval(() => {
      const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
      if (userId) {
        setIsLoggedIn(true); // If userId is found, mark as logged in
      } else {
        setIsLoggedIn(false); // If no userId, mark as logged out
      }
    }, 100); // Call every 1 second

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setNavbarBackground(true);
      } else {
        setNavbarBackground(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // Update search query and filter suggestions
  // Update search query and filter suggestions

  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // Filtered suggestions

  const navigate = useNavigate(); // Navigation hook

  const pages = [
    { label: "Tool and Fixture Design", path: "/it-services" },
    { label: "Virtual Robotics", path: "/web-mobile-applications" },
    { label: "Digital Facility Solutions", path: "/gaming" },

  ];

  // Handle input change and filter suggestions 
  const handleInputChange = (e) => {
    // const query = e.target.value;
    // setSearchQuery(query);

    // if (query.trim() === "") {
    //   setFilteredSuggestions([]);
    // } else {
    //   const lowerQuery = query.toLowerCase();
    //   setFilteredSuggestions(
    //     pages.filter((page) => page.label.toLowerCase().includes(lowerQuery))
    //   );
    // }
  };

  // Handle search button click
  const handleSearch = () => {
    if (searchQuery.trim() === "") return;

    const lowerQuery = searchQuery.toLowerCase();
    const matchedPage = pages.find(
      (page) => page.label.toLowerCase() === lowerQuery
    );

    if (matchedPage) {
      navigate(matchedPage.path); // Navigate to matched page
    } else {
      navigate("/not-found"); // Navigate to "Not Found" page
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.label); // Update input value
    navigate(suggestion.path); // Navigate to selected page
    setFilteredSuggestions([]); // Clear suggestions
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar state
  };

  return (
    <>
      {/* Navbar Header */}
      <header className="init">
        <div className={`navbar-default-white navbar-fixed-top ${navbarBackground ? 'navbar-scrolled' : ''}`}>
          <div className="container-fluid">
            <div className="row p-3-vh align-items-center">
              {/* Logo and Text */}
              <Link className="logo centered d-flex align-items-center" to="/">
                <div className="logo d-flex align-items-center">
                  <img alt="logo" src={logo} style={{ maxHeight: '103px', paddingBottom: '1px' }} className='logo-change' />
                  <span style={{ padding: '-5px' }}>
                    <p style={{ fontSize: '20px', minWidth: '300px', color: '#fff', fontWeight: '800', }} className='titlenamel'>  INTERNATIONAL INSTITUTE<br></br> OF AUTOMATE</p>
                    {/* <p style={{ fontSize: '13px' }}>(Powered by Envision Intigrated Services)</p> */}
                  </span>

                </div>
                {/* <img className="h-150 show" alt="logo" src={logo1} /> */}
                {/* <div className="ml-3 text-white">
                  <Link to="/" className="text-white text-decoration-none">
                    <h1 className="m-0 fontsmall" style={{ fontFamily: 'Raqupine Regular' }}>MODULEX</h1>
                    <p className="m-0 fontmini" >INTERIORS</p>
                  </Link>
                </div> */}
              </Link>
              {/* Main Menu */}
              <div
                className="menu-container mdflbg"
                style={{
                  display: "flex",
                  justifyContent: "space-between", // Space out sections evenly
                  alignItems: "center", // Vertically align items
                  padding: "24px", // Add padding for spacing
                  flexWrap: "wrap", // Allow items to wrap for smaller screens
                }}
              >
                {/* Search Bar */}
                <div>
                  {/* Search Bar */}
                  <div
                    className="firstsearch"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ddd",
                      borderRadius: "30px",
                      padding: "5px 10px",
                      position: "relative",
                      backgroundColor: "white",
                      maxWidth: "100%",
                      // width: "100%",
                      width: '345px',
                      marginRight: '44px',
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Search Here "
                      style={{
                        border: "none",
                        outline: "none",
                        padding: "5px 10px",
                        width: "80%",
                        marginLeft: '10px',
                      }}
                      value={searchQuery} // Bind input value
                      onChange={handleInputChange} // Update state on input change
                    />
                    <button
                      // onClick={handleSearch} // Call handleSearch on click
                      style={{
                        backgroundColor: "#000",
                        color: "white",
                        border: "none",
                        padding: "8px 9px",
                        borderRadius: "10px",
                        cursor: "pointer",
                        marginLeft: "50px", // Adjust margin coding
                      }}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>

                  {/* Suggestions Dropdown */}
                  {filteredSuggestions.length > 0 && (
                    <ul
                      style={{
                        listStyle: "none",
                        padding: "10px",
                        margin: "5px 0",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        position: "absolute",
                        zIndex: 1000,
                      }}
                    >
                      {filteredSuggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          style={{
                            padding: "5px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSuggestionClick(suggestion)} // Handle suggestion click
                        >
                          {suggestion.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Navigation Links */}
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    margin: "0",
                    padding: "24",
                    flex: "1", // Allow navigation to grow and fill available space
                    justifyContent: "center", // Center navigation links
                  }}
                >
                  {/* <li>
                    <Link
                      to="/"
                      className={`nav-link ${location.pathname === '/' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px", fontWeight: 'bold' }}
                    >
                      HOME
                    </Link>
                  </li> */}
                  <li>
                    {/* <Link
                      to="/who-we-are"
                      className={`nav-link ${location.pathname === '/who-we-are' ? 'active-link' : ''}`}
                      style={{ margin: "0 0px", fontWeight: 'bold' }}
                    >
                      ABOUT US
                    </Link> */}
                  </li>
                  {/* <li>
                    <Link
                      to="/what-we-do"
                      className={`nav-link ${location.pathname === '/what-we-do' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px", fontWeight: 'bold' }}
                    >
                      PROGRAMS
                    </Link>
                  </li> */}
                  {/* 777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777 */}





                  <Link
                      to="/contact"
                      className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
                      style={{ margin: "0 0px", fontWeight: 'bold' }}
                    >
                     PROGRAMS
                    </Link>
                  <li>
                    <Link
                      to="/jobportal"
                      className={`nav-link ${location.pathname === '/jobportal' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px", fontWeight: 'bold' }}
                    >
                  JOBS
                    </Link>
                  </li>


                   <li>
                    <Link
                      to="/placed-students"
                      className={`nav-link ${location.pathname === '/placed-students' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px",fontWeight:'bold', }}
                    >
                  TRAINERS
                    </Link>
                  </li> 
                  <li>
                    <Link
                      to="/contact"
                      className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
                      style={{ margin: "0 0px", fontWeight: 'bold' }}
                    >
                      SPEAKERS
                    </Link>
                  </li>
                  <div
                    className="social-icons"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                      // Space between icons
                    }}
                  >
                   
                    {/* <Link
                      to="https://www.facebook.com/profile.php?id=61566000185428&mibextid=ZbWKwL"
                      target="_blank"
                    >
                      <span className="ti-facebook" style={{ fontSize: "20px" }}></span>
                    </Link>

                   
                    <Link to="https://x.com/iiautomate?t=fIrwaO15RN7FiVsLMM8ujw&s=09" target="_blank">
                      <span className="ti-twitter" style={{ fontSize: "20px" }}></span>
                    </Link>

                  
                    <Link
                      to="https://www.linkedin.com/feed/?trk=onboarding-landing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="ti-linkedin" style={{ fontSize: "20px" }}></span>
                    </Link>

                    
                    <Link to="https://www.instagram.com/info.iiautomate/profilecard/?igsh=ZTdocXp5ajNpaW5o" target="_blank">
                      <span className="ti-instagram" style={{ fontSize: "20px" }}></span>
                    </Link>

                   
                    <Link to="https://www.youtube.com/@IIAUTOMATE" target="_blank">
                      <span className="ti-youtube" style={{ fontSize: "20px" }}></span>
                    </Link> */}


                    {isLoggedIn ? (
                      <>
                        <Link to="/shoppingcart" target="_blank">
                          <span className="ti-shopping-cart" style={{ fontSize: "22px" }}></span>
                        </Link>



                        <Link to="https://www.youtube.com/@IIAUTOMATE" target="_blank">
                          <span className="fa fa-heart" style={{ fontSize: "20px" }}></span>
                        </Link>

                        <Link to="https://www.youtube.com/@IIAUTOMATE" target="_blank">
                          <span className="fa fa-bell" style={{ fontSize: "20px" }}></span>
                        </Link>

                        <div style={{ display: "inline-block", position: "relative" }}>
                          <i
                            className="fa fa-user-circle"
                            style={{ fontSize: "22px", cursor: "pointer", color: "#fb9100", marginRight: '28px' }}
                            onClick={toggleDropdown} // Toggle dropdown when clicked
                          ></i>
                          {showDropdown && (
                            <div
                              style={{
                                position: "absolute",
                                top: "30px",
                                right: "0",
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                                borderRadius: "5px",
                                padding: "10px",
                                boxShadow: "0px 4px 8px rgba(255, 255, 255, 0.1)",
                                zIndex: "10",
                              }}
                            >
                              <button
                                onClick={handleLogout}
                                style={{
                                  display: "block",
                                  padding: "5px 10px",
                                  color: "#fb9100",
                                  fontSize: "12px",
                                  border: "none",
                                  background: "none",
                                  textDecoration: "none",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",  // Prevents wrapping to a new line
                                }}
                              >
                                Log Out
                              </button>
                            </div>
                          )}

                        </div>

                      </>
                    ) : (
                      // If not logged in, show the Login button
                      <Link
                        to="/logindashboard"
                        className={`nav-link ${location.pathname === '/logindashboard' ? 'active-link' : ''}`}

                        style={{
                          margin: "0 10px",
                          fontWeight: "bold",
                          padding: "5px 7px",
                          border: "1px solid #fb9100",
                          borderRadius: "5px",
                          color: "#fb9100",
                          textDecoration: "none",
                        }}
                      >
                        Log in
                      </Link>
                    )}




                  </div>
                  <div style={{ backgroundColor: 'black' }} />




                  {/* <Link
  to="/https://dashboard.iiautomate.com"
  onClick={(e) => {
    e.preventDefault(); // Prevent default link behavior
    window.open('https://dashboard.iiautomate.com/', '_blank');
  }}
  className={`nav-link ${location.pathname === '/dashboard.iiautomate.com' ? 'active-link' : ''}`}
  style={{
    margin: "0 10px",
    fontWeight: "bold",
    padding: "5px 7px",
    border: "1px solid #fb9100",
                borderRadius: "5px",
                        color: "#fb9100",
                        textDecoration: "none",
                        }}
                         >
                     Sign up
                     </Link> */}
                </ul>

                {/* Social Media Links */}


              </div>



              {/* Sidebar Toggle Button for Large Screens */}
              <div
                className="menu-right centered d-none d-md-block"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
              
                }}
               >
                {isLoggedIn && (
                  <Link to="/shoppingcart" target="_blank" className="shoppingcartmobileview">
                    <span className="ti-shopping-cart" style={{ fontSize: "22px" }}></span>
                  </Link>
                )}
                <ul
                  className="iconright"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    listStyle: "none", // Remove default list styling
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <li>
                    <div onClick={toggleSidebar} className="toggle-button" style={{ cursor: "pointer" }}>
                      <span className="ti-menu"></span>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </header>

      {/* Sidebar for Large Screens */}
      {/* Sidebar for Large Screens */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div
            className="search-bar"
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: "30px",
              padding: "5px 15px",
              position: "relative",
              backgroundColor: "white",
              // maxWidth: "27%",
              width: "80%",
              marginTop: '20px',
            
            }}
          >
            <input
              type="text"
              placeholder="Search What You Want"
              style={{
                border: "none",
                outline: "none",
                padding: "5px 10px",
                width: "80%",
                marginLeft: '20px'
              }}
              value={searchQuery} // Bind input value
              onChange={handleInputChange} // Update state on input change
            />
            {/* <button
              onClick={handleSearch} 
              style={{
                backgroundColor: "#000",
                color: "white",
                border: "none",
                padding: "8px 15px",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "10px", // Adjust margin
              }}
            >
              <i className="fas fa-search"></i>
            </button> */}
          </div>
          <ul >
            <li style={{marginLeft:'-20px',}}>
              <Link
                to="/"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/' ? 'active-link' : ''}`}
              >
                HOME
              </Link>
            </li>

            {isLoggedIn ? (
              <>
                <div
                  className="social-icons"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "38px", // Space between icons
                    marginLeft: '35px',
                  }}
                >
                  {/* <Link to="/shoppingcart" target="_blank">
                    <span className="ti-shopping-cart" style={{ fontSize: "22px", }}></span>
                  </Link> */}



                  <Link to="https://www.youtube.com/@IIAUTOMATE" target="_blank">
                    <span className="fa fa-heart" style={{ fontSize: "20px" }}></span>
                  </Link>

                  <Link to="https://www.youtube.com/@IIAUTOMATE" target="_blank">
                    <span className="fa fa-bell" style={{ fontSize: "20px", }}></span>
                  </Link>

                  <div style={{ display: "inline-block", position: "relative" }}>
                    <i
                      className="fa fa-user-circle"
                      style={{ fontSize: "22px", cursor: "pointer", color: "#fb9100", }}
                      onClick={toggleDropdown} // Toggle dropdown when clicked
                    ></i>
                    {showDropdown && (
                      <div
                        style={{
                          position: "absolute",
                          top: "30px",
                          right: "0",
                          backgroundColor: "#fff",
                          border: "1px solid #fb9100",
                          borderRadius: "5px",
                          padding: "10px",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          zIndex: "10",
                        }}
                      >
                        <button
                          onClick={handleLogout}
                          style={{
                            display: "block",
                            padding: "5px 10px",
                            color: "#fb9100",
                            fontSize: "12px",
                            border: "none",
                            background: "none",
                            textDecoration: "none",
                            cursor: "pointer",
                            whiteSpace: "nowrap",  // Prevents wrapping to a new line
                          }}
                        >
                          Log Out
                        </button>
                      </div>
                    )}

                  </div>
                </div>
              </>
            ) : (
              // If not logged in, show the Login button
              <Link
              onClick={() => setIsSidebarOpen(false)} 
                to="/logindashboard"
                className={`nav-link ${location.pathname === '/logindashboard' ? 'active-link' : ''}`}

                style={{
                  margin: "0 10px",
                  fontWeight: "bold",
                  padding: "5px 7px",
                  border: "1px solid #fff",
                  borderRadius: "5px",
                  color: "#fff",
                  textDecoration: "none",
                }}
              >
                Log in
              </Link>
            )}



            {/* <li>
              <Link
                to="/registration-form"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click 
                className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
              >
                SIGN UP
              </Link>
            </li> */}
            < br />< br />
          </ul>
          <div
            className="social-icons"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "38px", // Space between icons
              marginLeft: '35px',
            }}
          >
            <Link to="https://x.com/applications_it" target="_blank" style={{ color: '#fff' }}>
              <span className="ti-twitter" style={{ fontSize: "20px" }}></span>
            </Link>
            <Link
              to="https://www.linkedin.com/feed/?trk=onboarding-landing"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#fff' }}
            >
              <span className="ti-linkedin" style={{ fontSize: "20px" }}></span>
            </Link>
            <Link to="https://www.instagram.com/appli_cation123/" target="_blank" style={{ color: '#fff' }}>
              <span className="ti-instagram" style={{ fontSize: "20px" }}></span>
            </Link>
            <Link to="https://www.youtube.com/@IIAUTOMATE" target="_blank" style={{ color: '#fff' }}>
              <span className="ti-youtube" style={{ fontSize: "20px" }}></span>
            </Link>
          </div>
        </div>
      </div>

      {/* Sidebar and Active Link CSS */}
      <style jsx>{`
         .sidebar {
          position: fixed;
          top: 0;
          right: -250px; /* Start off-screen */
          width: 250px;
          height: 100%;
          background-color: #313840;
          transition: right 0.3s ease-in-out; /* Smooth transition */
          z-index: 1000; /* Ensure it stays on top */
        }

        .sidebar.open {
          right: 0; /* Slide in */
          width:100%;
        }

        .sidebar-content ul {
          padding: 0;
          list-style: none;
          margin: 0;
        }

        .sidebar-content ul li {
          padding: 15px 20px;
          border-bottom: 1px solid #444;
        }

        .sidebar-content ul li a {
          color: white;
          text-decoration: none;
        }

        .toggle-button {
          cursor: pointer;
        }

        .dropdown-toggle {
          background: none;
          border: none;
          color: white;
          padding: 10px;
          cursor: pointer;
          width: 100%;
          text-align: left;
        }

        .dropdown-menu {
          list-style: none;
          padding: 0;
          margin: 0;
          background-color: #444;
        }

        // .dropdown-menu li {
        //   padding: 10px 20px;
        // }

        .dropdown-menu li a {
          color: white;
          text-decoration: none;
        }

        .dropdown-menu li a:hover {
          color: #f76046;
        }

        .active-link {
          font-weight: bold;
          // color: #f76046;
        }

        /* Show toggle button and sidebar on larger screens */
        @media (min-width: 1200px) {
          .sidebar {
            display: none; /* Hide sidebar */
          }

          .toggle-button {
            display: block; /* Show toggle button */
          }

          /* Dropdown for mobile */
          .dropdown-toggle {
            display: none; /* Hide dropdown button on larger screens */
          }
        }

        /* For smaller screens (mobile view) */
        @media (max-width: 767px) {
          .sidebar-content ul li {
            padding: 10px 20px;
          }

          .dropdown-menu {
            display: block;
            background-color: #313840;
            border-top: 1px solid #313840;
          }

          .dropdown-menu li a {
            padding-left: 40px; /* Indent dropdown items */
          }

          .sidebar-content ul li .dropdown-menu li {
            padding: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default Nav;
