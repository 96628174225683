import React, { useEffect } from 'react';

import Aboutus from './Aboutus'
import MainBanner from './MainBanner'
import Stack from './Stack'
import Whychoose from './Whychoose'
//  import Gallery from './Gallery'
import ContactForm from './ContactForm';

import Newaboutcompony from'./Newaboutcompony';
import NewwhychosesUS from'./NewwhychosesUS';
import Testmonial from './Testmonial';
import OurLeadership from './OurLeadership';
// import Ourclints from './Ourclints';
import OurCoPartneres from './OurCoPartneres';

import FastrackYourCarrier from './FastrackYourCarrier';

import Secondtesitimonial from './Secondtesitimonial'
import PCMobileApplications from './PCMobileApplications';
import ClearPlanB from './ClearPlanB';
import Newtestmonial from'./Newtestmonial';
import Circle from'./Circle';
import Cardudmey from'./Cardudmey';

// import EnrollPage from './EnrollPage'



const Home = () => {
     // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs once when the component mounts

  return (
    <div style={{backgroundColor:'white'}}>
        <MainBanner/>  
         <Stack/>
        <Cardudmey/>
      
        <PCMobileApplications />
        {/* <ClearPlanB/> */}
       
        {/* <Aboutus/> */}
        {/* <Newaboutcompony/> */}
        {/* <Whychoose/> */}
       
        <NewwhychosesUS/>
        <FastrackYourCarrier/>
       
        {/* <OurCoPartneres/> */}
        {/* <Ourclints/> */}
        <Circle/>
        {/* <PlacementCards/> */}+
        {/* <Testmonial/> */}
        <Newtestmonial/>
    
        {/* <Secondtesitimonial /> */}
       
        <br></br>
        {/* <OurLeadership/> */}

        {/* <ContactForm/> */}
        {/* <Gallery/> */}
        {/* <EnrollPage /> */}

        <style>
          {`
          h1,h2,h3,h4,h5,h6,p{
          font-family: urbanist , sarif;
          }
          `}
        </style>
    </div>
  )
}

export default Home