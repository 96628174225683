




import React, { useState } from "react";
import Slider from 'react-slick'; // Importing Slick Slider component
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Arrow icons for custom navigation

// Import images
import navyaImage from '../img/aaaservices/WhatsApp Image 2024-12-27 at 4.19.57 PM.jpeg'; // Replace with actual path
import arjunImage from '../img/aaaservices/Akhil.jfif'; // Replace with actual path
import meeraImage from '../img/banner/01_NARESH_FRONTEND DEVELOPER.jpeg'; 
import rahulImage from '../img/aaaservices/03_RAMACHANDRA_BACKEND DEVELOPER.jpeg';
import rahulImage1 from '../img/aaaservices/04_VIKASH_WEB DEVELOPER.jpeg'; // Replace with actual path
import rahulImage2 from '../img/aaaservices/05_VIKASH SINGH_WEB DEVELOPER.jpeg'; // Replace with actual path
import rahulImage3 from '../img/aaaservices/01_SHIVA.jpeg'; 
import rahulImage4 from '../img/aaaservices/02_ANVESH.jpeg'; // Replace with actual path
import rahulImage5 from '../img/aaaservices/03_PRASANNA.jpeg'; // Replace with actual path
import rahulImage6 from '../img/aaaservices/04_WASIQ.jpeg'; // Replace with actual path
import rahulImage7 from '../img/aaaservices/10_ LINGESWAR RAO.jpg'; // Replace with actual path
import rahulImage8 from '../img/aaaservices/09_ (2).jpg'; // Replace with actual path
import rahulImage9 from '../img/aaaservices/12_SARANYA.jpg'; // Replace with actual path
import rahulImage10 from '../img/aaaservices/09_ SRIVASTHAV.jpg'; // Replace with actual path

import logoImage from '../img/banner/Picture2.png';
const CardComponent = () => {
  const cards = [
    {
      id: 1,
      name: 'Virtual Robotic Simulation',
      rating: '★★★★★',
      description: 'The training gave me hands-on experience in robotics, enhancing my skills.',
      image: navyaImage,
      buttonText: ' R Vishnu Vardhan',
      extraText: '(SIMULATION ENGINEER)',
      extraTexts: 'Got Placed At',
    },
    {
      id: 2,
      name: 'Flutter Developer',
      rating: '★★★★★',
      description: 'This course gave me the skills to develop apps for both Android and iOS seamlessly.',
      image: arjunImage,
      buttonText: 'Akhil Teli',
      extraText: '(REACT.JS)',
      extraTexts: 'Got Placed At',
    },
    {
      id: 3,
      name: 'Frontend Devloper',
      rating: '★★★★★',
      description: 'Great hands-on experience that sharpened my skills in HTML, CSS, and JavaScript.',
      image: meeraImage,
      buttonText: 'Naresh',
      extraText: '(REACT.JS)',
      extraTexts: 'Got Placed At',

    },
    {
      id: 4,
      name: 'Web Developer',
      rating: '★★★★★',
      description: 'A fantastic program that helped me build real-world front-end development skills.',
      image: rahulImage2,
      buttonText: 'Vikash Kumar Singh',
      extraText: '(REACT.JS)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 5,
      name: 'Virtual Robotics Simulation',
      rating: '★★★★★',
      description: 'I gained practical knowledge in robotics, making complex concepts easier to understand.',
      image: rahulImage10,
      buttonText: 'Shastrula Sreevasthsava',
      extraText: '(ASSOCIATE ENGINEER)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 6,
      name: 'Digital Factory',
      rating: '★★★★★',
      description: 'A great experience that enhanced my skills in designing efficient systems.',
      image: rahulImage8,
      buttonText: 'Chidura Samyuktha',
      extraText: '(ASSOCIATE ENGINEER)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 7,
      name: 'Virtual Robotics Simulation',
      rating: '★★★★★',
      description: 'The robotics projects were engaging and helped me visualize real-world systems.',
      image: rahulImage7,
      buttonText: 'K Lingeswarara Rao',
      extraText: '(ASSOCIATE ENGINEER)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 8,
      name: 'Banckend Developer',
      rating: '★★★★★',
      description: 'Hands-on experience with back-end technologies prepared me for real-world projects.',
      image: rahulImage,
      buttonText: 'D Ramachandra Reddy',
      extraText: '(JAVA)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 9,
      name: 'Frontend Developer',
      rating: '★★★★★',
      description: 'The training provided practical knowledge that’s directly applicable to web development projects.',
      image: rahulImage1,
      buttonText: 'Vikash Kumar ',
      extraText: '(REACT.JS)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 10,
      name: 'Tool& Fixture Design',
      rating: '★★★★★',
      description: 'Hands-on experience in tool design made all the difference – truly valuable!',
      image: rahulImage3,
      buttonText: 'P. Siva Nagaraju',
      extraText: '(DESIGN ENGINEER)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 11,
      name: 'Tool& Fixture Design',
      rating: '★★★★★',
      description: 'Excellent training that enhanced my understanding of the design process.',
      image: rahulImage4,
      buttonText: 'Eera Anvesh',
      extraText: '(DESIGN ENGINEER)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 12,
      name: 'Virtual Robotic Simulation',
      rating: '★★★★★',
      description: 'The program was an eye-opener, providing deep insights into robotics technology.',
      image: rahulImage5,
      buttonText: 'Prasanna Kumar reddy',
      extraText: '(SIMULATION ENGINEER)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 13,
      name: 'Virtual Robotic Simulation',
      rating: '★★★★★',
      description: 'The robotics projects were engaging and helped me visualize real-world systems.',
      image: rahulImage6,
      buttonText: 'Wasiq Uddin',
      extraText: '(SIMULATION ENGINEER)',
      extraTexts: 'Got Placed At',
     

    },
    {
      id: 14,
      name: 'Digital Factory',
      rating: '★★★★★',
      description: 'Excellent hands-on training that helped me understand cutting-edge solutions.',
      image: rahulImage9,
      buttonText: 'Saranya Balaka',
      extraText: '(ASSOCIATE ENGINEER)',
      extraTexts: 'Got Placed At',
     
    },

  ];

  const styles = {
    title: {
      textAlign: 'center',
      fontFamily: 'auto,',
      fontWeight: 700,
      fontSize: '48px',
      marginBottom: '31px',
    },
    titleBlack: {
      color: 'black',
    },
    titleOrange: {
      color: 'orange',
    },
    cardContainer: {
      width:'133%',
      display: 'flex',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      position: 'relative',
      marginRight: '20px',
      
    },
    cardWrapper: {
      display: 'flex',
      transition: 'transform 0.5s ease-in-out',
    },
   
      card: {
        position: 'relative',
        width: '133% !important', // Reduced the width to reduce space between cards
        height: '10px',
        borderRadius: '100px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        transition: 'transform 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
       
      }, // Adjust the width as needed
     
    image: {
      position: 'absolute',
      left: '0px',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'all 0.3s ease',
      top: '-45px',
    },
    imageCircle: {
      top: '20px',
      left: '20px',
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      borderRadius: '50%',
      transition: 'all 0.3s ease',
      border: '8px solid #215a9d',
    },
    button: {
      position: 'absolute',
      bottom: '-1px',
      left: '50%',
      width: '100%',
      height: '80px',
      borderRadius: '40px',
      transform: 'translateX(-50%)',
      padding: '10px 20px',
      backgroundColor: '#215a9d',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
    buttonHover: {
      // backgroundColor: 'orange',
      // height: '250px',
      // borderRadius: '50px',
    },
    cardContent: {
      position: 'absolute',
      top: '50%',
      // marginRight: '20px',
      width: '100%',
      height: '600px',
      backgroundColor: '#215a9d',
      color: 'white',
      padding: '10px',
      borderRadius: '50px',
      opacity: '0',
      transform: 'translateY(20px)',
      transition: 'opacity 0.3s ease, transform 0.3s ease, height 0.3s ease',
      textAlign: 'center',
    },
    cardContentVisible: {
      opacity: '1',
      transform: 'translateY(0)',
    },
    name: {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    rating: {
      color: 'gold',
      margin: '5px 0',
    },
    description: {
      fontSize: '17px',
      color: 'orange',
    },
    textWithLogo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px',
    },
    smallText: {
      fontSize: '20px',
      fontWeight: '700',
      color: 'black',
      fontFamily: 'roboto',
    },
    smallLogo: {
      width: '150px',
      height: '30px',
      marginRight: '-15px',
    },
    navButton: {
      position: 'absolute',
      top: '50%',
      zIndex: '10',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      color: '#215a9d',
      fontSize: '30px',
    },
    prevButton: {
      left: '10px',
      transform: 'translateY(-50%)',
    },
    nextButton: {
      right: '10px',
      transform: 'translateY(-50%)',
    },
  }
  const [hoveredCard, setHoveredCard] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false, // Enable dots for pagination
    infinite: true, // Infinite loop
    speed: 500, // Transition speed
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    nextArrow: (
      <div style={{
        position: 'absolute',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        backgroundColor: '#215a9d',
        border: 'none',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
      }}>
        <FaArrowRight size={24} />
      </div>
    ),
    prevArrow: (
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '10px',
        transform: 'translateY(-50%)',
        backgroundColor: '#215a9d',
        border: 'none',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        cursor: 'pointer',
      }}>
        <FaArrowLeft size={24} />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024, // Adjust settings for screens smaller than 1024px
        settings: {
          slidesToShow: 2, // Show 2 slides for medium screens
        },
      },
      {
        breakpoint: 600, // Adjust settings for screens smaller than 600px
        settings: {
          slidesToShow: 1, // Show 1 slide for smaller screens
        },
      },
    ],
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', fontFamily: 'auto', fontWeight: 700, fontSize: '48px', marginBottom: '31px' }}>
        <span style={{ color: 'black' }}>What Our</span>{' '}
        <span style={{ color: 'orange' }}>Learner Say</span>
      </h1>

      <Slider {...settings}>
        {cards.map((card) => (
          <div key={card.id} style={{ width: '533% !important', }} onMouseEnter={() => setHoveredCard(card.id)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <div
              style={{
                position: 'relative',
                width: '350px',
                height: '450px',
                borderRadius: '40px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                margin: '0 auto',
              }}
            >
              {/* Image */}
              <img
                src={card.image}
                alt={`card-${card.id}`}
                style={hoveredCard === card.id ? styles.imageCircle : styles.image}

              />

              {/* Button */}
              <button
                style={{
                  ...styles.button,
                  ...(hoveredCard === card.id ? styles.buttonHover : {}),

                }}
              >
                {card.buttonText}
                <br></br>  <br></br>
                {card.extraText}
              </button>
              <div style={{ ...styles.textWithLogo, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <span style={styles.smallText}>
    {card.extraTexts}
  </span>
  <img
    src={logoImage} // Replace with the actual logo path
    alt="logo"
    style={styles.smallLogo}
  />
</div>


              {/* Content (Hidden by default) */}
              <div
                style={{
                  ...styles.cardContent,
                  ...(hoveredCard === card.id ? styles.cardContentVisible : {}),
                }}

              >
                <div style={{ fontWeight: 'bold', fontSize: '18px' }}>{card.name}</div>
                <div style={{ color: 'gold', margin: '5px 0' }}>{card.rating}</div>
                <div style={{ fontSize: '17px', color: 'orange' }}>{card.description}</div>
              </div>
            </div>
          </div>
        ))}




      </Slider>
    </div>
  );
};

export default CardComponent;







