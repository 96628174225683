import react from 'react';
import OurSucessStoriesBanners from '../img/banners/ourSucessStoriesBanner.jpg'

const PlacementStudentsBanner = () => {
    return(

        <div>
            <img src={OurSucessStoriesBanners} alt="" />
        </div>
    );
};

export default PlacementStudentsBanner;