


import React, { useState } from "react";

import robotics from "../img/robotics hands working.mp4";
const KeyFuturesSectionDFS = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        date: "",
        gender: "",
        message: "",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiBody = {
            firstName: formData.name,
            emailId: formData.email,
            contactNumber: formData.phone,
            date: formData.date,
            gender: formData.gender,
            message: formData.message,
        };

        try {
            const response = await fetch(
                "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(apiBody),
                }
            );

            if (response.ok) {
                alert("Your details have been submitted successfully!");
            } else {
                alert("There was an issue submitting your details.");
            }
        } catch (error) {
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="container mt-5">
            <div className="row d-flex align-items-start">
                {/* Course Details Section */}
                <div className="col-md-7">
                    <div className="p-4 bg-white shadow rounded mb-4">
                        <h2 style={{ fontWeight: "bold" }}>
                            Digital Facilities Solution{" "}
                            <span style={{ color: "#ffa500", fontWeight: "800", }}>
                                
                            </span>
                        </h2>
                        <p style={{marginBottom:'40px' }}>
                            This course is designed for professionals and students eager to revolutionize facility management through digital technologies. It delves into smart building systems, IoT integration, energy management, and data-driven solutions, empowering learners to design and manage efficient, sustainable facilities.
                        </p>
                    </div>
                    {/* <h3 style={{ fontWeight: "bold", color: "rgb(34 64 153)", textAlign: "left",marginTop:'50px' }}>
                        Key Features
                    </h3>
                    <div className="row" style={{ textAlign: "justify" }}>
                        {[
                            "Receive a prestigious certificate endorsed by IIA and I-Robotics, validating your expertise in tools and data facility solutions.",
                            "Exclusive hackathons and Ask Me Anything sessions by IIA.",
                            "Live sessions on the latest AI trends, such as generative AI, prompt engineering, explainable AI, and more.",
                            "Capstone from 3 domains and 14+ Data Analytics Projects with Industry datasets.",
                            "Master Classes delivered by Purdue faculty and IBM experts.",
                            "We assist you to get noticed by top hiring companies.",
                        ].map((feature, index) => (
                            <div className="col-12 mb-3" key={index}>
                                <div className="key-feature-box p-3 bg-white shadow rounded" style={{ border: "1px solid grey" }}>
                                    <li style={{ listStyleType: "none", borderRadius: '50px' }}>
                                        {feature}
                                    </li>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>

                {/* Video and Enquiry Form Section */}
                <div className="col-md-5">
                    {/* Video Section */}
                    <div style={{ marginBottom: "20px" }}>
                        <video
                            src={robotics}
                            controls
                            style={{
                                width: "100%",
                                borderRadius: "10px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                            }}
                        />
                    </div>

                    {/* Enquiry Form */}
                    {/* <div
                        style={{
                            width: '100%',
                            minHeight: '500px',
                            maxHeight: '90vh',
                            overflowY: 'auto',
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                            marginTop:'110px',
                        }}
                     >
                        <h2 style={{ textAlign: 'center', width: '100%', }}>Enquiry Form</h2>
                        <p style={{ marginBottom: '30px', }}>Fill in your details and we’ll get back to you shortly.</p>

                        <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
                            <input
                                type="text"
                                id="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="email"
                                id="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="tel"
                                id="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="date"
                                id="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <div style={{ marginBottom: '10px' }}>
                                <select
                                    id="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                    required
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                            <div style={{ marginBottom: '10px' }}>
                                <textarea
                                    id="message"
                                    placeholder="Your Message"
                                    rows="4"
                                    value={formData.message}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                        height:'50px'
                                    }}
                                ></textarea>
                            </div>

                            <button
                                type="submit"
                                style={{
                                    width: '100%',
                                    padding: '12px',
                                    backgroundColor: '#495057',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                }}
                            >
                                Submit
                            </button>
                        </form>
                    </div> */}
                </div>
            </div>
            <div className="container-fluid px-0">
                    <h3 style={{ fontWeight: "bold", color: "#000", textAlign: "center", marginTop: '67px' }}>
                        Key <span style={{ color: 'orange' }}>Features</span>
                    </h3>
                    <div className="col-12 mx-0" style={{ textAlign: "justify" }}>
                        {[
                            "Receive a prestigious certificate endorsed by IIA and I-Robotics, validating your expertise in tools and virtual robotics.",
                            "Exclusive hackathons and Ask Me Anything sessions by IIA.",
                            "Live sessions on the latest AI trends, such as generative AI, prompt engineering, explainable AI, and more.",
                            "Capstone from 3 domains and 14+ Data Analytics Projects with Industry datasets.",
                            "Master Classes delivered by Purdue faculty and IBM experts.",
                            "We assist you to get noticed by top hiring companies.",
                        ].map((feature, index) => (
                            <div className="col-12 mb-3" key={index}>
                                <div
                                    className="key-feature-box p-3 bg-white shadow rounded"
                                    style={{ border: "1px solid grey" }}
                                >
                                    <li style={{ listStyleType: "none", borderRadius: "50px" }}>
                                        {feature}
                                    </li>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            <style>
                {`
                    .key-feature-box {
                        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
                        color: black;
                        border-radius: 25px;
                    }
                    .key-feature-box:hover {
                        transform: scale(1.02);
                        color: white;
                    }

                    .key-feature-box::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.9);
                        opacity: 0;
                        transition: opacity 0.3s ease-in-out;
                        pointer-events: none;
                        border-radius: 25px;
                    }

                    .key-feature-box:hover::before {
                        opacity: 2;
                    }

                    .key-feature-box li {
                        z-index: 1;
                        position: relative;
                    }
                `}
            </style>
        </div>
    );
};

export default KeyFuturesSectionDFS;


















































