import React, { useState, useEffect } from "react";

const ScrollTriangle = () => {
  const [atTop, setAtTop] = useState(true);

  const handleScroll = () => {
    const scrolledToBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    const scrolledToTop = window.scrollY === 0;

    if (scrolledToBottom) {
      setAtTop(false);
    } else if (scrolledToTop) {
      setAtTop(true);
    }
  };

  const handleClick = () => {
    if (atTop) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setAtTop(!atTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      onClick={handleClick}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        width: "35px", // Circle width
        height: "35px", // Circle height
        borderRadius: "50%",
        backgroundColor: "#fff", // Background of the circle
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional shadow
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        zIndex: 1002,
        border: "2px solid #fb9100", // Circular border
      }}
      title={atTop ? "Scroll Down" : "Scroll Up"}
    >
      <div
        style={{
          width: "0",
          height: "0",
          borderLeft: "10px solid transparent", // Adjust the size of the triangle
          borderRight: "10px solid transparent",
          borderTop: atTop ? "12px solid #fb9100" : "none",
          borderBottom: atTop ? "none" : "12px solid #fb9100",
        }}
      />
    </div>
  );
};

export default ScrollTriangle;
