import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from './Component/Nav';
import Footer from './Component/Footer';
import Home from './Component/Home';
import WhoweAre from "./Component/WhoweAre";
import Contactus from "./Component/Contactus";
import WhatWeDo from "./Component/WhatWeDo";
import Gaming from "./Component/Gaming";
import Resources from "./Component/Resources";
import PCMobileApplications from "./Component/PCMobileApplications";
import Itservices from "./Component/Itservices";
import PlacementStudentsPage from "./Component/PlacementStudentsPage";
import WebApplications from "./Component/WebApplications";
import MobileApp from "./Component/MobileApp";
import Jobs from "./Component/Jobs";
import GalleryPage from'./Component/GalleryPage';
import HumanResource from "./Component/HumanResource";
import PlacementWeProvide from "./Component/PlacementWeProvide";
import Showcase from "./Component/Showcase";
import Specialty from "./Component/Specialty";
import Outdoor from"./Component/Outdoor";
import ScrollTriangle  from "./Component/ScrollTriangle ";
import Login  from "./Component/Login";
import RegistrationForm  from "./Component/RegistrationForm";
import Cart from "./Component/Cart";
import WhatsAppChat from './Component/WhatsAppChat'; // Import WhatsAppChat component
import LoginEmp from "./Component/LoginDashboard";
import Enroll from "./Component/Enroll";
import SignUpEmp from "./Component/SignupDashboard";
import ForgotEmp from "./Component/ForgotPassword";
import ShoppingCart from "./Component/ShoppingCart";
import CardComponent from "./Component/Circle";
import JobPortal from "./Component/Jobportal";
import PCMobileApplications1 from "./Component/joballdata";
import ShoppingCart1 from "./Component/JobCart";



function App() {
  return (
    <div>
    <Router>
      <ScrollTriangle />
      <Nav />
      <Routes>

        
        <Route path="/" element={<Home />} />
        {/* <Route path="/about-us" element={<AboutUs />}></Route> */}
        <Route path="/jobportal1" element={<PCMobileApplications1  />}></Route>
        <Route path="/jobportal" element={<JobPortal />}></Route>
        <Route path="/jobcart" element={<ShoppingCart1 />}></Route>
        <Route path="/circle" element={<CardComponent />}></Route>
        <Route path="/shoppingcart" element={<ShoppingCart />}></Route>
        <Route path="/signupdashboard" element={<SignUpEmp />}></Route>
        <Route path="/logindashboard" element={<LoginEmp />}></Route>
        <Route path="/forgot" element={<ForgotEmp />}></Route>
        <Route path="/Enroll" element={<Enroll />}></Route>
        <Route path="/who-we-are" element={<WhoweAre />}></Route>
        <Route path="/contact" element={<Contactus />}></Route>
        <Route path="/what-we-do" element={<WhatWeDo />}></Route>
        <Route path="/gellerypage" element={<GalleryPage/>}></Route>
        <Route path="/placed-students" element={<PlacementStudentsPage/>}></Route>
        <Route path="/PlacementWeProvide" element={<PlacementWeProvide/>}></Route>
        

        
        <Route path="/gaming" element={<Gaming />}></Route>
        <Route path="/snowcsc" element={<Showcase/>}></Route>
        <Route path="/Specialty" element={<Specialty/>}></Route>
        <Route path="/Outdoor" element={<Outdoor/>}></Route>

        <Route path="/custom-applications" element={<Resources />}></Route>
        <Route path="/web-mobile-applications" element={<PCMobileApplications />}></Route>
        <Route path="/it-services" element={<Itservices />}></Route>
        <Route path="/web-applications" element={<WebApplications/>}></Route>
        <Route path="/mobile-application" element={<MobileApp/>}></Route>
        <Route path="/jobs" element={<Jobs/>}></Route>
        <Route path="/human-resource" element={<HumanResource/>}></Route>
        <Route path="/login-form" element={<Login/>}></Route>
        <Route path="/registration-form" element={<RegistrationForm/>}></Route>
        <Route path="/cart-page" element={<Cart/>}></Route>


        
      </Routes>

      <Footer/>
      <WhatsAppChat /> {/* Add the WhatsApp chat component here */}
    </Router>
  </div>
  );
}

export default App;
