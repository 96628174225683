import React, { useEffect , useState } from 'react';
import OurCommitments from './OurCommitments'
import WhoweareBanner from './WhoweareBanner'
import AboutCompany from './AboutCompany';
import AboutEnvisionBrief from './AboutEnvisionBrief';
 import OurLeadership from './OurLeadership';
 import OurTeamsAdd from './OurTeamsAdd';
 import OurCoPartneres from './OurCoPartneres';
 import ProgramAdvisiorIIA from './ProgramAdvisiorIIA';
//  import Testimonials from './Testimonials';

const WhoweAre = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs once when the component mounts
          // Scroll to the top of the page when the component mounts
       
        
        
  return (
    <div style={{backgroundColor:"white"}}>
        <WhoweareBanner/>

        <AboutCompany/>
        <AboutEnvisionBrief/>
        <OurCommitments/>
        <OurCoPartneres/>
        {/* <OurLeadership/> */}
        <ProgramAdvisiorIIA />
        {/* <OurTeamsAdd/> */}
        {/* { <OurLeadership/> } */}
        {/* { <Testimonials/> } */}

     

        <style>
          {`
           h1,h2,h3,h4,h5,h6,p{
          font-family: urbanist , sarif;
          }
          `}
        </style>
    </div>
  )
}

export default WhoweAre