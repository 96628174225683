import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
// import candidate1 from "../img/Placed/ppl/Placement_Poster_V1.jpg";
// import candidate2 from "../img/Placed/ppl/Placement_Poster_V2.jpg";
// import candidate3 from "../img/Placed/ppl/Placement_Poster_V3.jpg";
// import candidate4 from "../img/Placed/ppl/Placement_Poster_V4 copy.jpg";// not needed
// import candidate5 from "../img/Placed/ppl/Placement_Poster_V3 copy.jpg";// not needed
// import candidate6 from "../img/Placed/ppl/Placement_Poster_V2 copy.jpg";// not needed
// import candidate7 from "../img/Placed/ppl/Placement_Poster_V1 copy.jpg";// not needed
// import candidate8 from "../img/Placed/ppl/Placement_Poster_V6.jpg";
// import candidate9 from "../img/Placed/ppl/Placement_Poster_V7.jpg";






import candidate1 from "../img/05_PLACEMENT CERTIFICATES/01_SIVA NAGRAJU.jpg";
import candidate2 from "../img/05_PLACEMENT CERTIFICATES/02_ANVESH.jpg";
import candidate3 from "../img/05_PLACEMENT CERTIFICATES/03_PRASANNA.jpg";
import candidate4 from "../img/05_PLACEMENT CERTIFICATES/04_WAISQ.jpg";
import candidate5 from "../img/05_PLACEMENT CERTIFICATES/05_SARANYA.jpg";
import candidate6 from "../img/05_PLACEMENT CERTIFICATES/06_VISHNU.jpg";
import candidate7 from "../img/05_PLACEMENT CERTIFICATES/07_SREEVATHSAVA.jpg";
import candidate8 from "../img/05_PLACEMENT CERTIFICATES/08_SAMYUKTHA.jpg";
import candidate9 from "../img/05_PLACEMENT CERTIFICATES/09_LINGESWAR.jpg";
import candidate10 from "../img/05_PLACEMENT CERTIFICATES/10_NARESH.jpg";
import candidate11 from "../img/05_PLACEMENT CERTIFICATES/11_AKHIL.jpg";
import candidate12 from "../img/05_PLACEMENT CERTIFICATES/12_RAMACHANDRA.jpg";
import candidate13 from "../img/05_PLACEMENT CERTIFICATES/13_VIKASH.jpg";
import candidate14 from "../img/05_PLACEMENT CERTIFICATES/14_VIKASH SINGH.jpg";


const PlacementsCards = () => {
  const teamMembers = [
    {
      name: 'C Chandra Sekhar Reddy',
      jobTitle: 'CEO & Founder',
      imgSrc: candidate1,
    },
    {
      name: 'Asha Jyothi G',
      jobTitle: 'Chief Client Relations Officer',
      imgSrc: candidate2,
    },
    {
      name: 'Kiran Kumar M',
      jobTitle: 'Marketing Manager',
      imgSrc: candidate3,
    },
    {
      name: 'Eswar Raju K',
      jobTitle: 'SA Manager',
      imgSrc: candidate4,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate5,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate6,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate7,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate8,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate9,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate10,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate11,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate12,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate13,
    },
    {
      name: 'Priti Pragyan B',
      jobTitle: 'ITE Manager',
      imgSrc: candidate14,
    },
  ];

  const teamCardStyle = {
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.5)',
    background: '#fff',
    border: '2px solid  #0a8194',
    transition: 'transform 0.3s ease-in-out',
    width: '90%'
  };

  const teamImageStyle = {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.3s ease-in-out',
  };

  return (
    <section className="no-bottom pt-5" aria-label="team" style={{marginTop:'-84px'}}>
      <div className="container-fluid">
        <div className="" style={{ textAlign: 'center' }}>
          <div className="row d-flex justify-content-center" style={{  }}>
            <div className="container">
              <div className="heading " style={{ fontSize: '48px', fontWeight: '700', color: 'rgb(16 16 14)', marginBottom:'30px'}}>
                Our Alumni In  <span style={{ color: '#f7941d' }}>Top Companies</span>
              </div>
            </div>

            <div className="col-12 centered">
              <div className="detailcontent text-center p-0">
                {/* Optional additional text can go here */}
              </div>
            </div>

            <div className="col-10 p-0">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav={false} // Disable navigation arrows
                autoplay={true} // Enable autoplay
                autoplayTimeout={3000} // Set autoplay interval (3 seconds)
                autoplayHoverPause={true} // Pause on hover  rema
                responsive={{
                  0: {
                    items: 1, // Show 1 card on mobile
                  },
                  600: {
                    items: 2, // Show 2 cards on tablets
                  },
                  1000: {
                    items: 3, // Show 3 cards on desktops
                  },
                }}
                dots={false} // Disable dots pagination
              >
                {teamMembers.map((member, index) => (
                  <div className="item" key={index}>
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <div className="team-card" style={teamCardStyle}>
                        <div className="team-image" style={teamImageStyle}>
                          <img src={member.imgSrc} alt={member.name} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlacementsCards;
