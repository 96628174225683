import React from "react";
// import reac from "../img/our-team/ccr.png";
// import reac from "../img/our-team/ccreddy.png";
import reac from "../img/our-team/ccrReddy.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const TheProgramAdvisors = () => {
  const socialLinkStyle = {
    margin: "0 10px",  // Example margin to space out the icons
    color: "#000", // Set a default color (you can adjust as needed)
    fontSize: "30px", // Set the size of the icons
  };

  return (
    <div style={{ backgroundColor: "white", padding: "40px" }}>
      <div className="row d-flex justify-content-center">
        <div className="col-md-12">
          <h1 style={{ fontSize: '48px', textAlign: 'center' }}>Meet Our <span style={{ color: 'rgb(245, 134, 52)' }}>Experts</span> </h1>
        </div>
        <div className="col-md-8">
          <div className="row" style={{ border: "3px solid black", borderRadius: "20px" }}>
            <div className="col-xl-3 col-md-6" >
              <img src={reac} alt="" style={{ borderBottom: '6px solid black',marginBottom:'20px' }} className="adviser-img" />

                
                <p style={{fontSize:'18px',fontWeight:'700' }}>C Chandra Sekhar Reddy</p> <br/>
                <p style={{fontWeight:'900',marginBottom:'10px'}}> CEO & Founder</p>

              <div className="social-icons" style={{ textAlign: 'center' }}>
                <a href="#" style={socialLinkStyle}>
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="#" style={socialLinkStyle}>
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="#" style={socialLinkStyle}>
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="#" style={socialLinkStyle}>
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
            <div className=" ProvisorIIA col-xl-9 col-md-12" style={{ padding: "49px" }}>
              
              <p className="Provisor-IIA-para" style={{ textAlign: "left", marginBottom: "20px", fontSize: '16px' }}>
                As the founder of the International Institute of Automate (IIA), I, C. Chandra Sekhar Reddy embarked on this journey with a vision to create a bridge between academia and industry. Coming from a strong engineering background, I understood the challenges faced by professionals and fresh graduates in adapting to ever-evolving industry demands. I wanted to establish an institution that not only trains but also inspires individuals to achieve excellence in both mechanical and IT domains.
              </p>
              <p className="Provisor-IIA-para"  style={{ textAlign: "left", marginBottom: "20px", fontSize: '16px' }}>
                When I started IIA, my goal was clear: to empower students and professionals with skills that make them industry-ready. We focus on practical, hands-on learning, combining advanced tools, techniques, and real-world applications. The journey has been incredible, filled with collaboration, innovation, and a relentless commitment to providing world-class training.
              </p>
              <p  className="Provisor-IIA-para" style={{ textAlign: "left", marginBottom: "20px", fontSize: '16px' }}>
                What drives me every day is seeing the transformation in our learners — from being aspirants to becoming confident, skilled professionals ready to make a mark in their careers. At IIA, we don’t just teach; we create opportunities, foster leadership, and build futures.
              </p>
              <p  className="Provisor-IIA-para" style={{ textAlign: "left", marginBottom: "20px", fontSize: '16px' }}>
                I invite you to explore our journey and join us in shaping a brighter, skill-oriented today and tomorrow at IIA.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheProgramAdvisors;
