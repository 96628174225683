import React, { useState } from "react";

const LearningPathTFD = () => {
  const [toggleAllSections, setToggleAllSections] = useState(false);
  const [sectionToggle, setSectionToggle] = useState({});
  const [toggleNewModule, setToggleNewModule] = useState(false);
  const [newModuleToggle, setNewModuleToggle] = useState({});
  const [toggleExteraModule, setToggleExteraModule] = useState(false);
  const [exteraModuleToggle, setExteraModuleToggle] = useState({});
  const [toggleIsExteraModule, setToggleIsExteraModule] = useState(false);
  const [isExteraModuleToggle, setIsExteraModuleToggle] = useState({});
  const [toggleAdditionalModule, setToggleAdditionalModule] = useState(false);
  const [additionalModuleToggle, setAdditionalModuleToggle] = useState({});
  const [toggleExtraAdvancedModule, setToggleExtraAdvancedModule] = useState(false);
  const [extraAdvancedModuleToggle, setExtraAdvancedModuleToggle] = useState({});
  const [toggleAndLocalModule, setToggleAndLocalModule]= useState(false);
  const [AndLocalModuleToggle, setAndLocalModuleToggle]= useState({});
  const listContent = [
    // Existing sections
    {
      id: 1,
      title: "Introduction to Tool & Fixture Design",
      nestedContent: [
        { id: 1, text: "• 	Definition, scope and importance.", dummyText: [] },
        { id: 2, text: "•	Types of tools and fixtures: Jigs, dies, molds and welding fixtures.", dummyText: [] },
        { id: 3, text: "• 	Applications in various industries", dummyText: [] },
      ],
    },
    {
      id: 2,
      title: "Design Principles and Standards",
      nestedContent: [
        { id: 1, text: "• 	Key principles: Accuracy, rigidity, simplicity and economy..", dummyText: [] },
        { id: 2, text: "• 	Material selection for tools and fixtures.", dummyText: [] },
        { id: 3, text: "• 	Understanding industry standards (ISO, ASME).", dummyText: [] },
      ],
    },
  ];

  const newModuleContent = [
    // New module sections
    {
      id: 1,
      title: "Reading and Interpreting Engineering Drawings",
      nestedContent: [
        { id: 1, text: "• 	Basics of engineering drawings and symbols.", dummyText: [] },
        { id: 2, text: "• 	Geometric Dimensioning and Tolerancing (GD&T).", dummyText: [] },
       
      ],
    },
    {
      id: 2,
      title: " Introduction to CAD Tools",
      nestedContent: [
        { id: 1, text: "• Overview of CAD software (AutoCAD, SolidWorks, CATIA).", dummyText: [] },
        { id: 2, text: "• 	Hands-on practice: Creating 2D and 3D models.", dummyText: [] },
       
      ],
    },
    
      
  ];

  const exteraModuleContent = [
    // Extra module sections
    {
      id: 3,
      title: " Types of Tools",
      nestedContent: [
        { id: 1, text: "• 		Cutting tools, holding tools and measuring tools.", dummyText: [] },
        { id: 2, text: "• 		Tool materials and manufacturing processes.", dummyText: [] },
       
      ],
    },
    {
      id: 4,
      title: " Design of Jigs and Fixtures",
      nestedContent: [
        { id: 1, text: "• 		Types of jigs: Drill jigs, welding jigs.", dummyText: [] },
        { id: 2, text: "• 	•	Fixture design for machining and assembly operations.", dummyText: [] },
        
      ],
    },
    {
        id: 5,
        title: " Press Tool Design",
        nestedContent: [
          { id: 1, text: "• 	Introduction to press tools: Shearing, bending and drawing.", dummyText: [] },
          { id: 2, text: "• 	Case studies on press tool applications.", dummyText: [] },
          
        ],
      },
  ];

  const additionalModuleContent = [
    // Additional module sections
    {
      id: 5,
      title: " Principles of Fixture Design",
      nestedContent: [
        { id: 1, text: "•	Locating and clamping principles.", dummyText: [] },
        { id: 2, text: "•	Types of fixtures: Machining, welding and inspection fixtures.", dummyText: [] },
      ],
    },
    {
      id: 6,
      title: "Fixture Design Process",
      nestedContent: [
        { id: 1, text: "• 	Steps in fixture design: Concept, material selection and validation.", dummyText: [] },
        { id: 2, text: "•	Design for manufacturability and assembly.", dummyText: [] },
      ],
    },
    {
        id: 7,
        title: "Hands-On Practice",
        nestedContent: [
          { id: 1, text: "• 	CAD-based design of fixtures.", dummyText: [] },
          { id: 2, text: "•	Real-world examples of fixture applications.", dummyText: [] },
        ],
      },
      
  ];

  const isExteraModuleContent = [
    // Additional Module Sections
    {
      id: 7,
      title: " Finite Element Analysis (FEA)",
      nestedContent: [
        { id: 1, text: "• 	Basics of FEA for tool and fixture design.", dummyText: [] },
        { id: 2, text: "• 	Stress and deformation analysis.", dummyText: [] },
      ],
    },
    {
      id: 8,
      title: " Simulation and Validation",
      nestedContent: [
        { id: 1, text: "• 	Use of simulation tools for collision detection and motion optimization.", dummyText: [] },
        { id: 2, text: "• 	Virtual testing of designs.", dummyText: [] },
      ],
    },
    {
        id: 9,
        title: " Integration with Automation",
        nestedContent: [
          { id: 1, text: "• 		Designing tools and fixtures for robotic and CNC applications.", dummyText: [] },
          { id: 2, text: "• 		Case studies: Automation in automotive and aerospace industries", dummyText: [] },
        ],
      },
      
  ];

  const extraAdvancedModuleContent = [
    // Extra Advanced Module Sections
    {
      id: 9,
      title: " Industry-Specific Applications",
      nestedContent: [
        { id: 1, text: "•	Tool and fixture design in automotive, aerospace and consumer goods.", dummyText: [] },
        { id: 2, text: "•	Collaboration with industry experts for insights.", dummyText: [] },
      ],
    },
    {
      id: 10,
      title: " Soft Skills and Professional Development",
      nestedContent: [
        { id: 1, text: "• 		Effective communication and teamwork in design projects.", dummyText: [] },
        { id: 2, text: "• 	Resume building and interview preparation for tool design roles..", dummyText: [] },
      ],
    },
    {
      id: 11,
      title: "  Capstone Project",
      nestedContent: [
        { id: 1, text: "• 		Design a tool or fixture for a real-world application.", dummyText: [] },
        { id: 2, text: "• 	Create detailed CAD models and engineering drawings.", dummyText: [] },
        { id: 3, text: "• 		Validate the design using FEA or simulation tools.", dummyText: [] },
        { id: 4, text: "• 		Optimize the design for cost and manufacturability.", dummyText: [] },
        { id: 5, text: "• 		Present the project findings and results.", dummyText: [] },
      ],
    },
  ];
  const andLocalModuleContent = [
    //ExtraAdvancedModuleSections
    {
      id: 9,
      title: "Simulation Exercises",
      nestedContent: [
        { id: 1, text: "•		Design a virtual factory layout", dummyText: [] },
        { id: 2, text: "•		Simulate production workflows and identify bottlenecks.", dummyText: [] },
      ],
    },
    {
      id: 10,
      title: " Case Studies",
      nestedContent: [
        { id: 1, text: "• 		Analysis of successful digital factory implementations.", dummyText: [] },
        { id: 2, text: "• 	Lessons learned and best practices", dummyText: [] },
      ],
    },
    {
      id: 11,
      title: "  Group Discussions and Q&A",
      nestedContent: [
        { id: 1, text: "• 		Identifying opportunities for digital transformation in participants’ industries.", dummyText: [] },
        { id: 2, text: "• 		Addressing challenges and exploring innovative solutions.", dummyText: [] },
      ],
    },
  ];

  const toggleSection = (id, module = "existing") => {
    if (module === "existing") {
      setSectionToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "new") {
      setNewModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extera") {
      setExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "isextera") {
      setIsExteraModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "additional") {
      setAdditionalModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (module === "extraAdvanced") {
      setExtraAdvancedModuleToggle((prev) => ({ ...prev, [id]: !prev[id] }));
    }else if (module === "andLocal"){
      setToggleAndLocalModule((prev) => ({...prev,[id]: !prev[id]}));
    }
  };

  return (
    <div className="container my-5">
      <h2 className="fw-bold mb-4" style={{ color: "#000", fontFamily: "Roboto", textAlign: "center" }}>
        PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
      </h2>
      <br />
      
      {/* Existing Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAllSections(!toggleAllSections)}
      >
        Fundamentals of Tool & Fixture Design
      </div>

      {toggleAllSections && (
        <div className="list-tab mt-3">
          {listContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id)}
              >
                {title}
                <span>{sectionToggle[id] ? "-" : "+"}</span>
              </div>
              {sectionToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* New Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleNewModule(!toggleNewModule)}
      >
       Engineering Drawings and CAD Basics
      </div>

      {toggleNewModule && (
        <div className="list-tab mt-3">
          {newModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "new")}
              >
                {title}
                <span>{newModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {newModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Extera Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExteraModule(!toggleExteraModule)}
      >
        Tool Design
      </div>

      {toggleExteraModule && (
        <div className="list-tab mt-3">
          {exteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extera")}
              >
                {title}
                <span>{exteraModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {exteraModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Additional Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAdditionalModule(!toggleAdditionalModule)}
      >
          Fixture Design
      </div>

      {toggleAdditionalModule && (
        <div className="list-tab mt-3">
          {additionalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "additional")}
              >
                {title}
                <span>{additionalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {additionalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

<div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleIsExteraModule(!toggleIsExteraModule)}
      >
     Advanced Design and Analysis
      </div>

      {toggleIsExteraModule && (
        <div className="list-tab mt-3">
          {isExteraModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "isextera")}
              >
                {title}
                <span>{isExteraModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {isExteraModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {/* Extra Advanced Module */}
      <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleExtraAdvancedModule(!toggleExtraAdvancedModule)}
      >
       Technical Skill Enhancement Program 
      </div>

      {toggleExtraAdvancedModule && (
        <div className="list-tab mt-3">
          {extraAdvancedModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "extraAdvanced")}
              >
                {title}
                <span>{extraAdvancedModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {extraAdvancedModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
       {/* <div
        className="fw-bold mb-4 p-3 bg-light rounded mt-5"
        style={{ color: "blue", fontFamily: "Roboto", fontSize: "20px", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
        onClick={() => setToggleAndLocalModule(!toggleAndLocalModule)}
      >
        Practical Exercises and Case Studies 
      </div> */}

      {toggleAndLocalModule && (
        <div className="list-tab mt-3">
          {andLocalModuleContent.map(({ id, title, nestedContent }) => (
            <div key={id} className="mb-3">
              <div
                className="p-3 bg-light rounded d-flex justify-content-between align-items-center"
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", fontFamily: "Roboto", fontSize: "18px", cursor: "pointer" }}
                onClick={() => toggleSection(id, "andLocal")}
              >
                {title}
                <span>{AndLocalModuleToggle[id] ? "-" : "+"}</span>
              </div>
              {AndLocalModuleToggle[id] && (
                <div className="mt-2 px-4">
                  {nestedContent.map(({ id: nestedId, text }) => (
                    <div key={nestedId} className="my-2">
                      <div className="p-2 bg-white rounded" style={{ border: "1px solid #ddd", fontFamily: "Roboto" }}>
                        {text}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LearningPathTFD;




