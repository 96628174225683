import React from 'react';
// import aboutUsImage from '../img/aaaservices/IIA-AboutusDigramLogo.png'; // Import the image
import aboutUsImage from '../img/aaaservices/ABOUTUSpage.png'; // Import the image

const AboutUs = () => {
  return (
    <section className="about-area ptb-100">
      <div className="">
        <div className="row d-flex justify-content-center">
          {/* About Content */}
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <div className="container" style={{ textAlign: 'center' }}>
              <div className="row" style={{ paddingBottom: '20px' }}>
                <div className="container">
                  <h1
                    style={{
                      color: "#000",
                      fontSize: '48px',
                      display: 'inline-block',
                      position: 'relative',
                      fontFamily: 'urbanist',
                    }}
                  >
                    About <span style={{ color: "#f7941d" }}>Us</span>
                    {/* <span
                      style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000',
                      }}
                    /> */}
                  </h1>
                </div>
              </div>
              <h2
                style={{
                  color: 'rgb(16, 16, 14)',
                  fontSize: '22px',
                  fontWeight: '700',
                  fontFamily: 'lato, sans-serif',
                }}
              >
                Empowering Minds Through Knowledge and Innovation.
              </h2>
              <p style={{ fontSize: '18px', textAlign: 'justify' }}>
                Welcome to <span style={{ fontFamily: 'urbanist , sarif', color: 'black' }}>IIA Learning Institute</span>, where education meets innovation. We are committed to delivering world-class learning solutions that inspire, engage, and prepare individuals for success in an evolving world.
              </p>
              <p style={{ fontSize: '18px', textAlign: 'justify' }}>
              The International Institute of Automate (IIA) stands as a beacon of excellence in professional education. Established in 2012, IIA is a premier institution under the EnvisionIS Group. It’s committed to bridging the gap between theoretical learning and practical skills. Our mission is to prepare aspiring individuals for industry success with affordable, innovative programs that blend theory with hands-on training. Our dynamic, industry-oriented curriculum evolves with technological advancements, ensuring students stay ahead. Signature Live Techno-Talks connect learners with industry experts, offering insights into the latest trends. Whether for academic growth, career advancement, or skill enhancement. IIA delivers tailored education for all. We’re more than an education provider-We’re your career partner, helping you secure dream jobs by choice, not by chance.

              </p>
            </div>
          </div>

          {/* About Image */}
          <div className="col-lg-3 col-md-12">
            <div className="about-text text-center">
              <img src={aboutUsImage} alt="About Us"  className="img-fluid rounded about-img-side" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
