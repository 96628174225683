import React from 'react';
import Slider from 'react-slick';
import Abb from "../img/logo/DigitalFacilitiesSolutionLogo/Abb-removebg-preview.png";
import dalima from "../img/logo/DigitalFacilitiesSolutionLogo/dalima-removebg-preview.png";
import Technomatix from "../img/logo/DigitalFacilitiesSolutionLogo/Technomatix-removebg-preview.png";
import Kuka from "../img/logo/DigitalFacilitiesSolutionLogo/Kuka-removebg-preview.png";
import RobCad from "../img/logo/DigitalFacilitiesSolutionLogo/RobCad-removebg-preview.png";
import Simufact from "../img/logo/DigitalFacilitiesSolutionLogo/Simufact-removebg-preview.png";

// Settings for the Slick slider
const sliderSettings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000, // Change to your preferred speed
    arrows: false, // Disable next and previous arrows
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

const ToolCoveredImagesVR = () => {
    return (
        <div className="container mt-5 pb-5">
            <div className="row">
                <div className="col-12">
                    {/* Heading aligned to the left */}
                    <h3
                        style={{
                            fontFamily: 'roboto', // Roboto font with fallback
                            fontSize: '40px', // Set font size
                            fontWeight: 'bold',  // Bold weight for "Software"
                            color: 'black',   // Black color for "Software"
                        }}
                    >
                        Software{" "}
                        <span
                            style={{
                                fontWeight: 'bold', // Slightly less bold for "Tools"
                                color: 'orange', // Orange color for "Tools"
                            }}
                        >
                            Tools
                        </span>
                    </h3>



                    {/* Slider component */}
                    <Slider {...sliderSettings}>
                        {/* Images in Slider */}
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={Abb} alt="Abb" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={dalima} alt="Dalima" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={Technomatix} alt="Technomatix" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={Kuka} alt="Kuka" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={RobCad} alt="RobCad" className="img-fluid" style={styles.image} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={styles.imageContainer}>
                            <img src={Simufact} alt="Simufact" className="img-fluid" style={styles.image} />
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

// CSS-in-JS Styles
const styles = {
    heading: {
        fontSize: '35px',
        textAlign: 'center',
        marginBottom: '30px',
    },
    imageContainer: {
        padding: '30px',
        margin: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        height: '120px',               // Set image height
        backgroundColor: '#fff',       // Background color
        border: '1px solid #000',   // 2px solid yellow border
        margin: '0px 30px',            // Horizontal margin for spacing
        width: '90%',                   // Set width
        borderRadius: '20px'
    },
};

const spanStyle = {
    subHeading: {
        color: 'orange',
    }
};


export default ToolCoveredImagesVR;
