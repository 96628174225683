

import React, { useEffect, useState } from 'react';
import ToolDesignBanner from './ToolDesignBanner';
import BannerDFS from './BannerDFS';
import BannerVR from './BannerVR';
import VirtualRoboticsPage from './VirtualRoboticsPage';
import DigitalFacilitiesSolutionPage from './DigitalFacilitiesSolutionPage';
import FlutterTabs from './FlutterTabs';
import 'react-image-lightbox/style.css'; // Import the lightbox styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMicrochip, faHospital } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';


import ProgramFeesAndSchedules from './ProgramFeesAndSchedules';
import KeyFuturesSectionVR from './KeyFuturesSectionVR';
import CertificationVR from './CertificationVR';
import LearningPathVR from './LearningPathVR';
import SkilledUniversitiesVR from './SkilledUniversitiesVR';
import ToolCoveredImgesVR from './ToolCoveredImgesVR';



// import ProgramFeesAndSchedules from './ProgramFeesAndSchedules';
import KeyFuturesSectionDFS from './KeyFuturesSectionDFS';
import CertificationDFS from './CertificationDFS';
import LearningPathDFS from './LearningPathDFS';
import SkilledUniversitiesDFS from './SkilledUniversitiesDFS';
import ToolCoveredImgesDFS from './ToolCoveredImgesDFS';




// import ProgramFeesAndSchedules from './ProgramFeesAndSchedules';
import KeyFuturesSection from './KeyFuturesSection';
import CertificationTFD from './CertificationTFD';
import LearningPathTFD from './LearningPathTFD';
import SkilledUniversities from './SkilledUniversities';
import ToolCoveredImges from './ToolCoveredImges';
import Datavideos from './Datavideos';
import Outdoor from './Outdoor';
import DigitalFacilityBanner from './DigitalFacilityBanner';
import VertualroboticsBanner from './VertualroboticsBanner';



const Itservices = () => {
  const [toggle, setToggle] = useState(1);
  const [navbarHeight, setNavbarHeight] = useState(93); // Default navbar height
  const [tabVR, setTabVR] = useState(1);

  function updateTabsVR(num) {
    setTabVR(num);
  }

  function updateToggle(id) {
    setToggle(id);
  }

  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo(0, 0);

    // Calculate navbar height
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
       <div
          className="icon-slider-wrapper"
          style={{
            position: 'sticky',
            top: `${navbarHeight}px`,
            backgroundColor: '#fff',
            zIndex: 1000,
            // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            height: '100px',
            width: '100%',
          }}
         >
          <Slider {...settings}>
            {/* Tools & Fixture Design */}
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => updateToggle(1)}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  paddingBottom: '1rem',
                  color: toggle === 1 ? '#215a9d' : 'black',
                }}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  style={{
                    marginBottom: '5px',
                    fontSize: '24px',
                    color: toggle === 1 ? '#215a9d' : 'black',
                  }}
                />
                <p>Tools & Fixture Design</p>
              </a>
            </div>

            {/* Virtual Robotics */}
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => updateToggle(3)}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  color: toggle === 3 ? '#215a9d' : 'black',
                }}
              >
                <FontAwesomeIcon
                  icon={faHospital}
                  style={{
                    marginBottom: '5px',
                    fontSize: '24px',
                    color: toggle === 3 ? '#215a9d' : 'black',
                  }}
                />
                <p>Virtual Robotics</p>
              </a>
            </div>

            {/* Digital Facilities Solution */}
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => updateToggle(2)}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  color: toggle === 2 ? '#215a9d' : 'black',
                }}
              >
                <FontAwesomeIcon
                  icon={faMicrochip}
                  style={{
                    marginBottom: '5px',
                    fontSize: '24px',
                    color: toggle === 2 ? '#215a9d' : 'black',
                  }}
                />
                <p>Digital Factory Solution</p>
              </a>
            </div>
          </Slider>
        </div>

        <div className={toggle === 1 ? 'show-the-content' : 'dont-show-content'}>

          <div
            className="reCreateTabs"
            style={{
              position: 'sticky',
              top: `${navbarHeight + 100}px`, // Adjust to remain below the slider
              backgroundColor: '#fff',
              padding: '10px 0',
              zIndex: 999,
              // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
              <li
                onClick={() => updateTabsVR(1)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 1 ? 'gray' : 'transparent',
                  color: tabVR === 1 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Overview
              </li>
              <li
                onClick={() => updateTabsVR(2)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 2 ? 'gray' : 'transparent',
                  color: tabVR === 2 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Level-1 Certification
              </li>
              {/* <li
                onClick={() => updateTabsVR(3)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 3 ? 'gray' : 'transparent',
                  color: tabVR === 3 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Level-2 Diploma
              </li>
              <li
                onClick={() => updateTabsVR(4)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 4 ? 'gray' : 'transparent',
                  color: tabVR === 4 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Level-3 Post Graduation
              </li> */}
            </ul>
          </div>

          <div className={tabVR === 1 ? 'show-the-content' : 'dont-show-content'}>
            <KeyFuturesSection />
            <SkilledUniversities />
            <ToolCoveredImges />
            {/* <Datavideos /> 000000000000000000000000000000000000000000003333333333333333333333333333333333333333333333333333333333333333333333333*/}
          </div>

          <div className={tabVR === 2 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathTFD />
            <ProgramFeesAndSchedules />
            <CertificationTFD />
          </div>

          <div className={tabVR === 3 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathTFD />
          </div>

          <div className={tabVR === 4 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathTFD />
          </div>

        </div>
      {/* Hero Section */}
      <div className={toggle === 1 ? 'show-the-content' : 'dont-show-content'}>
        {/* <ToolDesignBanner /> */}
        <Outdoor />
      </div>

      <div className={toggle === 2 ? 'show-the-content' : 'dont-show-content'}>
        {/* <BannerDFS /> */}
        <DigitalFacilityBanner />
      </div>

      <div className={toggle === 3 ? 'show-the-content' : 'dont-show-content'}>
        {/* <BannerVR /> */}
        <VertualroboticsBanner />
      </div>

      {/* Sticky Icon Slider */}
      <div>
       

        {/* Sticky Tabs Section */}


        <div className={toggle === 3 ? 'show-the-content' : 'dont-show-content'}>

          <div
            className="reCreateTabs"
            style={{
              position: 'sticky',
              top: `${navbarHeight + 100}px`, // Adjust to remain below the slider
              backgroundColor: '#fff',
              padding: '10px 0',
              zIndex: 999,
              // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
           >
            <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
              <li
                onClick={() => updateTabsVR(1)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 1 ? 'gray' : 'transparent',
                  color: tabVR === 1 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Overview
              </li>
              <li
                onClick={() => updateTabsVR(2)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 2 ? 'gray' : 'transparent',
                  color: tabVR === 2 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Level-1 Certification
              </li>

            </ul>
          </div>

          <div className={tabVR === 1 ? 'show-the-content' : 'dont-show-content'}>
            <KeyFuturesSectionVR />
            <SkilledUniversitiesVR />
            <ToolCoveredImgesVR />
            {/* <Datavideos /> 000000000000000000000000000000000000000000003333333333333333333333333333333333333333333333333333333333333333333333333*/}
          </div>

          <div className={tabVR === 2 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathVR />
            <ProgramFeesAndSchedules />
            <CertificationVR />
          </div>

          <div className={tabVR === 3 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathVR />
          </div>

          <div className={tabVR === 4 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathVR />
          </div>

        </div>

        {/* 11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111 */}



        <div className={toggle === 2 ? 'show-the-content' : 'dont-show-content'}>

          <div
            className="reCreateTabs"
            style={{
              position: 'sticky',
              top: `${navbarHeight + 100}px`, // Adjust to remain below the slider
              backgroundColor: '#fff',
              padding: '10px 0',
              zIndex: 999,
              // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
           >
            <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
              <li
                onClick={() => updateTabsVR(1)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 1 ? 'gray' : 'transparent',
                  color: tabVR === 1 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Overview
              </li>
              <li
                onClick={() => updateTabsVR(2)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 2 ? 'gray' : 'transparent',
                  color: tabVR === 2 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Level-1 Certification
              </li>
              {/* <li
                onClick={() => updateTabsVR(3)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 3 ? 'gray' : 'transparent',
                  color: tabVR === 3 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Level-2 Diploma
              </li>
              <li
                onClick={() => updateTabsVR(4)}
                style={{
                  fontSize: "20px",
                  fontWeight: '800',
                  padding: '10px 50px',
                  backgroundColor: tabVR === 4 ? 'gray' : 'transparent',
                  color: tabVR === 4 ? 'orange' : 'black',
                  cursor: 'pointer',
                }}
              >
                Level-3 Post Graduation
              </li> */}
            </ul>
          </div>

          <div className={tabVR === 1 ? 'show-the-content' : 'dont-show-content'}>
            <KeyFuturesSectionDFS />
            <SkilledUniversitiesDFS />
            <ToolCoveredImgesDFS />
            {/* <Datavideos /> 000000000000000000000000000000000000000000003333333333333333333333333333333333333333333333333333333333333333333333333*/}
          </div>

          <div className={tabVR === 2 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathDFS />
            <ProgramFeesAndSchedules />
            <CertificationDFS />
          </div>

          <div className={tabVR === 3 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathDFS />
          </div>

          <div className={tabVR === 4 ? 'show-the-content' : 'dont-show-content'}>
            <LearningPathDFS />
          </div>

        </div>






        {/* 11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111 */}




        






























      </div>

      <style>
        {`
            .show-the-content {
              display: block;
            }

            .dont-show-content {
              display: none;
            }

            h1, h2, h3, h4, h5, h6, p {
              font-family: urbanist, serif;
            }

            .reCreateTabs ul {
              display: flex;
              justify-content: center;
              list-style: none;
              padding: 0;
              margin: 0;
              flex-wrap: wrap;
            }

            .reCreateTabs ul li {
              display: inline-block;
              padding: 10px 20px;
              font-size: 16px;
              background-color: black;
              border-radius: 10px;
              color: white;
              margin: 5px;
              cursor: pointer;
              text-align: center;
            }

            .reCreateTabs ul li:hover {
              color: orange;
              background-color: gray;
            }

            /* Responsive Styles */
            @media (max-width: 768px) {
              .reCreateTabs ul li {
                font-size: 14px;
                padding: 8px 15px;
              }
            }

            @media (max-width: 480px) {
              .reCreateTabs ul li {
                font-size: 12px;
                padding: 6px 10px;
              }

              .reCreateTabs ul {
                flex-direction: column;
                align-items: center;
              }
            }
        `}
      </style>

    </div>
  );
};

export default Itservices;
