import React, { useEffect } from 'react';

import PlacementStudentsBanner from './PlacementStudentsBanner'

 import PlacementCards from './PlacementCards';


const PlacementStudentsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <PlacementStudentsBanner/>
        <PlacementCards/>
        
        
    </div>
  );
};

export default PlacementStudentsPage;