

import React, { useEffect, useState, useRef } from 'react';


import Slider from 'react-slick';
import softwareEngineerImage from '../img/aaaservices/fdev.webp';
import dataScientistImage from '../img/aaaservices/bdev.jpeg';
import fullStackDeveloperImage from '../img/aaaservices/fdev.webp';
import Advertisment from './Advertisment';
import { WhoweAre } from './WhoweAre'; // Adjust the path accordingly


const PCMobileApplications = () => {
  const [toggle, setToggle] = useState(1);
  const [navbarHeight, setNavbarHeight] = useState(2); // Default navbar height
  const [tabVR, setTabVR] = useState(2);
  const [tabPages, setTabPages] = useState(1); // For new tab group
  const [mainTab, setMainTab] = useState(2);
  function updateTabsVR(num) {
    setTabVR(num);
  }

  const [loading, setLoading] = useState(false);
  const [cartJobs, setCartJobs] = useState([]);
     const [jobs, setJobs] = useState([]);

  const updateToggle = (id) => {
    setActiveButton(id);  // Set the clicked button as active
    setToggle(id);         // Set the toggle state
  };
  const updateMainTabs = (tabIndex) => {
    setMainTab(tabIndex);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setDropdownOpen5] = useState(false);
  const [isDropdownOpen6, setDropdownOpen6] = useState(false);
  const [isDropdownOpen7, setDropdownOpen7] = useState(false);
  const [isDropdownOpen8, setDropdownOpen8] = useState(false);
  const [isDropdownOpen9, setDropdownOpen9] = useState(false);
  const [isDropdownOpen10, setDropdownOpen10] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dummyData = [
    "Work from office(24782)",
    "Remote(829)",
    "Hybrid(259)",

  ];
  const dummyData1 = [
    "0 years",
    "1 year",
    "2 years",
    "3 years",
    "4 years",
    "5 years",
    "6 years",
    "8 years",
    "10 years",
  ];


  const dummyData2 = [
    "Production, Manufacturing & Engineering(77)",

    "Research & Development(13)",

    " Construction & Site Engineering(12)",

    "Project & Program Management(4)",

    " Engineering - Software & QA(3)",


  ];
  const dummyData3 = [
    "0-3 Lakhs(19)",


    "3-6 Lakhs(76)",


    " 6-10 Lakhs(76)",


    "10-15 Lakhs(35)",


    " 15-25 Lakhs(17)",



  ];
  const dummyData4 = [
    "Indian MNC(3)",

    "Corporate(21)",


    "Foreign MNC(9)",



    "Others(1)",

  ];
  const dummyData6 = [
    "Any Postgraduate(4392)",


    "Post Graduation Not Required(422)",


    "Any Graduate(238)",


    "B.Tech/B.E.(167)",
    "M.Tech(351)",


  ];
  const dummyData5 = [
    "Software Development(3219)",

    "Quality Assurance and Testing(590)",

    "DBA / Data warehousing(239)",

    "Engineering(193)",


  ];

  const dummyData7 = [
    "Company Jobs(374)",

    "Consultant Jobs(48)",



  ];

  const dummyData8 = [
    "IT Services & Consulting(227)",


    "Recruitment / Staffing(50)",


    "Education / Training(14)",



    "Industrial Equipment / Machinery(14)",



  ];
  const dummyData9 = [
    "Atria Convergence Technologies (ACT)(1)",

    "Pega(4)",


  ];
  const dummyData10 = [
    "Hyderabad",

    "chenai",


  ];

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleDropdown1 = () => {
    setDropdownOpen1(!isDropdownOpen1);
  };
  const toggleDropdown2 = () => {
    setDropdownOpen2(!isDropdownOpen2);
  };
  const toggleDropdown3 = () => {
    setDropdownOpen3(!isDropdownOpen3);
  };
  const toggleDropdown4 = () => {
    setDropdownOpen4(!isDropdownOpen4);
  };
  const toggleDropdown5 = () => {
    setDropdownOpen5(!isDropdownOpen5);
  };
  const toggleDropdown6 = () => {
    setDropdownOpen6(!isDropdownOpen6);
  };
  const toggleDropdown7 = () => {
    setDropdownOpen7(!isDropdownOpen7);

  };
  const toggleDropdown8 = () => {
    setDropdownOpen8(!isDropdownOpen8);

  };
  const toggleDropdown9 = () => {
    setDropdownOpen9(!isDropdownOpen9);

  };
  const toggleDropdown10 = () => {
    setDropdownOpen10(!isDropdownOpen10);

  };
  const handleCheckboxChange = (option) => {
    setSelectedOptions(prevState =>
      prevState.includes(option)
        ? prevState.filter(item => item !== option) // Remove if already selected
        : [...prevState, option] // Add if not selected
    );
  };
  const [totalPages, setTotalPages] = useState(1);
  const fetchWithTimeout = async (url, options = {}, timeout = 5000) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    
    const response = await fetch(url, { ...options, signal: controller.signal });
    clearTimeout(id);
    return response;
  };



  // Create a ref for the show-the-content container
  const contentRef = useRef(null);

  // Function to update sub-tabs and scroll smoothly
  const handleSubTabClick = (tabNumber) => {
    updateSubTabs(tabNumber); // Your existing function to update sub-tabs

    // Scroll to the show-the-content container with an offset
    setTimeout(() => {
      if (contentRef.current) {
        const offset = 180; // Adjust this value to control how much of the top you want to show
        const elementPosition = contentRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    }, 0);
  };



  const fetchJobs = async (page = 1) => {
    setLoading(true);
    try {
      const response = await fetchWithTimeout(
        `http://10.10.20.9:7071/IIA-Website-1.0.0/jobpostings/getall/${page}`
      );
      const data = await response.json();
      
      setJobs(data);
      setTotalPages(Math.ceil(data.total / data.limit));
      
      const nextPage = page + 1;
      const responseNextPage = await fetchWithTimeout(
        `http://10.10.20.9:7071/IIA-Website-1.0.0/jobpostings/getall/${nextPage}`
      );
      const dataNextPage = await responseNextPage.json();
      
      if (dataNextPage  && dataNextPage.length > 0) {
        console.log(`Page ${nextPage} exists, fetching next jobs.`);
        setTimeout(() => {
          fetchJobs(nextPage);
        }, 100);
      } else {
        console.log(`Page ${nextPage} does not exist.`);
      }
    } catch (error) {
      console.error("Failed to fetch jobs:", error);
    }
    setLoading(false);
  };
  
useEffect(() => {
    fetchJobs();
  },);




  const updateSubTabs = (tabIndex) => {
    setSubTab(tabIndex);
  };

  const tabContentRef = useRef(null);

  const handleTabClick = (tabIndex) => {
    // Scroll to the respective tab content section
    if (tabContentRef.current) {
      tabContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };




  const [subTab, setSubTab] = useState(5);  // Default sub tab






  const contentRefs = {
    tab5: useRef(null),
    tab2: useRef(null),
    tab3: useRef(null),
  };

  const scrollToTop = (tabId) => {
    if (contentRefs[tabId] && contentRefs[tabId].current) {
      contentRefs[tabId].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo(0, 0);

    // Calculate navbar height
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);


  const handleApply = (job) => {
    // Ensure job includes extra fields like jobDescription and skillsRequired
    const cartItem = {
      jobRole: job.jobRole,
      salary: job.ctcRange,
      location: job.location,
      companyName: job.companyName,
      eligibility: job.eligibility,
      image: job.image,
      workMode: job.workMode,
      jobDescription: job.jobDescription, // Extra field
      skillsRequired: job.skillsRequired, // Extra field
    };

    // Retrieve the existing cart data from localStorage
    let allCarts = JSON.parse(localStorage.getItem("carts1")) || [];

    // Ensure allCarts is an array
    if (!Array.isArray(allCarts)) {
      allCarts = [];
    }

    // Add the new cart item to the array
    allCarts.push(cartItem);

    // Save the updated cart data back to localStorage
    localStorage.setItem("carts1", JSON.stringify(allCarts));

    // Update state
    setCartJobs(allCarts);

    // Navigate to the job cart page
    window.location.href = "/jobcart";
  };

  const settingss = {
    dots: false, // Show dots for navigation
    infinite: true, // Enable infinite scrolling
    arrows: false,
    autoplay: true,
    speed: 500, // Transition speed
    slidesToShow: 3, // Number of items to show at once
    slidesToScroll: 1, // Number of items to scroll per action
    responsive: [
      {
        breakpoint: 768, // For screens smaller than 768px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          slidesToScroll: 1, // Scroll 1 slide at a time
        },
      },
    ],
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const tdStyle = {
    padding: '8px',
    border: '1px solid #ddd',
  };

  const firstTdStyle = {
    fontWeight: 'bold',
    width: '30%',
  };


  return (
    <div className='jobrole' style={{ height: '700px', width: '100%', marginTop: '120px', }}>

      <div>




      </div>

      <div style={{}}>









        <div
          className="icon-slider-wrapper iconddd"
          style={{
            position: 'sticky',
            top: 102,

            zIndex: 997,
            height: '82px',
            // width: '105%',
            maxWidth: '896px',
            margin: '0 auto',
            justifyContent: 'space-between',
            alignItems: 'center',
            //  padding: '16 39px', 


          }}
        >
          <Slider {...settings}>

          <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '270px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
                WorkMode
              </a>

              {/* Dropdown */}
              {isDropdownOpen && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>




            {/* Virtual Robotics */}
            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown1}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '270px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
                Experience
              </a>

              {/* Dropdown */}
              {isDropdownOpen1 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData1.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>



            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown2}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
                Department
              </a>

              {/* Dropdown */}
              {isDropdownOpen2 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData2.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>


            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown3}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
                Salary
              </a>

              {/* Dropdown */}
              {isDropdownOpen3 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData3.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>



            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown4}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
                Company Types
              </a>

              {/* Dropdown */}
              {isDropdownOpen4 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData4.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>


            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown5}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
                Role category
              </a>

              {/* Dropdown */}
              {isDropdownOpen5 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData5.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>



            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown6}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
               Education
              </a>

              {/* Dropdown */}
              {isDropdownOpen6 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData6.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown7}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
               Posted by
              </a>

              {/* Dropdown */}
              {isDropdownOpen7 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData7.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>


            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown8}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
             industary
              </a>

              {/* Dropdown */}
              {isDropdownOpen8 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData8.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>


            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown9}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'black';
                    e.target.style.transform = 'translateY(-5px)';
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) {
                    e.target.style.color = 'white';
                    e.target.style.backgroundColor = 'blue';
                    e.target.style.transform = 'translateY(0)';
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white',
                  fontSize: '20px',
                  fontWeight: '800',
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
               Top company
              </a>

              {/* Dropdown */}
              {isDropdownOpen9 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                    maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
                    overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData9.map((item, index) => (
                      <li
                        key={index}
                        style={{
                          padding: '10px',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>



            <div style={{ textAlign: 'center' }}>
              <a
                className="filterinform"
                onClick={toggleDropdown10}
                onMouseEnter={(e) => {
                  if (activeButton !== 0) { // Only apply hover effect if not active
                    e.target.style.color = 'white'; // Change text color to white on hover
                    e.target.style.backgroundColor = 'black'; // Change background to black on hover
                    e.target.style.transform = 'translateY(-5px)'; // Lift the button on hover
                  }
                }}
                onMouseLeave={(e) => {
                  if (activeButton !== 0) { // Only reset hover effect if not active
                    e.target.style.color = 'white'; // Reset text color
                    e.target.style.backgroundColor = 'blue'; // Reset background color
                    e.target.style.transform = 'translateY(0)'; // Reset button position
                  }
                }}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '20px',
                  width: '280px',
                  color: activeButton === 0 ? 'white' : 'white', // Text color
                  fontSize: "20px",
                  fontWeight: "800",
                  padding: '10px 20px',
                  borderRadius: '15px',
                  backgroundColor: activeButton === 0 ? 'blue' : 'blue',
                  transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
                  transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                }}
              >
                Location
              </a>

              {/* Dropdown */}
              {isDropdownOpen10 && (
                <div
                  style={{
                    marginTop: '10px',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    width: '250px',
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                    {dummyData10.map((item, index) => (
                      <li key={index} style={{ padding: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ marginRight: '10px' }}
                        />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Slider>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              marginTop: '30px',
              flexWrap: 'wrap',
            }}
          >
            {/* Map through job data */}
            {jobs.map((job, index) => (
              <div
                key={index}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  padding: '20px',
                  width: '280px',
                  textAlign: 'center',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#ffffff',
                }}
              >
                {/* Job Image */}
                <img
                  src={job.image}
                  alt={job.jobRole}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '10px',
                  }}
                />
               
               <div className="job-info" style={{ fontSize:'13px',textAlign:'left'}}>
      <h3>{job.jobRole}</h3>
      <table>
        <tbody>
         

        <tr>
  <td><strong>COMPANY:</strong></td>
  <td>{job.companyName}</td>
</tr>

<tr>
  <td><strong>ELIGIBILITY:</strong></td>
  <td>{job.eligibility}</td>
</tr>

<tr>
  <td><strong>LOCATION:</strong></td>
  <td>{job.location}</td>
</tr>

<tr>
  <td><strong>SALARY:</strong></td>
  <td>{job.ctcRange}</td>
</tr>
<tr>
  <td><strong>WORK-MODE:</strong></td>
  <td>{job.workMode}</td>
</tr>

        </tbody>
      </table>
    </div>
                <button
                  style={{
                    marginTop: '10px',
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: '#ffffff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                 
                  onClick={() => handleApply(job)}
>
                  Apply Now
                </button>
              </div>
            ))}
          </div>
         
          <Advertisment />
         


        </div>

      </div>






      <style>
        {`
            .show-the-content {
              display: block;
            }
              filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    gap: '10px',
  },
  dropdown: {
    padding: '10px',
    width: '30%',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '14px',
    fontFamily: 'Poppins', 
  },
  container: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    padding: '16px',
    width: '100%',
  },

  card: {
    fontFamily: "Poppins",
    width: "300px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    backgroundColor: "#fff",
    position: "relative",
    cursor: "pointer",
    textAlign: "left",
  },
  cardContent: {
    padding: "16px",
    textAlign: "left",
  },
  button: {
    padding: "10px 20px",
    fontSize: "14px",
    color: "#fff",
    backgroundColor: "orange",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: "100%",
    marginTop: "16px",

            .dont-show-content {
              display: none;
            }
 job-info {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

h3 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table td {
  padding: 12px;
  border: 1px solid #ddd;
  vertical-align: middle;
}

table td:first-child {
  font-weight: bold;
  width: 40%;  /* Adjusted width for better spacing */
  background-color: #f7f7f7; /* Light background for the labels */
  padding-right: 20px; /* Add space between label and value */
}

table td:last-child {
  text-align: left;
}

table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternating row colors for better readability */
}

table td,
table th {
  padding: 12px;
}




            h1, h2, h3, h4, h5, h6, p {
              font-family: urbanist, serif;
            }

            .reCreateTabs ul {
              display: flex;
              justify-content: center;
              list-style: none;
              padding: 0;
              margin: 0;
              flex-wrap: wrap;
            }

            .reCreateTabs ul li {
              display: inline-block;
              padding: 10px 20px;
              font-size: 16px;
              background-color: black;
              border-radius: 10px;
              color: white;
              margin: 5px;
              cursor: pointer;
              text-align: center;
            }

            .reCreateTabs ul li:hover {
              color: orange;
              background-color: gray;
            }

            /* Responsive Styles */
            @media (max-width: 768px) {
              .reCreateTabs ul li {
                font-size: 14px;
                padding: 8px 15px;
              }
            }

            @media (max-width: 480px) {
              .reCreateTabs ul li {
                font-size: 12px;
                padding: 6px 10px;
              }

              .reCreateTabs ul {
                flex-direction: column;
                // align-items: center;
              }
            }

             @media (max-width: 600px) {
    .reCreateTabs ul {
      flex-direction: column; /* Stack tabs vertically on small screens */
      padding: 0; /* Remove any extra padding */
    }

    .reCreateTabs ul li {
      font-size: 18px !important; /* Make the font smaller */
      padding: 16px 15px !important; /* Reduce padding to make the tab smaller */
    }
  }

ul {
  list-style-type: none; /* Removes bullet points */
  margin: 0; /* Removes any default margin */
  padding: 0; /* Removes any default padding */
}


              
        `}
      </style>

    </div>


  );
};

export default PCMobileApplications;



// 8888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888




// import React, { useEffect, useState, useRef } from 'react';


// import Slider from 'react-slick';
// import softwareEngineerImage from '../img/aaaservices/fdev.webp';
// import dataScientistImage from '../img/aaaservices/bdev.jpeg';

// import fullStackDeveloperImage from '../img/aaaservices/fdev.webp';
// import Advertisment from './Advertisment';
// import { WhoweAre } from './WhoweAre'; // Adjust the path accordingly
// import LeftAdd from'./LeftAdd';


// const PCMobileApplications = () => {
//   const [toggle, setToggle] = useState(1);
//   const [navbarHeight, setNavbarHeight] = useState(2); // Default navbar height
//   const [tabVR, setTabVR] = useState(2);
//   const [tabPages, setTabPages] = useState(1); // For new tab group
//   const [mainTab, setMainTab] = useState(2);
//   function updateTabsVR(num) {
//     setTabVR(num);
//   }



//   const updateToggle = (id) => {
//     setActiveButton(id);  // Set the clicked button as active
//     setToggle(id);         // Set the toggle state
//   };
//   const updateMainTabs = (tabIndex) => {
//     setMainTab(tabIndex);
//   };

//   const [isDropdownOpen, setDropdownOpen] = useState(false);
//   const [isDropdownOpen1, setDropdownOpen1] = useState(false);
//   const [isDropdownOpen2, setDropdownOpen2] = useState(false);
//   const [isDropdownOpen3, setDropdownOpen3] = useState(false);
//   const [isDropdownOpen4, setDropdownOpen4] = useState(false);
//   const [isDropdownOpen5, setDropdownOpen5] = useState(false);
//   const [isDropdownOpen6, setDropdownOpen6] = useState(false);
//   const [isDropdownOpen7, setDropdownOpen7] = useState(false);
//   const [isDropdownOpen8, setDropdownOpen8] = useState(false);
//   const [isDropdownOpen9, setDropdownOpen9] = useState(false);
//   const [isDropdownOpen10, setDropdownOpen10] = useState(false);
//   const [activeButton, setActiveButton] = useState(null);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const dummyData = [
//     "Work from office(24782)",
//     "Remote(829)",
//     "Hybrid(259)",

//   ];
//   const dummyData1 = [
//     "0 years",
//     "1 year",
//     "2 years",
//     "3 years",
//     "4 years",
//     "5 years",
//     "6 years",
//     "8 years",
//     "10 years",
//   ];


//   const dummyData2 = [
//     "Production, Manufacturing & Engineering(77)",

//     "Research & Development(13)",

//     " Construction & Site Engineering(12)",

//     "Project & Program Management(4)",

//     " Engineering - Software & QA(3)",


//   ];
//   const dummyData3 = [
//     "0-3 Lakhs(19)",


//     "3-6 Lakhs(76)",


//     " 6-10 Lakhs(76)",


//     "10-15 Lakhs(35)",


//     " 15-25 Lakhs(17)",



//   ];
//   const dummyData4 = [
//     "Indian MNC(3)",

//     "Corporate(21)",


//     "Foreign MNC(9)",



//     "Others(1)",

//   ];
//   const dummyData6 = [
//     "Any Postgraduate(4392)",


//     "Post Graduation Not Required(422)",


//     "Any Graduate(238)",


//     "B.Tech/B.E.(167)",
//     "M.Tech(351)",


//   ];
//   const dummyData5 = [
//     "Software Development(3219)",

//     "Quality Assurance and Testing(590)",

//     "DBA / Data warehousing(239)",

//     "Engineering(193)",


//   ];

//   const dummyData7 = [
//     "Company Jobs(374)",

//     "Consultant Jobs(48)",



//   ];

//   const dummyData8 = [
//     "IT Services & Consulting(227)",


//     "Recruitment / Staffing(50)",


//     "Education / Training(14)",



//     "Industrial Equipment / Machinery(14)",



//   ];
//   const dummyData9 = [
//     "Atria Convergence Technologies (ACT)(1)",

//     "Pega(4)",


//   ];
//   const dummyData10 = [
//     "Hyderabad",

//     "chenai",


//   ];

//   const toggleDropdown = () => {
//     setDropdownOpen(!isDropdownOpen);
//   };
//   const toggleDropdown1 = () => {
//     setDropdownOpen1(!isDropdownOpen1);
//   };
//   const toggleDropdown2 = () => {
//     setDropdownOpen2(!isDropdownOpen2);
//   };
//   const toggleDropdown3 = () => {
//     setDropdownOpen3(!isDropdownOpen3);
//   };
//   const toggleDropdown4 = () => {
//     setDropdownOpen4(!isDropdownOpen4);
//   };
//   const toggleDropdown5 = () => {
//     setDropdownOpen5(!isDropdownOpen5);
//   };
//   const toggleDropdown6 = () => {
//     setDropdownOpen6(!isDropdownOpen6);
//   };
//   const toggleDropdown7 = () => {
//     setDropdownOpen7(!isDropdownOpen7);

//   };
//   const toggleDropdown8 = () => {
//     setDropdownOpen8(!isDropdownOpen8);

//   };
//   const toggleDropdown9 = () => {
//     setDropdownOpen9(!isDropdownOpen9);

//   };
//   const toggleDropdown10 = () => {
//     setDropdownOpen10(!isDropdownOpen10);

//   };
//   const handleCheckboxChange = (option) => {
//     setSelectedOptions(prevState =>
//       prevState.includes(option)
//         ? prevState.filter(item => item !== option) // Remove if already selected
//         : [...prevState, option] // Add if not selected
//     );
//   };





//   // Create a ref for the show-the-content container
//   const contentRef = useRef(null);

//   // Function to update sub-tabs and scroll smoothly
//   const handleSubTabClick = (tabNumber) => {
//     updateSubTabs(tabNumber); // Your existing function to update sub-tabs

//     // Scroll to the show-the-content container with an offset
//     setTimeout(() => {
//       if (contentRef.current) {
//         const offset = 180; // Adjust this value to control how much of the top you want to show
//         const elementPosition = contentRef.current.getBoundingClientRect().top + window.scrollY;
//         window.scrollTo({
//           top: elementPosition - offset,
//           behavior: 'smooth',
//         });
//       }
//     }, 0);
//   };




//   const updateSubTabs = (tabIndex) => {
//     setSubTab(tabIndex);
//   };

//   const tabContentRef = useRef(null);

//   const handleTabClick = (tabIndex) => {
//     // Scroll to the respective tab content section
//     if (tabContentRef.current) {
//       tabContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//     }
//   };




//   const [subTab, setSubTab] = useState(5);  // Default sub tab






//   const contentRefs = {
//     tab5: useRef(null),
//     tab2: useRef(null),
//     tab3: useRef(null),
//   };

//   const scrollToTop = (tabId) => {
//     if (contentRefs[tabId] && contentRefs[tabId].current) {
//       contentRefs[tabId].current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   useEffect(() => {
//     // Scroll to top on mount
//     window.scrollTo(0, 0);

//     // Calculate navbar height
//     const navbar = document.querySelector('.navbar');
//     if (navbar) {
//       setNavbarHeight(navbar.offsetHeight);
//     }
//   }, []);

//   const settingss = {
//     dots: false, // Show dots for navigation
//     infinite: true, // Enable infinite scrolling
//     arrows: false,
//     autoplay: true,
//     speed: 500, // Transition speed
//     slidesToShow: 3, // Number of items to show at once
//     slidesToScroll: 1, // Number of items to scroll per action
//     responsive: [
//       {
//         breakpoint: 768, // For screens smaller than 768px
//         settings: {
//           slidesToShow: 1, // Show 1 slide at a time
//           slidesToScroll: 1, // Scroll 1 slide at a time
//         },
//       },
//     ],
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           arrows: false,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           arrows: false,
//         },
//       },
//     ],
//   };
//   const [filters, setFilters] = useState({
//     jobRole: '',
//     location: '',
//     salary: '',
//     workMode:'',
//   });

//   const jobData = [
//     {
//       jobRole: "Software Engineer",
//       salary: "₹12,00,000 - ₹18,00,000",
//       location: "Bengaluru, India",
//       workMode: "Hybrid",
//       image: fullStackDeveloperImage,// Replace with actual image path
//     },
//     {
//       jobRole: "Data Scientist",
//       salary: "₹8,00,000 - ₹15,00,000",
//       location: "Mumbai, India",
//       workMode: "Remote",
//       image: fullStackDeveloperImage, // Replace with actual image path
//     },
//   //  {
//   //     jobRole: "Full Stack Developer",
//   //     salary: "₹15,00,000 - ₹25,00,000",
//   //     location: "Hyderabad, Pune, India",
//   //     workMode: "On-site",
//   //     image: fullStackDeveloperImage, // Use the imported image
//   //   },
//     // {
//     //   jobRole: "Full Stack Developer",
//     //   salary: "₹15,00,000 - ₹25,00,000",
//     //   location: "Hyderabad,pune, India",
//     //   workMode: "On-site",
//     //   image: fullStackDeveloperImage,  // Replace with actual image path
//     // },
//   ];

//   const handleFilterChange = (e) => {
//     const { name, value } = e.target;
//     setFilters((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   // Filtering the jobs based on selected filters
//   const filteredJobs = jobData.filter((job) => {
//     return (
//       (filters.jobRole === '' || job.jobRole === filters.jobRole) &&
//       (filters.location === '' || job.location === filters.location) &&
//       (filters.salary === '' || job.salary === filters.salary)&&
//       (filters.workMode === '' || job.workMode === filters.workMode)
      
//     );
//   });
                                                             

//   return (
//     <div className='jobrole' style={{ height: '850px', width: '100%', marginTop: '120px',maxWidth:'1200px' }}>

//       <div>




//       </div>

//       <div style={{}}>










//         <div
//           className="icon-slider-wrapper iconddd"
//           style={{
//             position: 'sticky',
//             top: 102,

//             zIndex: 997,
//             height: '82px',
//             // width: '105%',
//             maxWidth: '896px',
//             margin: '0 auto',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             //  padding: '16 39px', 


//           }}
//         >
//           <Slider {...settings}>

//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Work mode
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>




//             {/* Virtual Robotics */}
//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown1}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Experience
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen1 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData1.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown2}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Department
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen2 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData2.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown3}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Salary
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen3 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData3.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown4}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Company Types
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen4 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData4.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown5}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Role category
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen5 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData5.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown6}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                Education
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen6 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData6.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>

//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown7}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                Posted by
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen7 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData7.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown8}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//              industary
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen8 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData8.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown9}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                Top company
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen9 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData9.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown10}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) { // Only apply hover effect if not active
//                     e.target.style.color = 'white'; // Change text color to white on hover
//                     e.target.style.backgroundColor = 'black'; // Change background to black on hover
//                     e.target.style.transform = 'translateY(-5px)'; // Lift the button on hover
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) { // Only reset hover effect if not active
//                     e.target.style.color = 'white'; // Reset text color
//                     e.target.style.backgroundColor = 'blue'; // Reset background color
//                     e.target.style.transform = 'translateY(0)'; // Reset button position
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white', // Text color
//                   fontSize: "20px",
//                   fontWeight: "800",
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Location
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen10 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData10.map((item, index) => (
//                       <li key={index} style={{ padding: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center' }}>
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>
//           </Slider>

//           <div style={{ display: 'flex', gap: '20px', }}>
//       {/* Filter Section (Left Side) */}
//       <div style={{ display: 'flex', marginTop: '30px' }}>
//       {/* Filter Section (Left Side) */}
//       <div
//         style={{
//           width: '250px',
//           height:'481px',
//           padding: '20px',
//           border: '1px solid #ccc',
//           borderRadius: '10px',
//           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//           backgroundColor: '#ffffff',
//           marginRight: '10px', // Adding margin between filter and job cards
//         }}
//       >
//         <h3>Filter Jobs</h3>

//         {/* Job Role Filter */}
//         <label htmlFor="jobRole">Job Role</label>
//         <select
//           id="jobRole"
//           name="jobRole"
//           onChange={handleFilterChange}
//           value={filters.jobRole}
//           style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//         >
//           <option value="">All</option>
//           <option value="Software Engineer">Software Engineer</option>
//           <option value="Data Scientist">Data Scientist</option>
//           <option value="Full Stack Developer">Full Stack Developer</option>
//         </select>

//         {/* Location Filter */}
//         <label htmlFor="location" style={{ marginTop: '20px' }}>
//           Location
//         </label>
//         <select
//           id="location"
//           name="location"
//           onChange={handleFilterChange}
//           value={filters.location}
//           style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//         >
//           <option value="">All</option>
//           <option value="Bengaluru, India">Bengaluru</option>
//           <option value="Mumbai, India">Mumbai</option>
//           <option value="Pune, India">Pune</option>
//         </select>
//         <label htmlFor="workMode" style={{ marginTop: '20px' }}>
//          workMode
//         </label>
//         <select
//           id="workMode"
//           name="workMode"
//           onChange={handleFilterChange}
//           value={filters.workMode}
//           style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//         >
//           <option value="">All</option>
//           <option value="Hybrid">Hybrid</option>
//           <option value="Remote">Remote</option>
//           <option value="On-site">On-site</option>
//         </select>


//         {/* Salary Filter */}
//         <label htmlFor="salary" style={{ marginTop: '20px' }}>
//           Salary Range
//         </label>
//         <select
//           id="salary"
//           name="salary"
//           onChange={handleFilterChange}
//           value={filters.salary}
//           style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//         >
//           <option value="">All</option>
//           <option value="₹12,00,000 - ₹18,00,000">₹12,00,000 - ₹18,00,000</option>
//           <option value="₹8,00,000 - ₹15,00,000">₹8,00,000 - ₹15,00,000</option>
//           <option value="₹15,00,000 - ₹25,00,000">₹15,00,000 - ₹25,00,000</option>
//         </select>
//       </div>

//       {/* Job Cards Section (Right Side) */}
//       <div
//         style={{
//           display: 'flex',
//           // flexWrap: 'wrap',
//           gap: '20px',
//           width: 'calc(100% - 270px)', // Adjust width to account for filter box + margin
//         }}
//       >
//         {filteredJobs.map((job, index) => (
//           <div
//             key={index}
//             style={{
//               border: '1px solid #ccc',
//               borderRadius: '10px',
//               padding: '20px',
//               width: '280px',
//               // height:'414px',
//               textAlign: 'center',
//               boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//               backgroundColor: '#ffffff',
//             }}
//           >
//             {/* Job Image */}
//             <img
//               src={job.image}
//               alt={job.jobRole}
//               style={{
//                 width: '100%',
//                 height: '200px',
//                 objectFit: 'cover',
//                 borderRadius: '10px',
//               }}
//             />
//             <h3>{job.jobRole}</h3>
//             <p>
//               <strong>Salary:</strong> {job.salary}
//             </p>
//             <p>
//               <strong>Location:</strong> {job.location}
//             </p>
//             <p>
//               <strong>workMode:</strong> {job.workMode}
//             </p>

//             <button
//               style={{
//                 marginTop: '10px',
//                 padding: '10px 20px',
//                 backgroundColor: '#007bff',
//                 color: '#ffffff',
//                 border: 'none',
//                 borderRadius: '5px',
//                 cursor: 'pointer',
//               }}
//               onClick={() => alert(`Applying for ${job.jobRole}`)}
//             >
//               Apply Now
//             </button>
//           </div>
//         ))}
//     </div>
//     </div>
   
   
           
//           </div>
//           <LeftAdd />
          
         


//         </div>

//       </div>






//       <style>
//         {`
//             .show-the-content {
//               display: block;
//             }
//               filtersContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     marginBottom: '20px',
//     gap: '10px',
//   },
//   dropdown: {
//     padding: '10px',
//     width: '30%',
//     borderRadius: '4px',
//     border: '1px solid #ddd',
//     fontSize: '14px',
//     fontFamily: 'Poppins', 
//   },
//   container: {
//     display: 'flex',
//     gap: '16px',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     padding: '16px',
//     width: '100%',
//   },
//   card: {
//     fontFamily: "Poppins",
//     width: "300px",
//     border: "1px solid #ddd",
//     borderRadius: "8px",
//     boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
//     overflow: "hidden",
//     backgroundColor: "#fff",
//     position: "relative",
//     cursor: "pointer",
//     textAlign: "left",
//   },
//   cardContent: {
//     padding: "16px",
//     textAlign: "left",
//   },
//   button: {
//     padding: "10px 20px",
//     fontSize: "14px",
//     color: "#fff",
//     backgroundColor: "orange",
//     border: "none",
//     borderRadius: "4px",
//     cursor: "pointer",
//     width: "100%",
//     marginTop: "16px",

//             .dont-show-content {
//               display: none;
//             }

//             h1, h2, h3, h4, h5, h6, p {
//               font-family: urbanist, serif;
//             }

//             .reCreateTabs ul {
//               display: flex;
//               justify-content: center;
//               list-style: none;
//               padding: 0;
//               margin: 0;
//               flex-wrap: wrap;
//             }

//             .reCreateTabs ul li {
//               display: inline-block;
//               padding: 10px 20px;
//               font-size: 16px;
//               background-color: black;
//               border-radius: 10px;
//               color: white;
//               margin: 5px;
//               cursor: pointer;
//               text-align: center;
//             }

//             .reCreateTabs ul li:hover {
//               color: orange;
//               background-color: gray;
//             }

//             /* Responsive Styles */
//             @media (max-width: 768px) {
//               .reCreateTabs ul li {
//                 font-size: 14px;
//                 padding: 8px 15px;
//               }
//             }

//             @media (max-width: 480px) {
//               .reCreateTabs ul li {
//                 font-size: 12px;
//                 padding: 6px 10px;
//               }

//               .reCreateTabs ul {
//                 flex-direction: column;
//                 // align-items: center;
//               }
//             }

//              @media (max-width: 600px) {
//     .reCreateTabs ul {
//       flex-direction: column; /* Stack tabs vertically on small screens */
//       padding: 0; /* Remove any extra padding */
//     }

//     .reCreateTabs ul li {
//       font-size: 18px !important; /* Make the font smaller */
//       padding: 16px 15px !important; /* Reduce padding to make the tab smaller */
//     }
//   }

// ul {
//   list-style-type: none; /* Removes bullet points */
//   margin: 0; /* Removes any default margin */
//   padding: 0; /* Removes any default padding */
// }


              
//         `}
//       </style>

//     </div>


//   );
// };

// export default PCMobileApplications;







// import React, { useEffect, useState, useRef } from 'react';


// import Slider from 'react-slick';
// import softwareEngineerImage from '../img/aaaservices/fdev.webp';
// import dataScientistImage from '../img/aaaservices/bdev.jpeg';

// import fullStackDeveloperImage from '../img/aaaservices/fdev.webp';
// import Advertisment from './Advertisment';
// import { WhoweAre } from './WhoweAre'; // Adjust the path accordingly
// import LeftAdd from'./LeftAdd';


// const PCMobileApplications = () => {
//   const [toggle, setToggle] = useState(1);
//   const [navbarHeight, setNavbarHeight] = useState(2); // Default navbar height
//   const [tabVR, setTabVR] = useState(2);
//   const [tabPages, setTabPages] = useState(1); // For new tab group
//   const [mainTab, setMainTab] = useState(2);
//   function updateTabsVR(num) {
//     setTabVR(num);
//   }

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleApplyClick = () => {
//     setIsModalOpen(true); // Open the modal
//   };

//   const closeModal = () => {
//     setIsModalOpen(false); // Close the modal
//   };
//   const [formType, setFormType] = useState(''); // 'register' or 'login'
  
//   const [isRegisterOpen, setRegisterOpen] = useState(false);
//   const [isLoginOpen, setLoginOpen] = useState(false);

//   const openRegisterForm = () => {
//     setRegisterOpen(true);
//     setLoginOpen(false);
//   };

//   const openLoginForm = () => {
//     setLoginOpen(true);
//     setRegisterOpen(false);
//   };

//   const closeForms = () => {
//     setRegisterOpen(false);
//     setLoginOpen(false);
//   };
//   const updateToggle = (id) => {
//     setActiveButton(id);  // Set the clicked button as active
//     setToggle(id);         // Set the toggle state
//   };
//   const updateMainTabs = (tabIndex) => {
//     setMainTab(tabIndex);
//   };

//   const [isDropdownOpen, setDropdownOpen] = useState(false);
//   const [isDropdownOpen1, setDropdownOpen1] = useState(false);
//   const [isDropdownOpen2, setDropdownOpen2] = useState(false);
//   const [isDropdownOpen3, setDropdownOpen3] = useState(false);
//   const [isDropdownOpen4, setDropdownOpen4] = useState(false);
//   const [isDropdownOpen5, setDropdownOpen5] = useState(false);
//   const [isDropdownOpen6, setDropdownOpen6] = useState(false);
//   const [isDropdownOpen7, setDropdownOpen7] = useState(false);
//   const [isDropdownOpen8, setDropdownOpen8] = useState(false);
//   const [isDropdownOpen9, setDropdownOpen9] = useState(false);
//   const [isDropdownOpen10, setDropdownOpen10] = useState(false);
//   const [activeButton, setActiveButton] = useState(null);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const dummyData = [
//     "Work from office(24782)",
//     "Remote(829)",
//     "Hybrid(259)",

//   ];
//   const dummyData1 = [
//     "0 years",
//     "1 year",
//     "2 years",
//     "3 years",
//     "4 years",
//     "5 years",
//     "6 years",
//     "8 years",
//     "10 years",
//   ];


//   const dummyData2 = [
//     "Production, Manufacturing & Engineering(77)",

//     "Research & Development(13)",

//     " Construction & Site Engineering(12)",

//     "Project & Program Management(4)",

//     " Engineering - Software & QA(3)",


//   ];
//   const dummyData3 = [
//     "0-3 Lakhs(19)",


//     "3-6 Lakhs(76)",


//     " 6-10 Lakhs(76)",


//     "10-15 Lakhs(35)",


//     " 15-25 Lakhs(17)",



//   ];
//   const dummyData4 = [
//     "Indian MNC(3)",

//     "Corporate(21)",


//     "Foreign MNC(9)",



//     "Others(1)",

//   ];
//   const dummyData6 = [
//     "Any Postgraduate(4392)",


//     "Post Graduation Not Required(422)",


//     "Any Graduate(238)",


//     "B.Tech/B.E.(167)",
//     "M.Tech(351)",


//   ];
//   const dummyData5 = [
//     "Software Development(3219)",

//     "Quality Assurance and Testing(590)",

//     "DBA / Data warehousing(239)",

//     "Engineering(193)",


//   ];

//   const dummyData7 = [
//     "Company Jobs(374)",

//     "Consultant Jobs(48)",



//   ];

//   const dummyData8 = [
//     "IT Services & Consulting(227)",


//     "Recruitment / Staffing(50)",


//     "Education / Training(14)",



//     "Industrial Equipment / Machinery(14)",



//   ];
//   const dummyData9 = [
//     "Atria Convergence Technologies (ACT)(1)",

//     "Pega(4)",


//   ];
//   const dummyData10 = [
//     "Hyderabad",

//     "chenai",


//   ];

//   const toggleDropdown = () => {
//     setDropdownOpen(!isDropdownOpen);
//   };
//   const toggleDropdown1 = () => {
//     setDropdownOpen1(!isDropdownOpen1);
//   };
//   const toggleDropdown2 = () => {
//     setDropdownOpen2(!isDropdownOpen2);
//   };
//   const toggleDropdown3 = () => {
//     setDropdownOpen3(!isDropdownOpen3);
//   };
//   const toggleDropdown4 = () => {
//     setDropdownOpen4(!isDropdownOpen4);
//   };
//   const toggleDropdown5 = () => {
//     setDropdownOpen5(!isDropdownOpen5);
//   };
//   const toggleDropdown6 = () => {
//     setDropdownOpen6(!isDropdownOpen6);
//   };
//   const toggleDropdown7 = () => {
//     setDropdownOpen7(!isDropdownOpen7);

//   };
//   const toggleDropdown8 = () => {
//     setDropdownOpen8(!isDropdownOpen8);

//   };
//   const toggleDropdown9 = () => {
//     setDropdownOpen9(!isDropdownOpen9);

//   };
//   const toggleDropdown10 = () => {
//     setDropdownOpen10(!isDropdownOpen10);

//   };
//   const handleCheckboxChange = (option) => {
//     setSelectedOptions(prevState =>
//       prevState.includes(option)
//         ? prevState.filter(item => item !== option) // Remove if already selected
//         : [...prevState, option] // Add if not selected
//     );
//   };





//   // Create a ref for the show-the-content container
//   const contentRef = useRef(null);

//   // Function to update sub-tabs and scroll smoothly
//   const handleSubTabClick = (tabNumber) => {
//     updateSubTabs(tabNumber); // Your existing function to update sub-tabs

//     // Scroll to the show-the-content container with an offset
//     setTimeout(() => {
//       if (contentRef.current) {
//         const offset = 180; // Adjust this value to control how much of the top you want to show
//         const elementPosition = contentRef.current.getBoundingClientRect().top + window.scrollY;
//         window.scrollTo({
//           top: elementPosition - offset,
//           behavior: 'smooth',
//         });
//       }
//     }, 0);
//   };




//   const updateSubTabs = (tabIndex) => {
//     setSubTab(tabIndex);
//   };

//   const tabContentRef = useRef(null);

//   const handleTabClick = (tabIndex) => {
//     // Scroll to the respective tab content section
//     if (tabContentRef.current) {
//       tabContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//     }
//   };




//   const [subTab, setSubTab] = useState(5);  // Default sub tab






//   const contentRefs = {
//     tab5: useRef(null),
//     tab2: useRef(null),
//     tab3: useRef(null),
//   };

//   const scrollToTop = (tabId) => {
//     if (contentRefs[tabId] && contentRefs[tabId].current) {
//       contentRefs[tabId].current.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   useEffect(() => {
//     // Scroll to top on mount
//     window.scrollTo(0, 0);

//     // Calculate navbar height
//     const navbar = document.querySelector('.navbar');
//     if (navbar) {
//       setNavbarHeight(navbar.offsetHeight);
//     }
//   }, []);

//   const settingss = {
//     dots: false, // Show dots for navigation
//     infinite: true, // Enable infinite scrolling
//     arrows: false,
//     autoplay: true,
//     speed: 500, // Transition speed
//     slidesToShow: 3, // Number of items to show at once
//     slidesToScroll: 1, // Number of items to scroll per action
//     responsive: [
//       {
//         breakpoint: 768, // For screens smaller than 768px
//         settings: {
//           slidesToShow: 1, // Show 1 slide at a time
//           slidesToScroll: 1, // Scroll 1 slide at a time
//         },
//       },
//     ],
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           arrows: false,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           arrows: false,
//         },
//       },
//     ],
//   };
//   const [filters, setFilters] = useState({
//     jobRole: '',
//     location: '',
//     salary: '',
//     workMode:'',
//   });

//   const jobData = [
//     {
//       jobRole: "Software Engineer",
//       salary: "₹12,00,000 - ₹18,00,000",
//       location: "Bengaluru, India",
//       workMode: "Hybrid",
//       jobDescription: 'Develop software applications, solve technical problems.',
//       Skills:'React.js,node.js,Basics html,css,java script, java,',
//       image: dataScientistImage,// Replace with actual image path
//     },
//     {
//       jobRole: "Data Scientist",
//       salary: "₹8,00,000 - ₹15,00,000",
//       location: "Mumbai, India",
//       workMode: "Remote",
//       jobDescription: 'Develop software applications, solve technical problems.',
//       Skills:'React.js,node.js,Basics html,css,java script, java,',
      
//       image: fullStackDeveloperImage, // Replace with actual image path
//     },
//   //  {
//   //     jobRole: "Full Stack Developer",
//   //     salary: "₹15,00,000 - ₹25,00,000",
//   //     location: "Hyderabad, Pune, India",
//   //     workMode: "On-site",
//   //     image: fullStackDeveloperImage, // Use the imported image
//   //   },
//     // {
//     //   jobRole: "Full Stack Developer",
//     //   salary: "₹15,00,000 - ₹25,00,000",
//     //   location: "Hyderabad,pune, India",
//     //   workMode: "On-site",
//     //   image: fullStackDeveloperImage,  // Replace with actual image path
//     // },
//   ];

//   const handleFilterChange = (e) => {
//     const { name, value } = e.target;
//     setFilters((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   // Filtering the jobs based on selected filters
//   const filteredJobs = jobData.filter((job) => {
//     return (
//       (filters.jobRole === '' || job.jobRole === filters.jobRole) &&
//       (filters.location === '' || job.location === filters.location) &&
//       (filters.salary === '' || job.salary === filters.salary)&&
//       (filters.workMode === '' || job.workMode === filters.workMode)
      
//     );
//   });
                                                             

//   return (
//     <div className='jobrole' style={{ height: '850px', width: '100%', marginTop: '120px',maxWidth:'1200px' }}>

//       <div>




//       </div>

//       <div style={{}}>










//         <div
//           className="icon-slider-wrapper iconddd"
//           style={{
//             position: 'sticky',
//             top: 102,

//             zIndex: 997,
//             height: '82px',
//             // width: '105%',
//             maxWidth: '896px',
//             margin: '0 auto',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             //  padding: '16 39px', 


//           }}
//         >
//           <Slider {...settings}>

//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Work mode
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>




//             {/* Virtual Robotics */}
//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown1}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Experience
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen1 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData1.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown2}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Department
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen2 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData2.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown3}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Salary
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen3 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData3.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown4}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Company Types
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen4 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData4.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown5}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Role category
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen5 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData5.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown6}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                Education
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen6 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData6.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>

//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown7}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                Posted by
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen7 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData7.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown8}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//              industary
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen8 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData8.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown9}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'black';
//                     e.target.style.transform = 'translateY(-5px)';
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) {
//                     e.target.style.color = 'white';
//                     e.target.style.backgroundColor = 'blue';
//                     e.target.style.transform = 'translateY(0)';
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white',
//                   fontSize: '20px',
//                   fontWeight: '800',
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                Top company
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen9 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                     maxHeight: '150px',  // Limit the height to two items (adjust according to item height)
//                     overflowY: 'auto',    // Add scroll bar when items exceed maxHeight
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData9.map((item, index) => (
//                       <li
//                         key={index}
//                         style={{
//                           padding: '10px',
//                           borderBottom: '1px solid #ccc',
//                           display: 'flex',
//                           alignItems: 'center',
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter inform"
//                 onClick={toggleDropdown10}
//                 onMouseEnter={(e) => {
//                   if (activeButton !== 0) { // Only apply hover effect if not active
//                     e.target.style.color = 'white'; // Change text color to white on hover
//                     e.target.style.backgroundColor = 'black'; // Change background to black on hover
//                     e.target.style.transform = 'translateY(-5px)'; // Lift the button on hover
//                   }
//                 }}
//                 onMouseLeave={(e) => {
//                   if (activeButton !== 0) { // Only reset hover effect if not active
//                     e.target.style.color = 'white'; // Reset text color
//                     e.target.style.backgroundColor = 'blue'; // Reset background color
//                     e.target.style.transform = 'translateY(0)'; // Reset button position
//                   }
//                 }}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '20px',
//                   width: '280px',
//                   color: activeButton === 0 ? 'white' : 'white', // Text color
//                   fontSize: "20px",
//                   fontWeight: "800",
//                   padding: '10px 20px',
//                   borderRadius: '15px',
//                   backgroundColor: activeButton === 0 ? 'blue' : 'blue',
//                   transform: activeButton === 0 ? 'translateY(-5px)' : 'none',
//                   transition: 'color 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
//                 }}
//               >
//                 Location
//               </a>

//               {/* Dropdown */}
//               {isDropdownOpen10 && (
//                 <div
//                   style={{
//                     marginTop: '10px',
//                     padding: '20px',
//                     border: '1px solid #ccc',
//                     borderRadius: '8px',
//                     width: '250px',
//                     backgroundColor: '#f9f9f9',
//                   }}
//                 >
//                   <ul style={{ listStyle: 'none', padding: '0' }}>
//                     {dummyData10.map((item, index) => (
//                       <li key={index} style={{ padding: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center' }}>
//                         <input
//                           type="checkbox"
//                           checked={selectedOptions.includes(item)}
//                           onChange={() => handleCheckboxChange(item)}
//                           style={{ marginRight: '10px' }}
//                         />
//                         {item}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>
//           </Slider>

//           <div style={{ display: 'flex', gap: '20px', }}>
     
//       <div style={{ display: 'flex', marginTop: '30px',gap:'36px', }}>
      
//       <div
//   style={{
//     width: '250px',
//     height: '481px',
//     padding: '20px',
//     border: '1px solid #ccc',
//     borderRadius: '10px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     backgroundColor: '#ffffff',
//     marginRight: '10px', // Adding margin between filter and job cards
//     position: 'relative',
//   }}
// >
//   <h3>Filter Jobs</h3>

//   {/* Job Role Filter */}
//   <label htmlFor="jobRole">Job Role</label>
//   <select
//     id="jobRole"
//     name="jobRole"
//     onChange={handleFilterChange}
//     value={filters.jobRole}
//     style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//   >
//     <option value="">All</option>
//     <option value="Software Engineer">Software Engineer</option>
//     <option value="Data Scientist">Data Scientist</option>
//     <option value="Full Stack Developer">Full Stack Developer</option>
//   </select>

//   {/* Location Filter */}
//   <label htmlFor="location" style={{ marginTop: '20px' }}>
//     Location
//   </label>
//   <select
//     id="location"
//     name="location"
//     onChange={handleFilterChange}
//     value={filters.location}
//     style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//   >
//     <option value="">All</option>
//     <option value="Bengaluru, India">Bengaluru</option>
//     <option value="Mumbai, India">Mumbai</option>
//     <option value="Pune, India">Pune</option>
//   </select>

//   {/* Work Mode Filter */}
//   <label htmlFor="workMode" style={{ marginTop: '20px' }}>
//     Work Mode
//   </label>
//   <select
//     id="workMode"
//     name="workMode"
//     onChange={handleFilterChange}
//     value={filters.workMode}
//     style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//   >
//     <option value="">All</option>
//     <option value="Hybrid">Hybrid</option>
//     <option value="Remote">Remote</option>
//     <option value="On-site">On-site</option>
//   </select>

//   {/* Salary Filter */}
//   <label htmlFor="salary" style={{ marginTop: '20px' }}>
//     Salary Range
//   </label>
//   <select
//     id="salary"
//     name="salary"
//     onChange={handleFilterChange}
//     value={filters.salary}
//     style={{ width: '100%', padding: '8px', marginTop: '10px' }}
//   >
//     <option value="">All</option>
//     <option value="₹12,00,000 - ₹18,00,000">₹12,00,000 - ₹18,00,000</option>
//     <option value="₹8,00,000 - ₹15,00,000">₹8,00,000 - ₹15,00,000</option>
//     <option value="₹15,00,000 - ₹25,00,000">₹15,00,000 - ₹25,00,000</option>
//   </select>
// </div>

// {/* Job Cards Section (Right Side) */}
// <div
//   style={{
//     display: 'flex',
//     flexWrap: 'wrap',
//     gap: '36px',
//     width: 'calc(100% - 270px)', // Adjust width to account for filter box + margin
//     justifyContent: 'flex-start',
//   }}
// >
//   {filteredJobs.map((job, index) => (
//     <div
//       key={index}
//       style={{
//         border: '1px solid #ccc',
//         borderRadius: '10px',
//         padding: '20px',
//         width: '280px',
//         textAlign: 'center',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//         backgroundColor: '#ffffff',
//         transition: 'transform 0.3s ease', // Smooth transition on hover
//       }}
//     >
//       {/* Job Image */}
//       <img
//         src={job.image}
//         alt={job.jobRole}
//         style={{
//           width: '100%',
//           height: '200px',
//           objectFit: 'cover',
//           borderRadius: '10px',
//         }}
//       />
//       <h3>{job.jobRole}</h3>
//       <p>
//         <strong>Salary:</strong> {job.salary}
//       </p>
//       <p>
//         <strong>Location:</strong> {job.location}
//       </p>
//       <p>
//         <strong>Work Mode:</strong> {job.workMode}
//       </p>

//       <button
//         style={{
//           marginTop: '10px',
//           padding: '10px 20px',
//           backgroundColor: '#007bff',
//           color: '#ffffff',
//           border: 'none',
//           borderRadius: '5px',
//           cursor: 'pointer',
//         }}
//         onClick={handleApplyClick}
//       >
//         Apply Now
//       </button>
//       {isModalOpen && (
//         <div
//         style={{
//           position: 'fixed',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//           backgroundColor: 'rgba(0, 0, 0, 0.5)',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           zIndex: 1000,
//         }}
//       >
//         <div className='logginbutton'
//           style={{
//             position: 'relative',
//             backgroundColor: '#ffffff',
//             padding: '20px',
//             borderRadius: '10px',
//             // width: '906px',
//             height: '410px',
//             textAlign: 'center',
//           }}
//         >
//           {/* Top-right buttons */}
//           <div
//             style={{
//               position: 'absolute',
//               top: '10px',
//               right: '10px',
//               display: 'flex',
//               gap: '20px',
//             }}
//           >
//             <button
//               style={{
//                 padding: '10px 15px',
//                 backgroundColor: 'blue',
//                 color: '#ffffff',
//                 border: 'none',
//                 borderRadius: '5px',
//                 cursor: 'pointer',
//               }}
//               onClick={openRegisterForm}
//             >
//               Register to Apply
//             </button>
//             <button
//               style={{
//                 padding: '10px 15px',
//                 backgroundColor: '#dc3545',
//                 color: '#ffffff',
//                 border: 'none',
//                 borderRadius: '5px',
//                 cursor: 'pointer',
//               }}
//               onClick={openLoginForm}
//             >
//               Login to Apply
//             </button>
//           </div>

//           <h2 style={{ marginTop: '72px' }}>JOBS DETAILS</h2>

//           <p>
//             <strong>Role:</strong> {job.jobRole}
//           </p>
//           <p>
//             <strong>Description:</strong> {job.jobDescription}
//           </p>
//           <p>
//             <strong>CTC:</strong> {job.salary}
//           </p>
//           <p>
//             <strong>Location:</strong> {job.location}
//           </p>
//           <p>
//             <strong>Work Mode:</strong> {job.workMode}
//           </p>
//           <p>
//             <strong>Skills:</strong> {job.Skills}
//           </p>

//           <button
//             style={{
//               marginTop: '45px',
//               padding: '10px 20px',
//               backgroundColor: 'blue',
//               color: '#ffffff',
//               border: 'none',
//               borderRadius: '5px',
//               cursor: 'pointer',
//             }}
//             onClick={() => {
//               alert(`Enquiring about the position: ${job.jobRole}`);
//               closeModal();
//             }}
//           >
//             Enquire Now
//           </button>
//           <button
//             style={{
//               marginTop: '10px',
//               padding: '10px 20px',
//               backgroundColor: '#dc3545',
//               color: '#ffffff',
//               border: 'none',
//               borderRadius: '5px',
//               cursor: 'pointer',
//               marginLeft: '10px',
//             }}
//             onClick={closeModal}
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     )}

//     {/* Register Form */}
//     {isRegisterOpen && (
//       <div
//         style={{
//           position: 'fixed',
//           top: 0,
//           right: 82,
//           width: '300px',
//           height: '60%',
//           borderRadius:'30px',
//           marginTop:'130px',
//           backgroundColor: '#ffffff',
//           boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)',
//           padding: '20px',
//           overflowY: 'auto',
//           zIndex: 1001,
//         }}
//       >
//         <h3>Register</h3>
//         <form>
//           <div style={{ marginBottom: '15px' }}>
//             <label>Full Name:</label>
//             <input
//               type="text"
//               style={{
//                 width: '100%',
//                 padding: '10px',
//                 marginTop: '5px',
//                 borderRadius: '5px',
//                 border: '1px solid #ccc',
//               }}
//             />
//           </div>
//           <div style={{ marginBottom: '15px' }}>
//             <label>Email:</label>
//             <input
//               type="email"
//               style={{
//                 width: '100%',
//                 padding: '10px',
//                 marginTop: '5px',
//                 borderRadius: '5px',
//                 border: '1px solid #ccc',
//               }}
//             />
//           </div>
//           <button
//             type="submit"
//             style={{
//               padding: '10px 15px',
//               backgroundColor: 'blue',
//               color: '#ffffff',
//               border: 'none',
//               borderRadius: '5px',
//               cursor: 'pointer',
//               marginTop: '10px',
//             }}
//           >
//             Submit
//           </button>
//         </form>
//         <button
//           style={{
//             marginTop: '20px',
//             padding: '10px 20px',
//             backgroundColor: '#dc3545',
//             color: '#ffffff',
//             border: 'none',
//             borderRadius: '5px',
//             cursor: 'pointer',
//           }}
//           onClick={closeForms}
//         >
//           Close
//         </button>
//       </div>
//     )}

//     {/* Login Form */}
//     {isLoginOpen && (
//       <div
//         style={{
//           position: 'fixed',
//           top: 0,
//           right: 82,
//           width: '300px',
//           height: '60%',
//           marginTop:'130px',
//           borderRadius:'30px',
//           backgroundColor: '#ffffff',
//           boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)',
//           padding: '20px',
//           overflowY: 'auto',
//           zIndex: 1001,
//         }}
//       >
//         <h3>Login</h3>
//         <form>
//           <div style={{ marginBottom: '15px' }}>
//             <label>Email:</label>
//             <input
//               type="email"
//               style={{
//                 width: '100%',
//                 padding: '10px',
//                 marginTop: '5px',
//                 borderRadius: '5px',
//                 border: '1px solid #ccc',
//               }}
//             />
//           </div>
//           <div style={{ marginBottom: '15px' }}>
//             <label>Password:</label>
//             <input
//               type="password"
//               style={{
//                 width: '100%',
//                 padding: '10px',
//                 marginTop: '5px',
//                 borderRadius: '5px',
//                 border: '1px solid #ccc',
//               }}
//             />
//           </div>
//           <button
//             type="submit"
//             style={{
//               padding: '10px 15px',
//               backgroundColor: 'blue',
//               color: '#ffffff',
//               border: 'none',
//               borderRadius: '5px',
//               cursor: 'pointer',
//               marginTop: '10px',
//             }}
//           >
//             Login
//           </button>
//         </form>
//         <button
//           style={{
//             marginTop: '20px',
//             padding: '10px 20px',
//             backgroundColor: '#dc3545',
//             color: '#ffffff',
//             border: 'none',
//             borderRadius: '5px',
//             cursor: 'pointer',
//           }}
//           onClick={closeForms}
//         >
//           Close
//         </button>
//       </div>
//     )}

  
//           </div>
//         ))}
//     </div>
//     </div>
   
   
           
//           </div>
//           <LeftAdd />
          
         


//         </div>

//       </div>






//       <style>
        
//         {`
//     @media (max-width: 768px) {
//       .job-filter {
//         width: 100%;
//         margin-right: 0;
//       }
//       .job-card {
//         width: 100%;
//         margin-bottom: 20px;
//       }
//       .job-cards-section {
//         width: 100%;
//       }
//       .job-cards-section .job-card {
//         width: calc(50% - 20px); // Two cards per row on tablets
//       }
//     }

//     @media (max-width: 480px) {
//       .job-filter {
//         width: 100%;
//         margin-right: 0;
//       }
//       .job-card {
//         width: 100%;
//         margin-bottom: 20px;
//       }
//       .job-cards-section {
//         width: 100%;
//       }
//       .job-cards-section .job-card {
//         width: 100%; // Single card per row on mobile
//       }
//     }
  
//             .show-the-content {
//               display: block;
//             }
//               filtersContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     marginBottom: '20px',
//     gap: '10px',
//   },
//   dropdown: {
//     padding: '10px',
//     width: '30%',
//     borderRadius: '4px',
//     border: '1px solid #ddd',
//     fontSize: '14px',
//     fontFamily: 'Poppins', 
//   },
//   container: {
//     display: 'flex',
//     gap: '16px',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     padding: '16px',
//     width: '100%',
//   },
//   card: {
//     fontFamily: "Poppins",
//     width: "300px",
//     border: "1px solid #ddd",
//     borderRadius: "8px",
//     boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
//     overflow: "hidden",
//     backgroundColor: "#fff",
//     position: "relative",
//     cursor: "pointer",
//     textAlign: "left",
//   },
//   cardContent: {
//     padding: "16px",
//     textAlign: "left",
//   },
//   button: {
//     padding: "10px 20px",
//     fontSize: "14px",
//     color: "#fff",
//     backgroundColor: "orange",
//     border: "none",
//     borderRadius: "4px",
//     cursor: "pointer",
//     width: "100%",
//     marginTop: "16px",

//             .dont-show-content {
//               display: none;
//             }

//             h1, h2, h3, h4, h5, h6, p {
//               font-family: urbanist, serif;
//             }

//             .reCreateTabs ul {
//               display: flex;
//               justify-content: center;
//               list-style: none;
//               padding: 0;
//               margin: 0;
//               flex-wrap: wrap;
//             }

//             .reCreateTabs ul li {
//               display: inline-block;
//               padding: 10px 20px;
//               font-size: 16px;
//               background-color: black;
//               border-radius: 10px;
//               color: white;
//               margin: 5px;
//               cursor: pointer;
//               text-align: center;
//             }

//             .reCreateTabs ul li:hover {
//               color: orange;
//               background-color: gray;
//             }

//             /* Responsive Styles */
//             @media (max-width: 768px) {
//               .reCreateTabs ul li {
//                 font-size: 14px;
//                 padding: 8px 15px;
//               }
//             }

//             @media (max-width: 480px) {
//               .reCreateTabs ul li {
//                 font-size: 12px;
//                 padding: 6px 10px;
//               }

//               .reCreateTabs ul {
//                 flex-direction: column;
//                 // align-items: center;
//               }
//             }
//               @media (max-width: 480px) {
//               .logginbutton {
//                 font-size: 12px;
//                 padding: 6px 10px;
//               }

//               .reCreateTabs ul {
//                 flex-direction: column;
//                 // align-items: center;
//               }
//             }


//              @media (max-width: 600px) {
//     .reCreateTabs ul {
//       flex-direction: column; /* Stack tabs vertically on small screens */
//       padding: 0; /* Remove any extra padding */
//     }

//     .reCreateTabs ul li {
//       font-size: 18px !important; /* Make the font smaller */
//       padding: 16px 15px !important; /* Reduce padding to make the tab smaller */
//     }
//   }

// ul {
//   list-style-type: none; /* Removes bullet points */
//   margin: 0; /* Removes any default margin */
//   padding: 0; /* Removes any default padding */
// }


              
//         `}
//       </style>

//     </div>


//   );
// };

// export default PCMobileApplications;











