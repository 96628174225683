
import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

const PlacementFAQs = () => {
  // State to store the learning path steps
  const [pathSteps, setPathSteps] = useState([
    { title: ' What is a T&F design certificate?', description: 'A Tool & Fixture Design Certificate is a course that teaches students to design specialized tools and fixtures used in manufacturing and production processes. These tools ensure precision, efficiency, and consistency in assembling or machining parts.', isOpen: false },

    { title: ' What does a T&F design engineer do?', description: 'A T&F Design Engineer designs tools and fixtures used in manufacturing to ensure precision and efficiency. They optimize production processes and collaborate with manufacturing teams. Their work improves quality and reduces costs.', isOpen: false },

    { title: ' What are the benefits of enrolling in T&F design course?', description: 'Enrolling in a T&F Design course offers benefits such as gaining expertise in designing tools and fixtures for efficient manufacturing, improving problem-solving skills in production processes, and enhancing career opportunities in industries like automotive, aerospace, and engineering.', isOpen: false },

    { title: ' What is the demand for T&F design experts?', description: 'The demand for T&F Design experts is high, particularly in industries like manufacturing, automotive, aerospace, and robotics. Their expertise in creating efficient tools and fixtures is crucial for improving production quality and reducing costs, making them essential for companies aiming to stay competitive.', isOpen: false },

  ]);

  const toggleDescription = (index) => {
    const updatedSteps = [...pathSteps];
    updatedSteps[index].isOpen = !updatedSteps[index].isOpen;
    setPathSteps(updatedSteps);
  };

  return (
    <div className="container py-5">
      {/* Course Overview Heading */}
      <div className="text-center mb-5">
        <h4 className="fw-bold" >Placements FAQs</h4>
        <p style={{marginRight:'54px'}}>
          
        </p>
      </div>
      
      {/* Learning Path Container */}
      <div className="mx-auto p-4 bg-white shadow-lg rounded" style={{ maxWidth: '800px' }}>
        <h2 className="text-center"></h2>
        
        {/* Learning Path Steps */}
        <ul className="list-unstyled mt-4">
          {pathSteps.map((step, index) => (
            <li key={index} className="mb-3">
              <div
                onClick={() => toggleDescription(index)}
                className="d-flex justify-content-between align-items-center border-bottom pb-2"
                style={{ cursor: 'pointer' }}
              >
                <h5 className="mb-0">{step.title}</h5>
                {/* Conditional arrow icon */}
                <span style={{ fontSize: '20px' }}>
                  {step.isOpen ? '▲' : '▼'}
                </span>
              </div>
              
              {step.isOpen && (
                <p className="text-muted mt-2">{step.description}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlacementFAQs;
