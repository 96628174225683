// import React, { useState } from 'react';
// import robotics from "../img/robotics hands working.mp4";

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         phone: "",
//     });

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const handleChange = (e) => {
//         const { id, value } = e.target;
//         setFormData((prevState) => ({
//             ...prevState,
//             [id]: value,
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const apiBody = {
//             firstName: formData.name,
//             emailId: formData.email,
//             contactNumber: formData.phone,
//         };

//         try {
//             const response = await fetch(
//                 "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
//                 {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                     body: JSON.stringify(apiBody),
//                 }
//             );

//             if (response.ok) {
//                 alert("Your details have been submitted successfully!");
//             } else {
//                 alert("There was an issue submitting your details.");
//             }
//         } catch (error) {
//             alert("Something went wrong. Please try again later.");
//         }
//     };

//     return (
//         <div className="container my-5">
//             <div className="row justify-content-center">
//                 <div className="col-lg-8">
//                     {/* <div className="p-4 bg-white shadow rounded mb-4">
//                         <h2 style={{ fontWeight: "bold" }}>
//                             Tools & Fixture Designs{" "}
//                             <span style={{ color: "#ffa500", fontWeight: "800", fontFamily: 'Roboto' }}>
//                                 Course Details
//                             </span>
//                         </h2>
//                         <p style={{ fontFamily: 'Roboto' }}>
//                             This course is tailored for aspiring engineers and tech enthusiasts eager to explore robotics in a virtual environment. It covers robotic simulation, and control systems with hands-on projects to build practical skills. Ideal for students and professionals aiming to excel in robotics and automation industries.
//                         </p>
//                     </div> */}


//                 </div>

//                 <div className="col-lg-4">
//                     {/* <div>
//                         <video
//                             src={robotics}
//                             controls
//                             style={{
//                                 width: '100%',
//                                 borderRadius: '10px',
//                                 boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
//                                 marginBottom: '20px',
//                             }}
//                         />
//                     </div> */}

//                     {/* <div
//                         style={{
//                             width: '100%',
//                             minHeight: '500px',
//                             maxHeight: '90vh',
//                             overflowY: 'auto',
//                             padding: '30px',
//                             display: 'flex',
//                             flexDirection: 'column',
//                             position: 'relative',
//                             boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
//                             borderRadius: '10px',
//                             backgroundColor: '#fff',
//                         }}
//                       >
//                         <h2 style={{ textAlign: 'center', width: '100%', fontFamily: 'Roboto' }}>Enquiry Form</h2>
//                         <p style={{ marginBottom: '30px', fontFamily: 'Roboto' }}>Fill in your details and we’ll get back to you shortly.</p>

//                         <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
//                             <input
//                                 type="text"
//                                 name="name"
//                                 placeholder="Name"
//                                 value={formData.name}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <input
//                                 type="email"
//                                 name="email"
//                                 placeholder="Email"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <input
//                                 type="tel"
//                                 name="phone"
//                                 placeholder="Phone Number"
//                                 value={formData.phone}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <input
//                                 type="date"
//                                 name="date"
//                                 value={formData.date}
//                                 onChange={handleChange}
//                                 required
//                                 style={{
//                                     width: '100%',
//                                     padding: '10px',
//                                     fontSize: '16px',
//                                     borderRadius: '5px',
//                                     border: '1px solid #ccc',
//                                 }}
//                             />
//                             <div style={{ marginBottom: '10px' }}>
//                                 <select
//                                     name="gender"
//                                     value={formData.gender}
//                                     onChange={handleChange}
//                                     style={{
//                                         width: '100%',
//                                         padding: '10px',
//                                         borderRadius: '5px',
//                                         border: '1px solid #ccc',
//                                     }}
//                                     required
//                                 >
//                                     <option value="">Select Gender</option>
//                                     <option value="male">Male</option>
//                                     <option value="female">Female</option>
//                                     <option value="other">Other</option>
//                                 </select>
//                             </div>

//                             <div style={{ marginBottom: '10px' }}>
//                                 <textarea
//                                     placeholder="Your Message"
//                                     rows="4"
//                                     style={{
//                                         width: '100%',
//                                         padding: '10px',
//                                         borderRadius: '5px',
//                                         border: '1px solid #ccc',
//                                     }}
//                                 ></textarea>
//                             </div>

//                             <button
//                                 type="submit"
//                                 style={{
//                                     width: '100%',
//                                     padding: '12px',
//                                     backgroundColor: '#495057',
//                                     color: '#fff',
//                                     border: 'none',
//                                     borderRadius: '5px',
//                                     fontSize: '16px',
//                                     cursor: 'pointer',
//                                     textAlign: 'center',
//                                 }}
//                             >
//                                 Submit
//                             </button>
//                         </form>
//                     </div> */}
//                 </div>
//                 <h2 className="fw-bold mb-4" style={{ color: '#000', fontFamily: 'Roboto', width: '100%', textAlign: 'center' }}>
//                 WHAT YOU WILL  <span style={{color:'rgb(251 145 0)'}}>GAIN</span> 
//                 </h2>

//                 <div className="list-tab" style={{ width: '100%', padding: 0 }}>
//                     {[{
//                         id: 1, title: "In-Depth Knowledge of 6-Axis Robotics:", content: "Gain expertise in designing, testing, and optimizing robotic systems using advanced virtual simulation tools.."
//                     }, {
//                         id: 2, title: "Practical Skills:", content: "Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming."
//                     }, {
//                         id: 3, title: "Career Advancement:", content: "In BIW fixture design, achieving a 3D finish typically refers to ensuring that the fixture not only meets functional requirements (alignment, clamping, support, etc.) but also supports the aesthetic or dimensional accuracy needed for the car's final body panel surfaces."
//                     }, {
//                         id: 4, title: "Hands-On Training:", content: "Gain practical knowledge through guided exercises, real-time simulations, and troubleshooting sessions."
//                     },
//                     ].map(({ id, title, content }) => (
//                         <div key={id} className="mb-3" style={{ width: '100%' }}>
//                             <div
//                                 className="p-3 bg-light rounded cursor-pointer"
//                                 onClick={() => updateToggle(id)}
//                                 style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}
//                             >
//                                 <span className="fw-bold" style={{ fontFamily: 'Roboto',fontSize:'20px' }}>{toggle === id ? '-' : '+'} {title}</span>
//                             </div>
//                             {toggle === id && (
//                                 <div className="mt-2 px-4">
//                                     <p style={{ fontFamily: 'Roboto' }}>{content}</p>
//                                 </div>
//                             )}
//                         </div>
//                     ))}
//                 </div>

//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;




// import React, { useState } from "react";

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [nestedToggle, setNestedToggle] = useState({});

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const updateNestedToggle = (id) => {
//         setNestedToggle((prev) => ({
//             ...prev,
//             [id]: !prev[id],
//         }));
//     };

//     const dummyContent = [
//         {
//             id: 1,
//             title: "Introduction to Virtual Simulation ",
//              nestedContent: [
//                 { id: "1-1", text: "•	What is Virtual Simulation? ." },
//                 { id: "1-2", text: "•	Why Virtual Simulation Matters? ." },
//             ],
//         },
//         {
//             id: 2,
//             title: "Practical Skills:",
//             content: "Hands-on experience with real-time simulations, guided exercises, and troubleshooting to build a solid foundation in robotic programming.",
//             nestedContent: [
//                 { id: "2-1", text: "Understanding control systems through simulations." },
//                 { id: "2-2", text: "Troubleshooting techniques in robotic programming." },
//             ],
//         },
//     ];

//     return (
//         <div className="container my-5">
//             <h2
//                 className="fw-bold mb-4"
//                 style={{ color: "#000", fontFamily: "Roboto", width: "100%", textAlign: "center" }}
//             >
//                 WHAT YOU WILL <span style={{ color: "rgb(251 145 0)" }}>GAIN</span>
//             </h2>

//             <div className="list-tab" style={{ width: "100%", padding: 0 }}>
//                 {dummyContent.map(({ id, title, content, nestedContent }) => (
//                     <div key={id} className="mb-3" style={{ width: "100%" }}>
//                         <div
//                             className="p-3 bg-light rounded cursor-pointer d-flex justify-content-between align-items-center"
//                             onClick={() => updateToggle(id)}
//                             style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", width: "100%" }}
//                         >
//                             <span className="fw-bold" style={{ fontFamily: "Roboto", fontSize: "20px" }}>
//                                 {title}
//                             </span>
//                             <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>
//                         </div>
//                         {toggle === id && (
//                             <div className="mt-2 px-4">
//                                 <p style={{ fontFamily: "Roboto" }}>{content}</p>
//                                 {nestedContent.map(({ id: nestedId, text }) => (
//                                     <div key={nestedId} className="my-2">
//                                         <div
//                                             className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
//                                             onClick={() => updateNestedToggle(nestedId)}
//                                             style={{ border: "1px solid #ddd" }}
//                                         >
//                                             <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
//                                             <span style={{ fontSize: "18px" }}>
//                                                 {nestedToggle[nestedId] ? "-" : "+"}
//                                             </span>
//                                         </div>
//                                         {nestedToggle[nestedId] && (
//                                             <div className="mt-2 px-3">
//                                                 <p style={{ fontFamily: "Roboto", color: "#555" }}>
//                                                     This is additional dummy text for nested content.
//                                                 </p>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;






// import React, { useState } from "react";

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [nestedToggle, setNestedToggle] = useState({});

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const updateNestedToggle = (nestedId) => {
//         setNestedToggle((prevState) => ({
//             ...prevState,
//             [nestedId]: !prevState[nestedId],
//         }));
//     };

//     const listContent = [
//         {
//             id: 0,
//             title: " INTRODUCTION TO MECANICAL ENGINEERING",
//             content: null,
//             nestedContent: [],
//         },
//         {
//             id: 1,
//             title: "Introduction to Virtual Simulation",
//             content: null,
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	What is Virtual Simulation? ",
//                     dummyText: ["*	Definition and principles.", "*	Difference between virtual simulation, augmented reality, and virtual reality.", ],
//                 },
//                 {
//                     id: 2,
//                     text: "•	Why Virtual Simulation Matters? ",
//                     dummyText: ["*	Importance in training, design, testing, and decision-making..",],
//                 },
//                 // {
//                 //     id: 3,
//                 //     text: "Nested Item 3",
//                 //     dummyText: ["Virtual modeling techniques.", "Collision detection basics.", "Simulation optimization."],
//                 // },
//             ],
//         },
//         {
//             id: 2,
//             title: "Types of Virtual Simulation ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	 Physical Simulation:",
//                     dummyText: ["*	Simulation of physical systems using virtual models.", "*	Examples: Mechanical stress testing, aerodynamic simulations..",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 Process Simulation:",
//                     dummyText: ["*	Virtual replication of industrial or business processes.", "*	Examples: Supply chain, manufacturing workflows.", ],
//                 },
//                 {
//                     id: 3,
//                     text: "•	 Interactive Simulation:",
//                     dummyText: ["*	User-interactive systems for testing and training.", "	*	Examples: Flight simulators, surgical training systems", ],
//                 },
//                 {
//                     id: 4,
//                     text: "•	  Immersive Simulation:",
//                     dummyText: ["*		High-level immersion through VR/AR tools.", "	*		Examples: Virtual reality training environments", ],
//                 },
//             ],
//         },
//         {
//             id: 3,
//             title: "Benefits of Virtual Simulation ",
           
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	Cost Efficiency:",
//                     dummyText: ["*	Reduction of physical prototyping and errors", ],
//                 },
//                 {
//                     id: 2,
//                     text: "•	Risk Reduction:",
//                     dummyText: ["*	Safe testing of hazardous or complex scenarios",],
//                 },
//                 {
//                     id: 3,
//                     text: "•	Improved Learning and Training:",
//                     dummyText: ["*	Enhanced engagement and practical understanding.",],
//                 },
//                 {
//                     id: 4,
//                     text: "•	Faster Iteration Cycles:",
//                     dummyText: ["*	Quick testing and refinement of designs and processes.",],
//                 },
//                 {
//                     id: 5,
//                     text: "•	Global Accessibility:",
//                     dummyText: ["*	Remote collaboration and access to simulated environments.",],
//                 },
//             ],
//         },
//         {
//             id: 4,
//             title: "Applications of Virtual Simulation  ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•	Industrial Applications:",
//                     dummyText: ["*		Manufacturing: Assembly line optimization, workflow simulation.", "* 	 Robotics: Robot motion planning and task simulations.",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 Medical Applications:",
//                     dummyText: ["*		Surgery training and diagnostics using virtual environments.",],
//                 },
//                 {
//                     id: 3,
//                     text: "•	Education and Training:",
//                     dummyText: ["*		Simulated labs for engineering and science education.", ],
//                 },
//                 {
//                     id: 4,
//                     text: "•	 Military and Defense:",
//                     dummyText: ["*			Combat and tactical training using immersive simulations..", ],
//                 },
//                 {
//                     id: 5,
//                     text: "•	Transportation:",
//                     dummyText: ["*		Flight and vehicle simulators for operator training..", ],
//                 },
//                 {
//                     id: 6,
//                     text: "•	 Entertainment:",
//                     dummyText: ["*				Gaming and immersive storytelling..", ],
//                 },
//             ],
//         },
//         {
//             id: 5,
//             title: " Virtual Simulation Software Tools  ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Overview of Common Tools:",
//                     dummyText: ["*			Process Simulate ,Delmia,Visual Components,Robostudio,Robcaed ",],
//                 },
//                 {
//                     id: 2,
//                     text: "•		Hands-On Overview:",
//                     dummyText: ["*			Quick exploration of a selected software.","*			Demonstration of basic simulation workflow.",],
//                 },
//             ]
//         },
//         {
//             id: 6,
//             title: " Practical Exercise and Discussions  ",
            
//             nestedContent: [
//                 {
//                     id: 1,
//                     text: "•		Case Studies:",
//                     dummyText: ["*			Examples of successful virtual simulation implementations",],
//                 },
//                 {
//                     id: 2,
//                     text: "•	 	Group Discussion:",
//                     dummyText: ["*			Identifying areas for virtual simulation in participants' fields.",],
//                 },
//                 {
//                     id: 3,
//                     text: "•		Q&A and Wrap-Up:",
//                     dummyText: ["*		Clarifying concepts and discussing future learning opportunities.", ],
//                 },
//             ]
//         }
        
//     ];

//     return (
//         <div className="container my-5">
//             {/* <h2
//                 className="fw-bold mb-4"
//                 style={{ color: "#000", fontFamily: "Roboto", width: "100%", textAlign: "center" }}
//             >
//                 PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
//             </h2> */}

//             <div className="list-tab" style={{ width: "100%", padding: 0 }}>
//                 {listContent.map(({ id, title, content, nestedContent }) => (
//                     <div key={id} className="mb-3" style={{ width: "100%" }}>
//                         <div
//                             className="p-3 bg-light rounded cursor-pointer d-flex justify-content-between align-items-center"
//                             onClick={() => updateToggle(id)}
//                             style={{
//                                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                                 width: "100%",
//                             }}
//                         >
//                             <span className="fw-bold" style={{ fontFamily: "Roboto", fontSize: "20px" }}>
//                                 {title}
//                             </span>
//                             <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>
//                         </div>
//                         {toggle === id && (
//                             <div className="mt-2 px-4">
//                                 {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
//                                 {nestedContent.map(({ id: nestedId, text, dummyText }) => (
//                                     <div key={nestedId} className="my-2">
//                                         <div
//                                             className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
//                                             onClick={() => updateNestedToggle(nestedId)}
//                                             style={{ border: "1px solid #ddd" }}
//                                         >
//                                             <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
//                                             <span style={{ fontSize: "18px" }}>
//                                                 {nestedToggle[nestedId] ? "-" : "+"}
//                                             </span>
//                                         </div>
//                                         {nestedToggle[nestedId] && (
//                                             <div className="mt-2 px-3">
//                                                 <ul style={{ fontFamily: "Roboto", color: "#555" }}>
//                                                     {dummyText.map((item, index) => (
//                                                         <li key={index}>{item}</li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;





import React, { useState, useEffect } from 'react';

const LearningPathTFD = () => {
    const [toggle, setToggle] = useState(null);
    const [nestedToggle, setNestedToggle] = useState({});
    const [data, setData] = useState([]);

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const updateNestedToggle = (nestedId) => {
        setNestedToggle((prevState) => ({
            ...prevState,
            [nestedId]: !prevState[nestedId],
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('your-api-url');
                const data = await response.json();
                setData(data); // Update the state with fetched data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const listContent = [
        {
            id: 1,
            title: " ABOUT VIRTUAL SIMULATION",
            content: null,
            nestedContent: [],
        },
        {
                        id: 1,
                        title: "Introduction to Virtual Simulation",
                        content: null,
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	What is Virtual Simulation? ",
                                dummyText: ["*	Definition and principles.", "*	Difference between virtual simulation, augmented reality, and virtual reality.", ],
                            },
                            {
                                id: 2,
                                text: "•	Why Virtual Simulation Matters? ",
                                dummyText: ["*	Importance in training, design, testing, and decision-making..",],
                            },
                            // {
                            //     id: 3,
                            //     text: "Nested Item 3",
                            //     dummyText: ["Virtual modeling techniques.", "Collision detection basics.", "Simulation optimization."],
                            // },
                        ],
                    },
                    {
                        id: 2,
                        title: "Types of Virtual Simulation ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	 Physical Simulation:",
                                dummyText: ["*	Simulation of physical systems using virtual models.", "*	Examples: Mechanical stress testing, aerodynamic simulations..",],
                            },
                            {
                                id: 2,
                                text: "•	 Process Simulation:",
                                dummyText: ["*	Virtual replication of industrial or business processes.", "*	Examples: Supply chain, manufacturing workflows.", ],
                            },
                            {
                                id: 3,
                                text: "•	 Interactive Simulation:",
                                dummyText: ["*	User-interactive systems for testing and training.", "	*	Examples: Flight simulators, surgical training systems", ],
                            },
                            {
                                id: 4,
                                text: "•	  Immersive Simulation:",
                                dummyText: ["*		High-level immersion through VR/AR tools.", "	*		Examples: Virtual reality training environments", ],
                            },
                        ],
                    },
                    {
                        id: 3,
                        title: "Benefits of Virtual Simulation ",
                       
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	Cost Efficiency:",
                                dummyText: ["*	Reduction of physical prototyping and errors", ],
                            },
                            {
                                id: 2,
                                text: "•	Risk Reduction:",
                                dummyText: ["*	Safe testing of hazardous or complex scenarios",],
                            },
                            {
                                id: 3,
                                text: "•	Improved Learning and Training:",
                                dummyText: ["*	Enhanced engagement and practical understanding.",],
                            },
                            {
                                id: 4,
                                text: "•	Faster Iteration Cycles:",
                                dummyText: ["*	Quick testing and refinement of designs and processes.",],
                            },
                            {
                                id: 5,
                                text: "•	Global Accessibility:",
                                dummyText: ["*	Remote collaboration and access to simulated environments.",],
                            },
                        ],
                    },
                    {
                        id: 4,
                        title: "Applications of Virtual Simulation  ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•	Industrial Applications:",
                                dummyText: ["*		Manufacturing: Assembly line optimization, workflow simulation.", "* 	 Robotics: Robot motion planning and task simulations.",],
                            },
                            {
                                id: 2,
                                text: "•	 Medical Applications:",
                                dummyText: ["*		Surgery training and diagnostics using virtual environments.",],
                            },
                            {
                                id: 3,
                                text: "•	Education and Training:",
                                dummyText: ["*		Simulated labs for engineering and science education.", ],
                            },
                            {
                                id: 4,
                                text: "•	 Military and Defense:",
                                dummyText: ["*			Combat and tactical training using immersive simulations..", ],
                            },
                            {
                                id: 5,
                                text: "•	Transportation:",
                                dummyText: ["*		Flight and vehicle simulators for operator training..", ],
                            },
                            {
                                id: 6,
                                text: "•	 Entertainment:",
                                dummyText: ["*				Gaming and immersive storytelling..", ],
                            },
                        ],
                    },
                    {
                        id: 5,
                        title: " Virtual Simulation Software Tools  ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Overview of Common Tools:",
                                dummyText: ["*			Process Simulate ,Delmia,Visual Components,Robostudio,Robcaed ",],
                            },
                            {
                                id: 2,
                                text: "•		Hands-On Overview:",
                                dummyText: ["*			Quick exploration of a selected software.","*			Demonstration of basic simulation workflow.",],
                            },
                        ]
                    },
                    {
                        id: 6,
                        title: " Practical Exercise and Discussions  ",
                        
                        nestedContent: [
                            {
                                id: 1,
                                text: "•		Case Studies:",
                                dummyText: ["*			Examples of successful virtual simulation implementations",],
                            },
                            {
                                id: 2,
                                text: "•	 	Group Discussion:",
                                dummyText: ["*			Identifying areas for virtual simulation in participants' fields.",],
                            },
                            {
                                id: 3,
                                text: "•		Q&A and Wrap-Up:",
                                dummyText: ["*		Clarifying concepts and discussing future learning opportunities.", ],
                            },
                        ]
                    }
                    
                ];

    return (
        <div className="container my-5">
            {/* <h2
                className="fw-bold mb-4"
                style={{
                    color: "#000",
                    fontFamily: "Roboto",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "-50px",
                }}
            >
                PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
            </h2> */}

            <div className="list-tab" style={{ width: "100%", padding: 0 }}>
                {listContent.map(({ id, title, content, nestedContent }, index) => (
                    <div key={id} className="mb-3" style={{ width: "100%" }}>
                        <div
                            className={`p-3 rounded cursor-pointer d-flex justify-content-between align-items-center ${
                                index === 0 ? "bg-white" : "bg-light"
                            }`}
                            onClick={() => index !== 0 && updateToggle(id)}
                            style={{
                                boxShadow: index === 0 ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "100%",
                                cursor: index === 0 ? "default" : "pointer",
                            }}
                        >
                            <span
                                className={`fw-bold`}
                                style={{
                                    fontFamily: "Roboto",
                                    fontSize: index === 0 ? "19px" : "20px",
                                    color: index === 0 ? "#0072FF" : undefined,
                                }}
                            >
                                {title}
                            </span>
                            {index !== 0 && (
                                <span style={{ fontSize: "20px" }}>
                                    {toggle === id ? "-" : "+"}
                                </span>
                            )}
                        </div>
                        {/* Add line below first item */}
                        {/* {index === 0 && (
                            <div
                                style={{
                                    height: "1px",
                                    backgroundColor: "orange",
                                    width: "20%",
                                     margin: "-13px",
                                     marginLeft: '50px',
                                }}
                            ></div>
                        )} */}
                        {toggle === id && index !== 0 && (
                            <div className="mt-2 px-4">
                                {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
                                {nestedContent.map(({ id: nestedId, text, dummyText }) => (
                                    <div key={nestedId} className="my-2">
                                        <div
                                            className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
                                            onClick={() => updateNestedToggle(nestedId)}
                                            style={{ border: "1px solid #ddd" }}
                                        >
                                            <span
                                                style={{
                                                    fontFamily: "Roboto",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                {text}
                                            </span>
                                            <span style={{ fontSize: "18px" }}>
                                                {nestedToggle[nestedId] ? "-" : "+"}
                                            </span>
                                        </div>
                                        {nestedToggle[nestedId] && (
                                            <div className="mt-2 px-3">
                                                <ul
                                                    style={{
                                                        fontFamily: "Roboto",
                                                        color: "#555",
                                                    }}
                                                >
                                                    {dummyText.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LearningPathTFD;

