



import React, { useState } from "react";
import robotics from "../img/robotics hands working.mp4";

const VirtualwebibarKeyfetures = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        date: "",
        gender: "",
        message: "",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiBody = {
            firstName: formData.name,
            emailId: formData.email,
            contactNumber: formData.phone,
            date: formData.date,
            gender: formData.gender,
            message: formData.message,
        };

        try {
            const response = await fetch(
                "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(apiBody),
                }
            );

            if (response.ok) {
                alert("Your details have been submitted successfully!");
            } else {
                alert("There was an issue submitting your details.");
            }
        } catch (error) {
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="container mt-5">
            <div className="row d-flex align-items-start keyfrtures-data" style={{ marginTop: "00px" }}>
                {/* Content Section */}
                <div className="col-md-7">
                    <div className="p-4 bg-white shadow rounded mb-4">
                        <h2 style={{ fontWeight: "bold" ,fontSize:'25px',}}>
                        <span style={{color:'blue'}}>  </span> Tool & Fixture  <span style={{color:'orange'}}> Design  </span>{" "}
                            <span style={{ color: "#ffa500", fontWeight: "500" }}>

                            </span>
                        </h2>
                        <p style={{ textAlign:'justify' }}>
                        Adoption of 6-axis industrial robots is revolutionizing sectors such as manufacturing and logistics, offering precise automation for tasks like welding and assembly. Virtual simulation allows for cost-effective testing and optimization without physical hardware, reducing risks and costs. As the demand for automation grows, and with advancements in AI and IoT, mastering robotic programming and simulation is crucial for staying ahead in this dynamic field.
                        </p>
                    </div>

                   
                    {/* <h3 style={{ fontWeight: "bold", color: "rgb(34 64 153)",textAlign:'left', marginTop:'40px' }}>Key Features</h3>
                    <div className="row">
                        {[
                            "Receive a prestigious certificate endorsed by IIA and I-Robotics, validating your expertise in tools and virtual robotics.",
                            "Exclusive hackathons and Ask Me Anything sessions by IIA.",
                            "Live sessions on the latest AI trends, such as generative AI, prompt engineering, explainable AI, and more.",
                            "Capstone from 3 domains and 14+ Data Analytics Projects with Industry datasets.",
                            "Master Classes delivered by Purdue faculty and IBM experts.",
                            "We assist you to get noticed by top hiring companies.",
                        ].map((feature, index) => (
                            <div className="col-12 mb-3" key={index}>
                                <div
                                    className="key-feature-box p-3 bg-white shadow rounded"
                                    style={{ border: "1px solid grey" }}
                                >
                                    <li style={{ listStyleType: "none", borderRadius: "50px" }}>
                                        {feature}
                                    </li>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>

                {/* Form and Video Section */}
                <div className="col-md-5">
                    {/* Video */}
                    <div style={{ marginBottom: "20px" }}>
                        <video
                            src={robotics}
                            controls
                            style={{
                                width: "96%",
                                borderRadius: "10px",
                                height:'265px',
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                            }}
                        />
                    </div>
                    {/* Form */}
                    {/* <div
                        style={{
                            width: "100%",
                            minHeight: "500px",
                            maxHeight: "90vh",
                            overflowY: "auto",
                            padding: "30px",
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                            borderRadius: "10px",
                            backgroundColor: "#fff",
                            marginTop:'100px',
                        }}
                     >
                        <h2 style={{ textAlign: "center" }}>Enquiry Form</h2>
                        <form
                            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
                            onSubmit={handleSubmit}
                        >
                            <input
                                type="text"
                                id="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    fontSize: "16px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                }}
                            />
                            <input
                                type="email"
                                id="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    fontSize: "16px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                }}
                            />
                            <input
                                type="tel"
                                id="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    fontSize: "16px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                }}
                            />
                            <input
                                type="date"
                                id="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    fontSize: "16px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                }}
                            />
                            <select
                                id="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                required
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                }}
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                            <textarea
                                id="message"
                                placeholder="Your Message"
                                rows="4"
                                value={formData.message}
                                onChange={handleChange}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    height:'50px'
                                }}
                            />
                            <button
                                type="submit"
                                style={{
                                    padding: "12px",
                                    backgroundColor: "#495057",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                Submit
                            </button>
                        </form>
                    </div> */}
                  
                </div>
                {/* <div className="container-fluid px-0">
                    <h3 style={{ fontWeight: "bold", color: "#000", textAlign: "center", marginTop: '67px' }}>
                    WHY YOU SHOULD  <span style={{ color: 'orange' }}>ATTEND</span>
                    </h3>
                    <div className="col-12 mx-0" style={{ textAlign: "justify",fontSize:'18px' ,}}>
                        {[
                            "Master Cutting-Edge Technology: Learn to design, test, and optimize robotic systems using            advance dsimulation tools like RobotStudio, V-REP, and RoboDK.",
                            "Real-World Applications: Discover how 6-axis robots are used in industries such as manufacturing, healthcare, and aerospace for tasks like welding, painting, and assembly.",
                            "Cost-Effective Learning: Experience virtual simulation without the need for costly physical hardware, allowing you to practice and refine your skills in a risk-free environment.",
                            "Industry Insights: Stay ahead with knowledge on AI, IoT, and digital twin integration in robotic systems.",
                           
                        ].map((feature, index) => (
                            <div className="col-12 mb-3" key={index}>
                                <div
                                    className="key-feature-box p-3 bg-white shadow rounded"
                                    style={{ border: "1px solid grey" }}
                                >
                                    <li style={{ listStyleType: "none",  }}>
                                        {feature}
                                    </li>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
        
    );
};

export default VirtualwebibarKeyfetures;
