import React, { useState } from "react";

const LearningPathTFD = () => {
    const [toggle, setToggle] = useState(null);
    const [nestedToggle, setNestedToggle] = useState({});

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const updateNestedToggle = (nestedId) => {
        setNestedToggle((prevState) => ({
            ...prevState,
            [nestedId]: !prevState[nestedId],
        }));
    };

    const listContent = [
        {
            id: 1,
            title: "Career Foundation Skills",
            content: null,
            nestedContent: [
                {
                    id: 1,
                    text: "•		Resume Writing and Cover Letter Drafting  ",
                    dummyText: ["*			Drafting and revising resumes.", "*			Peer reviews and instructor feedback..",],
                },
                {
                    id: 2,
                    text: "•		Hands-On Activities:  ",
                    dummyText: ["*	Importance in training, design, testing, and decision-making..",],
                },
                {
                    id: 3,
                    text: "•	Interview Preparation Techniques ",
                    dummyText: ["*	Interview Formats: Behavioural, technical, panel and case-study interviews.", "*	Strategies: Using STAR techniques for impactful responses.",],
                },
                {
                    id: 4,
                    text: "	•	Mock Interviews:  ",
                    dummyText: ["*		Individual practice with feedback.", "*		Group role-playing for varied scenarios.",],
                },
                {
                    id: 5,
                    text: "		•	Career Goal Setting and Personal Branding ",
                    dummyText: ["*				Goal Setting: Creating SMART (Specific, Measurable, Achievable, Relevant, Time-Bound) career goals.", "*			Personal Branding: Developing an elevator pitch and leveraging social media platforms.",],
                },
                {
                    id: 6,
                    text: "		•	Practical Activity:   ",
                    dummyText: ["*			Building a personalized career roadmap.", "*			Sharing and refining personal branding statements..",],
                },
                {
                    id: 7,
                    text: "		•	Internship Search and Application Strategies   ",
                    dummyText: ["*			Internship Portals: Navigating platforms like LinkedIn, Internshala and Glassdoor.", "*			Effective Applications: Writing tailored emails and follow-ups.","*	Practical Exercise: Drafting internship applications and evaluating real listings.",],
	

                },
            ],
        },
        {
            id: 2,
            title: "Soft Skills Development ",

            nestedContent: [
                {
                    id: 1,
                    text: "•	 	Communication and Public Speaking ",
                    dummyText: ["*		Foundations: Verbal, non-verbal and active listening skills.", "*		Public Speaking: Structuring speeches and delivering impactful presentations","*o	Practical Activity: Individual and group presentations with constructive "],
                },
                {
                    id: 2,
                    text: "•		Teamwork and Collaboration ",
                    dummyText: ["*		Building Trust: Essentials of effective teamwork and collaboration harmony.", "*		Conflict Resolution: Tools for managing disagreements and fostering ","*	Case Studies and Role Play: Analyzing real-world team scenarios."],
                },
                {
                    id: 3,
                    text: "•	 	Time Management and Productivity ",
                    dummyText: ["*		Techniques: Eisenhower Matrix, Pomodoro and Kanban systems.", "	*		Productivity Tools: Trello, Asana and Notion for task management.","*	Practical Activity: Creating individual and group productivity plans."],
                },
                {
                    id: 4,
                    text: "•	 	Leadership Skills and Decision-Making ",
                    dummyText: ["*			Leadership Styles: Identifying and leveraging personal leadership ", "	*			Leadership Styles: Identifying and leveraging personal leadership approaches.","*	Simulations and Role Plays: Practice leadership in group decision-making exercises."],
                },
                {
                    id: 5,
                    text: "•	 		Emotional Intelligence (EI) and Workplace Etiquette",
                    dummyText: ["*				EI Foundations: Self-awareness, empathy and adaptability. ", "	*				Professional Etiquette: Navigating workplace communication and culture.",],
                },
            ],
        },
        // {
        //     id: 3,
        //     title: "Professional Certifications  ",

        //     nestedContent: [
        //         {
        //             id: 1,
        //             text: "•		Certification Insights ",
        //             dummyText: ["*		Overview of certifications like Google Career Certificates, Microsoft Office Specialist and language certifications.","*	Importance of certifications for career progression."],
        //         },
        //         {
        //             id: 2,
        //             text: "•		Cybersecurity Foundations ",
        //             dummyText: ["*		Introduction to foundational cybersecurity skills.","*	Overview of industry-relevant certifications (e.g., CompTIA Security+, CISSP)."],
        //         },
        //         {
        //             id: 3,
        //             text: "•		Certifications Practice Sessions :",
        //             dummyText: ["*		Simulated test environments for participants pursuing certifications.",],
        //         },
               
        //     ],
        // },
        {
            id: 4,
            title: "Digital Presence Skills   ",

            nestedContent: [
                {
                    id: 1,
                    text: "•		Building and Maintaining Professional Networks ",
                    dummyText: ["*			Networking Skills: Building and maintaining strong professional relationships", "* 	 o	Practical Activity: Drafting LinkedIn messages and email outreach plans.",],

                },
                {
                    id: 2,
                    text: "•		Optimizing LinkedIn Profiles ",
                    dummyText: ["*			Profile Essentials: Crafting compelling summaries, adding skills, and showcasing achievements.","*	Hands-On Exercise: Step-by-step profile creation and optimization."],
                },
                {
                    id: 3,
                    text: "•		Creating a Personal Portfolio",
                    dummyText: ["*			Portfolio Tools: Exploring platforms like Wix, Behance and GitHub.","*o	Practical Activity:Designing an initial portfolio template with sample projects. "],

                },
                {
                    id: 4,
                    text: "•	 	Engaging on Professional Platforms and Staying Updated ",
                    dummyText: ["*				Platform Exploration: Overview of GitHub, Medium and online ","*	Staying Updated: Setting up alerts and tracking trends using tools like Feedly and newsletters."],
                },
                
            ],
        },
        // {
        //     id: 5,
        //     title: " Capstone Project  ",
        //    content:"•	Participants will apply their skills to complete a comprehensive capstone project that integrates: ",
        //     nestedContent: [
        //         {
        //             id: 1,
        //             text: "•			Resume and LinkedIn Profile Building: Tailored for a specific job or internship application.",
        //             dummyText: [,],
        //         },
        //         {
        //             id: 2,
        //             text: "•			Mock Interviews and Presentations: Demonstrating communication and interview preparation skills.",
        //             dummyText: [,],
        //         },
        //         {
        //             id: 3,
        //             text: "•				Personal Portfolio: Showcasing projects, certifications and achievements.",
        //             dummyText: [,],
        //         },
        //         {
        //             id: 4,
        //             text: "•					Career Roadmap: Presenting a detailed career progression plan.",
        //             dummyText: [,],
        //         },
        //     ]
        // },
       
    ]
       

    

    return (
        <div className="container my-5">
            <h2
                className="fw-bold mb-4"
                style={{ color: "#000", fontFamily: "Roboto", width: "100%", textAlign: "center" }}
            >
                PROGRAM <span style={{ color: "rgb(251 145 0)" }}>OVERVIEW</span>
            </h2>

            <div className="list-tab" style={{ width: "100%", padding: 0 }}>
                {listContent.map(({ id, title, content, nestedContent }) => (
                    <div key={id} className="mb-3" style={{ width: "100%" }}>
                        <div
                            className="p-3 bg-light rounded cursor-pointer d-flex justify-content-between align-items-center"
                            onClick={() => updateToggle(id)}
                            style={{
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                width: "100%",
                            }}
                        >
                            <span className="fw-bold" style={{ fontFamily: "Roboto", fontSize: "20px" }}>
                                {title}
                            </span>
                            <span style={{ fontSize: "20px" }}>{toggle === id ? "-" : "+"}</span>
                        </div>
                        {toggle === id && (
                            <div className="mt-2 px-4">
                                {content && <p style={{ fontFamily: "Roboto" }}>{content}</p>}
                                {nestedContent.map(({ id: nestedId, text, dummyText }) => (
                                    <div key={nestedId} className="my-2">
                                        <div
                                            className="p-2 bg-white rounded cursor-pointer d-flex justify-content-between align-items-center"
                                            onClick={() => updateNestedToggle(nestedId)}
                                            style={{ border: "1px solid #ddd" }}
                                        >
                                            <span style={{ fontFamily: "Roboto", fontSize: "18px" }}>{text}</span>
                                            <span style={{ fontSize: "18px" }}>
                                                {nestedToggle[nestedId] ? "-" : "+"}
                                            </span>
                                        </div>
                                        {nestedToggle[nestedId] && (
                                            <div className="mt-2 px-3">
                                                <ul style={{ fontFamily: "Roboto", color: "#555" }}>
                                                    {dummyText.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LearningPathTFD;
