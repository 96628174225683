import React, { useState, useEffect } from 'react';
import cloudComputingBanner from '../img/banners/cloud-computing-banner.jpg';

const AdvertisementShow = () => {
  const [ads, setAds] = useState([
    {
      name: "Job Ad 1",
      image: cloudComputingBanner, // Example image URL
    },
    {
      name: "Job Ad 2",
      image: cloudComputingBanner, // Example image URL
    },
    {
      name: "Job Ad 3",
      image: cloudComputingBanner, // Example image URL
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  // Function to toggle animation pause/play
  const toggleAnimation = () => {
    setIsPaused(!isPaused); // Toggle animation state
  };

  // If you want to simulate loading, you can use this effect
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulate 1-second loading
  }, []);

  if (loading) {
    return <div className="ad-container">Loading...</div>;
  }

  if (error) {
    return <div className="ad-container">Error: {error}</div>;
  }

  return (
    <div className="ad-container" onClick={toggleAnimation}>
      <div
        className="ad-content"
        style={{ animationPlayState: isPaused ? 'paused' : 'running' }}
      >
        {ads.map((ad, index) => (
          <div key={index} className="ad-item">
            {ad.image ? (
              <img
                src={ad.image}
                alt={ad.name}
                className="ad-image"
              />
            ) : (
              <div className="ad-placeholder">No Image</div>
            )}
            <div className="ad-name">{ad.name || `Advertisement ${index + 1}`}</div>
          </div>
        ))}
        <style>
          {`
            .ad-container {
              position: fixed;
              right: -75px;
              top: 100px;
              bottom: 10px;
              width: 25vw; /* Use a dynamic width based on the viewport */
              max-width: 384px; /* Maximum width for larger screens */
              min-width: 150px; /* Minimum width to ensure ads are still visible on very small screens */
              overflow: hidden;
              z-index: -1;
            
              transition: width 0.5s ease; /* Smooth transition for resizing */
            }

            .ad-content {
              display: flex;
              flex-direction: column;
              animation: scrollUp 15s linear infinite;
              position: absolute;
              bottom: 0; /* Start from the bottom */
              width: 100%; /* Ensure the content fills the container */
            }

            .ad-item {
              padding: 20px;
              margin-left: 50px;
              color: white;
              margin: 5px 0;
              margin-right: 100px;
              background-color: #1367c8;
              text-align: center;
            }

            .ad-image {
              max-width: 100%;
              height: auto;
              border-radius: 4px;
              margin-bottom: 10px;
            }

            .ad-placeholder {
              width: 100%;
              height: 150px;
              background-color: #ccc;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              font-size: 14px;
              color: #555;
            }

            .ad-name {
              font-size: 16px;
              font-weight: bold;
              color: #fff;
              margin-top: 10px;
            }

            /* Keyframes for scrolling up */
            @keyframes scrollUp {
              0% {
                transform: translateY(100%);
              }
              100% {
                transform: translateY(-10%);
              }
            }

            /* Media Queries for responsiveness */
            @media (max-width: 1200px) {
              .ad-container {
                width: 30vw; /* Adjust for medium screens */
              }
            }

            @media (max-width: 992px) {
              .ad-container {
                width: 35vw; /* Adjust for smaller screens */
              }
            }

            @media (max-width: 768px) {
              .ad-container {
                width: 40vw; /* Further adjust for mobile screens */
              }
            }

            @media (max-width: 480px) {
              .ad-container {
                width: 50vw; /* Max width for very small screens */
              }
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default AdvertisementShow;
