
// import React, { useState } from 'react';
// // import './Carousel.css'; // Create a separate CSS file for styles if needed

// // Import images
// import img1 from '../img/aaaservices/java V1.jpg';
// import img2 from '../img/aaaservices/java V1.jpg';
// import img3 from '../img/aaaservices/java V1.jpg';
// import img4 from '../img/aaaservices/java V1.jpg';

// const Carousel = () => {
//     const cards = [
//       {
       
//        image: img1,
//         bestseller: true
//       },
//       {
//         image: img2,
        
       
//         bestseller: true
//       },
//       {
//         image: img3,
       
        
//         bestseller: true
//       },
//       {
//         image: img4,
       
       
//         bestseller: true
//       }
//     ];
  
//     return (
//       <div className="carousel-container">
//         {cards.map((card, index) => (
//           <div key={index} className="card">
//             <div className="card-image-container">
//               <img src={card.image} alt={card.title} className="card-image" />
//             </div>
//             <div className="card-content">
//               <h3>{card.title}</h3>
//               <p>{card.description}</p>
//               <div className="card-rating">
//                 {/* <span className="rating">{card.rating} <span className="stars">⭐</span></span> */}
//                 {/* <span className="reviews">({card.reviews})</span> */}
//               </div>
//               <div className="card-pricing">
//                 <span className="price">₹{card.price}</span>
//                 <span className="original-price">₹{card.originalPrice}</span>
//               </div>
//               {card.bestseller && <span className="bestseller">Bestseller</span>}
//             </div>
//           </div>
//         ))}
  
//         <style jsx>{`
//           .carousel-container {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             max-width: 1400px;
//             height:800px;
//             margin: auto;
//             flex-wrap: wrap;
//           }
  
//           .card {
//             position: relative;
//             width: 23%;
//             margin: 1%;
//             text-align: left;
//             background-color: #fff;
//             border-radius: 8px;
//             box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//             overflow: hidden;
//           }
  
//           .card-image-container {
//             position: relative;
//             overflow: hidden;
//           }
  
//           .card-image {
//             width: 100%;
//             height: 186px;
//             transition: transform 0.3s ease;
//           }
  
//           .card:hover .card-image {
//             transform: scale(1.1);
//           }
  
//           .card-content {
//             padding: 15px;
//           }
  
//           .card-rating {
//             display: flex;
//             align-items: center;
//             margin-top: 5px;
//           }
  
//           .rating {
//             font-weight: bold;
//             margin-right: 5px;
//           }
  
//           .stars {
//             color: #f4c150;
//           }
  
//           .reviews {
//             color: #6c757d;
//             font-size: 0.9em;
//           }
  
//           .card-pricing {
//             margin-top: 10px;
//           }
  
//           .price {
//             font-weight: bold;
//             font-size: 1.2em;
//             margin-right: 10px;
//           }
  
//           .original-price {
//             text-decoration: line-through;
//             color: #6c757d;
//           }
  
//           .bestseller {
//             display: inline-block;
//             margin-top: 10px;
//             padding: 5px 10px;
//             background-color: #ffc107;
//             color: #000;
//             font-size: 0.9em;
//             border-radius: 4px;
//           }
  
//           @media (max-width: 768px) {
//             .card {
//               width: 48%;
//             }
//           }
  
//           @media (max-width: 480px) {
//             .card {
//               width: 100%;
//             }
//           }
//         `}</style>
//       </div>
//     );
//   };
  
//   export default Carousel;




// import React from 'react';
// import img1 from '../img/aaaservices/java V1.jpg';
// import img2 from '../img/aaaservices/java V1.jpg';
// import img3 from '../img/aaaservices/java V1.jpg';
// import img4 from '../img/aaaservices/java V1.jpg';

// const Carousel = () => {
//     const cards = [
//         {
//             image: img1,
//             title: 'Java Basics',
//             description: 'Learn the fundamentals of Java programming.',
//             bestseller: true,
//         },
//         {
//             image: img2,
//             title: 'Advanced Java',
//             description: 'Master advanced Java concepts.',
//             bestseller: true,
//         },
//         {
//             image: img3,
//             title: 'Java Frameworks',
//             description: 'Explore popular Java frameworks.',
//             bestseller: true,
//         },
//         {
//             image: img4,
//             title: 'Java for Beginners',
//             description: 'Start your Java journey today!',
//             bestseller: true,
//         },
//     ];

//     return (
//         <div className="carousel-container">
//             {cards.map((card, index) => (
//                 <div key={index} className="card">
//                     <div className="card-image-container">
//                         <img src={card.image} alt={card.title} className="card-image" />
//                     </div>
//                     <div className="card-content">
//                         <h3 className="card-title">{card.title}</h3>
//                         <p className="card-description">{card.description}</p>
//                         {card.bestseller && <span className="bestseller">Bestseller</span>}
//                     </div>
//                 </div>
//             ))}

//             <style jsx>{`
//                 .carousel-container {
//                     display: flex;
//                     justify-content: space-between;
//                     align-items: center;
//                     max-width: 1400px;
//                     margin: auto;
//                     flex-wrap: wrap;
//                 }

//                 .card {
//                     position: relative;
//                     width: 23%;
//                     margin: 1%;
//                     text-align: left;
//                     background-color: #fff;
//                     border-radius: 8px;
//                     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//                     overflow: hidden;
//                 }

//                 .card-image-container {
//                     position: relative;
//                     overflow: hidden;
//                 }

//                 .card-image {
//                     width: 100%;
//                     height: 186px;
//                     transition: transform 0.3s ease;
//                 }

//                 .card:hover .card-image {
//                     transform: scale(1.1);
//                 }

//                 .card-content {
//                     padding: 15px;
//                 }

//                 .card-title {
//                     margin: 0;
//                     font-size: 1.2em;
//                     font-weight: bold;
//                     color: #333;
//                 }

//                 .card-description {
//                     margin: 5px 0 0;
//                     font-size: 0.95em;
//                     color: #555;
//                 }

//                 .bestseller {
//                     display: inline-block;
//                     margin-top: 10px;
//                     padding: 5px 10px;
//                     background-color: #ffc107;
//                     color: #000;
//                     font-size: 0.9em;
//                     border-radius: 4px;
//                 }

//                 @media (max-width: 768px) {
//                     .card {
//                         width: 48%;
//                     }
//                 }

//                 @media (max-width: 480px) {
//                     .card {
//                         width: 100%;
//                     }
//                 }
//             `}</style>
//         </div>
//     );
// };

// export default Carousel;

  
// import React from 'react';
// import img1 from '../img/aaaservices/java V1.jpg';
// import img2 from '../img/aaaservices/java V1.jpg';
// import img3 from '../img/aaaservices/java V1.jpg';
// import img4 from '../img/aaaservices/java V1.jpg';

// const Carousel = () => {
//     const cards = [
//         {
//             image: img1,
//             title: 'WEBINAR PROGRAM',
//             description: '6-Axis Industrial Robotic virtual simulation.',
//             bestseller: true,
//         },
//         {
//             image: img2,
//             title: 'Advanced Java',
//             description: 'Master advanced Java concepts.',
//             bestseller: true,
//         },
//         {
//             image: img3,
//             title: 'Java Frameworks',
//             description: 'Explore popular Java frameworks.',
//             bestseller: true,
//         },
//         {
//             image: img4,
//             title: 'Java for Beginners',
//             description: 'Start your Java journey today!',
//             bestseller: true,
//         },
//     ];

//     return (
//         <div className="carousel-container">
//             {cards.map((card, index) => (
//                 <div key={index} className="card">
//                     <div className="card-image-container">
//                         <img src={card.image} alt={card.title} className="card-image" />
//                     </div>
//                     <div className="card-content">
//                         <h3 className="card-title">{card.title}</h3>
//                         <h6 className="card-">{card.description}</h6>
//                         <p className="card-description">{card.description}</p>
//                         {card.bestseller && (
//                             <button className="enquire-button">Enquire Now</button>
//                         )}
//                     </div>
//                 </div>
//             ))}

//             <style jsx>{`
//                 .carousel-container {
//                     display: flex;
//                     justify-content: space-between;
//                     align-items: center;
//                     max-width: 1400px;
//                     margin: auto;
//                     flex-wrap: wrap;
//                 }

//                 .card {
//                     position: relative;
//                     width: 23%;
//                     margin: 1%;
//                     text-align: left;
//                     background-color: #fff;
//                     border-radius: 8px;
//                     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//                     overflow: hidden;
//                 }

//                 .card-image-container {
//                     position: relative;
//                     overflow: hidden;
//                 }

//                 .card-image {
//                     width: 100%;
//                     height: 186px;
//                     transition: transform 0.3s ease;
//                 }

//                 .card:hover .card-image {
//                     transform: scale(1.1);
//                 }

//                 .card-content {
//                     padding: 15px;
//                 }

//                 .card-title {
//                     margin: 0;
//                     font-size: 1.2em;
//                     font-weight: bold;
//                     color: #333;
//                 }

//                 .card-description {
//                     margin: 10px 0 0;
//                     font-size: 0.95em;
//                     color: #555;
//                 }

//                 .enquire-button {
//                     display: inline-block;
//                     margin-top: 10px;
//                     padding: 10px 15px;
//                     background-color: #ffc107;
//                     color: #000;
//                     font-size: 1em;
//                     font-weight: bold;
//                     border: none;
//                     border-radius: 4px;
//                     cursor: pointer;
//                     transition: background-color 0.3s ease;
//                 }

//                 .enquire-button:hover {
//                     background-color: #e0a800;
//                 }

//                 @media (max-width: 768px) {
//                     .card {
//                         width: 48%;
//                     }
//                 }

//                 @media (max-width: 480px) {
//                     .card {
//                         width: 100%;
//                     }
//                 }
//             `}</style>
//         </div>
//     );
// };

// export default Carousel;








// import React from 'react';
// import image1 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path
// import image2 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path
// import image3 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path
// import image4 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path

// const CardComponent = () => {
//   const cards = [
//     {
//       image: image1,
//       title: 'Fundamentals of Successful Leadership - Leading with ',
//       description: 'Baba Oyewole, Helen Hicks',
//       rating: '4.4',
//       reviews: '1,047',
//       price: '₹449',
//       originalPrice: '₹2,299',
//     },
//     {
//       image: image2,
//       title: 'Leadership - Leading a Community',
//       description: 'Baba Oyewole',
//       rating: '4.4',
//       reviews: '518',
//       price: '₹399',
//       originalPrice: '₹2,299',
//     },
//     {
//       image: image3,
//       title: 'Executive Diploma in Business Administration',
//       description: 'MTF Institute of Management',
//       rating: '4.4',
//       reviews: '616',
//       price: '₹399',
//       originalPrice: '₹799',
//     },
//     {
//       image: image4,
//       title: 'Leadership: Leading When You Are Not In Charge!',
//       description: 'Steve Ballinger, MBA',
//       rating: '4.5',
//       reviews: '8,073',
//       price: '₹449',
//       originalPrice: '₹3,499',
//     },
//   ];

//   return (
//     <div style={styles.container}>
//       {cards.map((card, index) => (
//         <div key={index} style={styles.card}>
//           <img src={card.image} alt={card.title} style={styles.image} />
//           <div style={styles.cardContent}>
//             <h3 style={styles.title}>{card.title}</h3>
//             <p style={styles.description}>{card.description}</p>
//             <div style={styles.rating}>
//               <span>{card.rating} ★ </span>
//               <span>({card.reviews})</span>
//             </div>
//             <div style={styles.pricing}>
//               <span style={styles.price}>{card.price}</span>
//               <span style={styles.originalPrice}>{card.originalPrice}</span>
//             </div>
//             <button style={styles.button}>Enquire Now</button>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     gap: '16px',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     padding: '16px',
//   },
//   card: {
//     width: '300px',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//     backgroundColor: '#fff',
//   },
//   image: {
//     width: '100%',
//     height: '200px',
//     objectFit: 'cover',
//   },
//   cardContent: {
//     padding: '16px',
//     textAlign: 'center',
//   },
//   title: {
//     fontSize: '18px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//   },
//   description: {
//     fontSize: '14px',
//     color: '#555',
//     marginBottom: '8px',
//   },
//   rating: {
//     fontSize: '14px',
//     color: '#f39c12',
//     marginBottom: '8px',
//   },
//   pricing: {
//     fontSize: '14px',
//     color: '#555',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '8px',
//     marginBottom: '16px',
//   },
//   price: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     color: '#2ecc71',
//   },
//   originalPrice: {
//     fontSize: '14px',
//     color: '#999',
//     textDecoration: 'line-through',
//   },
//   button: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#007bff',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//   },
// };

// export default CardComponent;



// import React, { useState } from 'react';
// import image1 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path
// import image2 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path
// import image3 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path
// import image4 from '../img/aaaservices/DIGITAL FACILITY SIMULATIONS V5.jpg'; // Replace with the actual path

// const CardComponent = () => {
//   const [popupVisible, setPopupVisible] = useState(false);
//   const [currentCard, setCurrentCard] = useState(null);

//   const cards = [
//     {
//       image: image1,
//       title: 'Fundamentals of Successful Leadership - Leading with',
//       secondTitle: 'Understanding Leadership Strategies',
//       description: 'Baba Oyewole, Helen Hicks',
//       rating: '4.4',
//       reviews: '1,047',
//       price: '₹449',
//       originalPrice: '₹2,299',
//       listItems: ['Lesson 1: Leadership Skills', 'Lesson 2: Team Management', 'Lesson 3: Conflict Resolution', 'Lesson 4: Leading with Integrity'],
//     },
//     {
//       image: image2,
//       title: 'Leadership - Leading a Community',
//       secondTitle: 'Community Leadership Principles',
//       description: 'Baba Oyewole',
//       rating: '4.4',
//       reviews: '518',
//       price: '₹399',
//       originalPrice: '₹2,299',
//       listItems: ['Lesson 1: Building Trust', 'Lesson 2: Effective Communication', 'Lesson 3: Community Engagement', 'Lesson 4: Managing Volunteers'],
//     },
//     {
//       image: image3,
//       title: 'Executive Diploma in Business Administration',
//       secondTitle: 'MBA-Level Business Management',
//       description: 'MTF Institute of Management',
//       rating: '4.4',
//       reviews: '616',
//       price: '₹399',
//       originalPrice: '₹799',
//       listItems: ['Lesson 1: Business Strategies', 'Lesson 2: Corporate Finance', 'Lesson 3: Marketing Management', 'Lesson 4: Organizational Behavior'],
//     },
//     {
//       image: image4,
//       title: 'Leadership: Leading When You Are Not In Charge!',
//       secondTitle: 'Influence Without Authority',
//       description: 'Steve Ballinger, MBA',
//       rating: '4.5',
//       reviews: '8,073',
//       price: '₹449',
//       originalPrice: '₹3,499',
//       listItems: ['Lesson 1: Leading by Example', 'Lesson 2: Influencing Peers', 'Lesson 3: Handling Pressure', 'Lesson 4: Leading During Crisis'],
//     },
//   ];

//   const handleMouseEnter = (index) => {
//     setCurrentCard(index);
//     setPopupVisible(true);
//   };

//   const handleMouseLeave = () => {
//     setPopupVisible(false);
//   };

//   return (
//     <div style={styles.container}>
//       {cards.map((card, index) => (
//         <div
//           key={index}
//           style={styles.card}
//           onMouseEnter={() => handleMouseEnter(index)}
//           onMouseLeave={handleMouseLeave}
//         >
//           <img src={card.image} alt={card.title} style={styles.image} />
//           <div style={styles.cardContent}>
//             <h3 style={styles.title}>{card.title}</h3>
//             <p style={styles.description}>{card.description}</p>
//             <div style={styles.rating}>
//               <span>{card.rating} ★ </span>
//               <span>({card.reviews})</span>
//             </div>
//             <div style={styles.pricing}>
//               <span style={styles.price}>{card.price}</span>
//               <span style={styles.originalPrice}>{card.originalPrice}</span>
//             </div>
//             <button style={styles.button}>Enquire Now</button>
//           </div>
//           {popupVisible && currentCard === index && (
//             <div style={styles.popup}>
//               <h4 style={styles.popupTitle}>{cards[index].title}</h4>
//               <h5 style={styles.popupSecondTitle}>{cards[index].secondTitle}</h5>
//               <ul style={styles.popupList}>
//                 {cards[index].listItems.map((item, idx) => (
//                   <li key={idx} style={styles.popupListItem}>
//                     {item}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     gap: '16px',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     padding: '16px',
//   },
//   card: {
//     width: '300px',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//     backgroundColor: '#fff',
//     position: 'relative',
//   },
//   image: {
//     width: '100%',
//     height: '200px',
//     objectFit: 'cover',
//   },
//   cardContent: {
//     padding: '16px',
//     textAlign: 'center',
//   },
//   title: {
//     fontSize: '18px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//   },
//   description: {
//     fontSize: '14px',
//     color: '#555',
//     marginBottom: '8px',
//   },
//   rating: {
//     fontSize: '14px',
//     color: '#f39c12',
//     marginBottom: '8px',
//   },
//   pricing: {
//     fontSize: '14px',
//     color: '#555',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '8px',
//     marginBottom: '16px',
//   },
//   price: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     color: '#2ecc71',
//   },
//   originalPrice: {
//     fontSize: '14px',
//     color: '#999',
//     textDecoration: 'line-through',
//   },
//   button: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#007bff',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//   },
//   popup: {
//     position: 'absolute',
//     top: '10px',
//     left: '10px',
//     backgroundColor: '#fff',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
//     padding: '16px',
//     width: '250px',
//     zIndex: 10,
//     textAlign: 'left',
//   },
//   popupTitle: {
//     fontSize: '18px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//   },
//   popupSecondTitle: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//     color: '#555',
//   },
//   popupList: {
//     listStyleType: 'none',
//     paddingLeft: '0',
//   },
//   popupListItem: {
//     fontSize: '14px',
//     marginBottom: '4px',
//   },
// };

// export default CardComponent;





// import React, { useState } from 'react';
// import image1 from '../img/banner/V1 copy.jpg'; // Replace with the actual path
// import image2 from '../img/aaaservices/V2 copy.jpg'; // Replace with the actual path
// import image3 from '../img/aaaservices/V4 copy.jpg'; 
// import image4 from '../img/aaaservices/V5 copy.jpg'; // Replace with the actual path
// import { color, fontWeight, textAlign } from '@mui/system';

// const CardComponent = () => {
//   const [popupVisible, setPopupVisible] = useState(false);
//   const [currentCard, setCurrentCard] = useState(null);

//   const cards = [
//     {
//       image: image1,
//       title: 'WEBINAR PROGRAM',
//       secondTitle: '6-Axis indisturial robotics  virtual simulation',
//       description: 'Spaical Offer',
//       rating: 'Get free training On',
      
//        price: 'Process Simulate/ Dalemia Software',
//     //   originalPrice: '₹2,299',
//       listItems: ['Lesson 1: Leadership Skills', 'Lesson 2: Team Management', 'Lesson 3: Conflict Resolution', 'Lesson 4: Leading with Integrity'],
//     },
//     {
//       image: image2,
//       title: 'TRAINING PLACEMENT PROGRAM',
//       secondTitle: '6-Axis indisturial robotics  virtual simulation',
//       description: 'Spaical Offer',
//       rating: '4.4',
//       reviews: '518',
//       price: 'Mode: Online',
//     //   originalPrice: '₹2,299',
//       listItems: ['Lesson 1: Building Trust', 'Lesson 2: Effective Communication', 'Lesson 3: Community Engagement', 'Lesson 4: Managing Volunteers'],
//     },
//     {
//       image: image3,
//       title: 'INTERNSHIP PLACEMENT PROGRAM',
//       secondTitle: '6-Axis indisturial robotics  virtual simulation',
//       description: 'Spaical Offer',
//       rating: '4.4',
//       reviews: '616',
//       price: 'Mode: Online',
//     //   originalPrice: '₹799',
//       listItems: ['Lesson 1: Business Strategies', 'Lesson 2: Corporate Finance', 'Lesson 3: Marketing Management', 'Lesson 4: Organizational Behavior'],
//     },
//     {
//       image: image4,
//       title: '100% PLACEMENT PROGRAM',
//       secondTitle: '6-Axis indisturial robotics  virtual simulation',
//       description: 'Spaical Offer',
//       rating: '4.5',
//       reviews: '8,073',
//       price: 'Mode: Online',
//     //   originalPrice: '₹3,499',
//       listItems: ['Lesson 1: Leading by Example', 'Lesson 2: Influencing Peers', 'Lesson 3: Handling Pressure', 'Lesson 4: Leading During Crisis'],
//     },
//   ];

//   const handleMouseEnter = (index) => {
//     setCurrentCard(index);
//     setPopupVisible(true);
//   };

//   const handleMouseLeave = () => {
//     setPopupVisible(false);
//   };

//   return (
//     <div style={styles.container}>
//       {cards.map((card, index) => (
//         <div
//           key={index}
//           style={styles.card}
//           onMouseEnter={() => handleMouseEnter(index)}
//           onMouseLeave={handleMouseLeave}
//         >
//           <img src={card.image} alt={card.title} style={styles.image} />
//           <div style={styles.cardContent}>
//             <h3 style={styles.title}>{card.title}</h3> <br />
//             <p style={styles.description}>{card.description}</p>
//             <div style={styles.rating}>
//               <span>{card.rating}  </span> 
//               <span>{card.reviews}</span>
//             </div>
//             <div style={styles.pricing}>
//               <span style={styles.price}>{card.price}</span>
//               <span style={styles.originalPrice}>{card.originalPrice}</span>
//             </div>
//             {/* <button style={styles.button}>Enquire Now</button> */}
//           </div>
//           {popupVisible && currentCard === index && (
//             <div style={styles.popup}>
//               <h4 style={styles.popupTitle}>{cards[index].title}</h4> <br/>
//               <h5 style={styles.popupSecondTitle}>{cards[index].secondTitle}</h5> <br/>
//               <ul style={styles.popupList}>
//                 {cards[index].listItems.map((item, idx) => (
//                   <li key={idx} style={styles.popupListItem}>
//                     {item}
//                   </li>
//                 ))}
//               </ul>
//               <button style={styles.addToCartButton}>Add to Cart</button> < br/>
//               <button style={styles.addToCartButton}>Brochure</button>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     gap: '16px',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     padding: '16px',
//   },
//   card: {
//     width: '300px',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//     backgroundColor: '#fff',
//     position: 'relative',
//   },
//   image: {
//     width: '100%',
//     height: '200px',
//     objectFit: 'cover',
//   },
//   cardContent: {
//     padding: '16px',
//     textAlign: 'left',
//   },
//   title: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     color: '#000',
//     marginBottom: '8px',
//     textAlign: 'left', // Aligns the text to the left
//   },
  
//   description: {
//     fontSize: '20px',
//     color: '#000',
//     fontWeight:'bold',
//     marginBottom: '8px',
//     textAlign: 'left',
//   },
//   rating: {
//     fontSize: '20px',
//     color: '#000',
//     marginBottom: '8px',
//     fontWeight: 'bold',
//     alignItems: 'left',
//   },
//   pricing: {
//     fontSize: '20px',
//     color: '#555',
//     display: 'flex',
//     justifyContent: 'left',
//     alignItems: 'center',
//     fontWeight: 'bold',
//     gap: '8px',
//     marginBottom: '16px',
//     alignItems: 'left',
//   },
//   price: {
//     fontSize: '20px',
//     fontWeight: 'bold',
//     color: '#2ecc71',
//     alignItems: 'left',
//   },
//   originalPrice: {
//     fontSize: '14px',
//     color: '#999',
//     textDecoration: 'line-through',
//   },

//   popup: {
//     position: 'absolute',
//     top: '0',
//     left: '5px',
//     backgroundColor: '#fff',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
//     padding: '24px',
//     width: '300px',
//     height:'500px',
//     zIndex: 10,
//     textAlign: 'left',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'left',
//   },
//   popupTitle: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//     textAlign: 'left',
    
//   },
//   popupSecondTitle: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//     color: '#555',
//   },
//   popupList: {
//     listStyleType: 'none',
//     paddingLeft: '0',
//     marginBottom: '16px',
//   },
//   popupListItem: {
//     fontSize: '14px',
//     marginBottom: '4px',
//   },
//   addToCartButton: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#28a745', // Green color
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//   },
//   addToCartButton: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#28a745', // Green color
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//   },
// };

// export default CardComponent;




// import React, { useState } from 'react';
// import image1 from '../img/banner/V1 copy.jpg'; // Replace with the actual path
// import image2 from '../img/aaaservices/V2 copy.jpg'; // Replace with the actual path
// import image3 from '../img/aaaservices/V4 copy.jpg'; 
// import image4 from '../img/aaaservices/V5 copy.jpg'; // Replace with the actual path
// import sampleVideo from '../img/robotics hands working.mp4'; // Import the video
// const CardComponent = () => {
//   const [popupVisible, setPopupVisible] = useState(false);
//   const [currentCard, setCurrentCard] = useState(null);
//   const [contactPopupVisible, setContactPopupVisible] = useState(false);

//   const cards = [
//     {
//       image: image1,
//       title: 'WEBINAR PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Leadership Skills', 'Lesson 2: Team Management', 'Lesson 3: Conflict Resolution', 'Lesson 4: Leading with Integrity'],
//     },
//     {
//       image: image2,
//       title: 'TRAINING PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       // reviews: '518',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Building Trust', 'Lesson 2: Effective Communication', 'Lesson 3: Community Engagement', 'Lesson 4: Managing Volunteers'],
//     },
//     {
//       image: image3,
//       title: 'INTERNSHIP PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       // reviews: '616',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Business Strategies', 'Lesson 2: Corporate Finance', 'Lesson 3: Marketing Management', 'Lesson 4: Organizational Behavior'],
//     },
//     {
//       image: image4,
//       title: 'PLACEMENT PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       // reviews: '8,073',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Leading by Example', 'Lesson 2: Influencing Peers', 'Lesson 3: Handling Pressure', 'Lesson 4: Leading During Crisis'],
//     },
//   ];

//   const handleMouseEnter = (index) => {
//     setCurrentCard(index);
//   };

//   const handleMouseLeave = () => {
//     setCurrentCard(null);
//   };

//   const openContactPopup = () => {
//     setContactPopupVisible(true);
//   };

//   const closeContactPopup = () => {
//     setContactPopupVisible(false);
//   };

//   return (
//     <div style={styles.container}>
//       {cards.map((card, index) => (
//         <div
//           key={index}
//           style={styles.card}
//           onMouseEnter={() => handleMouseEnter(index)}
//           onMouseLeave={handleMouseLeave}
//         >
//           <img src={card.image} alt={card.title} style={styles.image} />
//           <div style={styles.cardContent}>
//             <h3 style={styles.title}>{card.title}</h3>
//             <p style={styles.description}>{card.description}</p>
//             <div style={styles.rating}>
//               <span>{card.rating} </span>
//               <span>{card.reviews}</span>
//             </div>
//             <div style={styles.pricing}>
//               <span style={styles.price}>{card.price}</span>
//             </div>
//             <button onClick={openContactPopup} style={styles.button}>Get in Touch</button>
//           </div>

//           {popupVisible && currentCard === index && (
//             <div style={styles.popup}>
//               <h4 style={styles.popupTitle}>{card.title}</h4>
//               <h5 style={styles.popupSecondTitle}>{card.secondTitle}</h5>
//               <ul style={styles.popupList}>
//                 {card.listItems.map((item, idx) => (
//                   <li key={idx} style={styles.popupListItem}>{item}</li>
//                 ))}
//               </ul>
//               <button style={styles.addToCartButton}>Add to Cart</button>
//               <button style={styles.addToCartButton}>Download Brochure</button>
//             </div>
//           )}
//         </div>
//       ))}

//       {/* Contact Popup */}
//       {contactPopupVisible && (
//         <div style={styles.contactPopup}>
//           <div style={styles.contactPopupContent}>
//             <button onClick={closeContactPopup} style={styles.closeButton}>X</button>
//             {/* Video player here */}
//             <video width="100%" height="auto" controls style={styles.videoFrame}>
//               <source src={sampleVideo} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//             <h2 style={styles.popupTitle}>Contact Us</h2>
//             <h4 style={styles.popupSecondTitle}>Get in Touch with Our Team</h4>
//             <ul style={styles.popupList}>
//               <li>Lesson 1: Leadership Skills</li>
//               <li>Lesson 2: Team Management</li>
//               <li>Lesson 3: Conflict Resolution</li>
//               <li>Lesson 4: Leading with Integrity</li>
//             </ul>
           
//             <div style={styles.buttonContainer}>
//   <button style={styles.addToCartButtonFirst}>Add to Cart</button>
//   <button style={styles.addToCartButton}>Download Brochure</button>
// </div>
// </div>
//         </div>
       
//       )}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     gap: '16px',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     padding: '16px',
//   },
//   card: {
//     width: '300px',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//     backgroundColor: '#fff',
//     position: 'relative',
//     cursor: 'default', // Remove pointer cursor
//   },
//   image: {
//     width: '100%',
//     height: '200px',
//     objectFit: 'cover',
//   },
//   cardContent: {
//     padding: '16px',
//     textAlign: 'left',
//   },
//   title: {
//     fontSize: '20px',
//     fontWeight: 'bold',
//     color: '#000',
//     marginBottom: '8px',
//     textAlign: 'left',
//   },
//   description: {
//     fontSize: '20px',
//     color: '#000',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//     textAlign: 'left',
//   },
//   rating: {
//     fontSize: '20px',
//     color: '#000',
//     marginBottom: '8px',
//     fontWeight: 'bold',
//   },
//   pricing: {
//     fontSize: '20px',
//     color: '#555',
//     display: 'flex',
//     justifyContent: 'left',
//     alignItems: 'center',
//     fontWeight: 'bold',
//     gap: '8px',
//     marginBottom: '16px',
//   },
//   price: {
//     fontSize: '20px',
//     fontWeight: 'bold',
//     color: '#2ecc71',
//   },
//   button: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#28a745',
//     border: 'none',
//     borderRadius: '4px',
   
//     cursor: 'pointer',
//     width: '100%',
//     marginTop: '16px',
//   },
//   popup: {
//     position: 'fixed',
//     top: '0',
//     left: '0px',
//     backgroundColor: '#fff',
//     border: '1px solid #ddd',
//     // borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
//     padding: '24px',
//     width: '800px',
//     height:'auto',
//     zIndex: 10,
//     textAlign: 'left',
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   popupTitle: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//     textAlign: 'left',
//   },
//   popupSecondTitle: {
//     fontSize: '16px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//     color: '#555',
//     textAlign: 'left',
//   },
//   popupList: {
//     listStyleType: 'none',
//     paddingLeft: '0',
//     marginBottom: '16px',
//     textAlign: 'left',
//   },
//   popupListItem: {
//     fontSize: '14px',
//     marginBottom: '4px',
//   },
//   buttonContainer: {
//     display: 'flex',
//     gap: '10px', // Adds space between the buttons
//     justifyContent: 'center', // Centers the buttons horizontally (optional)
//     marginTop: '20px', // Adjusts the space above the buttons (optional)
//   },
//   addToCartButton: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#28a745', // Green color
//     border: 'none',
//     gap:'10px',
   
//     cursor: 'pointer',
//     marginBottom: '10px',// Add margin to create space
   
//   },
//   addToCartButtonFirst: {
//     padding: '10px 20px',
   
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#28a745',
//     border: 'none',
   
//     cursor: 'pointer',
//     marginBottom: '10px', // Space between the buttons
//   },
//   contactPopup: {
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     right: '0',
//     bottom: '0',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     zIndex: 999,
//   },
//   contactPopupContent: {
//     backgroundColor: '#fff',
//     padding: '20px',
//     width: '80%',
//     maxWidth: '600px',
//     borderRadius: '8px',
//     textAlign: 'center',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: '10px',
//     right: '10px',
//     backgroundColor: 'transparent',
//     border: 'none',
//     fontSize: '24px',
//     cursor: 'pointer',
//     color: '#888',
//   },
//   videoFrame: {
//     marginBottom: '20px',
//     width: '100%',
//     height: 'auto',
//   },
// };

// export default CardComponent;





// import React, { useState } from 'react';
// import image1 from '../img/banner/V1 copy.jpg';
// import image2 from '../img/aaaservices/V2 copy.jpg';
// import image3 from '../img/aaaservices/V4 copy.jpg'; 
// import image4 from '../img/aaaservices/V5 copy.jpg';
// import sampleVideo from '../img/robotics hands working.mp4'; 
// import { textAlign } from '@mui/system';

// const CardComponent = () => {
//   const [popupVisible, setPopupVisible] = useState(false);
//   const [currentCard, setCurrentCard] = useState(null);
//   const [contactPopupVisible, setContactPopupVisible] = useState(false);

//   const cards = [
//     {
//       image: image1,
//       title: 'WEBINAR PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Leadership Skills', 'Lesson 2: Team Management', 'Lesson 3: Conflict Resolution', 'Lesson 4: Leading with Integrity'],
//     },
//     {
//       image: image2,
//       title: 'TRAINING PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Building Trust', 'Lesson 2: Effective Communication', 'Lesson 3: Community Engagement', 'Lesson 4: Managing Volunteers'],
//     },
//     {
//       image: image3,
//       title: 'INTERNSHIP PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Business Strategies', 'Lesson 2: Corporate Finance', 'Lesson 3: Marketing Management', 'Lesson 4: Organizational Behavior'],
//     },
//     {
//       image: image4,
//       title: 'PLACEMENT PROGRAM',
//       secondTitle: '6-Axis industrial robotics virtual simulation',
//       description: 'Special Offer',
//       rating: 'Get free training On',
//       price: 'Process Simulate/ Dalmia Software',
//       listItems: ['Lesson 1: Leading by Example', 'Lesson 2: Influencing Peers', 'Lesson 3: Handling Pressure', 'Lesson 4: Leading During Crisis'],
//     },
//   ];

//   const handleMouseEnter = (index) => {
//     setCurrentCard(index);
//   };

//   const handleMouseLeave = () => {
//     setCurrentCard(null);
//   };

//   const openContactPopup = () => {
//     setContactPopupVisible(true);
//   };

//   const closeContactPopup = () => {
//     setContactPopupVisible(false);
//   };

//   return (
//     <div style={styles.container}>
//       {cards.map((card, index) => (
//         <div
//           key={index}
//           style={styles.card}
//           onMouseEnter={() => handleMouseEnter(index)}
//           onMouseLeave={handleMouseLeave}
//         >
//           <img src={card.image} alt={card.title} style={styles.image} />
//           <div style={styles.cardContent}>
//             <h3 style={styles.title}>{card.title}</h3>
//             <p style={styles.description}>{card.description}</p>
//             <div style={styles.rating}>
//               <span>{card.rating}</span>
//             </div>
//             <div style={styles.pricing}>
//               <span style={styles.price}>{card.price}</span>
//             </div>
//             <button onClick={openContactPopup} style={styles.button}>Get in Touch</button>
//           </div>

//           {popupVisible && currentCard === index && (
//             <div style={{ ...styles.popup }}>
//               <div style={styles.popupContent}>
//                 <div style={styles.textContainer}>
//                   <h4 style={styles.popupTitle}>{card.title}</h4>
//                   <h5 style={styles.popupSecondTitle}>{card.secondTitle}</h5>
//                   <ul style={styles.popupList}>
//                     {card.listItems.map((item, idx) => (
//                       <li key={idx} style={styles.popupListItem}>{item}</li>
//                     ))}
//                   </ul>
//                   <div style={styles.buttonContainer}>
//                     <button style={styles.addToCartButton}>Add to Cart</button>
//                     <button style={styles.addToCartButton}>Download Brochure</button>
//                   </div>
//                 </div>
//                 <div style={styles.videoContainer}>
//                   <video width="100%" height="auto" controls style={styles.videoFrame}>
//                     <source src={sampleVideo} type="video/mp4" />
//                     Your browser does not support the video tag.
//                   </video>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       ))}

//       {/* Contact Popup */}
//       {contactPopupVisible && (
//         <div style={styles.contactPopup}>
//           <div style={styles.contactPopupContent}>
//             <button onClick={closeContactPopup} style={styles.closeButton}>X</button>
//             <div style={styles.popupContent}>
//               <div style={styles.textContainer}>
//                 <h2 style={styles.popupTitle}>WEBINAR PROGRAM </h2> <br/>
//                 <h4 style={styles.popupSecondTitle}>6-Axis indastrial robotics virtual simulation</h4>
//                 <ul style={styles.popupList}>
//                   <li>Lesson 1: Leadership Skills</li>
//                   <li>Lesson 2: Team Management</li>
//                   <li>Lesson 3: Conflict Resolution</li>
//                   <li>Lesson 4: Leading with Integrity</li>
//                 </ul>
//                 <div style={styles.buttonContainer}>
//                   <button style={styles.addToCartButton}>Add to Cart</button>
//                   <button style={styles.addToCartButton}>Download Brochure</button>
//                 </div>
//               </div>
//               <div style={styles.videoContainer}>
//                 <video width="100%" height="auto" controls style={styles.videoFrame}>
//                   <source src={sampleVideo} type="video/mp4" />
//                   Your browser does not support the video tag.
//                 </video>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     gap: '16px',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     flexWrap: 'wrap',
//     padding: '16px',
//   },
//   card: {
//     width: '300px',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//     backgroundColor: '#fff',
//     position: 'relative',
//     cursor: 'pointer',
//   },
//   image: {
//     width: '100%',
//     height: '200px',
//     objectFit: 'cover',
//   },
//   cardContent: {
//     padding: '16px',
//    textAlign:'left'
//   },
//   title: {
//     fontSize: '20px',
//     fontWeight: 'bold',
//     color: '#000',
//     marginBottom: '8px',
//     textAlign:'left'
//   },
//   description: {
//     fontSize: '18px',
//     color: '#000',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//     textAlign:'left'
//   },
//   rating: {
//     fontSize: '18px',
//     color: '#000',
//     marginBottom: '8px',
//     fontWeight: 'bold',
//     textAlign:'left'
//   },
//   pricing: {
//     fontSize: '18px',
//     color: '#555',
//     display: 'flex',
//     justifyContent: 'left',
//     alignItems: 'center',
//     fontWeight: 'bold',
//     gap: '8px',
//     marginBottom: '16px',
//    textAlign:'left'
//   },
//   price: {
//     fontSize: '18px',
//     fontWeight: 'bold',
//     color: '#2ecc71',
//     textAlign:'left'
//   },
//   button: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#28a745',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     width: '100%',
//     marginTop: '16px',
//   },
//   popup: {
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     backgroundColor: '#000', 
//     width: '100%',
//     height: '100%',
//     zIndex: 999,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     textAlign: 'left',
//     padding: '20px',
//   },
//   popupContent: {
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     padding: '24px',
//     backgroundColor: '#fff',
//     maxWidth: '1309px',
//     width: '1363px',
//     height:'480px',
//     marginTop:'-13px',
//     // borderRadius: '8px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//   },
//   textContainer: {
//     width: '60%',
//     paddingRight: '20px',
//   },
//   videoContainer: {
//     width: '40%',
//     paddingLeft: '20px',
//   },
//   popupTitle: {
//     fontSize: '24px',
//     fontWeight: 'bold',
//     marginBottom: '8px',
//   },
//   popupSecondTitle: {
//     fontSize: '20px',
//     color: '#555',
//     marginBottom: '12px',
//   },
//   popupList: {
//     listStyleType: 'none',
//     paddingLeft: '0',
//     marginBottom: '16px',
//   },
//   popupListItem: {
//     fontSize: '16px',
//     marginBottom: '8px',
//   },
//   buttonContainer: {
//     display: 'flex',
//     gap: '10px',
//     justifyContent: 'center',
//   },
//   addToCartButton: {
//     padding: '10px 20px',
//     fontSize: '14px',
//     color: '#fff',
//     backgroundColor: '#28a745',
//     border: 'none',
//     cursor: 'pointer',
//     marginTop:'231px',
//   },
//   contactPopup: {
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     right: '0',
//     bottom: '0',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     zIndex: 999,
//   },
//   closeButton: {
//     position: 'absolute',
//     top: '10px',
//     right: '10px',
//     backgroundColor: 'transparent',
//     border: 'none',
//     fontSize: '24px',
//     cursor: 'pointer',
//     color: '#888',
//   },
//   videoFrame: {
//     width: '100%',
//     height: 'auto',
//   },
// };

// export default CardComponent;




// import React, { useState } from "react";
// import image1 from "../img/banner/V1 copy.jpg";
// import image2 from "../img/aaaservices/V2 copy.jpg";
// import image3 from "../img/aaaservices/V4 copy.jpg";
// import image4 from "../img/aaaservices/V5 copy.jpg";
// import video1 from "../img/robotics hands working.mp4";
// import video2 from "../img/robotics hands working.mp4";
// import video3 from "../img/robotics hands working.mp4";
// import video4 from "../img/robotics hands working.mp4";
// import VertualroboticsBanner from './VertualroboticsBanner';
// const CardComponent = () => {
//   const [popupVisible, setPopupVisible] = useState(false);
//   const [currentCard, setCurrentCard] = useState(null);

//   const cards = [
//     {
//       image: image1,
//       title: "WEBINAR PROGRAM",
//       secondTitle: "6-Axis industrial robotics virtual simulation",
//       description: "Special Offer",
//       rating: "Get free training On",
//       price: "Process Simulate/ Dalmia Software",
//       video: video1,
      
//     },
//     {
//       image: image2,
//       title: "TRAINING PROGRAM",
//       secondTitle: "6-Axis industrial robotics virtual simulation",
//       description: "Special Offer",
//       rating: "Get free training On",
//       price: "Process Simulate/ Dalmia Software",
//       video: video2,
     
//     },
//     {
//       image: image3,
//       title: "INTERNSHIP PROGRAM",
//       secondTitle: "6-Axis industrial robotics virtual simulation",
//       description: "Special Offer",
//       rating: "Get free training On",
//       price: "Process Simulate/ Dalmia Software",
//       video: video3,
      
//     },
//     {
//       image: image4,
//       title: "PLACEMENT PROGRAM",
//       secondTitle: "6-Axis industrial robotics virtual simulation",
//       description: "Special Offer",
//       rating: "Get free training On",
//       price: "Process Simulate/ Dalmia Software",
//       video: video4,
      
//     },
//   ];

//   const handleCardClick = (index) => {
//     setCurrentCard(index);
//     setPopupVisible(true);
//   };

//   const closePopup = () => {
//     setPopupVisible(false);
//     setCurrentCard(null);
//   };

//   const styles = {
//     container: {
//       display: "flex",
//       gap: "16px",
//       justifyContent: "center",
//       alignItems: "flex-start",
//       flexWrap: "wrap",
//       padding: "16px",
//     },
//     card: {
//       width: "300px",
//       border: "1px solid #ddd",
//       borderRadius: "8px",
//       boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
//       overflow: "hidden",
//       backgroundColor: "#fff",
//       position: "relative",
//       cursor: "pointer",
//       textAlign: "left",
//     },
//     image: {
//       width: "100%",
//       height: "200px",
//       objectFit: "cover",
//     },
//     cardContent: {
//       padding: "16px",
//       textAlign:'left'
//     },
//     title: {
//       fontSize: "20px",
//       fontWeight: "bold",
//       color: "#000",
//       marginBottom: "8px",
//       textAlign:'left'
//     },
//     description: {
//       fontSize: "18px",
//       fontWeight: "bold",
//       color: "#000",
//       marginBottom: "8px",
//       textAlign:'left'
//     },
//     rating: {
//       fontSize: "18px",
//       fontWeight: "bold",
//       color: "#000",
//       marginBottom: "8px",
//       textAlign:'left'
//     },
//     price: {
//       fontSize: "18px",
//       fontWeight: "bold",
//       color: "#2ecc71",
//       textAlign:'left'
//     },
//     button: {
//       padding: "10px 20px",
//       fontSize: "14px",
//       color: "#fff",
//       backgroundColor: "#28a745",
//       border: "none",
//       borderRadius: "4px",
//       cursor: "pointer",
//       width: "100%",
//       marginTop: "16px",
//     },
//     popup: {
//       position: "fixed",
//       top: "0",
//       left: "0",
//       backgroundColor: "rgba(0, 0, 0, 0.8)",
//       width: "100%",
//       height: "100%",
//       zIndex: "999",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       padding: "20px",
//     },
//     popupContent: {
//       display: "flex",
//       flexDirection: "row",
//       padding: "24px",
//       backgroundColor: "#fff",
//       maxWidth: "1300px",
//       width: "90%",
//       boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
//     },
//     textContainer: {
//       width: "100%",
//       paddingRight: "0px",
//     },
//     videoContainer: {
//       width: "40%",
//       paddingLeft: "20px",
//     },
    
//     closeButton: {
//       position: "absolute",
//       top: "155px",
//       right: "109px",
//       backgroundColor: "black",
//       color: "#fff",
//       border: "none",
//       borderRadius: "260%",
//       width: "26px",
//       height: "24px",
//       cursor: "pointer",
//     },
//   };

//   return (
//     <div style={styles.container}>
//       {cards.map((card, index) => (
//         <div key={index} style={styles.card} onClick={() => handleCardClick(index)}>
//           <img src={card.image} alt={card.title} style={styles.image} />
//           <div style={styles.cardContent}>
//             <h3 style={styles.title}>{card.title}</h3>
//             <p style={styles.description}>{card.description}</p>
//             <div style={styles.rating}>
//               <span>{card.rating}</span>
//             </div>
//             <div style={styles.price}>
//               <span>{card.price}</span>
//             </div>
//             <button style={styles.button}>Know More</button>
//           </div>
//         </div>
//       ))}

//       {popupVisible && currentCard !== null && (
//         <div style={styles.popup}>
//           <div style={styles.popupContent}>
//             <button onClick={closePopup} style={styles.closeButton}>
//               X
//             </button>
           
//             <div style={styles.textContainer}>
//             <VertualroboticsBanner />
              
              
//             </div>
//             {/* <div style={styles.videoContainer}>
//               <video width="100%" height="auto" controls>
//                 <source src={cards[currentCard].video} type="video/mp4" />
//                 Your browser does not support the video tag.
//               </video>
//             </div> */}
//           </div>
//         </div>
//       )}
      
//     </div>
//   );
// };

// export default CardComponent;





import React, { useState } from "react";
import image1 from "../img/aaaservices/1 (2).png";
import image2 from "../img/aaaservices/image (18).png";
import image3 from "../img/aaaservices/image (40).png";
import image4 from "../img/aaaservices/image (32).png";
import image5 from "../img/aaaservices/java.avif";
import image6 from "../img/aaaservices/image (87).png";
import image7 from "../img/aaaservices/image (10).png";
import image8 from "../img/aaaservices/fluter.png";
import video1 from "../img/robotics hands working.mp4";
import video2 from "../img/robotics hands working.mp4";
import video3 from "../img/robotics hands working.mp4";
import video4 from "../img/robotics hands working.mp4";
import VertualroboticsBanner from './VertualroboticsBanner';
import Toolfixturebanner from './Toolfixturebanner';
import Digitalfactory from './Digitalfactory';
import JobsOriented from "./JobsOriented";
import Informationtec from './Informationtec';
import Reactbanner from './Reactbanner';
import Pythonbanner from './Pythonbanner';
import Fluuterbanner from './Fluuterbanner';
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  arrows: true,
};

const CardComponent = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);

  const cards = [
    {
      image: image1,
      title: "Robotic Virtual Simulation",
      secondTitle: "6-Axis industrial robotics virtual simulation",
      description: "Special Offer",
      rating: "Get free training On",
      price: "Process Simulate/ Dalmia Software",
      video: video1,
      bannerComponent: <VertualroboticsBanner />
    },
    {
      image: image2,
      title: "Tool & Fixture Design",
      secondTitle: " Robotics Virtual Simulation",
      description: "Special Offer",
      rating: "Get free training On",
      price: "Process Simulate/ Dalmia Software",
      video: video2,
      bannerComponent: <Toolfixturebanner />
    },
    {
      image: image3,
      title: "Digital factory Solution",
      secondTitle: "6-Axis industrial robotics virtual simulation",
      description: "Special Offer",
      rating: "Get free training On",
      price: "Process Simulate/ Dalmia Software",
      video: video3,
      bannerComponent: <Digitalfactory />
    },
    {
      image: image4,
      title: "Job Oriented  Enhancement",
      secondTitle: "6-Axis industrial robotics virtual simulation",
      description: "Special Offer",
      rating: "Get free training On",
      price: "Process Simulate/ Dalmia Software",
      video: video4,
      bannerComponent: <JobsOriented/> // You can add a banner for this card if needed
    },
    {
      image: image5,
      title: "Java Full Stack program",
      secondTitle: "6-Axis industrial robotics virtual simulation",
      description: "Special Offer",
      rating: "Enhance your Java ",
      price: "Full Stack skills with hands-on experience!",
      video: video3,
      bannerComponent:   <Informationtec/>
      
    },
    {
      image: image6,
      title: "React.js program",
      secondTitle: "6-Axis industrial robotics virtual simulation",
      description: "Special Offer",
      rating: "Enhance your React.js ",
      price: "Full Stack skills with hands-on experience!",
      video: video3,
      bannerComponent:   <Reactbanner/>
      
    },
    {
      image: image7,
      title: "Python Full Stack program",
      secondTitle: "6-Axis industrial robotics virtual simulation",
      description: "Special Offer",
      rating: "Enhance your Python",
      price: "Full Stack skills with hands-on experience!",
      video: video3,
      bannerComponent:    <Pythonbanner/>
      
    },
    {
      image: image8,
      title: "Flutter App Development",
      secondTitle: "6-Axis industrial robotics virtual simulation",
      description: "Special Offer",
      rating: "Enhance your Flutter",
      price: "Full Stack skills with hands-on experience!",
      video: video3,
      bannerComponent:   <Fluuterbanner/>
      
    },
  ];

  const handleCardClick = (index) => {
    setCurrentCard(index);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setCurrentCard(null);
  };

  const styles = {
    container: {
      display: "flex",
      gap: "16px",
      justifyContent: "center",
      alignItems: "flex-start",
      flexWrap: "wrap",
      padding: "16px",
      width: "100%",         // full width of the screen
      height: "100",       // full height of the screen
    },
    
    card: {
      fontFamily: "Poppins", 
      width: "300px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      backgroundColor: "#fff",
      position: "relative",
      cursor: "pointer",
      textAlign: "left",
    },
    image: {
      width: "100%",
      height: "200px",
      objectFit: "cover",
    },
    cardContent: {
      padding: "16px",
      textAlign:'left',
      fontFamily: "Poppins", 
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "8px",
      textAlign:'left',
      fontFamily: "Poppins", 
      
    },
    description: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "8px",
      textAlign:'left',
      fontFamily: "Poppins", 
    },
    rating: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "8px",
      textAlign:'left',
      fontFamily: "Poppins", 
    },
    price: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "blue",
      textAlign:'left',
      fontFamily: "Poppins", 
    },
    button: {
      padding: "10px 20px",
      fontSize: "14px",
      color: "#000",
      fontWeight:'800',
      backgroundColor: "orange",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      width: "100%",
      marginTop: "16px",
    },
    popup: {
      position: "fixed",
      top: "0",
      left: "0",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      width: "100%",
      height: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    },
    popupContent: {
      display: "flex",
      flexDirection: "row",
      // padding: "24px",
      backgroundColor: "#fff",
      maxWidth: "1300px",
      width: "100%",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    },
    textContainer: {
      width: "100%",
      paddingRight: "0px",
    },
    videoContainer: {
      width: "40%",
      paddingLeft: "20px",
    },
    closeButton: {
      position: "absolute",
      // top: "155px",
      marginTop:'-27px',
      right: "106px",
      backgroundColor: "red",
      color: "#fff",
      border: "none",
      borderRadius: "260%",
      width: "26px",
      height: "24px",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      {cards.map((card, index) => (
        <div key={index} style={styles.card} onClick={() => handleCardClick(index)}>
          <img src={card.image} alt={card.title} style={styles.image} />
          <div style={styles.cardContent}>
            <h3 style={styles.title}>{card.title}</h3>
            <p style={styles.description}>{card.description}</p>
            <div style={styles.rating}>
              <span>{card.rating}</span>
            </div>
            <div style={styles.price}>
              <span>{card.price}</span>
            </div>
            <button style={styles.button}>KNOW MORE</button>
          </div>
          
        </div>
      ))}
 
      {popupVisible && currentCard !== null && (
        <div className="poupclose" style={styles.popup}>
           
          <div  style={styles.popupContent}>
            <button onClick={closePopup} style={styles.closeButton}>
              X
            </button>
            <div className="poupcomtener" style={styles.textContainer}>
              <div className="poupcontener">
              {cards[currentCard].bannerComponent}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardComponent;


