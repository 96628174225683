
import React, { useState } from "react";
import FixtureCertificate from "../img/aaaservices/CERTIFICATE-2.jpg";
import FixtureDesignCertificate from "../img/aaaservices/CERTIFICATE-3.jpg";

import reac from "../img/our-team/hshhs.PNG";
const CertificationDFS = () => {
    const [showMore, setShowMore] = useState(false);

    // Toggle visibility of hidden features
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="d-flex align-items-center justify-content-center" style={{marginBottom:'0px',marginTop:'100px'}}>
            <div className="container mb-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                        <div>
                            <h2 style={{ fontWeight: 'bold',}}>Digital Factory Solution <span style={{color:'#ffa500',fontWeight:'800'}}>Certification Advantage</span> </h2>
                            <p style={{textAlign:'left' }}>Elevate your career in mechanical design and manufacturing with the Digital Facilities Solution Program by the International Institute of Automate (IIA). Our certification offers comprehensive insights into the world of design engineering, enabling professionals to create high-precision tools and fixtures that drive production efficiency and product quality. </p>
                        </div>
                        <br />

                        <div className="row justify-content-center ml-5">
                            {/* First column with text content */}
                            <div className="col-lg-6 custom-column">
                                <div className="detailcontent">
                                    <img
                                        src={FixtureDesignCertificate}  // Replace with your actual image URL
                                        alt="Course Image"
                                        className="img-fluid"  // Makes the image responsive
                                        style={{ borderRadius: '8px', width: '100%' }}
                                    />
                                </div>
                            </div>

                            {/* Second column with text content */}
                            <div className="col-lg-6 custom-column">
                                <div className="detailcontent">
                                    <img
                                        src={FixtureCertificate}  // Replace with your actual image URL
                                        alt="Course Image"
                                        className="img-fluid certiCertification"  // Makes the image responsive
                                        style={{ borderRadius: '8px', width: '100%',height:'350px'}}
                                    />
                                </div>
                            </div>

                            {/* Third column with an image */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CertificationDFS;
