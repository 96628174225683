// import React, { useState } from "react";

// const SkilledUniversitiesDFS = () => {
//     const [showMore, setShowMore] = useState(false);

//     // Toggle visibility of hidden features
//     const toggleShowMore = () => {
//         setShowMore(!showMore);
//     };

//     return (

//         <div className="container "> {/* Adjusted column width to 11 */}

//             <div className="row  " style={{marginTop:'50px'}}>
//                 <div className="col-md-12">
//                     <h4 style={{ fontWeight: 'bold',paddingBottom:'50px',textAlign:'left' }}>7+ Skills Covered</h4>


//                     <ul className="col-md-12 d-flex flex-wrap justify-content-left">
//                         <li className="col-md-4">
//                             <p className="tick-mark">Parametric Modeling </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Line Designing </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">2D layout study </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Problem-solving</p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Planning and techniques   </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Validation </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Quality Control  </p>
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//             <style>
//                 {`
//                     /* Common styles for tick-mark items */
//                     .tick-mark {
//                         font-size: 16px;
//                         text-align: justify;
//                         text-indent: -28px;
//                         margin-bottom: 20px;
//                         padding-left: 40px;
//                         position: relative;
//                         font-family: 'Roboto';
//                     }

//                     /* Tick mark styling */
//                     .tick-mark::before {
//                         content: "✓";
//                         color: #164485;
//                         font-weight: bold;
//                         margin-right: 10px;
//                         padding: 5px;
//                         border-radius: 50%;
//                         display: inline-block;
//                         position: absolute;
//                         left: 0;
//                         top: 0;
//                         transform: translateY(-5%);
//                         line-height: 1.2;
//                         font-size: 20px;
//                     }

//                     /* Flexbox layout tweaks */
//                     ul {
//                         list-style: none;
//                         padding: 0;
//                         margin: 0;
//                     }

//                     li {
//                         margin-bottom: 10px;
//                     }

//                     @media (max-width: 768px) {
//                         .tick-mark {
//                             font-size: 14px;
//                         }
//                     }
//                 `}
//             </style>
//         </div>



//     );
// };

// export default SkilledUniversitiesDFS;



import React from "react";
import { FaCogs, FaDraftingCompass, FaCheckCircle, FaLightbulb, FaProjectDiagram, FaTasks, FaShieldAlt } from 'react-icons/fa';

const SkilledUniversitiesDFS = () => {
  const skills = [
    { icon: <FaCogs />, text: "Parametric Modeling" },
    { icon: <FaDraftingCompass />, text: "Line Designing" },
    { icon: <FaProjectDiagram />, text: "2D Layout Study" },
    { icon: <FaLightbulb />, text: "Problem-solving" },
    { icon: <FaTasks />, text: "Planning and Techniques" },
    { icon: <FaCheckCircle />, text: "Validation" },
    { icon: <FaShieldAlt />, text: "Quality Control" },
  ];

  return (
    <div className="container">
      <div className="row" style={{ marginTop: "77px" }}>
        <div className="col-md-12">
          <h4
            style={{
              fontWeight: "900",
              paddingBottom: "50px",
              textAlign: "center",
              fontSize: "35px",
              textTransform: "initial",
              width: "100%",
            }}
          >
            Top <span style={{ color: "orange", fontSize: "35px" }}>Skills Covered</span>
          </h4>
          <ul className="skills-grid">
            {skills.map((skill, index) => (
              <li
                key={index}
                className={`skill-item ${index === 6 ? "centered" : ""}`}
              >
                <div className="skill-icon">{skill.icon}</div>
                <p className="skill-text">{skill.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <style>
        {`
          .skills-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .skill-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            background-color: #f5f5f5;
            border: 2px solid #ffc107;
            border-radius: 15px;
            text-align: center;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }

          .skill-item:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
            border-top: 7px solid orange;
            border-right: 4px solid orange;
          }

          .skill-icon {
            font-size: 30px;
            color: #164485;
            margin-bottom: 10px;
          }

          .skill-text {
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            color: #333;
          }

          /* Center the last item in the last row */
          .centered {
            grid-column: 2 / 3; /* Place the last container in the middle column */
          }

          /* Responsive for mobile view */
          @media (max-width: 768px) {
            .skills-grid {
              grid-template-columns: repeat(2, 1fr);
              gap: 15px;
            }
            .centered {
              grid-column: 1 / 3; /* Center the container across two columns */
            }
            .skill-text {
              font-size: 14px;
            }
          }

          @media (max-width: 576px) {
            .skills-grid {
              grid-template-columns: 1fr;
            }
            .skill-item {
              padding: 15px;
            }
            .skill-icon {
              font-size: 24px;
            }
            .skill-text {
              font-size: 13px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SkilledUniversitiesDFS;
