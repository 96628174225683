import React from 'react';
import collageImage from '../img/latest-imgs/kkkkl.jpg';
// import collageImage from '../img/latest-imgs/kkkkl.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRocket,
  faPalette,
  faHandshake,
  faCogs,
  faShieldAlt,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';

const WhyChooseUs = () => {
  return (
    <section className="why-choose-us-area">
      <div className="container-fluid">
        <div className="row">
          {/* Image Section */}
          <div className="col-lg-5 col-md-12">
            <div className="why-choose-us-image">
              <img
                className='image-mirage'
                src={collageImage}
                alt="About Home"
                style={{ maxHeight: "691px", width: '100%',height:'687px'}}
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-7 col-md-12">
            <div className="why-choose-us-content">
              <div className="contents">
                <h2 className='ChooseH2' style={{ color: '#fff', marginBottom: '45px', textAlign: 'center', fontWeight: '900' }}>
                  Why <span style={{ color: '#fff', fontWeight: '900' }}>Choose Us</span>
                </h2>
                <h3 style={{color:'#f7941d', marginBottom:'0px'}}>Empower your path with knowledge and endless possiblities.</h3>
                

                {/* Features List */}
                <ul className="features-list">
                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faRocket} />
                    </div>
                    <span style={{ color: '#f7941d' }}>Innovative Learning Tools</span>
                    <div className='ChoosePara' style={{ width: '80%' }}>We leverage advanced learning technologies, including virtual classrooms and AI-driven content.</div>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faPalette} />
                    </div>
                    <span style={{ color: '#f7941d' }}>Customized Learning Paths</span>
                    <div className='ChoosePara'  style={{ width: '80%' }}>Our courses are designed to cater to diverse learning needs, offering tailored educational journeys.</div>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faHandshake} />
                    </div>
                    <span style={{ color: '#f7941d' }}>Expert Instructors</span>
                    <div className='ChoosePara'  style={{ width: '80%' }}>Learn from industry professionals and experienced educators who bring real-world expertise to the classroom.</div>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCogs} />
                    </div>
                    <span style={{ color: '#f7941d' }}>Seamless Learning Experience</span>
                    <div className='ChoosePara'  style={{ width: '80%' }}>Our platform offers an intuitive, user-friendly interface to ensure a smooth learning process.</div>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faShieldAlt} />
                    </div>
                    <span style={{ color: '#f7941d' }}>Commitment to Excellence</span>
                    <div className='ChoosePara'  style={{ width: '80%' }}>We focus on delivering high-quality educational content that meets the highest standards.</div>
                  </li>

                  <li>
                    <div className="icon">
                      <FontAwesomeIcon icon={faUserCheck} />
                    </div>
                    <span style={{ color: '#f7941d' }}>Student Success</span>
                    <div className='ChoosePara'  style={{ width: '80%' }}>Your success is our priority, and we provide support every step of the way to ensure you achieve your goals.</div>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;



