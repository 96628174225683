// import React, { useState } from "react";

// const SkilledUniversitiesVR = () => {
//     const [showMore, setShowMore] = useState(false);

//     // Toggle visibility of hidden features
//     const toggleShowMore = () => {
//         setShowMore(!showMore);
//     };

//     return (

//         <div className="container "> {/* Adjusted column width to 11 */}

//             <div className="row  d-flex justify-content-center" style={{marginTop:'77px'}}>
//                 <div className="col-md-12">
//                     <h4 style={{ fontWeight: 'bold',paddingBottom:'50px',textAlign:'left' }}>7+ Skills Covered</h4>


//                     <ul className="col-md-12 d-flex flex-wrap justify-content-left">
//                         <li className="col-md-4">
//                             <p className="tick-mark">Planning and techniques    </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Problem-Solving in Robotics  </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Validation  </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Kinematics</p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Path Planning   </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Quality Control   </p>
//                         </li>
//                         <li className="col-md-4">
//                             <p className="tick-mark">Tool Testing and Validation Procedures</p>
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//             <style>
//                 {`
//                     /* Common styles for tick-mark items */
//                     .tick-mark {
//                         font-size: 16px;
//                         text-align: justify;
//                         text-indent: -28px;
//                         margin-bottom: 20px;
//                         padding-left: 40px;
//                         position: relative;
//                         font-family: 'Roboto';
//                     }

//                     /* Tick mark styling */
//                     .tick-mark::before {
//                         content: "✓";
//                         color: #164485;
//                         font-weight: bold;
//                         margin-right: 10px;
//                         padding: 5px;
//                         border-radius: 50%;
//                         display: inline-block;
//                         position: absolute;
//                         left: 0;
//                         top: 0;
//                         transform: translateY(-5%);
//                         line-height: 1.2;
//                         font-size: 20px;
//                     }

//                     /* Flexbox layout tweaks */
//                     ul {
//                         list-style: none;
//                         padding: 0;
//                         margin: 0;
//                     }

//                     li {
//                         margin-bottom: 10px;
//                     }

//                     @media (max-width: 768px) {
//                         .tick-mark {
//                             font-size: 14px;
//                         }
//                     }
//                 `}
//             </style>
//         </div>



//     );
// };

// export default SkilledUniversitiesVR;



// import React from "react";
// import {
//   FaTools,
//   FaRobot,
//   FaClipboardCheck,
//   FaArrowsAlt,
//   FaRoute,
//   FaClipboardList,
//   FaWrench,
// } from "react-icons/fa";

// const SkilledUniversitiesVR = () => {
//   const skills = [
//     { icon: <FaTools />, text: "Planning and Techniques" },
//     { icon: <FaRobot />, text: "Problem-Solving in Robotics" },
//     { icon: <FaClipboardCheck />, text: "Validation" },
//     { icon: <FaArrowsAlt />, text: "Kinematics" },
//     { icon: <FaRoute />, text: "Path Planning" },
//     { icon: <FaClipboardList />, text: "Quality Control" },
//     { icon: <FaWrench />, text: "Tool Testing and Validation Procedures" },
//   ];

//   return (
//     <div className="container">
//       <div className="row" style={{ marginTop: "10px" }}>
//         <div className="col-md-12">
//           <h4
//             style={{
//               fontWeight: "900",
//               paddingBottom: "50px",
//               textAlign: "center",
//               fontSize: "35px",
//               textTransform: "initial",
//               width: "100%",
//             }}
//           >
//             Top <span style={{ color: "orange", fontSize: "35px" }}>Skills Covered</span>
//           </h4>
//           <ul className="skills-grid">
//             {skills.map((skill, index) => (
//               <li
//                 key={index}
//                 className={`skill-item ${index === 6 ? "centered" : ""}`}
//               >
//                 <div className="skill-icon">{skill.icon}</div>
//                 <p className="skill-text">{skill.text}</p>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//       <style>
//         {`
//           .skills-grid {
//             display: grid;
//             grid-template-columns: repeat(3, 1fr);
//             gap: 20px;
//             list-style: none;
//             padding: 0;
//             margin: 0;
//           }

//           .skill-item {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             flex-direction: column;
//             padding: 20px;
//             background-color: #f5f5f5;
//             border: 2px solid #ffc107;
//             border-radius: 15px;
//             text-align: center;
//             transition: transform 0.3s ease, box-shadow 0.3s ease;
//             box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//           }

//           .skill-item:hover {
//             transform: scale(1.05);
//             box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
//             border-top: 7px solid orange;
//             border-right: 4px solid orange;
//           }

//           .skill-icon {
//             font-size: 30px;
//             color: #164485;
//             margin-bottom: 10px;
//           }

//           .skill-text {
//             font-size: 16px;
//             font-family: 'Roboto', sans-serif;
//             color: #333;
//           }

//           /* Center the last item in the last row */
//           .centered {
//             grid-column: 2 / 3; /* Place the last container in the middle column */
//           }

//           /* Responsive for mobile view */
//           @media (max-width: 768px) {
//             .skills-grid {
//               grid-template-columns: repeat(2, 1fr);
//               gap: 15px;
//             }
//             .centered {
//               grid-column: 1 / 3; /* Center the container across two columns */
//             }
//             .skill-text {
//               font-size: 14px;
//             }
//           }

//           @media (max-width: 576px) {
//             .skills-grid {
//               grid-template-columns: 1fr;
//             }
//             .skill-item {
//               padding: 15px;
//             }
//             .skill-icon {
//               font-size: 24px;
//             }
//             .skill-text {
//               font-size: 13px;
//             }
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default SkilledUniversitiesVR;










// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail">
//       <ul style={{ fontSize: '20px', textAlign: 'justify', listStyleType: 'none', paddingLeft: '0', fontFamily: 'Roboto', color: '#000' }}>
//         <li style={{ marginBottom: '20px' }}>
//           <div style={boxStyle}>
//             <strong>DENTAL IMPLANTS</strong>: A permanent solution for replacing missing teeth, involving the placement of titanium posts into the jawbone.
//           </div>
//         </li>
//         <li style={{ marginBottom: '20px' }}>
//           <div style={boxStyle}>
//             <strong>DENTAL IMPLANTS</strong>: Designed to match the look and feel of natural teeth, offering a more comfortable and secure fit than traditional dentures.
//           </div>
//         </li>
//         <li style={{ marginBottom: '20px' }}>
//           <div style={boxStyle}>
//             <strong>DENTAL IMPLANTS</strong>: Help restore function and appearance, allowing you to eat, speak, and smile with confidence.
//           </div>
//         </li>
//         <li style={{ marginBottom: '20px' }}>
//           <div style={boxStyle}>
//             <strong>DENTAL IMPLANTS</strong>: Provide long-lasting results with proper care, often lasting for decades or a lifetime.
//           </div>
//         </li>
//         <li style={{ marginBottom: '20px' }}>
//           <div style={boxStyle}>
//             <strong>DENTAL IMPLANTS</strong>: A safe and effective procedure with a high success rate, often preferred over other alternatives.
//           </div>
//         </li>
//       </ul>
//     </div>
//   );
// }

// const boxStyle = {
//   border: '1px solid #ccc',
//   padding: '15px',
//   borderRadius: '8px',
//   backgroundColor: '#f9f9f9',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   fontSize: '18px',
//   margin: '10px 0'
// };

// export default DentalImplants;





// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//       WHO HAS TO   <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>1.	Professionals in Robotics and Automation:</strong>: Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong> 2.	Students and Graduates: </strong>:Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>3.	Manufacturing and Industrial Managers: </strong> Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>4.	Technology Enthusiasts: </strong>Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools. .
//           </div>
//         </li>
//       </ul>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px', // Limiting the max width to prevent full-screen usage
//   margin: '0 auto', // Center the container
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: "bold",
//   color: "#000",
//   textAlign: "center",
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center', // Center-align the text inside the boxes
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap', // Wraps the boxes to the next line if there's not enough space
//   justifyContent: 'space-between', // Distribute space evenly between boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   width: 'calc(50% - 10px)', // Each box takes up 50% of the container width (2 per row)
//   padding: '10px',
//   boxSizing: 'border-box',
// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '1px solid #ccc',
//   padding: '15px',
//   borderRadius: '8px',
//   backgroundColor: '#f9f9f9',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   fontSize: '18px',
//   margin: '10px',
//   textAlign: 'left', // Align the text inside the box to the left
// };

// export default DentalImplants;






// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>1. Professionals in Robotics and Automation:</strong> Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>2. Students and Graduates:</strong> Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>3. Manufacturing and Industrial Managers:</strong> Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>4. Technology Enthusiasts:</strong> Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//           }
//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px', // Limiting the max width to prevent full-screen usage
//   margin: '0 auto', // Center the container
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center', // Center-align the text inside the boxes
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap', // Wraps the boxes to the next line if there's not enough space
//   justifyContent: 'space-between', // Distribute space evenly between boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   width: 'calc(50% - 10px)', // Each box takes up 50% of the container width (2 per row)
//   padding: '10px',
//   boxSizing: 'border-box',
// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '1px solid #ccc',
//   padding: '15px',
//   borderRadius: '8px',
//   backgroundColor: '#f9f9f9',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   fontSize: '18px',
//   margin: '10px',
//   textAlign: 'left', // Align the text inside the box to the left
// };

// export default DentalImplants;




// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>1. Professionals in Robotics and Automation:</strong> Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>2. Students and Graduates:</strong> Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>3. Manufacturing and Industrial Managers:</strong> Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>4. Technology Enthusiasts:</strong> Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
           
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//           }
//           li {
//             width: 100%;
//             margin-bottom: 15px;
            
//           }
//           div {
//             font-size: 16px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px', // Limiting the max width to prevent full-screen usage
//   margin: '0 auto', // Center the container
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center', // Center-align the text inside the boxes
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap', // Wraps the boxes to the next line if there's not enough space
//   justifyContent: 'space-between', // Distribute space evenly between boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   // width: 'calc(50% - 10px)', // Each box takes up 50% of the container width (2 per row)
//   padding: '10px',
//   boxSizing: 'border-box',
// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '1px solid #ccc',
//   padding: '15px',
//   borderRadius: '8px',
//   backgroundColor: '#f9f9f9',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   fontSize: '18px',
//   margin: '10px',
//   textAlign: 'left', // Align the text inside the box to the left
//   height:'135px',
//   width:'580px',
// };

// export default DentalImplants;




// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>1. Professionals in Robotics and Automation:</strong> Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>2. Students and Graduates:</strong> Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>3. Manufacturing and Industrial Managers:</strong> Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle}>
//             <strong>4. Technology Enthusiasts:</strong> Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//           }
//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center',
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap', // Boxes will wrap into a new row when there's not enough space
//   justifyContent: 'space-between', // Distribute space evenly between the boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   width: '48%', // Each box will take up roughly 50% of the container's width, with a small gap
//   boxSizing: 'border-box',
//   padding: '10px',
// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '1px solid #ccc',
//   padding: '15px',
//   borderRadius: '8px',
//   backgroundColor: '#f9f9f9',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   fontSize: '18px',
//   margin: '10px',
//   textAlign: 'left',
//   height: '135px',
//   width:'560px',
// };

// export default DentalImplants;



// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong>1. Professionals in Robotics and Automation:</strong> Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong>2. Students and Graduates:</strong> Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong>3. Manufacturing and Industrial Managers:</strong> Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong>4. Technology Enthusiasts:</strong> Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//           }
//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }

//         .box:hover {
//           background-color: black;
//           color: white;
//           border-color: orange;
//         }

//         .box:hover strong {
//           color: orange;
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center',
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap', // Boxes will wrap into a new row when there's not enough space
//   justifyContent: 'space-between', // Distribute space evenly between the boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   width: '48%', // Each box will take up roughly 50% of the container's width, with a small gap
//   boxSizing: 'border-box',
//   padding: '10px',
// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '2px solid #orange',
//   padding: '15px',
//   borderRadius: '8px',
  
//   fontSize: '18px',
//   margin: '10px',
  
//   height: '135px',
//   width: '560px',
//   transition: 'all 0.3s ease-in-out',
// };

// export default DentalImplants;


// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>1. Professionals in Robotics and Automation:</strong> Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>2. Students and Graduates:</strong> Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>3. Manufacturing and Industrial Managers:</strong> Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>4. Technology Enthusiasts:</strong> Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//           }
//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }

//         .box {
//           display: flex;
//           flex-direction: column;
//           justify-content: space-between;
//         }

//         .box:hover {
//           background-color: black;
//           color: white;
        
//            border-color: orange; /* Border turns yellow */
//                     border-width: 2px; /* Border becomes thicker */
//         }

//         .box:hover strong {
//           color: orange;
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center',
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap', // Boxes will wrap into a new row when there's not enough space
//   justifyContent: 'space-between', // Distribute space evenly between the boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   width: '48%', // Each box will take up roughly 50% of the container's width, with a small gap
//   boxSizing: 'border-box',
//   padding: '10px',
// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '2px solid #ccc',
//   padding: '15px',
//   borderRadius: '8px',
//   fontSize: '18px',
//   margin: '10px',
//   height: '135px',
//   width: '560px',
//   transition: 'all 0.3s ease-in-out',
// };

// // Styles for the strong element to center it inside the box
// const strongStyle = {
//   textAlign: 'center', // Center align the strong text
//   marginBottom: '5px', // Add spacing below the heading
// };

// export default DentalImplants;





// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: 'orange' }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>1. Professionals in Robotics and Automation:</strong> Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>2. Students and Graduates:</strong> Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>3. Manufacturing and Industrial Managers:</strong> Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>4. Technology Enthusiasts:</strong> Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//           }
//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }

//         .box {
//           display: flex;
//           flex-direction: column;
//           justify-content: space-between;
//         }

//         .box:hover {
//           background-color: black;
//           color: white;
//           border-color: orange; /* Border turns yellow */
//           border-width: 2px; /* Border becomes thicker */
//         }

//         .box:hover strong {
//           color: orange;
//         }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
// };

// // Styles for the heading
// const headingStyle = {
//   fontWeight: 'bold',
//   color: '#000',
//   textAlign: 'center',
//   marginTop: '67px',
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center',
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap', // Boxes will wrap into a new row when there's not enough space
//   justifyContent: 'space-between', // Distribute space evenly between the boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   width: '48%', // Each box will take up roughly 50% of the container's width, with a small gap
//   boxSizing: 'border-box',
//   padding: '10px',
// };

// // Styles for the individual boxes
// const boxStyle = {
//   border: '2px solid #ccc',
//   padding: '15px',
  
//   fontSize: '18px',
//   margin: '10px',
//   height: '135px',
//   width: '560px',
//   transition: 'all 0.3s ease-in-out',
// };

// // Styles for the strong element to center it inside the box
// const strongStyle = {
//   textAlign: 'center', // Center align the strong text
//   marginBottom: '10px', // Add spacing below the heading
//   position: 'relative', // Position relative to the parent
// };

// // Add line below the heading using a pseudo-element
// const lineStyle = {
//   content: '""',
//   display: 'block',
//   width: '50%', // The line width is smaller than the text width
//   height: '2px', // Line thickness
//   backgroundColor: 'orange', // Line color
//   margin: '10px auto', // Center the line
// };

// export default DentalImplants;

// import React from 'react';

// const DentalImplants = () => {
//   return (
//     <div className="textdetail" style={containerStyle}>
//        <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: 'orange' }}>ATTEND</span>
//        </h3>
//       <ul style={listStyle}>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>
//               1. Professionals in Robotics and Automation:
//             </strong>
//             Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>
//               2. Students and Graduates:
//             </strong>
//             Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>
//               3. Manufacturing and Industrial Managers:
//             </strong>
//             Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.
//           </div>
//         </li>
//         <li style={listItemStyle}>
//           <div style={boxStyle} className="box">
//             <strong style={strongStyle}>
//               4. Technology Enthusiasts:
//             </strong>
//             Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.
//           </div>
//         </li>
//       </ul>
//       <style jsx>{`
//         @media (max-width: 768px) {
//           .textdetail {
//             padding: 0 10px;
//           }
//           h3 {
//             font-size: 22px;
//           }
//           ul {
//             flex-direction: column;
//           }
//           li {
//             width: 100%;
//             margin-bottom: 15px;
//           }
//           div {
//             font-size: 16px;
//           }
//         }

//         .box {
//           display: flex;
//           flex-direction: column;
//           justify-content: space-between;
//         }

//         .box:hover {
//           background-color: black;
//           color: white;
//           border-color: orange; /* Border turns yellow */
//           border-width: 2px; /* Border becomes thicker */
//         }

//         .box:hover strong {
//           color: orange;
//         }

//         /* Add underline to strong heading */
//         strong {
//           position: relative; /* So that we can position the underline */
//           display: inline-block;
//         }

//         strong {
//   position: relative; /* Ensure the strong element can position the pseudo-element */
// }

// strong::after {
//   content: ''; /* Create an empty pseudo-element */
//   position: absolute;
//   left: 50%; /* Position the line at the center horizontally */
//   bottom: -5px; /* Position the line slightly below the text */
//   width: 20%; /* The underline width is 20% of the text width */
//   height: 2px; /* Line thickness */
//   background-color: #ccc; /* Line color */
//   transform: translateX(-50%); /* Adjust for the line's own width, centering it */
// }
//       `}</style>
//     </div>
//   );
// };

// // Styles for the container (fluid layout with space between items)
// const containerStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   padding: '0 15px',
//   width: '100%',
//   maxWidth: '1200px',
//   margin: '0 auto',
//   marginTop:'29px',
// };
// const headingStyle = {
//   fontSize: '30px',  // Example style
//   fontWeight: 'bold', // Example style
//   textAlign: 'center', // Example style
//   // Add any other styles you need
// };

// // Styles for the list
// const listStyle = {
//   listStyleType: 'none',
//   paddingLeft: '0',
//   fontFamily: 'Roboto',
//   fontSize: '20px',
//   color: '#000',
//   textAlign: 'center',
//   width: '100%',
//   display: 'flex',
//   flexWrap: 'wrap',
//   justifyContent: 'space-between', // Distribute space evenly between the boxes
// };

// // Styles for the list items
// const listItemStyle = {
//   marginBottom: '20px',
//   width: '48%', // Each box will take up roughly 50% of the container's width, with a small gap
//   boxSizing: 'border-box',
//   padding: '10px',
// };


// // Styles for the individual boxes
// const boxStyle = {
//   border: '2px solid #ccc',
//   padding: '15px',
//   // borderRadius: '8px',
//   fontSize: '18px',
//   margin: '10px',
//   height: '135px',
//   width: '560px',
//   transition: 'all 0.3s ease-in-out',
// };

// // Styles for the strong element to center it inside the box
// const strongStyle = {
//   textAlign: 'center', // Center align the strong text
//   marginBottom: '10px', // Add spacing below the heading

// };


// export default DentalImplants;





// import React, { useState } from "react";

// const DentalImplants = () => {
//   // Define hover state for boxes
//   const [hoveredBox, setHoveredBox] = useState(null);

//   // Function to handle mouse enter for box
//   const handleMouseEnter = (index) => {
//     setHoveredBox(index);
//   };

//   // Function to handle mouse leave for box
//   const handleMouseLeave = () => {
//     setHoveredBox(null);
//   };

//   // Box styles (dynamic based on hover state)
//   const getBoxStyle = (index) => ({
//     border: hoveredBox === index ? "2px solid orange" : "2px solid #ccc",
//     padding: "15px",
//     fontSize: "18px",
//     margin: "10px",
//     height: "135px",
//     width: "560px",
//     transition: "all 0.3s ease-in-out",
//     backgroundColor: hoveredBox === index ? "#000" : "#fff",
//     color: hoveredBox === index ? "#fff" : "#000",
//   });

//   // Heading styles (dynamic based on box hover state)
//   const getHeadingStyle = (index) => ({
//     color: hoveredBox === index ? "orange" : "#000",
//     position: "relative",
//     transition: "color 0.3s ease-in-out",
//   });

//   // Underline styles (visible only when the box is hovered)
//   const getUnderlineStyle = (index) => ({
//     content: "''",
//     position: "absolute",
//     bottom: "-5px",
//     left: "50%",
//     transform: "translateX(-50%)",
//     width: hoveredBox === index ? "50%" : "0%",
//     height: "2px",
//     backgroundColor: "orange",
//     transition: "width 0.3s ease-in-out",
//   });

//   return (
//     <div className="textdetail" style={containerStyle}>
//       <h3 style={headingStyle}>
//         WHO HAS TO <span style={{ color: "orange" }}>ATTEND</span>
//       </h3>
//       <ul style={listStyle}>
//         {boxContent.map((content, index) => (
//           <li
//             key={index}
//             style={listItemStyle}
//             onMouseEnter={() => handleMouseEnter(index)}
//             onMouseLeave={handleMouseLeave}
//           >
//             <div style={getBoxStyle(index)}>
//               <strong style={getHeadingStyle(index)}>
//                 {content.title}
//                 <span style={getUnderlineStyle(index)}></span>
//               </strong>
//               <p style={{ marginTop: "10px", textAlign: "center",fontFamily:'roboto',fontSize:'17px', }}>
//                 {content.description}
//               </p>
//             </div>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// // Content for the boxes
// const boxContent = [
//   {
//     title: "1. Professionals in Robotics and Automation:",
//     description:
//       "Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.",
//   },
//   {
//     title: "2. Students and Graduates:",
//     description:
//       "Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.",
//   },
//   {
//     title: "3. Manufacturing and Industrial Managers:",
//     description:
//       "Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.",
//   },
//   {
//     title: "4. Technology Enthusiasts:",
//     description:
//       "Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.",
//   },
// ];

// // Styles for the container
// const containerStyle = {
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   padding: "0 15px",
//   width: "100%",
//   maxWidth: "1200px",
//   margin: "0 auto",
//   marginTop: "29px",
// };

// const headingStyle = {
//   fontSize: "30px",
//   fontWeight: "bold",
//   textAlign: "center",
//   fontFamily:'roboto',
// };

// const listStyle = {
//   listStyleType: "none",
//   paddingLeft: "0",
//   fontFamily: "Roboto",
//   fontSize: "20px",
//   color: "#000",
//   textAlign: "center",
//   width: "100%",
//   display: "flex",
//   flexWrap: "wrap",
//   justifyContent: "space-between",
//   fontFamily:'roboto',
// };

// const listItemStyle = {
//   marginBottom: "20px",
//   width: "48%",
//   boxSizing: "border-box",
//   padding: "10px",
// };

// export default DentalImplants;



import React, { useState } from "react";

const DentalImplants = () => {
  // Define hover state for boxes
  const [hoveredBox, setHoveredBox] = useState(null);

  // Function to handle mouse enter for box
  const handleMouseEnter = (index) => {
    setHoveredBox(index);
  };

  // Function to handle mouse leave for box
  const handleMouseLeave = () => {
    setHoveredBox(null);
  };

  // Box styles (dynamic based on hover state)
  const getBoxStyle = (index) => ({
    border: hoveredBox === index ? "2px solid orange" : "2px solid #ccc",
    padding: "15px",
    fontSize: "18px",
    margin: "10px",
    height: "auto",
    width: "100%",
    maxWidth: "560px",
    transition: "all 0.3s ease-in-out",
    backgroundColor: hoveredBox === index ? "#000" : "#fff",
    color: hoveredBox === index ? "#fff" : "#000",
  });

  // Heading styles (dynamic based on box hover state)
  const getHeadingStyle = (index) => ({
    color: hoveredBox === index ? "orange" : "#000",
    position: "relative",
    transition: "color 0.3s ease-in-out",
  });

  // Underline styles (visible only when the box is hovered)
  const getUnderlineStyle = (index) => ({
    content: "''",
    position: "absolute",
    bottom: "-5px",
    left: "50%",
    transform: "translateX(-50%)",
    width: hoveredBox === index ? "50%" : "0%",
    height: "2px",
    backgroundColor: "orange",
    transition: "width 0.3s ease-in-out",
  });

  return (
    <div className="textdetail" style={containerStyle}>
      <h3 style={headingStyle}>
        WHO HAS TO <span style={{ color: "orange" }}>ATTEND</span>
      </h3>
      <ul style={listStyle}>
        {boxContent.map((content, index) => (
          <li
            key={index}
            style={listItemStyle}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div style={getBoxStyle(index)}>
              <strong style={getHeadingStyle(index)}>
                {content.title}
                <span style={getUnderlineStyle(index)}></span>
              </strong>
              <p
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  fontFamily: "roboto",
                  fontSize: "17px",
                  
                }}
              >
                {content.description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

// Content for the boxes
const boxContent = [
  {
    title: "1. Professionals in Robotics and Automation:",
    description:
      "Engineers, technicians, and specialists looking to deepen their knowledge and expertise in robotic programming and simulation.",
  },
  {
    title: "2. Students and Graduates:",
    description:
      "Individuals studying robotics, mechatronics, or related fields who want practical experience and to enhance their career prospects.",
  },
  {
    title: "3. Manufacturing and Industrial Managers:",
    description:
      "Those overseeing automation processes who need to understand how to integrate and manage robotic systems effectively.",
  },
  {
    title: "4. Technology Enthusiasts:",
    description:
      "Individuals passionate about automation and robotics who wish to gain hands-on experience with cutting-edge simulation tools.",
  },
];

// Styles for the container
const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "0 15px",
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
  marginTop: "29px",
};

const headingStyle = {
  fontSize: "30px",
  fontWeight: "bold",
  textAlign: "center",
  fontFamily: "roboto",
};

const listStyle = {
  listStyleType: "none",
  paddingLeft: "0",
  fontFamily: "Roboto",
  fontSize: "20px",
  color: "#000",
  textAlign: "center",
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
};

const listItemStyle = {
  marginBottom: "20px",
  width: "100%",
  maxWidth: "48%",
  boxSizing: "border-box",
  padding: "10px",
 
};

export default DentalImplants;




