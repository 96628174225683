import React from 'react';
import bannerImage from "../img/banners/placements-banner.jpg"; // Adjust the path according to your project structure

const WhoweareBanner = () => {
  return (
    <div className="hero-area overly-style-1 opacity-point-4">
      {/* Replace video with image data in the functining formates WhoweAre.js, WhyJoinThisProgramme.js, WorkshoapBanner.js,*/}
      <img
        className="banner-image  aboutbanner-dataimg"
        src={bannerImage}
        alt="About AA Applications Banner"
        style={{ width: '100%', height: 'auto' }}
      />

      <div style={{ color: '#fff', position: 'absolute', top: '300px', textAlign: 'left' }} className='about-databanner'>

        {/* <h1 style={{color:"#fff",fontSize:"48px", fontFamily:'urbanist , sarif'}}>ABOUT <span style={{fontFamily: 'urbanist , sarif', }}> IIA </span></h1> */}

        <h1 style={{ marginBottom: '21px', fontSize: '70px',textAlign:'center' }}>At <span style={{ color: '#fb9100' }}>IIA</span></h1>

        {/* <p style={{textAlign:'left', fontSize:'23px',fontWeight:'500',letterSpacing:'0'}} className='aboutcontentdata'>We offer dynamic, industry-oriented training that aligns with the latest technological advancements, ensuring our students become highly employable professionals. Our courses are designed to equip you with the skills needed to excel in the global workforce.</p> */}

        <div  className='aboutdata-banner'>
          {/* <p style={{ textAlign: 'left', fontSize: '30px', fontWeight: '900' }}>We Provide :</p> */}

          <ul style={{fontSize:'25px',fontWeight:'800'}}>
            <li>Industry-Oriented Training. </li>
            <li>Live Expert Techno-Talks.</li>
            <li>Affordable Education.</li>
            <li>Guaranteed Career Growth.  </li>
            <li>Latest Technology Integration.</li>
          </ul>
        </div>


      </div>
    </div>
  );
};

export default WhoweareBanner;
