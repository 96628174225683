// import React from 'react';
// import CountUp from 'react-countup';
// import interiorDesignIcon from '../img/icon/interior-designs.png'; // Adjust the path  src/img/icon/certificate.png
// import recommendIcon from '../img/icon/recommend.png'; // Adjust the path
// import experienceIcon from '../img/icon/collabration.png'; // Adjust the path
// import experienceIcons from '../img/icon/certificate.png'; // Adjust the path



// const Stack = () => {
//   return (
//     <section className="counter-section stack">
//       <div className="container">
//         {/* <h1 className="main-title">Counter Section</h1> */}
//         <div className="row" style={{ marginTop: '-180px' }}>
//           <div className="col-lg-3 col-sm-6">
//             <div className="counter-count">
//               <img src={experienceIcons} alt="Experience Icon" height="50px" />
//               <span className="count">
//                 <CountUp start={0} end={6} duration={4} />
//               </span>
//               <h3 style={{fontSize:'20px'}}>Courses</h3>
//             </div>
//           </div>
//           <div className="col-lg-3 col-sm-6">
//             <div className="counter-count">
//               <img src={experienceIcon} alt="Experience Icon" height="50px" />
//               <span className="count">
//                 <CountUp start={0} end={50} duration={3} />
//               </span>
//               <h3 style={{fontSize:'20px'}}>Corporate Partnership</h3>

//             </div>
//           </div>



//           <div className="col-lg-3 col-sm-3">
//             <div className="counter-count">
//               <img src={interiorDesignIcon} alt="Branches Icon" height="50px" />
//               <span className="count">
//                 <CountUp start={0} end={4} duration={4} />
//               </span>
//               <h3 style={{fontSize:'20px'}}>Branches</h3>
//             </div>
//           </div>


//           <div className="col-lg-3 col-sm-6">
//             <div className="counter-count">
//               <img src={recommendIcon} alt="Experience Icon" height="50px" />
//               <span className="count">
//                 <CountUp start={0} end={1000} duration={2.5} />
//               </span>
//               <h3 style={{fontSize:'20px'}}>Placements</h3>
//             </div>
//           </div>

//         </div>
//       </div>
//     </section>
//   );
// };

// export default Stack;























import React from 'react';
import CountUp from 'react-countup';
import interiorDesignIcon from '../img/icon/interior-designs.png'; // Adjust the path  src/img/icon/certificate.png
import recommendIcon from '../img/icon/recommend.png'; // Adjust the path
import experienceIcon from '../img/icon/collabration.png'; // Adjust the path
import experienceIcons from '../img/icon/certificate.png'; // Adjust the path

const Stack = () => {
  return (
    <section className="counter-section stack">
      <div className="container">
        
        <div className="row" style={{ marginTop: '-228px' }}>
          <div className="col-lg-3 col-sm-6">
            <div className="counter-count">
              <div>
                <img src={experienceIcons} alt="Experience Icon" height="50px" />
              </div>
              <span className="count" style={{ display: 'inline',fontSize:'60px' }}>
                <CountUp start={0} end={6} duration={5} />
              </span>
              <span style={{ display: 'inline', marginLeft: '5px',color:'white', fontSize:'60px' }}>+</span> 

              <h3 style={{ fontSize: '20px' ,fontWeight:'100'}}>Courses</h3>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="counter-count">
              <div>
                <img src={experienceIcon} alt="Experience Icon" height="50px" />
              </div>
              <span className="count" style={{ display: 'inline',fontSize:'60px' }}>
                <CountUp start={0} end={50} duration={4} />
              </span>
              <span style={{ display: 'inline', marginLeft: '5px' ,color:'white', fontSize:'60px' }}>+</span> 
              <h3 style={{ fontSize: '20px',fontWeight:'100'}}>Corporate Partnership</h3>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3">
            <div className="counter-count">
              <div>
                <img src={interiorDesignIcon} alt="Branches Icon" height="50px" />
              </div>
              <span className="count" style={{ display: 'inline',fontSize:'60px' }}>
                <CountUp start={0} end={1} duration={5} />
              </span>
              {/* <span style={{ display: 'inline', marginLeft: '5px' ,color:'white',  fontSize:'60px'}}>+</span>  */}
              <h3 style={{ fontSize: '20px',fontWeight:'100'}}>Branches</h3>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="counter-count">
              <div>
                <img src={recommendIcon} alt="Experience Icon" height="50px" />
              </div>
              <span className="count" style={{ display: 'inline',fontSize:'60px' }}>
                <CountUp start={0} end={1000} duration={3} />
              </span>
              <span style={{ display: 'inline', marginLeft: '5px' ,color:'white', fontSize:'60px' }}>+</span> 
              <h3 style={{ fontSize: '20px',fontWeight:'100' }}>Placements</h3>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Stack;
