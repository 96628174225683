
// import React, { useState } from 'react';

// const LearningPathTFD = () => {
//     const [toggle, setToggle] = useState(null);
//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         phone: ""
//     });

//     const updateToggle = (id) => {
//         setToggle(toggle === id ? null : id);
//     };

//     const handleChange = (e) => {
//         const { id, value } = e.target;
//         setFormData((prevState) => ({
//             ...prevState,
//             [id]: value
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const apiBody = {
//             firstName: formData.name,
//             emailId: formData.email,
//             contactNumber: formData.phone,
//         };

//         try {
//             const response = await fetch("http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact", {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json"
//                 },
//                 body: JSON.stringify(apiBody)
//             });

//             if (response.ok) {
//                 alert("Your details have been submitted successfully!");
//             } else {
//                 alert("There was an issue submitting your details.");
//             }
//         } catch (error) {
//             alert("Something went wrong. Please try again later.");
//         }
//     };

//     return (
//         <div className="container my-5">
//             <div className="row justify-content-center">
//                 {/* 11 column container */}
//                 <div >
//                     {/* Heading with left-alignment */}


//                     <div className="row">

//                         {/* Learning Path Section */}
//                         <div className="col-lg-7 col-md-12" style={{paddingLeft:'30px'}}>
//                             <h1 className="text-left mb-4 mt-5">Tools & Fixture Design <span style={{ color: '#ffa500', fontWeight: '700' }}>Course Details</span></h1>
//                             <p className="text-left mb-5">The Tools & Fixture Design course is meticulously crafted to prepare aspiring engineers and professionals to excel in the manufacturing industry. This program focuses on BIW (Body in White) fixtures and delivers a holistic learning experience by combining theoretical principles and practical applications.
//                             </p>
//                             <h2 className="fw-bold mb-4" style={{ color: 'rgb(34 64 153)' }}>Learning Path</h2>
//                             <div className="list-tab">
//                                 {[
//                                     { id: 1, title: "Introduction to BIW Fixture Design", content: "BIW (Body in White) Fixture Design considerations in both 3D and 2D. It’s essential to focus on the main design elements that influence both visual clarity and functional efficiency." },

//                                     { id: 2, title: "Concept Design", content: "In BIW fixture design, several key concepts are involved, as fixtures play a crucial role in supporting, positioning, and clamping various components of a vehicle's body during the manufacturing process.  This includes a brief explanation about clamps, pins, slide and dump units, cylinder calculations, gripper design, and gripper validation." },
//                                     { id: 3, title: "3D Finish", content: "In BIW fixture design, achieving a 3D finish typically refers to ensuring that the fixture not only meets functional requirements (alignment, clamping, support, etc.) but also supports the aesthetic or dimensional accuracy needed for the car's final body panel surfaces. The 3D finish is crucial for precision and quality in the assembly process." },
//                                     { id: 4, title: "2D Detailing", content: "2D manufacturing drawings are essential documents used in the design, fabrication, and assembly of mechanical components, structures, and systems. These drawings provide a detailed view of a part or product, often showing multiple views and dimensions, material specifications, and other essential information required for manufacturing and the Bill of Materials (BOM)." },
//                                     { id: 5, title: "Tool Test", content: "In this tool test, we will conduct assessments on Concept, 3D Finish, and 2D Detailing." }
//                                 ].map(({ id, title, content }) => (
//                                     <div key={id} className="mb-3">
//                                         <div
//                                             className="p-3 bg-light rounded cursor-pointer"
//                                             onClick={() => updateToggle(id)}
//                                         >
//                                             <span className="fw-bold">{toggle === id ? '-' : '+'} {title}</span>
//                                         </div>
//                                         {toggle === id && (
//                                             <div className="mt-2 px-4">
//                                                 <p>{content}</p>
//                                             </div>
//                                         )}
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>

//                         {/* Contact Us Section (Toll-Free Number) */}
//                         <div className="col-md-5">
//                             <div className="p-4 bg-light shadow rounded mt-4">
//                                 <h5 className="fw-bold">Request More Information</h5>
//                                 <form onSubmit={handleSubmit}>
//                                     {["name", "email", "phone"].map((field, idx) => (
//                                         <div className="mb-3" key={idx}>
//                                             <input
//                                                 type={field === "email" ? "email" : "text"}
//                                                 id={field}
//                                                 className="form-control"
//                                                 placeholder={`Enter your ${field}`}
//                                                 value={formData[field]}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                     ))}
//                                     <div className="mb-3">
//                                         <label className="form-label">Inquiry For</label>
//                                         <div>
//                                             <div className="form-check form-check-inline">
//                                                 <input className="form-check-input" type="checkbox" id="myself" />
//                                                 <label className="form-check-label" htmlFor="myself">Myself</label>
//                                             </div>
//                                             <div className="form-check form-check-inline">
//                                                 <input className="form-check-input" type="checkbox" id="company" />
//                                                 <label className="form-check-label" htmlFor="company">My Company</label>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="mb-3">
//                                         <input
//                                             type="number"
//                                             className="form-control"
//                                             id="experience"
//                                             placeholder="Enter years of experience"
//                                         />
//                                     </div>
//                                     <button type="submit" className="btn btn-primary w-100">Submit</button>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LearningPathTFD;









































import React, { useState } from 'react';
import robotics from "../img/robotics hands working.mp4";

const LearningPathTFD = () => {
    const [toggle, setToggle] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const updateToggle = (id) => {
        setToggle(toggle === id ? null : id);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiBody = {
            firstName: formData.name,
            emailId: formData.email,
            contactNumber: formData.phone,
        };

        try {
            const response = await fetch(
                "http://10.10.20.9:7071/IIA-Website-1.0.0/contactus/contact",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(apiBody),
                }
            );

            if (response.ok) {
                alert("Your details have been submitted successfully!");
            } else {
                alert("There was an issue submitting your details.");
            }
        } catch (error) {
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    {/* <div className="p-4 bg-white shadow rounded mb-4">
                        <h2 style={{ fontWeight: "bold" }}>
                            Tools & Fixture Designs{" "}
                            <span style={{ color: "#ffa500", fontWeight: "800", fontFamily: 'Roboto' }}>
                                Course Details
                            </span>
                        </h2>
                        <p style={{ fontFamily: 'Roboto' }}>
                            This course is tailored for aspiring engineers and tech enthusiasts eager to explore robotics in a virtual environment. It covers robotic simulation, and control systems with hands-on projects to build practical skills. Ideal for students and professionals aiming to excel in robotics and automation industries.
                        </p>
                    </div> */}


                </div>

                <div className="col-lg-4">
                    {/* <div>
                        <video
                            src={robotics}
                            controls
                            style={{
                                width: '100%',
                                borderRadius: '10px',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                                marginBottom: '20px',
                            }}
                        />
                    </div> */}

                    {/* <div
                        style={{
                            width: '100%',
                            minHeight: '500px',
                            maxHeight: '90vh',
                            overflowY: 'auto',
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                            borderRadius: '10px',
                            backgroundColor: '#fff',
                        }}
                      >
                        <h2 style={{ textAlign: 'center', width: '100%', fontFamily: 'Roboto' }}>Enquiry Form</h2>
                        <p style={{ marginBottom: '30px', fontFamily: 'Roboto' }}>Fill in your details and we’ll get back to you shortly.</p>

                        <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone Number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                                style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                            <div style={{ marginBottom: '10px' }}>
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                    required
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                            <div style={{ marginBottom: '10px' }}>
                                <textarea
                                    placeholder="Your Message"
                                    rows="4"
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                ></textarea>
                            </div>

                            <button
                                type="submit"
                                style={{
                                    width: '100%',
                                    padding: '12px',
                                    backgroundColor: '#495057',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                }}
                            >
                                Submit
                            </button>
                        </form>
                    </div> */}
                </div>
                <h2 className="fw-bold mb-4" style={{ color: '#000', fontFamily: 'Roboto', width: '100%', textAlign: 'center' }}>
                    Learning <span style={{color:'rgb(251 145 0)'}}>Path</span> 
                </h2>

                <div className="list-tab" style={{ width: '100%', padding: 0 }}>
                    {[{
                        id: 1, title: "Introduction to BIW Fixture Design", content: "BIW (Body in White) Fixture Design considerations in both 3D and 2D. It’s essential to focus on the main design elements that influence both visual clarity and functional efficiency."
                    }, {
                        id: 2, title: "Concept Design", content: "In BIW fixture design, several key concepts are involved, as fixtures play a crucial role in supporting, positioning, and clamping various components of a vehicle's body during the manufacturing process. This includes a brief explanation about clamps, pins, slide and dump units, cylinder calculations, gripper design, and gripper validation."
                    }, {
                        id: 3, title: "3D Finish", content: "In BIW fixture design, achieving a 3D finish typically refers to ensuring that the fixture not only meets functional requirements (alignment, clamping, support, etc.) but also supports the aesthetic or dimensional accuracy needed for the car's final body panel surfaces."
                    }, {
                        id: 4, title: "2D Detailing", content: "2D manufacturing drawings are essential documents used in the design, fabrication, and assembly of mechanical components, structures, and systems. These drawings provide a detailed view of a part or product, often showing multiple views and dimensions, material specifications, and other essential information required for manufacturing and the Bill of Materials (BOM)."
                    }, {
                        id: 5, title: "Tool Test", content: "In this tool test, we will conduct assessments on Concept, 3D Finish, and 2D Detailing."
                    }].map(({ id, title, content }) => (
                        <div key={id} className="mb-3" style={{ width: '100%' }}>
                            <div
                                className="p-3 bg-light rounded cursor-pointer"
                                onClick={() => updateToggle(id)}
                                style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}
                            >
                                <span className="fw-bold" style={{ fontFamily: 'Roboto',fontSize:'20px' }}>{toggle === id ? '-' : '+'} {title}</span>
                            </div>
                            {toggle === id && (
                                <div className="mt-2 px-4">
                                    <p style={{ fontFamily: 'Roboto' }}>{content}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default LearningPathTFD;
