



// import React, { useState } from 'react';
// import bannerImage from "../img/virtualroboticsbanners/V6 copy.jpg"; 
// import './BtnPage.css';
// import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';
// import { useNavigate } from 'react-router-dom';
// const VirtualroboticscertificationBanner = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     date: '',
//     gender: '',
//     message: '',
//   });
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//   const handleEnrollClick = () => {
//     setShowPopup(true);
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted:', formData);
//     // Add further logic for submission if needed (e.g., sending data to an API)
   
//     closePopup();
//   };

//   const navigate = useNavigate();
//   const handleRedirect = () => {
//     navigate('/login-form'); // Replace '/contact' with your target route
//   };


//   return (
//     <div className="hero-area overly-style-1 opacity-point-4">

//       <img
//         className="banner-image  bddgh"
//         src={bannerImage}
//         alt="About AA Applications Banner"
//         style={{ width: '100%', height: '600px' }}
//       />

//       <div style={{ color: '#fff', position: 'absolute',  textAlign: 'left' }} className='about-databanner datatotal'>


//         <h1 style={{ fontWeight: '700', padding: '10px',marginLeft:'70px',fontSize:'40px' }} className="bannerhead">
//         Internship on <span style={{ fontSize: '40px', color: '#fb9100',fontSize:'50px' }}>Virtual Robotics</span>
//         </h1>



//         <div style={{marginTop:'80px' }} className='workshop-data'>
//           {/* <p style={{ textAlign: 'left', fontSize: '30px', fontWeight: '900',marginLeft:'19px' }}>We Provide :</p> */}

//           <ul style={{ fontSize: '20px', fontWeight:"600" }}>
//             <li>Explore the future of automation through advanced virtual robotics techniques.</li>
//             <li>Master robotic simulation for efficient and precise process optimization.</li>
//             <li>Gain expertise in designing, testing, and implementing robotic systems.</li>
//             <li>Build hands-on skills with cutting-edge virtual robotics software.</li>
//             <li>Learn to optimize workflows for cost-effective automation solutions. </li>
//             <li>Empower your career in the rapidly evolving robotics and automation industry.  </li>
//           </ul>
//         </div>

//         <div
//           className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrd"
//           style={{ width: '72%',marginTop:'70px' }}
//          >
//           <div className="col-auto">
//           <button
//               type="button"
//               className="btn brittle ps-4 pe-4 custom-button"
//               style={{ marginTop: '20px', backgroundColor: '#224099', color: 'white', }}
//               onClick={() => window.open('https://dashboard.iiautomate.com/', '_blank')}
//              >
//               Enquiry
//             </button>
//           </div>
//           <div className="col-auto">
//             <button
//               type="button"
//               className="btn btn-primary brittle  ps-4 pe-4 custom-button"
//               style={{ marginTop: '20px', backgroundColor: 'rgb(34, 64, 153)' }}
//               onClick={() => {
//                 const link = document.createElement('a');
//                 link.href = brochure;
//                 link.download = 'Brochure.pdf';
//                 link.click();
//               }}
//             >
//               Download Brochure
//             </button>
//           </div>
//         </div>
//           {/* Popup Component */}
//           {showPopup && (
//           <>
//             <div
//               style={{
//                 position: 'fixed',
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 backgroundColor: 'white',
//                 padding: '20px',
//                 boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
//                 zIndex: 1000,
//                 borderRadius: '10px',
//                 width: '500px', // Increased width
//               }}
//             >
//               <div style={{ textAlign: 'right', marginBottom: '10px' }}>
//                 <span
//                   style={{
//                     fontSize: '24px',
//                     cursor: 'pointer',
//                     color: '#555',
//                   }}
//                   onClick={closePopup}
//                 >
//                   ✖
//                 </span>
//               </div>

//               <h3 style={{ textAlign: 'center' }}>Enrollment Details</h3>
//               <p style={{ textAlign: 'center' }}>Please fill out the form to enroll.</p>

//               {/* Registration Form */}
//               <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} onSubmit={handleSubmit}>
//                 <input
//                   type="text"
//                   name="name"
//                   placeholder="Name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   required
//                   style={{
//                     width: '100%',
//                     padding: '10px',
//                     fontSize: '16px',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                   }}
//                 />
//                 <input
//                   type="email"
//                   name="email"
//                   placeholder="Email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                   style={{
//                     width: '100%',
//                     padding: '10px',
//                     fontSize: '16px',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                   }}
//                 />
//                 <input
//                   type="tel"
//                   name="phone"
//                   placeholder="Phone Number"
//                   value={formData.phone}
//                   onChange={handleChange}
//                   required
//                   style={{
//                     width: '100%',
//                     padding: '10px',
//                     fontSize: '16px',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                   }}
//                 />
//                 <input
//                   type="date"
//                   name="date"
//                   value={formData.date}
//                   onChange={handleChange}
//                   required
//                   style={{
//                     width: '100%',
//                     padding: '10px',
//                     fontSize: '16px',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                   }}
//                 />
//                 <select
//                   name="gender"
//                   value={formData.gender}
//                   onChange={handleChange}
//                   required
//                   style={{
//                     width: '100%',
//                     padding: '10px',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                   }}
//                 >
//                   <option value="">Select Gender</option>
//                   <option value="male">Male</option>
//                   <option value="female">Female</option>
//                   <option value="other">Other</option>
//                 </select>
//                 <textarea
//                   name="message"
//                   placeholder="Your Message"
//                   rows="4"
//                   value={formData.message}
//                   onChange={handleChange}
//                   style={{
//                     width: '100%',
//                     padding: '10px',
//                     borderRadius: '5px',
//                     border: '1px solid #ccc',
//                     height: '50px',
//                   }}
//                 ></textarea>
//                 <button
//                   type="submit"
//                   style={{
//                     width: '100%',
//                     padding: '12px',
//                     backgroundColor: '#495057',
//                     color: '#fff',
//                     border: 'none',
//                     borderRadius: '5px',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     textAlign: 'center',
//                   }}
//                 >
//                   Submit
//                 </button>
//               </form>
//             </div>

//             {/* Overlay Background */}
//             <div
//               style={{
//                 position: 'fixed',
//                 top: 0,
//                 left: 0,
//                 width: '100%',
//                 height: '100%',
//                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                 zIndex: 999,
//               }}
//               onClick={closePopup}
//             />
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default VirtualroboticscertificationBanner;




// import React, { useState, useEffect } from 'react';
// import bannerImage from "../img/virtualroboticsbanners/V6 copy.jpg"; 
// import './BtnPage.css';
// import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';
// import { useNavigate } from 'react-router-dom';

// const VirtualroboticscertificationBanner = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     date: '',
//     gender: '',
//     message: '',
//   });

//   const [daysLeft, setDaysLeft] = useState(7); // Countdown timer for the offer

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setDaysLeft((prevDays) => (prevDays > 1 ? prevDays - 1 : 7)); // Reset to 7 days after reaching 0
//     }, 24 * 60 * 60 * 1000); // Decrease every 24 hours

//     return () => clearInterval(interval); // Cleanup interval on unmount
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleEnrollClick = () => {
//     setShowPopup(true);
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted:', formData);
//     closePopup();
//   };

//   const navigate = useNavigate();
//   const handleRedirect = () => {
//     navigate('/login-form');
//   };

//   return (
//     <div className="hero-area overly-style-1 opacity-point-4">
//       <img
//         className="banner-image  bddgh"
//         src={bannerImage}
//         alt="About AA Applications Banner"
//         style={{ width: '100%', height: '600px' }}
//       />

//       {/* Transparent Offer Box */}
//       <div
//         style={{
//           position: 'absolute',
//           top: '20px',
//           right: '20px',
//           backgroundColor: 'rgba(0, 0, 0, 0.7)',
//           padding: '20px',
//           borderRadius: '10px',
//           color: 'white',
//           textAlign: 'center',
//           width: '300px',
//         }}
//       >
//         <h4 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
//           Special Offer
//         </h4>
//         <p style={{ fontSize: '16px', marginBottom: '10px' }}>Limited Time Offer</p>
//         <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#fb9100' }}>{daysLeft} days left</p>
//         <button
//           style={{
//             marginTop: '10px',
//             padding: '10px 20px',
//             backgroundColor: '#fb9100',
//             border: 'none',
//             borderRadius: '5px',
//             color: 'white',
//             fontSize: '14px',
//             cursor: 'pointer',
//           }}
//           onClick={handleEnrollClick}
//         >
//           Join Now
//         </button>
//       </div>

//       <div
//         style={{ color: '#fff', position: 'absolute', textAlign: 'left' }}
//         className='about-databanner datatotal'
//       >
//         <h1 style={{ fontWeight: '700', padding: '10px', marginLeft: '70px', fontSize: '40px' }} className="bannerhead">
//           Internship on <span style={{ fontSize: '40px', color: '#fb9100', fontSize: '50px' }}>Virtual Robotics</span>
//         </h1>

//         <div style={{ marginTop: '80px' }} className='workshop-data'>
//           <ul style={{ fontSize: '20px', fontWeight: "600" }}>
//             <li>Explore the future of automation through advanced virtual robotics techniques.</li>
//             <li>Master robotic simulation for efficient and precise process optimization.</li>
//             <li>Gain expertise in designing, testing, and implementing robotic systems.</li>
//             <li>Build hands-on skills with cutting-edge virtual robotics software.</li>
//             <li>Learn to optimize workflows for cost-effective automation solutions. </li>
//             <li>Empower your career in the rapidly evolving robotics and automation industry. </li>
//           </ul>
//         </div>

//         <div
//           className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrd"
//           style={{ width: '72%', marginTop: '70px' }}
//         >
//           <div className="col-auto">
//             <button
//               type="button"
//               className="btn brittle ps-4 pe-4 custom-button"
//               style={{ marginTop: '20px', backgroundColor: '#224099', color: 'white', }}
//               onClick={() => window.open('https://dashboard.iiautomate.com/', '_blank')}
//             >
//               Enquiry
//             </button>
//           </div>
//           <div className="col-auto">
//             <button
//               type="button"
//               className="btn btn-primary brittle ps-4 pe-4 custom-button"
//               style={{ marginTop: '20px', backgroundColor: 'rgb(34, 64, 153)' }}
//               onClick={() => {
//                 const link = document.createElement('a');
//                 link.href = brochure;
//                 link.download = 'Brochure.pdf';
//                 link.click();
//               }}
//             >
//               Download Brochure
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VirtualroboticscertificationBanner;





import React, { useState, useEffect } from 'react';
import bannerImage from "../img/virtualroboticsbanners/V6 copy.jpg"; 
import './BtnPage.css';
import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';
import { useNavigate } from 'react-router-dom';

const VirtualroboticscertificationBanner = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    gender: '',
    message: '',
  });

  const [daysLeft, setDaysLeft] = useState(7); // Countdown timer for the offer
  const [timeLeft, setTimeLeft] = useState({
    days: 7,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [expiryDate, setExpiryDate] = useState(null);

  useEffect(() => {
    // Set the expiry date 7 days from the current time
    const currentDate = new Date();
    const expiry = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    setExpiryDate(expiry);

    // Update the countdown every second
    const interval = setInterval(() => {
      const now = new Date();
      const remainingTime = expiry - now; // Calculate remaining time

      if (remainingTime <= 0) {
        clearInterval(interval); // Stop when the expiry date has passed
      } else {
        const remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const remainingHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const remainingMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const remainingSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setTimeLeft({
          days: remainingDays,
          hours: remainingHours,
          minutes: remainingMinutes,
          seconds: remainingSeconds,
        });
      }
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEnrollClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
    closePopup();
  };

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/login-form');
  };

  return (
    <div className="hero-area overly-style-1 opacity-point-4">
      <img
        className="banner-image  bddgh"
        src={bannerImage}
        alt="About AA Applications Banner"
        style={{ width: '100%', height: '600px' }}
      />

      {/* Transparent Offer Box */}
      <div
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '20px',
          borderRadius: '10px',
          color: 'white',
          textAlign: 'center',
          width: '300px',
        }}
      >
        <h4 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>
          Special Offer
        </h4>
        <p style={{ fontSize: '16px', marginBottom: '10px' }}>Limited Time Offer</p>
        <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#fb9100' }}>
          {timeLeft.days} d, {timeLeft.hours} h, {timeLeft.minutes} m, {timeLeft.seconds} s
        </p>
        <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px', color: '#fb9100' }}>
          Expiry Date: {expiryDate && expiryDate.toLocaleString()}
        </p>
        <button
          style={{
            marginTop: '10px',
            padding: '10px 20px',
            backgroundColor: '#fb9100',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
            fontSize: '14px',
            cursor: 'pointer',
          }}
          onClick={handleEnrollClick}
        >
          Join Now
        </button>
      </div>

      <div
        style={{ color: '#fff', position: 'absolute', textAlign: 'left' }}
        className='about-databanner datatotal'
      >
        <h1 style={{ fontWeight: '700', padding: '10px', marginLeft: '70px', fontSize: '40px' }} className="bannerhead">
          Internship on <span style={{ fontSize: '40px', color: '#fb9100', fontSize: '50px' }}>Virtual Robotics</span>
        </h1>

        <div style={{ marginTop: '80px' }} className='workshop-data'>
          <ul style={{ fontSize: '20px', fontWeight: "600" }}>
            <li>Explore the future of automation through advanced virtual robotics techniques.</li>
            <li>Master robotic simulation for efficient and precise process optimization.</li>
            <li>Gain expertise in designing, testing, and implementing robotic systems.</li>
            <li>Build hands-on skills with cutting-edge virtual robotics software.</li>
            <li>Learn to optimize workflows for cost-effective automation solutions. </li>
            <li>Empower your career in the rapidly evolving robotics and automation industry. </li>
          </ul>
        </div>

        <div
          className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrd"
          style={{ width: '72%', marginTop: '70px' }}
        >
          <div className="col-auto">
            <button
              type="button"
              className="btn brittle ps-4 pe-4 custom-button"
              style={{ marginTop: '20px', backgroundColor: '#224099', color: 'white', }}
              onClick={() => window.open('https://dashboard.iiautomate.com/', '_blank')}
            >
              Enquiry
            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn btn-primary brittle ps-4 pe-4 custom-button"
              style={{ marginTop: '20px', backgroundColor: 'rgb(34, 64, 153)' }}
              onClick={() => {
                const link = document.createElement('a');
                link.href = brochure;
                link.download = 'Brochure.pdf';
                link.click();
              }}
            >
              Download Brochure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualroboticscertificationBanner;
