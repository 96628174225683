// // Import React and the image
// import React from "react";
// import bannerImage from "../img/banner/online-robotics.jpg"; 
// import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';
// import './BtnPage.css'; 
// const DigitalFacilityBanner = () => {
//   const bannerStyle = {
//     height: "100vh", // Full screen height
//     width: "100%", // Full width
//     display: "flex", // Flexbox for centering content
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundImage: `url(${bannerImage})`, // Use imported image
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     position: "relative",
//     overflow: "hidden",
//   };

//   const overlayStyle = {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     // Adjust opacity for contrast
//     zIndex: 1,
//   };

//   const contentStyle = {
//     position: "relative",
//     zIndex: 2, // Ensure it is above the overlay
//     textAlign: "center",
//     color: "#fff", // White text for contrast
//   };

//   const headingStyle = {
//     fontSize: "3rem",
//     margin: 0,
//   };

//   const paragraphStyle = {
//     fontSize: "1.5rem",
//     margin: "10px 0 20px",
//   };

//   const buttonStyle = {
//     display: "inline-block",
//     padding: "10px 20px",
//     fontSize: "1rem",
//     color: "#fff",
//     backgroundColor: "#ff5722", // Button color
//     border: "none",
//     borderRadius: "5px",
//     textDecoration: "none",
//     cursor: "pointer",
//     transition: "background-color 0.3s",
//   };

//   const buttonHoverStyle = {
//     backgroundColor: "#e64a19", // Hover color
//   };

//   return (
//     <div style={bannerStyle} className="banner-image">
//       <div style={overlayStyle}></div>
//       <div style={contentStyle}>
//         <div className="row">
//           <div className="col-lg-8 bannerheadtwo" style={{ padding: '10px' }}>
//             <div>
//               <h1 style={{ fontWeight: '700', padding: '10px', color: 'black', width: '106%' }} className="bannerhead">
//                 Certification on <span style={{fontSize:'40px',color:'#fb9100'}}>Digital Factory Solution</span> 
//               </h1>
//             </div>
//             <div className="under-the-line"></div>
//             <div className="bannerhaedthreeone">
//               {/* <h4 className="bannerhaedthree mb-3" style={{ width: '80%' }}>
//                       Gain Your Certificate With Envision And I-Robotics
//                     </h4> */}
//               <p style={{ fontSize: '21px', color: '#000' }} className="banner-boostdatadf">
//                 Unlock the potential of cutting-edge technology in facilities management with the Certification in Digital Facilities Solution by the International Institute of Automate. This program equips professionals with in-depth knowledge and practical skills to implement and manage digital solutions in modern facilities.
//               </p>
//             </div>
//             <div
//               className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrd"
//               style={{ width: '72%' }}
//             >
//               <div className="col-auto">
//                 <button
//                   type="button"
//                   className="btn brittle ps-4 pe-4 custom-button"
//                   style={{ marginTop: '20px',backgroundColor:'black' }}
//                 >
//                   Enroll now
//                 </button>
//               </div>
//               <div className="col-auto">
//                 <button
//                   type="button"
//                   className="btn brittle ps-4 pe-4 custom-button"
//                   style={{ marginTop: '20px',backgroundColor:'black'}}
//                   onClick={() => {
//                     const link = document.createElement('a');
//                     link.href = brochure;
//                     link.download = 'Brochure.pdf';
//                     link.click();
//                   }}
//                 >
//                   Download Brochure
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <style>
//         {`
//         .custom-button {
//           background-color: #0d3c6e; /* Base color for contrast */
//           color: white; /* Text color */
//           border: none;
//           padding: 10px 20px;
//           border-radius: 5px;
//           transition: all 0.3s ease;
//           }

//         .custom-button:hover {
//           background-color: #075ab3; /* Darker blue on hover */
//           color: #ffffff; /* Ensure text remains visible */
//           transform: scale(1.05); /* Slight scaling for effect */
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
//         }

//         .custom-button:active {
//           transform: scale(0.98); /* Slight compression on click */
//           box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow */
//         }

//         `}
//       </style>
//     </div>
//   );
// };

// export default DigitalFacilityBanner;





















import React from 'react';
import bannerImage from "../img/banner/online-robotics.jpg"; 
import './BtnPage.css';
import brochure from '../img/TOOL & FIXURE DESIGN BROCHURE V3.pdf';

const Outdoor = () => {
  return (
    <div className="hero-area overly-style-1 opacity-point-4">

      <img
        className="banner-image  bddgh"
        src={bannerImage}
        alt="About AA Applications Banner"
        style={{ width: '100%', height: '530px' }}
      />

      <div style={{ color: '#fff', position: 'absolute', top: '100px', textAlign: 'left' }} className='about-databanner'>


        <h1 style={{ fontWeight: '700', padding: '10px',color:'black',marginLeft:'70px',fontSize:'40px' }} className="bannerhead">
          Certification on <span style={{ fontSize: '40px', color: '#fb9100',fontSize:'50px' }}>Digital Factory Solution</span>
        </h1>



        <div style={{ marginLeft: '60px' }}>
          {/* <p style={{ textAlign: 'left', fontSize: '30px', fontWeight: '900',color:'black' ,marginLeft:'19px'}}>We Provide :</p> */}

          <ul style={{ fontSize: '20px', fontWeight:"600" }}>
            <li  style={{color:'black'}}>Discover the future of facilities management through digital transformation. </li>
            <li style={{color:'black'}}>Master the integration of Scan-to-3D, BIM, and point cloud technologies.</li>
            <li style={{color:'black'}}>Gain hands-on expertise in implementing advanced digital solutions.</li>
            <li style={{color:'black'}}>Learn to optimize modern facilities with precise, data-driven methods.</li>
            <li style={{color:'black'}}>Enhance operational efficiency and cost management using innovative tools. </li>
            <li style={{color:'black'}}>Prepare for a thriving career in smart infrastructure and digital facility management.  </li>
          </ul>
        </div>

        <div
          className="enroll-download-buttons d-flex flex-column flex-md-row justify-content-center align-items-center buttondownbrochure endbrd"
          style={{ width: '72%' }}
        >
          <div className="col-auto">
            <button
              type="button"
              className="btn brittle ps-4 pe-4 custom-button"
              style={{ marginTop: '20px', backgroundColor: 'black' }}
            >
              <a href="https://dashboard.iiautomate.com/" style={{ color: 'white' }} target='blank'>Enroll now</a>

            </button>
          </div>
          <div className="col-auto">
            <button
              type="button"
              className="btn brittle  ps-4 pe-4 custom-button"
              style={{ marginTop: '20px', backgroundColor: 'black' }}
              onClick={() => {
                const link = document.createElement('a');
                link.href = brochure;
                link.download = 'Brochure.pdf';
                link.click();
              }}
            >
              Download Brochure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outdoor;
